import { InfiniteData, QueryKey, UseInfiniteQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { SortingListItemProps } from '@UiCommon/Components/UiAnalyticalTable/SettingsModal/SortSettings/useSortingSettingsState';
import { ConditionType } from '@UiCommon/Components/UiAnalyticalTable/SettingsModal/types';
import {
  SortingOrder,
  UiAnalyticalTableColumnDefinition,
  UiColumnFiltersType,
} from '@UiCommon/Components/UiAnalyticalTable/types';
import { UiListItemType } from '@UiCommon/Components/UiList/types';
import { MappersParameters } from '@Pages/Shared/SmartComponents/SmartAnalyticalTableCard/Workers/types';

export type PaginationResponse<T> = {
  content: T[];
  currentPage: number;
  totalPages: number;
  totalElements: number;
};

export type CustomPaginatedDataQueryOutput<T = Record<string, unknown>> = UseInfiniteQueryResult<
  InfiniteData<PaginationResponse<T>>,
  unknown
> &
  Partial<PaginationResponse<T>> & {
    mappedData: (T | Record<string, unknown>)[];
    pageParams: CollectionQueryParams;
  };

export type RqHandlerConfigs<T = never> = {
  onSuccess?: () => void;
  onError?: (error?: AxiosError<T>) => void;
};

export type FilterPayloadType = { field: string; conditions: ConditionType[] };

export type SortingPayloadType = { sort: string; order: SortingOrder };

export type CollectionQueryParams = {
  size: number;
  page?: number;
  filter?: FilterPayloadType[];
  sort?: SortingPayloadType[];
};

export type PaginatedRqHookProps<T = Record<string, unknown>> = {
  columns?: UiAnalyticalTableColumnDefinition<T>[];
  size?: number;
  mapper?: (params: MappersParameters) => Record<string, unknown>;
  filters?: UiColumnFiltersType[];
  sorting?: UiListItemType<SortingListItemProps>[];
  enabled?: boolean;
};

export type TablePaginatedQueryProps<T = Record<string, unknown>> = PaginatedRqHookProps<T> & {
  apiCall: (params: PaginatedTableParams) => Promise<PaginationResponse<T>>;
  key: (params: CollectionQueryParams) => QueryKey;
  refetchInterval?: number;
  enabled?: boolean;
};

export type GetBeFieldProps<T = Record<string, unknown>> = {
  columnId: keyof T;
  columns?: UiAnalyticalTableColumnDefinition<T>[];
};

export enum SearchParamsKeys {
  FILTERS = 'filters',
}

export type PaginatedTableParams = {
  tableParams: CollectionQueryParams;
  pageParam: number;
  pathParameter?: string;
};

export type CommonCollectedDto = {
  dataSource: string;
  systemDate: string;
  processId: string;
  enabledFlag: boolean;
  businessValidityFrom: string;
  businessValidityTo: string;
};
