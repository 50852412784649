import { Bar } from '@ui5/webcomponents-react';
import styled from '@emotion/styled';

import { useUiCommonTranslation } from '@GlobalProviders/i18n/hooks';

import { CommonProps } from '../../types';
import { UiButton } from '../UiButton/UiButton';

const StyledBar = styled(Bar)`
  border-top: none;
  padding-right: 0;
`;

export type Props = CommonProps & {
  slot?: string;

  onClose?: () => void;

  startContent?: CommonProps['children'];
  middleContent?: CommonProps['children'];
  endContent?: CommonProps['children'];
};

const SlotWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.25rem;
  gap: 0.25rem;
`;

export const UiDialogFooter = ({
  slot,
  startContent,
  middleContent,
  endContent,
  onClose,
  className,
}: Props) => {
  const { t } = useUiCommonTranslation();

  return (
    <StyledBar
      className={className}
      slot={slot}
      design='Footer'
      startContent={<div>{startContent}</div>}
      endContent={
        <SlotWrapper>
          {endContent}
          {onClose && (
            <UiButton design='Transparent' onClick={onClose}>
              {t('buttons.close')}
            </UiButton>
          )}
        </SlotWrapper>
      }
    >
      {middleContent}
    </StyledBar>
  );
};
