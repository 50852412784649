import { RouteObject } from 'react-router-dom';

import { CustomizationGHGRouting } from '../GHG/CustomizationGHGRouting';
import { CustomizationPRRouting } from '../PR/CustomizationPRRouting';
import { CUSTOMIZATION_ROUTE } from './routes';

export const CustomizationRouting: RouteObject = {
  path: CUSTOMIZATION_ROUTE,
  children: [...CustomizationGHGRouting, ...CustomizationPRRouting],
};
