export default {
  layout: {
    headers: {
      editButtons: {
        edit: 'Edit',
        save: 'Save',
        cancel: 'Cancel',
      },
    },
    navigationPanel: {
      logo: 'SAP Fioneer ESG',
    },
  },
  buttons: {
    close: 'Close',
    back: 'Back',
  },
  sorting: {
    sortBy: 'Sort by: {{value}}',
    order: {
      ASC: 'Ascending',
      DESC: 'Descending',
    },
  },
  settingsModal: {
    title: 'View Settings',
    reset: 'Reset',
    ok: 'Ok',
    cancel: 'Cancel',
    search: 'Search',
    filterBy: 'Filter by',
    tabs: {
      columns: 'Columns',
      sort: 'Sort',
      filter: 'Filter',
    },
    filterModal: {
      title: 'Define Conditions: {{label}}',
      add: 'Add',
      inputPlaceholder: 'Value',
    },
  },
  exportModal: {
    title: 'Export As',
    progressBarTitle: 'Export Document',
    export: 'Export',
    cancel: 'Cancel',
    fileName: 'File Name',
    fileType: 'File Type',
    exportAsOption: 'Export As...',
    csvExtensionOption: '*.csv',
  },
  types: {
    filterConditions: {
      EQUAL: 'equal to',
      NOT_EQUAL: 'not equal to',
      STARTS_WITH: 'begins with',
      NOT_STARTS_WITH: 'does not begin with',
      ENDS_WITH: 'ends with',
      NOT_ENDS_WITH: 'does not end with',
      CONTAINS: 'contains',
      NOT_CONTAINS: 'does not contain',
      GREATER_THAN: 'is greater than',
      GREATER_THAN_OR_EQUAL: 'is greater than or equal to',
      LESS_THAN: 'is less than',
      LESS_THAN_OR_EQUAL: 'is less than or equal to',
    },
    booleanOptions: {
      true: 'Yes',
      false: 'No',
    },
  },
  versionMenu: {
    title: 'Version History',
    noVersions: 'No versions, try different filters',
    isLatest: 'Current',
  },
};
