import { RouteObject } from 'react-router-dom';

import { HIERARCHY_MANAGER_ROUTE } from '@Pages/DataManagement/dmRoutes';

import { PRHierarchyManagerPage } from './PRHierarchyManagerPage';

export const PRHierarchyManagerRouting: RouteObject = {
  path: HIERARCHY_MANAGER_ROUTE,
  element: <PRHierarchyManagerPage />,
};
