import { Navigate, RouteObject } from 'react-router-dom';

import { SplitScopeBuildingPage } from '../Building/SplitScopeBuildingPage';
import { SplitScopeBusinessPartnerPage } from '../BusinessPartner/SplitScopeBusinessPartnerPage';
import { GHGSplitScopePage } from '../GHGSplitScopePage';
import { SplitScopeProjectPage } from '../Project/SplitScopeProjectPage';
import {
  SPLIT_SCOPE_ROUTE,
  SPLIT_SCOPE_BUSINESS_PARTNER_ROUTE,
  SPLIT_SCOPE_BUILDING_ROUTE,
  SPLIT_SCOPE_PROJECT_ROUTE,
} from './routes';

export const SplitScopeRouting: RouteObject = {
  path: SPLIT_SCOPE_ROUTE,
  element: <GHGSplitScopePage />,
  children: [
    {
      index: true,
      element: <Navigate to={SPLIT_SCOPE_BUSINESS_PARTNER_ROUTE} />,
    },
    {
      path: SPLIT_SCOPE_BUILDING_ROUTE,
      element: <SplitScopeBuildingPage />,
    },
    {
      path: SPLIT_SCOPE_BUSINESS_PARTNER_ROUTE,
      element: <SplitScopeBusinessPartnerPage />,
    },
    {
      path: SPLIT_SCOPE_PROJECT_ROUTE,
      element: <SplitScopeProjectPage />,
    },
  ],
};
