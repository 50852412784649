import { useCollectedEmissionBuildingCertificates } from '@DataAccess/GHG/GHGFiles/GHGFiles.hooks';
import { useProcessManagerTranslation } from '@GlobalProviders/i18n/hooks';
import { useSmartAnalyticalTableCard } from '@Pages/Shared/SmartComponents/SmartAnalyticalTableCard/useSmartAnalyticalTableCard';
import { SubPageHeader } from '@UiCommon/Layout/Components/Headers/SubPageHeader';
import { SmartAnalyticalTableCard } from '@Pages/Shared/SmartComponents/SmartAnalyticalTableCard/SmartAnalyticalTableCard';
import { useMultiAppStore } from '@GlobalProviders/MultiApp/MultiAppProvider';

import { useEmissionBuildingCertificatesColumns } from './useEmissionBuildingCertificatesColumns';

export const EmissionBuildingCertificatesPage = () => {
  const { t } = useProcessManagerTranslation();
  const { translatedProductInUse } = useMultiAppStore();

  const TABLE_ID = 'collectedEmissionBuildingCertificates';
  const columns = useEmissionBuildingCertificatesColumns();
  const { sorting, filters, data, fetchNextPage, totalElements, setFilters, setSorting } =
    useSmartAnalyticalTableCard({ columns, queryHook: useCollectedEmissionBuildingCertificates });

  return (
    <>
      <SubPageHeader
        productName={translatedProductInUse}
        title={t('processManager.manualUpload.emissionBuildingCertificates.title')}
        subtitle={t('processManager.manualUpload.emissionBuildingCertificates.subtitle')}
      />
      <SmartAnalyticalTableCard
        columns={columns}
        data={data}
        title={t('processManager.manualUpload.emissionBuildingCertificates.tableTitle', {
          amount: totalElements,
        })}
        onLoadMore={fetchNextPage}
        totalNumberOfItems={totalElements}
        tableId={TABLE_ID}
        hasSettingsModal
        hasExportCSV
        sorting={sorting}
        filters={filters}
        onSorting={setSorting}
        onFilters={setFilters}
        apiPath='ghg.files.getCollectedEmissionBuildingCertificates'
      />
    </>
  );
};
