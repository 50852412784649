import { TitleLevel, VariantManagement } from '@ui5/webcomponents-react';

import { useResizeLoopErrorHandler } from '@Utils/useResizeLoopErrorHandler';

import { CommonProps } from '../../types';
import {
  SaveManageViewsProps,
  useUiVariantManagementCallbacks,
} from './useUiVariantManagementCallbacks';

type Props = CommonProps & {
  level?: TitleLevel;
  disabled?: boolean;
  hideManageVariants?: boolean;
  hideSaveAs?: boolean;
  onSelect?: (name: string) => void;
  onSaveManageViews?: ({
    variants,
    updatedVariants,
    deletedVariants,
    prevVariants,
  }: SaveManageViewsProps) => void;
  onSaveAs?: ({ name, isDefault }: { name: string; isDefault?: boolean }) => void;
  onSave?: (name: string) => void;
  dirtyState?: boolean;
  closeOnItemSelect?: boolean;
};

export const UiVariantManagement = ({
  level = TitleLevel.H5,
  disabled,
  hideManageVariants,
  hideSaveAs,
  children,
  onSaveManageViews,
  onSave,
  onSaveAs,
  onSelect,
  dirtyState,
  closeOnItemSelect = true,
}: Props) => {
  useResizeLoopErrorHandler();
  const { onSaveAsHandler, onSaveManageViewsHandler, onSaveHandler, onSelectHandler } =
    useUiVariantManagementCallbacks({ onSaveAs, onSaveManageViews, onSave, onSelect });

  return (
    <VariantManagement
      showOnlyFavorites
      hideApplyAutomatically
      hideCreatedBy
      hideShare
      level={level}
      closeOnItemSelect={closeOnItemSelect}
      dirtyState={dirtyState}
      disabled={disabled}
      hideManageVariants={hideManageVariants}
      hideSaveAs={hideSaveAs}
      onSave={onSaveHandler as (e: unknown) => void}
      onSaveAs={onSaveAsHandler as (e: unknown) => void}
      onSaveManageViews={onSaveManageViewsHandler as (e: unknown) => void}
      onSelect={onSelectHandler}
    >
      {children}
    </VariantManagement>
  );
};
