import { List } from '@ui5/webcomponents-react';
import { DndContext } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { ReactNode, useState } from 'react';
import { ThemingParameters } from '@ui5/webcomponents-react-base';
import styled from '@emotion/styled';

import { MoveDirection } from '@Types/types';
import { useCustomSensors } from '@Utils/Draggable/useCustomSensors';

import { UiDraggableContainer } from '../UiDraggableContainer/UiDraggableContainer';
import { UiSelectableListItem, UiListItemChangeEvent } from './UiSelectableListItem';
import { useListDragReorderHandler } from './Draggable/useListDragReorderHandler';
import { useListItemMoveHandler } from './Draggable/useListItemMoveHandler';
import { UiListItemType, ListOnChangeEvent, RenderItemProps } from './types';
import { useListSelectionStateHelper } from './useListSelectionStateHelper';
import { CommonProps } from '../../types';

type ListProps<T> = {
  selectableItemClassName?: string;
  isDragAndDropActive?: boolean;
  isSelectionActive?: boolean;
  items?: UiListItemType<T>[];
  isSelectAllActive?: boolean;
  resourceName?: string;
  resourceId?: string;
  selectedItems?: string[];
  onChange?: (event: ListOnChangeEvent) => void;
  onNavigate?: (event: MoveDirection) => void;
  onItemsOrderChange?: (items: UiListItemType<T>[], resourceId?: string) => void;
  onSelectAllChange?: (isSelected: boolean) => void;
  renderItemContent?: (props: RenderItemProps<T>) => ReactNode | ReactNode[];
};

const ListHeader = styled(UiSelectableListItem)`
  position: sticky;
  top: 0;
  z-index: 100;
  background: ${ThemingParameters.sapBaseColor};
`;

const StyledList = styled(List)`
  position: relative;
  overflow-y: auto;
  height: 100%;
`;

const useListItems = <T,>({
  isDragAndDropActive,
  isSelectionActive,
  items = [],
  selectedItems,
  onChange,
  onItemsOrderChange,
  renderItemContent,
  selectableItemClassName,
}: ListProps<T>) => {
  const { selectionSet, onChangeHandler } = useListSelectionStateHelper(selectedItems, onChange);
  const onListItemMoveHandler = useListItemMoveHandler(items, onItemsOrderChange);
  const [focusedItem, setFocusedItem] = useState<string>();

  return {
    Items: items.map((item) => {
      const { value } = item;
      const isSelected = selectionSet.has(value);

      return (
        <UiDraggableContainer key={value} id={value} isDragActive={isDragAndDropActive}>
          <UiSelectableListItem
            className={selectableItemClassName}
            value={value}
            isSelectionActive={isSelectionActive}
            isSelected={isSelected}
            onChange={onChangeHandler}
            onFocus={() => setFocusedItem(value)}
          >
            {renderItemContent?.({
              item,
              isSelected,
              focusedItemValue: focusedItem,
              moveElement: (direction: MoveDirection, element) =>
                onListItemMoveHandler(value, direction, element),
            })}
          </UiSelectableListItem>
        </UiDraggableContainer>
      );
    }),
    values: items.map(({ value }) => value),
  };
};

type Props<T> = CommonProps & ListProps<T>;

export const UiList = <T,>({
  children,
  onSelectAllChange,
  isSelectAllActive,
  resourceName,
  resourceId,
  className,
  ...listProps
}: Props<T>) => {
  const { Items, values } = useListItems<T>(listProps);

  const sensors = useCustomSensors();
  const dragReorderHandler = useListDragReorderHandler(
    listProps.items,
    listProps.onItemsOrderChange,
    resourceId,
  );
  const handleAllSelection = (value: UiListItemChangeEvent) => {
    onSelectAllChange?.(value.isSelected);
  };

  const selectedAmount = listProps?.selectedItems?.length;
  const itemsAmount = listProps?.items?.length;

  return (
    <StyledList className={className}>
      {isSelectAllActive && (
        <ListHeader
          value='allSelection'
          onChange={handleAllSelection}
          isSelected={selectedAmount === itemsAmount}
          isSelectionActive
        >
          {resourceName} ({selectedAmount}/{itemsAmount})
        </ListHeader>
      )}
      <DndContext
        modifiers={[restrictToVerticalAxis]}
        sensors={sensors}
        onDragEnd={dragReorderHandler}
      >
        <SortableContext items={values}>{Items}</SortableContext>
      </DndContext>
      {children}
    </StyledList>
  );
};
