import { ActionSheet } from '@ui5/webcomponents-react';

import { CommonProps } from '../types';

type Props = CommonProps & {
  openerId: string;
  open?: boolean;
  onAfterClose?: () => void;
};

export const UiActionSheet = ({ children, openerId, className, onAfterClose, open }: Props) => (
  <ActionSheet opener={openerId} open={open} className={className} onAfterClose={onAfterClose}>
    {children as JSX.Element}
  </ActionSheet>
);
