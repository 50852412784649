import styled from '@emotion/styled';
import React from 'react';
import { ThemingParameters } from '@ui5/webcomponents-react-base';

import { useSharedTranslation } from '@GlobalProviders/i18n/hooks';
import { UiTitle } from '@UiCommon/Components';
import { EntityType } from '@DataAccess/Platform/FileUpload/fileUpload.types';

import { UploadFileCell } from './UploadFileCell';

const Table = styled.div`
  display: table;
  width: 100%;
  table-layout: fixed;
  background: ${ThemingParameters.sapBaseColor} !important;
  overflow: auto;
  margin: 0;
  font-size: ${ThemingParameters.sapFontSize} !important;
  border: 1px solid ${ThemingParameters.sapList_BorderColor};
`;

const TableRow = styled.div`
  display: table-row;
  background: ${ThemingParameters.sapBaseColor} !important;
`;

const TableCell = styled.div`
  display: table-cell;
  padding: 10px;
  border-bottom: 1px solid ${ThemingParameters.sapList_BorderColor} !important;

  &:nth-of-type(odd) {
    width: 34rem;
  }
  &:nth-of-type(even) {
    width: 19rem;
    border-left: 1px solid ${ThemingParameters.sapList_BorderColor} !important;
  }
`;

type Props = {
  counterPoint?: number;
  entities: Array<{
    title: string;
    data: EntityType[];
  }>;
};

export const UploadDialogTable = ({ entities, counterPoint = 0 }: Props) => {
  const { t } = useSharedTranslation();

  return (
    <Table>
      {entities.map((section, index) => (
        <React.Fragment key={index + section.title}>
          <TableRow>
            <TableCell>
              <UiTitle level='H6'>
                {index + counterPoint + 1}. {section.title}
              </UiTitle>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
          {section.data.map((item, itemIndex) => (
            <TableRow key={itemIndex + item}>
              <TableCell>{t(`types.entityType.${item}`)}</TableCell>
              <TableCell>
                <UploadFileCell entity={item} />
              </TableCell>
            </TableRow>
          ))}
        </React.Fragment>
      ))}
    </Table>
  );
};
