import React, { useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import { PopoverDomRef } from '@ui5/webcomponents-react';
import { ThemingParameters } from '@ui5/webcomponents-react-base';
import { css } from '@emotion/css';

import { IconSet } from '../../../../GlobalProviders/IconSetProvider';
import { FontWeight } from '../../../typography';
import { UiIcon } from '../../UiIcon';
import { UiText } from '../../UiText';
import { useAnalyticalTableContext } from '../AnalyticalTableContext';
import { SettingsTab } from '../SettingsModal/useSettingsModalTabs';
import { SortingOrder } from '../types';
import { HeaderPopover } from './HeaderPopover';

type HeaderProps = {
  title?: string;
  id: string;
  disableSortBy?: boolean;
  disableFilters?: boolean;
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledIcon = styled(UiIcon)`
  color: ${ThemingParameters.sapNeutralColor};
  min-width: 1rem;
`;

const textStyles = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const UiHeader = ({ title, id, disableFilters, disableSortBy }: HeaderProps) => {
  const popoverRef = useRef<PopoverDomRef>(null);
  const { sortingItems, onHeaderSettingsItemClick, onHeaderSortClick } =
    useAnalyticalTableContext();
  const sortingItem = useMemo(() => {
    return sortingItems?.find((item) => item.value === id);
  }, [sortingItems, id]);

  const handleListItemClick = (tab: SettingsTab) => {
    onHeaderSettingsItemClick?.(tab);
    popoverRef?.current?.close();
  };

  const handleSortButtonClick = (order: SortingOrder) => {
    onHeaderSortClick?.(id, order);
    popoverRef?.current?.close();
  };

  return (
    <Wrapper
      data-subcomponent-active-element
      onClick={(e) => {
        popoverRef?.current?.showAt(e.target);
      }}
    >
      <UiText className={textStyles} weight={FontWeight.BOLD}>
        {title}
      </UiText>
      {sortingItem && onHeaderSortClick && (
        <StyledIcon
          name={
            sortingItem.data?.order === SortingOrder.ASC
              ? IconSet.SORT_ASCENDING
              : IconSet.SORT_DESCENDING
          }
        />
      )}
      {title && onHeaderSettingsItemClick && (
        <HeaderPopover
          handleListItemClick={handleListItemClick}
          label={title}
          id={id}
          onSortClick={handleSortButtonClick}
          ref={popoverRef}
          disableFilters={disableFilters}
          disableSortBy={disableSortBy}
        />
      )}
    </Wrapper>
  );
};
