import { css } from '@emotion/css';
import { ComponentProps } from 'react';
import { ThemingParameters } from '@ui5/webcomponents-react-base';

import { UiCard } from './UiCard';

type UiTableCardProps = ComponentProps<typeof UiCard>;

const cardBodyStyles = css`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${ThemingParameters.sapList_BorderColor};
`;

export const UiTableCard = ({ children, ...props }: UiTableCardProps) => (
  <UiCard bodyClassName={cardBodyStyles} {...props}>
    {children}
  </UiCard>
);
