import { useSharedTranslation } from '@GlobalProviders/i18n/hooks';
import { SmartAnalyticalTableCard } from '@Pages/Shared/SmartComponents/SmartAnalyticalTableCard/SmartAnalyticalTableCard';
import { useSmartAnalyticalTableCard } from '@Pages/Shared/SmartComponents/SmartAnalyticalTableCard/useSmartAnalyticalTableCard';
import { useAcuteQuery } from '@DataAccess/PhysicalRisk/SingleAsset/singleAsset.hooks';

import { usePhysicalAssetAcuteColumns } from './Hooks/Columns/usePhysicalAssetAcuteColumns';

export const PhysicalAssetAcutePage = () => {
  const { t: tShared } = useSharedTranslation();

  const TABLE_ID = 'pa-acute';
  const columns = usePhysicalAssetAcuteColumns();
  const { sorting, filters, data, fetchNextPage, totalElements, setFilters, setSorting } =
    useSmartAnalyticalTableCard({
      columns,
      queryHook: useAcuteQuery,
    });

  return (
    <SmartAnalyticalTableCard
      tableId={TABLE_ID}
      totalNumberOfItems={totalElements}
      title={tShared('dataTables.dataSets', { count: totalElements })}
      columns={columns}
      data={data}
      sorting={sorting}
      filters={filters}
      onFilters={setFilters}
      onSorting={setSorting}
      onLoadMore={fetchNextPage}
      hasViewsManager
      hasSettingsModal
      hasExportCSV
      apiPath='physicalRisk.singleAsset.getAcuteRisks'
    />
  );
};
