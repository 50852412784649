import styled from '@emotion/styled';
import { ThemingParameters } from '@ui5/webcomponents-react-base';
import { css } from '@emotion/css';

import { IconSet } from '@GlobalProviders/IconSetProvider';
import { UiButton, UiIcon, UiText } from '@UiCommon/Components';
import { UiListItemType } from '@UiCommon/Components/UiList/types';
import { FontWeight } from '@UiCommon/typography';
import { useOnHoverElementSupport } from '@Utils/useOnHoverElementSupport';
import { CommonProps } from '@UiCommon/types';

import { DataHierarchyData } from './types';

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 3rem;
  width: 100%;
  gap: 0.375rem;
`;

const OrderNumber = styled.span`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${ThemingParameters.sapBackgroundColor};
`;

const dataSourceTextClass = css`
  overflow: auto;
  text-overflow: ellipsis;
`;

const RightAlignedButton = styled(UiButton)`
  margin-left: auto;
`;

type Props = {
  className: CommonProps['className'];
  item: UiListItemType<DataHierarchyData>;
  onEditSource: (id: string) => void;
  isEditing?: boolean;
};

export const HierarchyItemList = ({ item, isEditing, onEditSource, className }: Props) => {
  const { eventListeners } = useOnHoverElementSupport();
  const isEditable = !item.data?.isDefault;

  return (
    <ContentWrapper className={className} {...eventListeners}>
      <OrderNumber>
        <UiText weight={FontWeight.BOLD}>{item.data?.order}</UiText>
      </OrderNumber>
      <UiText className={dataSourceTextClass} weight={FontWeight.BOLD}>
        {item.label}
      </UiText>
      {isEditing && isEditable && (
        <RightAlignedButton data-no-dnd='true' onClick={() => onEditSource(item.value)}>
          <UiIcon name={IconSet.EDIT} />
        </RightAlignedButton>
      )}
    </ContentWrapper>
  );
};
