import { DatePickerDomRef, Ui5CustomEvent } from '@ui5/webcomponents-react';
import { DatePickerChangeEventDetail } from '@ui5/webcomponents/dist/DatePicker';
import { useCallback } from 'react';

export const useUiDateTimePickerOnChangeHandler = (callback?: (value?: string) => void) =>
  useCallback(
    (event: Ui5CustomEvent<DatePickerDomRef, DatePickerChangeEventDetail>) => {
      if (!callback) return;

      const {
        target: { value },
      } = event;

      const callbackArgument = !value ? undefined : new Date(value).toISOString();

      callback(callbackArgument);
    },
    [callback],
  );
