import { UiButton } from '@UiCommon/Components/UiButton/UiButton';
import { CommonProps } from '@UiCommon/types';
import { useUiCommonTranslation } from '@GlobalProviders/i18n/hooks';

type Props = CommonProps & {
  isEditMode: boolean;
  handleEdit: () => void;
  handleSave: () => void;
  onCancel: () => void;
};

export const HeaderEditButtons = ({ isEditMode, handleEdit, handleSave, onCancel }: Props) => {
  const { t } = useUiCommonTranslation();

  return (
    <>
      {isEditMode && (
        <>
          <UiButton design='Transparent' onClick={onCancel}>
            {t('layout.headers.editButtons.cancel')}
          </UiButton>
          <UiButton design='Emphasized' onClick={handleSave}>
            {t('layout.headers.editButtons.save')}
          </UiButton>
        </>
      )}
      {!isEditMode && (
        <UiButton design='Emphasized' onClick={handleEdit}>
          {t('layout.headers.editButtons.edit')}
        </UiButton>
      )}
    </>
  );
};
