import {
  Popover,
  PopoverDomRef,
  PopoverHorizontalAlign,
  PopoverPlacementType,
  PopoverVerticalAlign,
} from '@ui5/webcomponents-react';
import { forwardRef, Ref } from 'react';

import { CommonProps } from '../types';

type Props = CommonProps & {
  hideArrow?: boolean;
  onAfterClose?: () => void;
  verticalAlign?: PopoverVerticalAlign | keyof typeof PopoverVerticalAlign;
  horizontalAlign?: PopoverHorizontalAlign | keyof typeof PopoverHorizontalAlign;
  placementType?: PopoverPlacementType | keyof typeof PopoverPlacementType;
  allowTargetOverlap?: boolean;
};

export const UiPopover = forwardRef(function UiPopover(
  {
    hideArrow,
    className,
    children,
    onAfterClose,
    horizontalAlign,
    verticalAlign,
    placementType,
    allowTargetOverlap,
  }: Props,
  ref: Ref<PopoverDomRef>,
) {
  return (
    <Popover
      className={className}
      ref={ref}
      allowTargetOverlap={allowTargetOverlap}
      onAfterClose={onAfterClose}
      hideArrow={hideArrow}
      verticalAlign={verticalAlign}
      horizontalAlign={horizontalAlign}
      placementType={placementType}
    >
      {children}
    </Popover>
  );
});
