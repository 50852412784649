import styled from '@emotion/styled';
import { ThemingParameters } from '@ui5/webcomponents-react-base';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

import { useUiCommonTranslation } from '@GlobalProviders/i18n/hooks';
import { UiText } from '@UiCommon/Components';
import { CommonProps } from '@UiCommon/types';

type StyledProps = {
  isSelected?: boolean;
};

export type CommonVersionData = {
  id: string;
  isLatest?: boolean;
};

type VersionOptionProps<T extends CommonVersionData> = {
  data: T;
  renderData: (props: T) => JSX.Element;
  isLatest?: boolean;
};

type Props<T extends CommonVersionData> = CommonProps & StyledProps & VersionOptionProps<T>;

const VersionOption = styled.li<StyledProps>`
  display: grid;
  grid-template-areas: 'data isLatest';
  column-gap: 2.625rem;
  padding: 0.75rem 1rem;
  cursor: pointer;
  background-color: ${({ isSelected }) =>
    isSelected
      ? ThemingParameters.sapList_SelectionBackgroundColor
      : ThemingParameters.sapList_Background};
  border-top: ${ThemingParameters.sapList_BorderWidth} solid
    ${ThemingParameters.sapList_BorderColor};

  &:hover {
    background-color: ${ThemingParameters.sapList_Hover_Background};
  }

  &:last-of-type {
    border-bottom: ${ThemingParameters.sapList_BorderWidth} solid
      ${ThemingParameters.sapList_BorderColor};
  }
`;

const DataGrid = styled.div`
  grid-area: 'data';
`;

const IsLatest = styled(UiText)`
  grid-area: 'isLatest';
`;

export const UiVersionOption = <T extends CommonVersionData>({
  isLatest,
  isSelected,
  renderData,
  data,
}: Props<T>) => {
  const { t } = useUiCommonTranslation();
  const navigate = useNavigate();

  const handleClick = useCallback(
    (id: string) => {
      navigate(id);
    },
    [data.id],
  );

  return (
    <VersionOption onClick={() => handleClick(data.id)} isSelected={isSelected}>
      <DataGrid>{renderData?.(data)}</DataGrid>
      {isLatest && <IsLatest variant='caption2'>{t('versionMenu.isLatest')}</IsLatest>}
    </VersionOption>
  );
};
