import { AxiosInstance } from 'axios';

import { DataQualityOverviewDto, KpiInfoDto } from './dataQuality.types';

export const initialiseDataQualityEndpoints = (api: AxiosInstance) => ({
  getDataQualityOverview(processId?: string) {
    // agreed with business to return empty when no calculations (processId) exist (BaU)
    if (!processId) {
      const output: DataQualityOverviewDto = { scope1: [], scope2: [], scope3: [] };

      return Promise.resolve(output).then((data) => data);
    }

    return api
      .get<DataQualityOverviewDto>('/data-quality/financed-ghg/overview', { params: { processId } })
      .then(({ data }) => data);
  },
  getKpiInfo(processId: string) {
    return api
      .get<KpiInfoDto>('/data-quality/financed-ghg/kpi-info', { params: { processId } })
      .then(({ data }) => data);
  },
});
