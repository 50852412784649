import { SwitchDomRef, Ui5CustomEvent } from '@ui5/webcomponents-react';
import { useCallback } from 'react';

export const useUiSwitchOnChangeHandler = (callback?: (value?: boolean) => void) =>
  useCallback(
    (event: Ui5CustomEvent<SwitchDomRef, never>) => {
      if (!callback) return;
      const {
        target: { checked },
      } = event;
      callback(checked);
    },
    [callback],
  );
