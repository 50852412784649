import { Switch } from '@ui5/webcomponents-react';

import { CommonProps } from '@UiCommon/types';

import { useUiSwitchOnChangeHandler } from './useUiSwitchOnChangeHandler';

type Props = CommonProps & {
  isChecked?: boolean;
  onChange?: (value?: boolean) => void;
};

export const UiSwitch = ({ onChange, isChecked, className }: Props) => {
  const onChangeHandler = useUiSwitchOnChangeHandler(onChange);

  return <Switch className={className} checked={isChecked} onChange={onChangeHandler} />;
};
