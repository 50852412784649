import React, { useState } from 'react';
import styled from '@emotion/styled';
import { ThemingParameters } from '@ui5/webcomponents-react-base';

import { useUiCommonTranslation } from '@GlobalProviders/i18n/hooks';

import { UiText } from '../../../../UiText';
import { UiButton } from '../../../../UiButton/UiButton';
import { SortingOrder } from '../../../types';
import { IconSet } from '../../../../../../GlobalProviders/IconSetProvider';
import { ElementSize } from '../../../../../types';
import { textStyles } from './styles';

const ButtonWrapper = styled.div`
  display: flex;
`;

const StyledButton = styled(UiButton)<{ isActive?: boolean }>`
  ${({ isActive }) =>
    isActive &&
    `
 background: ${ThemingParameters.sapButton_Active_Background};
 box-shadow:${ThemingParameters.sapContent_Interaction_Shadow}; 
 border: 1px solid ${ThemingParameters.sapButton_Active_BorderColor};
 color: ${ThemingParameters.sapButton_Active_TextColor};
  `}
`;

type SortingButtonsProps = {
  disabled?: boolean;
  onChange?: (order: SortingOrder) => void;
  order?: SortingOrder;
};

export const SortingButtons = ({ disabled, onChange, order }: SortingButtonsProps) => {
  const { t } = useUiCommonTranslation();
  const [currentOrder, setCurrentOrder] = useState(order || SortingOrder.ASC);

  const handleButtonClick = (order: SortingOrder) => {
    setCurrentOrder(order);
    onChange?.(order);
  };

  return (
    <>
      <ButtonWrapper data-no-dnd='true'>
        <StyledButton
          isActive={order === SortingOrder.ASC}
          disabled={disabled}
          onClick={() => handleButtonClick(SortingOrder.ASC)}
          size={ElementSize.NORMAL}
          icon={IconSet.SORT_ASCENDING}
        />
        <StyledButton
          isActive={order === SortingOrder.DESC}
          disabled={disabled}
          onClick={() => handleButtonClick(SortingOrder.DESC)}
          size={ElementSize.NORMAL}
          icon={IconSet.SORT_DESCENDING}
        />
      </ButtonWrapper>
      <UiText className={textStyles}>{t(`sorting.order.${currentOrder}`)}</UiText>
    </>
  );
};
