import { useEffect } from 'react';
import { cloneDeep } from 'lodash';

import { toMap } from '@Utils/utils';

import { UiAnalyticalTableColumnDefinition } from '../types';
import { STANDARD_NAME } from './constants';
import { ViewsDictionary, ViewSettings, ViewsShape } from './types';

export type ViewsState<DataType> = {
  views: ViewsDictionary<DataType>;
  defaultView: string;
  selectedView: string;
  settings?: ViewSettings<DataType>;
};

type Props<DataType> = {
  localStorageKey: string;
  defaultColumns: UiAnalyticalTableColumnDefinition<DataType>[];
  onStorageLoad: (viewsState: ViewsState<DataType>) => void;
  loadStandardView?: boolean;
};

const convertStorageColumnsToFullColumnsDefinition = <DataType>(
  viewsDictionary: ViewsDictionary<DataType>,
  columns: UiAnalyticalTableColumnDefinition<DataType>[],
): ViewsDictionary<DataType> => {
  return Object.entries(viewsDictionary).reduce((result, [key, value]) => {
    const columnsMap = toMap(columns, 'id');
    const localStorageColumns = value.settings?.columns || [];

    const matchedColumns = localStorageColumns
      .map((column) => {
        const fullColumnDefinition = columnsMap.get(column.id);
        if (fullColumnDefinition) {
          columnsMap.delete(column.id);
        }

        return (
          fullColumnDefinition && {
            ...fullColumnDefinition,
            isVisible: column.isVisible,
          }
        );
      })
      .filter((val) => !!val) as UiAnalyticalTableColumnDefinition<DataType>[];

    const unmatchedColumns = Array.from(columnsMap.values());

    result[key] = {
      ...value,
      settings: {
        columns: [...matchedColumns, ...unmatchedColumns],
        sorting: value.settings?.sorting || undefined,
        filters: value.settings?.filters || undefined,
      },
    };

    return result;
  }, {} as ViewsDictionary<DataType>);
};

export const useViewsInitializer = <DataType>({
  localStorageKey,
  defaultColumns,
  onStorageLoad,
  loadStandardView,
}: Props<DataType>) => {
  useEffect(() => {
    const localStorageViewsValue = localStorage.getItem(localStorageKey);
    if (localStorageViewsValue) {
      const localStorageValue: ViewsShape<DataType> = JSON.parse(localStorageViewsValue);
      const defaultStorageView = localStorageValue.defaultView;
      const storageStateViews = convertStorageColumnsToFullColumnsDefinition(
        localStorageValue.views,
        defaultColumns,
      );
      onStorageLoad({
        views: storageStateViews,
        defaultView: loadStandardView ? STANDARD_NAME : defaultStorageView,
        selectedView: loadStandardView ? STANDARD_NAME : defaultStorageView,
        settings:
          storageStateViews[loadStandardView ? STANDARD_NAME : defaultStorageView]?.settings,
      });
    } else {
      const defaultVariantItem: ViewsDictionary<DataType> = {
        [STANDARD_NAME]: {
          name: STANDARD_NAME,
          favorite: true,
          readOnly: true,
          labelReadOnly: true,
          global: true,
          settings: cloneDeep({ sorting: [], filters: [], columns: defaultColumns }),
        },
      };
      onStorageLoad({
        views: defaultVariantItem,
        defaultView: STANDARD_NAME,
        selectedView: STANDARD_NAME,
        settings: { columns: defaultColumns },
      });
    }
  }, [loadStandardView]);
};
