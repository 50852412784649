import { useMemo } from 'react';

import { usePRTranslation } from '@GlobalProviders/i18n/hooks';
import {
  TableInstance,
  UiAnalyticalTableColumnDefinition,
  UiTableColumnDataType,
} from '@UiCommon/Components/UiAnalyticalTable/types';
import { UiDateFormatter } from '@UiCommon/Components/UiFormatter/UiDateFormatter';
import {
  AcuteAssetDto,
  ChronicAssetDto,
  CommonSingleAssetDto,
} from '@DataAccess/PhysicalRisk/SingleAsset/singleAsset.types';

type TableColumnType = UiAnalyticalTableColumnDefinition<AcuteAssetDto | ChronicAssetDto>;

export const useCommonPhysicalAssetColumns = () => {
  const { t } = usePRTranslation();

  return useMemo<TableColumnType[]>(
    () => [
      {
        label: t('physicalAsset.shared.physicalAssetId'),
        accessor: 'physicalAssetId',
        id: 'physicalAssetId',
        isVisible: true,
        beField: 'PHYSICAL_ASSET_ID',
      },
      {
        label: t('physicalAsset.shared.systemDate'),
        accessor: 'systemDate',
        id: 'systemDate',
        isVisible: true,
        beField: 'SYSTEM_DATE',
        type: UiTableColumnDataType.DATE_TIME,
        Cell: (table: TableInstance<CommonSingleAssetDto>) => (
          <UiDateFormatter value={table.row.original.systemDate} />
        ),
      },
    ],
    [t],
  );
};
