import { SortingListItemProps } from '@UiCommon/Components/UiAnalyticalTable/SettingsModal/SortSettings/useSortingSettingsState';
import {
  SortingOrder,
  UiAnalyticalTableColumnDefinition,
} from '@UiCommon/Components/UiAnalyticalTable/types';
import { UiListItemType } from '@UiCommon/Components/UiList/types';

import { getBeField } from '../utils';

type Props<T = Record<string, unknown>> = {
  sorting?: UiListItemType<SortingListItemProps>[];
  columns?: UiAnalyticalTableColumnDefinition<T>[];
};

export const mapSortingForPayload = <T>({ sorting, columns }: Props<T>) => {
  return sorting?.map(({ value, data }) => ({
    sort: getBeField({ columnId: value as keyof T, columns }),
    order: data?.order || SortingOrder.ASC,
  }));
};
