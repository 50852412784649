import { UiDateTimePicker } from '@UiCommon/Components/UiDateTimePicker/UiDateTimePicker';

import { ConditionType } from '../../types';

type Props = {
  value: ConditionType['value'];
  onValueChange: (newValue: ConditionType['value']) => void;
};

export const DateTimeCondition = ({ value, onValueChange }: Props) => {
  return (
    <UiDateTimePicker
      value={value?.toString()}
      onChange={(changedValue) => {
        if (!changedValue) return;
        onValueChange(changedValue);
      }}
    />
  );
};
