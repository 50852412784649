export enum Category {
  CORPORATE_FINANCE = 'CORPORATE_FINANCE',
  BUILDING_FINANCE = 'BUILDING_FINANCE',
  PHYSICAL_ASSET_FINANCE = 'PHYSICAL_ASSET_FINANCE',
}

export enum Subcategory {
  DIRECT_EMISSIONS = 'DIRECT_EMISSIONS',
  ENERGY = 'ENERGY',
  ENERGY_EMISSION_FACTOR = 'ENERGY_EMISSION_FACTOR',
  PRODUCTION = 'PRODUCTION',
  PRODUCTION_EMISSION_FACTOR = 'PRODUCTION_EMISSION_FACTOR',
  CORE = 'CORE',
  SECTOR_EMISSION_FACTOR = 'SECTOR_EMISSION_FACTOR',
  BUILDING = 'BUILDING',
  BUILDING_CERTIFICATE_CLASS_EMISSION_FACTOR = 'BUILDING_CERTIFICATE_CLASS_EMISSION_FACTOR',
  FLOOR_AREA_EMISSION_FACTOR = 'FLOOR_AREA_EMISSION_FACTOR',
  BUILDINGS_EMISSION_FACTOR = 'BUILDINGS_EMISSION_FACTOR',
  PHYSICAL_ASSET = 'PHYSICAL_ASSET',
}

export type DataHierarchyEntry = {
  id: string;
  name: string;
  isDefault: boolean;
  isEnabled: boolean;
};

export type SubcategoriesDto = Partial<Record<Subcategory, DataHierarchyEntry[]>>;

export type DataHierarchyDto = Record<Category, SubcategoriesDto>;

export type DataHierarchySearchRequestDto = {
  dataHierarchyId?: string;
  rangeFrom?: string;
  rangeTo?: string;
  page?: number;
  size?: number;
};

export type DataHierarchySearchItemDto = {
  id: string;
  createdDate: string;
  isLatest?: boolean;
};
