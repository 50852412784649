import { UiDatePicker } from '../../../../UiDatePicker/UiDatePicker';
import { ConditionType } from '../../types';

type Props = {
  value: ConditionType['value'];
  onValueChange: (newValue: ConditionType['value']) => void;
};

export const DateCondition = ({ value, onValueChange }: Props) => {
  return (
    <UiDatePicker
      value={value?.toString()}
      onChange={(changedValue) => {
        if (!changedValue) return;
        onValueChange(changedValue);
      }}
    />
  );
};
