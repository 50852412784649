import styled from '@emotion/styled';

import { useGHGTranslation, useUiCommonTranslation } from '@GlobalProviders/i18n/hooks';
import { UiButton } from '@UiCommon/Components';

import { useSplitScopeContext } from '../Context/SplitScopeContext';

const ButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;

type Props = {
  onSaveClick: () => void;
  onAddClick: () => void;
  isEditableItem?: boolean;
};

export const SplitScopeTableActions = ({ onSaveClick, onAddClick, isEditableItem }: Props) => {
  const { isEditMode, setIsEditMode, hasErrors } = useSplitScopeContext();
  const { t } = useGHGTranslation();
  const { t: uiCommonT } = useUiCommonTranslation();

  return (
    <>
      {!isEditMode ? (
        <ButtonWrapper>
          <UiButton design='Emphasized' onClick={onAddClick}>
            {t('splitScope.createRule')}
          </UiButton>
          {isEditableItem && (
            <UiButton onClick={() => setIsEditMode(true)}>
              {uiCommonT('layout.headers.editButtons.edit')}
            </UiButton>
          )}
        </ButtonWrapper>
      ) : (
        <UiButton disabled={hasErrors} design='Emphasized' onClick={onSaveClick}>
          {t('splitScope.save')}
        </UiButton>
      )}
    </>
  );
};
