import styled from '@emotion/styled';
import { useMemo } from 'react';
import { findLast, find } from 'lodash';
import { ThemingParameters } from '@ui5/webcomponents-react-base';

import { useUiCommonTranslation } from '@GlobalProviders/i18n/hooks';
import { IconSet } from '@GlobalProviders/IconSetProvider';

import { UiIcon } from '../../../UiIcon';
import { UiInput } from '../../../UiInput/UiInput';
import { UiList } from '../../../UiList/UiList';
import { UiListItemType } from '../../../UiList/types';
import { useSettingsModalContext } from '../SettingsModalContext';
import { ColumnListItem } from './ColumnListItem';
import { useColumnsSettingsFilter } from './useColumnsSettingsFilter';

const InteractiveIconWrapper = styled.div<{ isActive: boolean }>`
  pointer-events: ${({ isActive }) => !isActive && 'none'};
  cursor: ${({ isActive }) => isActive && 'pointer'};

  display: flex;
  align-items: center;
  justify-content: center;

  & :hover {
    fill: ${({ isActive }) => isActive && ThemingParameters.sapButton_Active_TextColor};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const FILTER_BAR_HEIGHT = '2.75rem';
const FilterBar = styled.div`
  padding: 0 1rem;
  height: ${FILTER_BAR_HEIGHT};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ListWrapper = styled.div`
  height: calc(100% - ${FILTER_BAR_HEIGHT});
  flex-grow: 1;
  overflow: auto;
`;

export const ColumnsSettings = () => {
  const { t } = useUiCommonTranslation();

  const { columns, visibleColumns, onColumnsVisibilityChange, onColumnsReorder } =
    useSettingsModalContext();

  const { filter, filteredColumns, clearFilter, filterPhrase } = useColumnsSettingsFilter(columns);

  const { firstVisible, lastVisible } = useMemo(() => {
    const visibleSet = new Set(visibleColumns);
    const checkIfVisible = ({ value }: UiListItemType) => visibleSet.has(value);

    return {
      firstVisible: find(columns, checkIfVisible)?.value,
      lastVisible: findLast(columns, checkIfVisible)?.value,
    };
  }, [columns, visibleColumns]);

  const handleColumnsSelectionChange = (value: boolean) => {
    const allColumns = columns?.map((item) => item.value);
    allColumns && onColumnsVisibilityChange?.(value ? allColumns : []);
  };

  return (
    <Wrapper>
      <FilterBar>
        <UiInput
          onChange={filter}
          placeholder={t('settingsModal.search')}
          value={filterPhrase}
          endContent={
            <InteractiveIconWrapper isActive={!!filterPhrase} onClick={clearFilter}>
              <UiIcon name={filterPhrase ? IconSet.DECLINE : IconSet.SEARCH} />
            </InteractiveIconWrapper>
          }
        />
      </FilterBar>
      <ListWrapper>
        <UiList
          isSelectAllActive
          resourceName={t('settingsModal.tabs.columns')}
          items={filteredColumns}
          selectedItems={visibleColumns}
          onSelectAllChange={handleColumnsSelectionChange}
          onChange={({ selectedItems }) => onColumnsVisibilityChange?.(selectedItems)}
          onItemsOrderChange={onColumnsReorder}
          isDragAndDropActive={!filterPhrase}
          renderItemContent={({ focusedItemValue, item, moveElement, isSelected }) => (
            <ColumnListItem
              isSearching={!!filterPhrase}
              focusedItemValue={focusedItemValue}
              item={item}
              onNavigate={moveElement}
              isFirstActiveItem={firstVisible === item.value}
              isLastActiveItem={lastVisible === item.value}
              isSelected={isSelected}
            />
          )}
          isSelectionActive
        />
      </ListWrapper>
    </Wrapper>
  );
};
