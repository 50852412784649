import styled from '@emotion/styled';

import { useUiCommonTranslation } from '@GlobalProviders/i18n/hooks';
import { UiBusyIndicator, UiText } from '@UiCommon/Components';

import { CommonVersionData, UiVersionOption } from './UiVersionOption';

export type VersionListProps<T extends CommonVersionData> = {
  versions: T[];
  paramId?: string;
  isFetched?: boolean;
  isLoading?: boolean;
  renderData: (props: T) => JSX.Element;
};

const MARGIN_TOP = '18.75rem';

const Wrapper = styled.ul`
  margin: 0.563rem 0 0 0;
  padding: 0;
  list-style: none;
`;

const NoVersionsPlaceholder = styled(UiText)`
  margin-top: ${MARGIN_TOP};
  text-align: center;
`;

const LoadingPlaceholder = styled(UiBusyIndicator)`
  margin-top: ${MARGIN_TOP};
`;

export const UiVersionList = <T extends CommonVersionData>({
  versions,
  isFetched,
  isLoading,
  paramId,
  renderData,
}: VersionListProps<T>) => {
  const { t } = useUiCommonTranslation();

  const noVersions = !versions.length && isFetched;

  if (isLoading) return <LoadingPlaceholder isActive={isLoading} />;

  if (noVersions)
    return (
      <NoVersionsPlaceholder variant='caption2'>
        {t('versionMenu.noVersions')}
      </NoVersionsPlaceholder>
    );

  return (
    <Wrapper>
      {versions.map((data) => (
        <UiVersionOption
          key={data.id}
          data={data}
          renderData={renderData}
          isLatest={data.isLatest}
          isSelected={paramId === data.id}
        />
      ))}
    </Wrapper>
  );
};
