import { useMemo } from 'react';
import { TextAlign } from '@ui5/webcomponents-react';

import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';
import { UiAnalyticalTableColumnDefinition } from '@UiCommon/Components';
import { TableInstance, UiTableColumnDataType } from '@UiCommon/Components/UiAnalyticalTable/types';
import { UiCurrencyFormatter } from '@UiCommon/Components/UiFormatter/UiCurrencyFormatter';
import { UiNumberFormatter } from '@UiCommon/Components/UiFormatter/UiNumberFormatter';
import { useSettingsStore } from '@GlobalProviders/SettingsProvider';
import { EmissionDto } from '@DataAccess/GHG/SingleExposure/singleExposure.types';

const getBP = (tableApi: TableInstance<EmissionDto>) => tableApi.row.original.businessPartner;

export const useBusinessPartnerColumns = () => {
  const { t } = useGHGTranslation();
  const { currency } = useSettingsStore();

  return useMemo(() => {
    const output: UiAnalyticalTableColumnDefinition<EmissionDto>[] = [
      {
        label: t('singleExposure.table.businessPartner.businessPartnerId'),
        accessor: 'businessPartner.id',
        id: 'businessPartner.id',
        minWidth: 188,
        isVisible: true,
        beField: 'BUSINESS_PARTNER_ID',
      },
      {
        label: t('singleExposure.table.businessPartner.name'),
        accessor: 'businessPartner.name',
        id: 'businessPartner.name',
        minWidth: 170,
        isVisible: true,
        beField: 'BUSINESS_PARTNER_NAME',
      },
      {
        label: t('singleExposure.table.businessPartner.lei'),
        accessor: 'businessPartner.lei',
        id: 'businessPartner.lei',
        minWidth: 170,
        isVisible: true,
        beField: 'BUSINESS_PARTNER_LEI',
      },
      {
        label: t('singleExposure.table.businessPartner.businessPartnerCategory'),
        accessor: 'businessPartner.category',
        id: 'businessPartner.category',
        minWidth: 212,
        isVisible: true,
        beField: 'BUSINESS_PARTNER_CATEGORY',
      },
      {
        label: t('singleExposure.table.businessPartner.naceCode'),
        accessor: 'businessPartner.naceCode',
        id: 'businessPartner.naceCode',
        minWidth: 94,
        isVisible: true,
        beField: 'BUSINESS_PARTNER_NACE_CODE',
      },
      {
        label: t('singleExposure.table.businessPartner.listedCompanyFlag'),
        accessor: 'businessPartner.listedCompanyFlag',
        id: 'businessPartner.listedCompanyFlag',
        minWidth: 120,
        isVisible: true,
        beField: 'BUSINESS_PARTNER_LISTED_COMPANY_FLAG',
        type: UiTableColumnDataType.BOOLEAN,
      },
      {
        label: t('singleExposure.table.businessPartner.countryRegion'),
        accessor: 'businessPartner.countryRegion',
        id: 'businessPartner.countryRegion',
        minWidth: 70,
        isVisible: true,
        beField: 'BUSINESS_PARTNER_COUNTRY_REGION',
      },
      {
        label: t('singleExposure.table.businessPartner.evic', { currency }),
        accessor: 'businessPartner.evic',
        id: 'businessPartner.evic',
        minWidth: 50,
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'BUSINESS_PARTNER_EVIC',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiCurrencyFormatter value={getBP(table).evic} />
        ),
      },
      {
        label: t('singleExposure.table.businessPartner.totalAssets', { currency }),
        accessor: 'businessPartner.totalAssets',
        id: 'businessPartner.totalAssets',
        minWidth: 70,
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'BUSINESS_PARTNER_TOTAL_ASSETS',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiCurrencyFormatter value={getBP(table).totalAssets} />
        ),
      },
      {
        label: t('singleExposure.table.businessPartner.totalRevenue', { currency }),
        accessor: 'businessPartner.totalRevenue',
        id: 'businessPartner.totalRevenue',
        minWidth: 70,
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'BUSINESS_PARTNER_TOTAL_REVENUE',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiCurrencyFormatter value={getBP(table).totalRevenue} />
        ),
      },
      {
        label: t('singleExposure.table.businessPartner.emissionsScope1'),
        accessor: 'businessPartner.emissionsScope1',
        id: 'businessPartner.emissionsScope1',
        minWidth: 140,
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'BUSINESS_PARTNER_EMISSIONS_SCOPE_1',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiNumberFormatter value={getBP(table).emissionsScope1} />
        ),
      },
      {
        label: t('singleExposure.table.businessPartner.emissionsScope2'),
        accessor: 'businessPartner.emissionsScope2',
        id: 'businessPartner.emissionsScope2',
        minWidth: 140,
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'BUSINESS_PARTNER_EMISSIONS_SCOPE_2',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiNumberFormatter value={getBP(table).emissionsScope2} />
        ),
      },
      {
        label: t('singleExposure.table.businessPartner.emissionsScope3'),
        accessor: 'businessPartner.emissionsScope3',
        id: 'businessPartner.emissionsScope3',
        minWidth: 140,
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'BUSINESS_PARTNER_EMISSIONS_SCOPE_3',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiNumberFormatter value={getBP(table).emissionsScope3} />
        ),
      },
      {
        label: t('singleExposure.table.businessPartner.emissionsScope1Plus2'),
        accessor: 'businessPartner.emissionsScope1Plus2',
        id: 'businessPartner.emissionsScope1Plus2',
        minWidth: 140,
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'BUSINESS_PARTNER_EMISSIONS_SCOPE_1_PLUS_2',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiNumberFormatter value={getBP(table).emissionsScope1Plus2} />
        ),
      },
      {
        label: t('singleExposure.table.businessPartner.emissionsVerifiedFlag'),
        accessor: 'businessPartner.emissionsVerifiedFlag',
        id: 'businessPartner.emissionsVerifiedFlag',
        minWidth: 135,
        isVisible: true,
        beField: 'BUSINESS_PARTNER_EMISSIONS_VERIFIED_FLAG',
        type: UiTableColumnDataType.BOOLEAN,
      },
      {
        label: t('singleExposure.table.businessPartner.avoidedEmissionsFlag'),
        accessor: 'businessPartner.avoidedEmissionsFlag',
        id: 'businessPartner.avoidedEmissionsFlag',
        isVisible: true,
        type: UiTableColumnDataType.BOOLEAN,
        beField: 'BUSINESS_PARTNER_AVOIDED_EMISSIONS_FLAG',
      },
      {
        label: t('singleExposure.table.businessPartner.baselineEmissions'),
        accessor: 'businessPartner.baselineEmissions',
        id: 'businessPartner.baselineEmissions',
        isVisible: true,
        beField: 'BUSINESS_PARTNER_BASELINE_EMISSIONS',
        hAlign: TextAlign.Right,
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('singleExposure.table.businessPartner.avoidedEmissions'),
        accessor: 'businessPartner.avoidedEmissions',
        id: 'businessPartner.avoidedEmissions',
        isVisible: true,
        beField: 'BUSINESS_PARTNER_AVOIDED_EMISSIONS',
        hAlign: TextAlign.Right,
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('singleExposure.table.businessPartner.dataQualityScoreBaselineEmissions'),
        accessor: 'businessPartner.dataQualityScoreBaselineEmissions',
        id: 'businessPartner.dataQualityScoreBaselineEmissions',
        isVisible: true,
        beField: 'BUSINESS_PARTNER_DATA_QUALITY_SCORE_BASE_LINE_EMISSIONS',
      },
      {
        label: t('singleExposure.table.businessPartner.dataQualityScoreOptionBaselineEmissions'),
        accessor: 'businessPartner.dataQualityScoreOptionBaselineEmissions',
        id: 'businessPartner.dataQualityScoreOptionBaselineEmissions',
        isVisible: true,
        beField: 'BUSINESS_PARTNER_DATA_QUALITY_SCORE_OPTION_BASELINE_EMISSIONS',
      },
      {
        label: t('singleExposure.table.businessPartner.dataQualityScoreScope1'),
        accessor: 'businessPartner.dataQualityScoreScope1',
        id: 'businessPartner.dataQualityScoreScope1',
        minWidth: 140,
        isVisible: true,
        beField: 'BUSINESS_PARTNER_DATA_QUALITY_SCORE_SCOPE_1',
      },
      {
        label: t('singleExposure.table.businessPartner.dataQualityScoreScope2'),
        accessor: 'businessPartner.dataQualityScoreScope2',
        id: 'businessPartner.dataQualityScoreScope2',
        minWidth: 140,
        isVisible: true,
        beField: 'BUSINESS_PARTNER_DATA_QUALITY_SCORE_SCOPE_2',
      },
      {
        label: t('singleExposure.table.businessPartner.dataQualityScoreScope3'),
        accessor: 'businessPartner.dataQualityScoreScope3',
        id: 'businessPartner.dataQualityScoreScope3',
        minWidth: 140,
        isVisible: true,
        beField: 'BUSINESS_PARTNER_DATA_QUALITY_SCORE_SCOPE_3',
      },
      {
        label: t('singleExposure.table.businessPartner.dataQualityScoreScope1Plus2'),
        accessor: 'businessPartner.dataQualityScoreScope1Plus2',
        id: 'businessPartner.dataQualityScoreScope1Plus2',
        minWidth: 140,
        isVisible: true,
        beField: 'BUSINESS_PARTNER_DATA_QUALITY_SCORE_SCOPE_1_PLUS_2',
      },
      {
        label: t('singleExposure.table.businessPartner.dataQualityScoreOptionScope1'),
        accessor: 'businessPartner.dataQualityScoreOptionScope1',
        id: 'businessPartner.dataQualityScoreOptionScope1',
        minWidth: 210,
        isVisible: true,
        beField: 'BUSINESS_PARTNER_DATA_QUALITY_SCORE_OPTION_SCOPE_1',
      },
      {
        label: t('singleExposure.table.businessPartner.dataQualityScoreOptionScope2'),
        accessor: 'businessPartner.dataQualityScoreOptionScope2',
        id: 'businessPartner.dataQualityScoreOptionScope2',
        minWidth: 210,
        isVisible: true,
        beField: 'BUSINESS_PARTNER_DATA_QUALITY_SCORE_OPTION_SCOPE_2',
      },
      {
        label: t('singleExposure.table.businessPartner.dataQualityScoreOptionScope3'),
        accessor: 'businessPartner.dataQualityScoreOptionScope3',
        id: 'businessPartner.dataQualityScoreOptionScope3',
        minWidth: 210,
        isVisible: true,
        beField: 'BUSINESS_PARTNER_DATA_QUALITY_SCORE_OPTION_SCOPE_3',
      },
      {
        label: t('singleExposure.table.businessPartner.dataQualityScoreOptionScope1Plus2'),
        accessor: 'businessPartner.dataQualityScoreOptionScope1Plus2',
        id: 'businessPartner.dataQualityScoreOptionScope1Plus2',
        minWidth: 210,
        isVisible: true,
        beField: 'BUSINESS_PARTNER_DATA_QUALITY_SCORE_OPTION_SCOPE_1_PLUS_2',
      },
      {
        label: t('singleExposure.table.businessPartner.emissionFactorScope1'),
        accessor: 'businessPartner.emissionFactorScope1',
        id: 'businessPartner.emissionFactorScope1',
        minWidth: 210,
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'BUSINESS_PARTNER_EMISSION_FACTOR_SCOPE_1',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiNumberFormatter value={getBP(table).emissionFactorScope1} />
        ),
      },
      {
        label: t('singleExposure.table.businessPartner.emissionFactorScope2'),
        accessor: 'businessPartner.emissionFactorScope2',
        id: 'businessPartner.emissionFactorScope2',
        minWidth: 210,
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'BUSINESS_PARTNER_EMISSION_FACTOR_SCOPE_2',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiNumberFormatter value={getBP(table).emissionFactorScope2} />
        ),
      },
      {
        label: t('singleExposure.table.businessPartner.emissionFactorScope3'),
        accessor: 'businessPartner.emissionFactorScope3',
        id: 'businessPartner.emissionFactorScope3',
        minWidth: 210,
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'BUSINESS_PARTNER_EMISSION_FACTOR_SCOPE_3',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiNumberFormatter value={getBP(table).emissionFactorScope3} />
        ),
      },
      {
        label: t('singleExposure.table.businessPartner.emissionFactorLocationReference'),
        accessor: 'businessPartner.emissionFactorLocationReference',
        id: 'businessPartner.emissionFactorLocationReference',
        isVisible: true,
        beField: 'BUSINESS_PARTNER_EMISSION_FACTOR_LOCATION_REFERENCE',
      },
      {
        label: t('singleExposure.table.businessPartner.revenueEmissionIntensityScope1'),
        accessor: 'businessPartner.revenueEmissionIntensityScope1',
        id: 'businessPartner.revenueEmissionIntensityScope1',
        minWidth: 170,
        isVisible: true,
        beField: 'BUSINESS_PARTNER_REVENUE_EMISSION_INTENSITY_SCOPE_1',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiNumberFormatter value={getBP(table).revenueEmissionIntensityScope1} />
        ),
      },
      {
        label: t('singleExposure.table.businessPartner.revenueEmissionIntensityScope2'),
        accessor: 'businessPartner.revenueEmissionIntensityScope2',
        id: 'businessPartner.revenueEmissionIntensityScope2',
        minWidth: 170,
        isVisible: true,
        beField: 'BUSINESS_PARTNER_REVENUE_EMISSION_INTENSITY_SCOPE_2',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiNumberFormatter value={getBP(table).revenueEmissionIntensityScope2} />
        ),
      },
      {
        label: t('singleExposure.table.businessPartner.revenueEmissionIntensityScope3'),
        accessor: 'businessPartner.revenueEmissionIntensityScope3',
        id: 'businessPartner.revenueEmissionIntensityScope3',
        minWidth: 170,
        isVisible: true,
        beField: 'BUSINESS_PARTNER_REVENUE_EMISSION_INTENSITY_SCOPE_3',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiNumberFormatter value={getBP(table).revenueEmissionIntensityScope3} />
        ),
      },
      {
        label: t('singleExposure.table.businessPartner.naceIndustry'),
        accessor: 'businessPartner.naceIndustry',
        id: 'businessPartner.naceIndustry',
        minWidth: 170,
        isVisible: true,
        beField: 'BUSINESS_PARTNER_NACE_INDUSTRY',
      },
      {
        label: t('singleExposure.table.businessPartner.naceSection'),
        accessor: 'businessPartner.naceSection',
        id: 'businessPartner.naceSection',
        minWidth: 170,
        isVisible: true,
        beField: 'BUSINESS_PARTNER_NACE_SECTION',
      },
      {
        label: t('singleExposure.table.businessPartner.financialsDataSource'),
        accessor: 'businessPartner.financialsDataSource',
        id: 'businessPartner.financialsDataSource',
        isVisible: true,
        beField: 'BUSINESS_PARTNER_FINANCIALS_DATA_SOURCE',
      },
      {
        label: t('singleExposure.table.businessPartner.directEmissionsDataSource'),
        accessor: 'businessPartner.directEmissionsDataSource',
        id: 'businessPartner.directEmissionsDataSource',
        isVisible: true,
        beField: 'BUSINESS_PARTNER_DIRECT_EMISSIONS_DATA_SOURCE',
      },
      {
        label: t('singleExposure.table.businessPartner.emissionFactorSectorDataSource'),
        accessor: 'businessPartner.emissionFactorSectorDataSource',
        id: 'businessPartner.emissionFactorSectorDataSource',
        isVisible: true,
        beField: 'BUSINESS_PARTNER_EMISSION_FACTOR_SECTOR_DATA_SOURCE',
      },
    ];

    return output;
  }, [t]);
};
