import styled from '@emotion/styled';
import { DatePicker, Label } from '@ui5/webcomponents-react';

import { GenericInputWrapper, commonInputProperties } from '../styles';
import { useUiDatePickerOnChangeHandler } from './useUiDatePickerOnChangeHandler';
import { CommonProps, ElementSize } from '../../types';
import { DATE_FORMAT, PRIMARY_CALENDAR_TYPE } from '../constants';

type Props = CommonProps & {
  size?: ElementSize;
  value?: string;
  onChange?: (value?: string) => void;
  label?: string;
  placeholder?: string;
};

const StyledDatePicker = styled(DatePicker)`
  ${commonInputProperties()};
`;

export const UiDatePicker = ({
  value,
  size = ElementSize.SMALL,
  onChange,
  label,
  placeholder,
}: Props) => {
  const onChangeHandler = useUiDatePickerOnChangeHandler(onChange);

  return (
    <GenericInputWrapper>
      {label && <Label>{label}:</Label>}
      <StyledDatePicker
        value={value ?? ''}
        onChange={onChangeHandler}
        formatPattern={DATE_FORMAT}
        primaryCalendarType={PRIMARY_CALENDAR_TYPE}
        data-ui5-compact-size={size === ElementSize.SMALL ? true : undefined}
        placeholder={placeholder}
      />
    </GenericInputWrapper>
  );
};
