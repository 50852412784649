import { AuthProviderProps, AuthProvider as AuthenticationProvider, UserManager } from 'oidc-react';
import { WebStorageStateStore } from 'oidc-client-ts';

import { CommonProps } from '@UiCommon/types';

type Props = CommonProps & {
  disabled?: boolean;
};

const OIDC_STORAGE_PREFIX = 'oidc.';

const removeStorageItemsByOIDCPrefix = (storage: Storage) => {
  const localStorageKeys = Object.keys(storage);
  const keysToBeRemoved = localStorageKeys.filter((key) => key.startsWith(OIDC_STORAGE_PREFIX));
  keysToBeRemoved.forEach((key) => storage.removeItem(key));
};

const authConfig: AuthProviderProps = {
  userManager: new UserManager({
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    authority: import.meta.env.VITE_APP_AUTHORITY_URL,
    client_id: import.meta.env.VITE_APP_CLIENT_ID,
    redirect_uri: import.meta.env.VITE_APP_FRONTEND_URL,
    metadataUrl: import.meta.env.VITE_APP_METADATA_URL,
    scope:
      `${import.meta.env.VITE_APP_SCOPES_1} ` +
      `${import.meta.env.VITE_APP_SCOPES_2} ` +
      `${import.meta.env.VITE_APP_SCOPES_3}`,
  }),

  onSignOut: () => {
    removeStorageItemsByOIDCPrefix(window.localStorage);
    window.location.replace(import.meta.env.VITE_APP_LOGOUT_URL);
  },
};

export const AuthProvider = ({ children, disabled }: Props) => {
  if (disabled) return <>{children}</>;

  return <AuthenticationProvider {...authConfig}>{children}</AuthenticationProvider>;
};
