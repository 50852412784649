import { useMemo } from 'react';

import { useProcessManagerTranslation } from '@GlobalProviders/i18n/hooks';
import { UiTableColumnDataType } from '@UiCommon/Components/UiAnalyticalTable/types';

import { useCommonCollectedColumns } from '../useCommonCollectedColumns';

export const useCollectedPhysicalAssetColumns = () => {
  const { t } = useProcessManagerTranslation();
  const commonColumns = useCommonCollectedColumns();

  return useMemo(
    () => [
      {
        label: t('processManager.manualUpload.physicalAsset.table.physicalAssetId'),
        accessor: 'physicalAssetId',
        id: 'physicalAssetId',
        isVisible: true,
        beField: 'PHYSICAL_ASSET_ID',
      },
      {
        label: t('processManager.manualUpload.physicalAsset.table.type'),
        accessor: 'type',
        id: 'type',
        minWidth: 104,
        isVisible: true,
        beField: 'TYPE',
      },
      {
        label: t('processManager.manualUpload.physicalAsset.table.emissionsScope1'),
        accessor: 'emissionsScope1',
        id: 'emissionsScope1',
        minWidth: 104,
        isVisible: true,
        type: UiTableColumnDataType.NUMBER,
        beField: 'EMISSIONS_SCOPE_1',
      },
      {
        label: t('processManager.manualUpload.physicalAsset.table.emissionsScope2'),
        accessor: 'emissionsScope2',
        id: 'emissionsScope2',
        minWidth: 104,
        isVisible: true,
        type: UiTableColumnDataType.NUMBER,
        beField: 'EMISSIONS_SCOPE_2',
      },
      {
        label: t('processManager.manualUpload.physicalAsset.table.emissionsScope1Plus2'),
        accessor: 'emissionsScope1Plus2',
        id: 'emissionsScope1Plus2',
        isVisible: true,
        type: UiTableColumnDataType.NUMBER,
        beField: 'EMISSIONS_SCOPE_1_PLUS_2',
      },
      {
        label: t('processManager.manualUpload.physicalAsset.table.dataQualityScoreScope1'),
        accessor: 'dataQualityScoreScope1',
        id: 'dataQualityScoreScope1',
        minWidth: 104,
        isVisible: true,
        beField: 'DATA_QUALITY_SCORE_SCOPE_1',
      },
      {
        label: t('processManager.manualUpload.physicalAsset.table.dataQualityScoreScope2'),
        accessor: 'dataQualityScoreScope2',
        id: 'dataQualityScoreScope2',
        minWidth: 104,
        isVisible: true,
        beField: 'DATA_QUALITY_SCORE_SCOPE_2',
      },
      {
        label: t('processManager.manualUpload.physicalAsset.table.dataQualityScoreScope1Plus2'),
        accessor: 'dataQualityScoreScope1Plus2',
        id: 'dataQualityScoreScope1Plus2',
        isVisible: true,
        beField: 'DATA_QUALITY_SCORE_SCOPE_1_PLUS_2',
      },
      {
        label: t('processManager.manualUpload.physicalAsset.table.dataQualityScoreOptionScope1'),
        accessor: 'dataQualityScoreOptionScope1',
        id: 'dataQualityScoreOptionScope1',
        minWidth: 104,
        isVisible: true,
        beField: 'DATA_QUALITY_SCORE_OPTION_SCOPE_1',
      },
      {
        label: t('processManager.manualUpload.physicalAsset.table.dataQualityScoreOptionScope2'),
        accessor: 'dataQualityScoreOptionScope2',
        id: 'dataQualityScoreOptionScope2',
        minWidth: 104,
        isVisible: true,
        beField: 'DATA_QUALITY_SCORE_OPTION_SCOPE_2',
      },
      {
        label: t(
          'processManager.manualUpload.physicalAsset.table.dataQualityScoreOptionScope1Plus2',
        ),
        accessor: 'dataQualityScoreOptionScope1Plus2',
        id: 'dataQualityScoreOptionScope1Plus2',
        isVisible: true,
        beField: 'DATA_QUALITY_SCORE_OPTION_SCOPE_1_PLUS_2',
      },
      {
        label: t('processManager.manualUpload.shared.year'),
        accessor: 'year',
        id: 'year',
        isVisible: true,
        beField: 'YEAR',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.shared.emissionsReportingDate'),
        accessor: 'emissionsReportingDate',
        id: 'emissionsReportingDate',
        isVisible: true,
        beField: 'EMISSIONS_REPORTING_DATE',
        type: UiTableColumnDataType.DATE,
      },
      ...commonColumns,
    ],
    [t, commonColumns],
  );
};
