import { IllustratedMessage, IllustrationMessageType } from '@ui5/webcomponents-react';

import { CommonProps } from '@UiCommon/types';

type Props = CommonProps & {
  name: IllustrationMessageType | keyof typeof IllustrationMessageType;
  titleText: string;
  subtitleText: string;
};

export const UiIllustrateMessage = ({ name, titleText, subtitleText, className }: Props) => {
  return (
    <IllustratedMessage
      className={className}
      name={name}
      titleText={titleText}
      subtitleText={subtitleText}
    />
  );
};
