import { useState } from 'react';

import { useExportCsv } from '@Pages/Shared/SmartComponents/SmartAnalyticalTableCard/Hooks/useExportCsv';
import {
  SettingsTab,
  UiAnalyticalTable,
  UiCard,
  UiExportModal,
  UiExportProgressModal,
  UiSettingsModal,
} from '@UiCommon/Components';
import { SortingListItemProps } from '@UiCommon/Components/UiAnalyticalTable/SettingsModal/SortSettings/useSortingSettingsState';
import { useSettingsModalState } from '@UiCommon/Components/UiAnalyticalTable/SettingsModal/useSettingsModalState';
import { TableHeaderActions } from '@UiCommon/Components/UiAnalyticalTable/TableHeaderActions';
import {
  SortingOrder,
  UiAnalyticalTableColumnDefinition,
  UiColumnFiltersType,
} from '@UiCommon/Components/UiAnalyticalTable/types';
import { ViewSettings } from '@UiCommon/Components/UiAnalyticalTable/ViewsManagement/types';
import { UiListItemType } from '@UiCommon/Components/UiList/types';
import { NestedKeyOf } from '@Types/types';
import api from '@DataAccess/api';

import { checkForSmartTableInputErrors } from './checkForSmartTableInputErrors';
import { SmartCardHeader } from './Components/SmartCardHeader';

type UiAnalyticalTableProps<T> = React.ComponentProps<typeof UiAnalyticalTable<T>>;

export type SmartAnalyticalProps<T = Record<string, unknown>> = {
  tableId: string;
  columns: UiAnalyticalTableProps<T>['columns'];
  data: UiAnalyticalTableProps<T>['data'];
  title: UiAnalyticalTableProps<T>['title'];
  onLoadMore?: UiAnalyticalTableProps<T>['onLoadMore'];
  totalNumberOfItems: UiAnalyticalTableProps<T>['totalNumberOfItems'];
  rowHeight?: UiAnalyticalTableProps<T>['rowHeight'];
  filters?: UiColumnFiltersType[];
  sorting?: UiListItemType<SortingListItemProps>[];
  onFilters?: (filters: UiColumnFiltersType[]) => void;
  onSorting?: (sorting: UiListItemType<SortingListItemProps>[]) => void;
  apiPath?: NestedKeyOf<typeof api>;
  hasSettingsModal?: boolean;
  hasExportCSV?: boolean;
  hasViewsManager?: boolean;
  infiniteScroll?: boolean;
  pathParameter?: string;
  externalFiltersApplied?: boolean;
};

export const SmartAnalyticalTableCard = <T,>({
  title,
  columns: initialColumns,
  data = [],
  onLoadMore,
  infiniteScroll,
  totalNumberOfItems,
  rowHeight,
  tableId,
  hasViewsManager,
  hasSettingsModal,
  hasExportCSV,
  filters,
  sorting,
  onSorting,
  onFilters,
  apiPath,
  pathParameter,
  externalFiltersApplied,
}: SmartAnalyticalProps<T>) => {
  checkForSmartTableInputErrors({ hasExportCSV, hasSettingsModal, filters, sorting, apiPath });
  const [columns, setColumns] = useState<UiAnalyticalTableColumnDefinition<T>[]>(initialColumns);

  const { tab, setIsSettingsModalOpen, isSettingsModalOpen, openSettingsTab } =
    useSettingsModalState();

  const updateSorting = (id: string, order: SortingOrder) => {
    if (!sorting || !onSorting) return;
    const currentElement = sorting?.find((item) => item.value === id);
    if (currentElement) {
      if (currentElement.data) currentElement.data.order = order;
      onSorting([...sorting]);

      return;
    }
    const newSortingItem = { label: id, value: id, data: { order } };
    onSorting([...sorting, newSortingItem]);
  };

  const onSettingsChange = ({ filters, columns, sorting }: ViewSettings<T>) => {
    filters && onFilters?.(filters);
    sorting && onSorting?.(sorting);
    setColumns(columns);
  };

  const onLoadInitialize = ({ filters, columns, sorting }: ViewSettings<T>) => {
    setColumns(columns);
    if (!externalFiltersApplied) {
      filters && onFilters?.(filters);
      sorting && onSorting?.(sorting);
    }
  };

  const {
    isExportModalOpen,
    isProgressModalOpen,
    onExportClick,
    onExportModalCancel,
    onProgressModalCancel,
    onExportModalClose,
    onSubmitExport,
    progressValue,
  } = useExportCsv({
    tableId,
    apiPath,
    columns,
    filters,
    sorting,
    totalElements: totalNumberOfItems,
    pathParameter,
  });

  const showTableActionsHeader = hasSettingsModal || hasExportCSV;
  const isFiltersBarVisible = hasSettingsModal && !!filters?.length;
  const isCardHeaderVisible = hasViewsManager || isFiltersBarVisible;

  const onSettingsClick = hasSettingsModal ? () => setIsSettingsModalOpen(true) : undefined;
  const onExportCsvClick = hasExportCSV ? onExportClick : undefined;
  const onHeaderSortClick = hasSettingsModal ? updateSorting : undefined;
  const onHeaderSettingsItemClick = hasSettingsModal ? openSettingsTab : undefined;

  return (
    <UiCard>
      {isCardHeaderVisible && (
        <SmartCardHeader
          loadStandardView={externalFiltersApplied}
          tableId={tableId}
          columns={columns}
          defaultColumns={initialColumns}
          onFilterBarClick={() => openSettingsTab(SettingsTab.FILTER)}
          onSettingsChange={onSettingsChange}
          onLoadInitialize={onLoadInitialize}
          filters={filters}
          sorting={sorting}
          hasViewsManager={hasViewsManager}
          isFiltersBarVisible={isFiltersBarVisible}
        />
      )}
      {hasSettingsModal && (
        <UiSettingsModal
          selectedTab={tab}
          columns={columns}
          sorting={sorting}
          filters={filters}
          onClose={() => setIsSettingsModalOpen(false)}
          onConfirm={(settings) => {
            onSettingsChange(settings);
            setIsSettingsModalOpen(false);
          }}
          isOpen={isSettingsModalOpen}
        />
      )}
      {hasExportCSV && (
        <>
          <UiExportModal
            onSubmit={onSubmitExport}
            isOpen={isExportModalOpen}
            onCancel={onExportModalCancel}
            onClose={onExportModalClose}
          />
          <UiExportProgressModal
            isOpen={isProgressModalOpen}
            value={progressValue}
            onCancel={onProgressModalCancel}
          />
        </>
      )}
      <UiAnalyticalTable
        headerActions={
          showTableActionsHeader && (
            <TableHeaderActions
              onSettingsClick={onSettingsClick}
              onExportClick={onExportCsvClick}
            />
          )
        }
        title={title}
        columns={columns}
        onColumnsReorder={setColumns}
        data={data}
        infiniteScroll={infiniteScroll}
        totalNumberOfItems={totalNumberOfItems}
        rowHeight={rowHeight}
        numberOfItems={data.length}
        onLoadMore={onLoadMore}
        sortingItems={sorting}
        onHeaderSortClick={onHeaderSortClick}
        onHeaderSettingsItemClick={onHeaderSettingsItemClick}
      />
    </UiCard>
  );
};
