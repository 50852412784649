import { AxiosInstance } from 'axios';

import { PaginatedTableParams, PaginationResponse } from '@DataAccess/types';

import { AcuteAssetDto } from './singleAsset.types';
import { PHYSICAL_RISK_ENDPOINT } from '../PhysicalRisk.endpoint';

const singleAssetEndpoint = `${PHYSICAL_RISK_ENDPOINT}/single-asset`;

export const initialiseSingleAssetEndpoints = (api: AxiosInstance) => ({
  getAcuteRisks({ tableParams, pageParam }: PaginatedTableParams) {
    return api
      .post<PaginationResponse<AcuteAssetDto>>(`${singleAssetEndpoint}/acute`, {
        page: pageParam,
        ...tableParams,
      })
      .then(({ data }) => data);
  },
  getChronicRisks({ tableParams, pageParam }: PaginatedTableParams) {
    return api
      .post<PaginationResponse<AcuteAssetDto>>(`${singleAssetEndpoint}/chronic`, {
        page: pageParam,
        ...tableParams,
      })
      .then(({ data }) => data);
  },
});
