import { RouteObject } from 'react-router-dom';

import { ERROR_LOG_ROUTE } from './routes';
import { ErrorLogPage } from '../ErrorLogPage';

export const ErrorLogRouting: RouteObject = {
  path: ERROR_LOG_ROUTE,
  children: [
    {
      index: true,
      element: <ErrorLogPage />,
    },
  ],
};
