import { CheckBox } from '@ui5/webcomponents-react';

import { useUiCheckboxOnChangeHandler } from './useUiCheckboxOnChangeHandler';

type Props = {
  onChange?: (value: boolean) => void;
  isChecked?: boolean;
};

export const UiCheckbox = ({ onChange, isChecked }: Props) => {
  const onChangeHandler = useUiCheckboxOnChangeHandler(onChange);

  return <CheckBox onChange={onChangeHandler} checked={isChecked} />;
};
