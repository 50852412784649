import { UiDialogHeader } from '../../UiDialog/UiDialogHeader';
import { UiTitle } from '../../UiTitle';

type Props = {
  title: string;
};

export const UiExportModalHeader = ({ title }: Props) => {
  return <UiDialogHeader startContent={<UiTitle level='H5'>{title}</UiTitle>} />;
};
