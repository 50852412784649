import { useMemo } from 'react';

import { usePRTranslation } from '@GlobalProviders/i18n/hooks';

import { usePhysicalAssetOutputAddressColumns } from '../../../Hooks/Columns/usePhysicalAssetOutputAddressColumns';
import { usePhysicalAssetAcuteRootColumns } from './usePhysicalAssetAcuteRootColumns';
import { usePhysicalAssetInputAddressColumns } from '../../../Hooks/Columns/usePhysicalAssetInputAddressColumns';

export const usePhysicalAssetAcuteColumns = () => {
  const { t } = usePRTranslation();

  const rootColumns = usePhysicalAssetAcuteRootColumns();
  const inputAddressColumns = usePhysicalAssetInputAddressColumns();
  const outputAddressColumns = usePhysicalAssetOutputAddressColumns();

  return useMemo(() => {
    return [...inputAddressColumns, ...outputAddressColumns, ...rootColumns];
  }, [t]);
};
