import styled from '@emotion/styled';
import { Label, MultiInput } from '@ui5/webcomponents-react';
import { ReactNode, useEffect } from 'react';

import { GenericInputWrapper, commonInputProperties } from '../styles';
import { useUiMultiInputOnChangeHandler } from './useUiMultiInputOnChangeHandler';
import { CommonProps, ElementSize } from '../../types';
import { querySelectorAllShadows } from '../../utils';

type Props = CommonProps & {
  size?: ElementSize;
  onChange?: (value?: string) => void;
  onClick?: () => void;
  value?: string;
  placeholder?: string;
  className?: string;
  endContent?: ReactNode | ReactNode[];
  tokens?: ReactNode | ReactNode[];
  label?: string;
  isDisabled?: boolean;
};

const StyledMultiInput = styled(MultiInput)`
  ${commonInputProperties()};
`;

export const UiMultiInput = ({
  endContent,
  tokens,
  onChange,
  size = ElementSize.SMALL,
  label,
  className,
  isDisabled,
  ...props
}: Props) => {
  const onChangeHandler = useUiMultiInputOnChangeHandler(onChange);

  // dirty fix to hide token menus (fired when "more" is clicked)
  useEffect(() => {
    const tokenMenus = querySelectorAllShadows('ui5-list.ui5-tokenizer-list');
    tokenMenus.forEach((el) => {
      const parent = el.parentElement;
      if (!parent) return;
      parent.style.visibility = 'hidden';
    });
  }, [tokens]);

  return (
    <GenericInputWrapper
      className={className}
      data-ui5-compact-size={size === ElementSize.SMALL ? true : undefined}
    >
      {label && <Label>{label}:</Label>}
      <StyledMultiInput
        onInput={onChangeHandler}
        tokens={!!tokens && <>{tokens}</>}
        icon={!!endContent && <>{endContent}</>}
        disabled={isDisabled}
        {...props}
      />
    </GenericInputWrapper>
  );
};
