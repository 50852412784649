import { RouteObject } from 'react-router-dom';

import { ReportedEnergyPage } from '../ReportedEnergyPage';
import { REPORTED_ENERGY_ROUTE } from './routes';

export const ReportedEnergyRouting: RouteObject = {
  path: REPORTED_ENERGY_ROUTE,
  children: [
    {
      index: true,
      element: <ReportedEnergyPage />,
    },
  ],
};
