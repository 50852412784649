import { Navigate } from 'react-router-dom';

import { KpiRouting } from '@GlobalProviders/MultiApp/KpiRouting';
import { KPIRoutingFactory } from '@Pages/Shared/Routing/KPIRoutingFactory';

import { SINGLE_EXPOSURE_ROUTE } from './SingleExposure/Routing/routes';
import { SingleExposureRouting } from './SingleExposure/Routing/SingleExposureRouting';

export const InsightsGHGRouting = KPIRoutingFactory(
  [{ index: true, element: <Navigate to={SINGLE_EXPOSURE_ROUTE} /> }, SingleExposureRouting],
  KpiRouting.GHG,
);
