import styled from '@emotion/styled';

import { useUiCommonTranslation } from '@GlobalProviders/i18n/hooks';

import { useNavigationPanelContext } from '../NavigationPanelContext';
import { UiTitle } from '../../../../Components/UiTitle';
import { StyleProps } from '../types';
import LogoSvg from './Logo.svg';

const LogoIconWrapper = styled.div`
  height: 100%;
`;

const LogoWrapper = styled.div<StyleProps>`
  margin: 2rem 0.75rem 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const Logo = () => {
  const { isPanelExtended } = useNavigationPanelContext();
  const { t } = useUiCommonTranslation();

  return (
    <LogoWrapper isExtended={isPanelExtended}>
      <LogoIconWrapper>
        <LogoSvg />
      </LogoIconWrapper>
      {isPanelExtended && <UiTitle level='H4'>{t('layout.navigationPanel.logo')}</UiTitle>}
    </LogoWrapper>
  );
};
