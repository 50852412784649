import { BusyIndicator } from '@ui5/webcomponents-react';

import { CommonProps } from '../types';

type Props = CommonProps & {
  isActive: boolean;
  delay?: number;
};

export const UiBusyIndicator = ({ className, isActive, children, delay = 0 }: Props) => (
  <BusyIndicator className={className} active={isActive} delay={delay}>
    {children}
  </BusyIndicator>
);
