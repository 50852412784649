import styled from '@emotion/styled';
import { css } from '@emotion/css';

export const SortingItemWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  height: 3.75rem;
`;

export const ContentWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
`;

export const textStyles = css`
  display: flex;
  align-items: center;
`;

export const smallSelectClass = css`
  width: 13.125rem;
  height: 26px;
`;
