import { EsgProduct } from '@DataAccess/Platform/Products/products.types';
import { OVERVIEW_ROUTE } from '@Pages/Overview/Routing/routes';

import { KpiRouting } from './KpiRouting';

const mapper: Record<EsgProduct, string> = {
  [EsgProduct.GHG]: `/${OVERVIEW_ROUTE}/${KpiRouting.GHG}`,
  [EsgProduct.PHYSICAL_RISK]: `/${OVERVIEW_ROUTE}/${KpiRouting.PHYSICAL_RISK}`,
};

export const defaultAppRouting = (product: EsgProduct) => mapper[product];
