import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { ReactNode } from 'react';

import { StyledTr } from './Styles';

type Props = {
  id: string;
  className?: string;
  children: ReactNode;
  isDragAndDropActive?: boolean;
};

export const DraggableRow = ({ id, className, children, isDragAndDropActive }: Props) => {
  const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
  });

  const dndAttributes = isDragAndDropActive
    ? {
        ...attributes,
        ...listeners,
      }
    : {};

  return (
    <StyledTr
      ref={setNodeRef}
      className={className}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        position: 'relative',
        zIndex: isDragging ? 1 : 0,
      }}
      {...dndAttributes}
    >
      {children}
    </StyledTr>
  );
};
