import { AxiosInstance } from 'axios';

import { PaginatedTableParams, PaginationResponse } from '@DataAccess/types';

import { EmissionDto } from './singleExposure.types';

export const initialiseSingleExposureEndpoints = (api: AxiosInstance) => ({
  getEmissions({
    tableParams,
    pageParam,
  }: PaginatedTableParams): Promise<PaginationResponse<EmissionDto>> {
    return api
      .post<PaginationResponse<EmissionDto>>('/ghg-emissions', {
        page: pageParam,
        ...tableParams,
      })
      .then(({ data }) => data);
  },
});
