import { Navigate } from 'react-router-dom';

import { KpiRouting } from '@GlobalProviders/MultiApp/KpiRouting';
import { KPIRoutingFactory } from '@Pages/Shared/Routing/KPIRoutingFactory';

import { PRHierarchyManagerRouting } from './HierarchyManager/PRHierarchyManagerRouting';
import { HIERARCHY_MANAGER_ROUTE } from '../dmRoutes';
import { PRProcessManagerRouting } from './ProcessManager/PRProcessManagerRouting';

export const DmPRRouting = KPIRoutingFactory(
  [
    { index: true, element: <Navigate to={HIERARCHY_MANAGER_ROUTE} /> },
    PRHierarchyManagerRouting,
    PRProcessManagerRouting,
  ],
  KpiRouting.PHYSICAL_RISK,
);
