export enum FileErrorCode {
  INVALID_ENTITY = 'INVALID_ENTITY',
  INVALID_EXTENSION = 'INVALID_EXTENSION',
  GENERAL_ERROR = 'GENERAL_ERROR',
  INVALID_GEOCOORDINATES = 'INVALID_GEOCOORDINATES',
}

export type FileErrorResponse = {
  error: FileErrorCode;
};

export enum EntityType {
  FINANCIAL_CONTRACT = 'FINANCIAL_CONTRACT',
  FINANCIAL_INSTRUMENT = 'FINANCIAL_INSTRUMENT',
  PROJECT = 'PROJECT',
  BUSINESS_PARTNER = 'BUSINESS_PARTNER',
  REPORTED_GOODS = 'REPORTED_GOODS',
  BUILDING = 'BUILDING',
  PHYSICAL_ASSET = 'PHYSICAL_ASSET',
  PHYSICAL_ASSET_TO_FINANCIAL_CONTRACT = 'PHYSICAL_ASSET_TO_FINANCIAL_CONTRACT',
  EMISSION_FACTOR_BUILDING_CERTIFICATE_CLASS = 'EMISSION_FACTOR_BUILDING_CERTIFICATE_CLASS',
  EMISSION_FACTOR_SECTOR = 'EMISSION_FACTOR_SECTOR',
  EMISSION_FACTOR_NUMBER_OF_BUILDINGS = 'EMISSION_FACTOR_NUMBER_OF_BUILDINGS',
  EMISSION_FACTOR_BUILDING_FLOOR_AREA = 'EMISSION_FACTOR_BUILDING_FLOOR_AREA',
  INDUSTRY_CLASSIFICATION_CODE = 'INDUSTRY_CLASSIFICATION_CODE',
  ASSET_VALUATION = 'ASSET_VALUATION',
  REPORTED_ENERGY = 'REPORTED_ENERGY',
  EMISSION_FACTOR_ENERGY = 'EMISSION_FACTOR_ENERGY',
  COUNTRY_REGION = 'COUNTRY_REGION',
  EMISSION_FACTOR_GOODS = 'EMISSION_FACTOR_GOODS',
  BUSINESS_PARTNER_ADDRESS = 'BUSINESS_PARTNER_ADDRESS',
  PROJECT_ADDRESS = 'PROJECT_ADDRESS',
  PHYSICAL_ASSET_ADDRESS = 'PHYSICAL_ASSET_ADDRESS',
  AIRCRAFT = 'AIRCRAFT',
}
