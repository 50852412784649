import { Navigate, RouteObject } from 'react-router-dom';

import { PhysicalAssetAcutePage } from '../Acute/PhysicalAssetAcutePage';
import { PhysicalAssetChronicPage } from '../Chronic/PhysicalAssetChronicPage';
import { PRPhysicalAssetPage } from '../PRPhysicalAssetPage';
import {
  INSIGHTS_PHYSICAL_ASSET_ROUTE,
  INSIGHTS_PHYSICAL_ASSET_ACUTE_ROUTE,
  INSIGHTS_PHYSICAL_ASSET_CHRONIC_ROUTE,
} from './routes';

export const PhysicalAssetRouting: RouteObject = {
  path: INSIGHTS_PHYSICAL_ASSET_ROUTE,
  element: <PRPhysicalAssetPage />,
  children: [
    {
      index: true,
      element: <Navigate to={INSIGHTS_PHYSICAL_ASSET_ACUTE_ROUTE} />,
    },
    {
      path: INSIGHTS_PHYSICAL_ASSET_ACUTE_ROUTE,
      element: <PhysicalAssetAcutePage />,
    },
    {
      path: INSIGHTS_PHYSICAL_ASSET_CHRONIC_ROUTE,
      element: <PhysicalAssetChronicPage />,
    },
  ],
};
