import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import api from '@DataAccess/api';

import { EntityType, FileErrorResponse } from './fileUpload.types';

type MutationVariables = {
  entityType: EntityType;
  files: FileList;
};
type HandlerProps<T = never> = {
  onSuccess?: (fileName: string) => void;
  onError?: (error?: AxiosError<T>) => void;
};

export const useUploadFileMutation = ({
  onSuccess,
  onError,
}: HandlerProps<FileErrorResponse> = {}) => {
  return useMutation({
    mutationFn: ({ entityType, files }: MutationVariables) =>
      api.platform.fileUpload.uploadFilesForEntity(entityType, files),
    onSuccess: async (_, variables) => {
      const { files: fileList } = variables;
      onSuccess?.(fileList[0].name);
    },
    onError,
  });
};
