import { useRef } from 'react';

import { toMap } from '@Utils/utils';

import { UiAnalyticalTableColumnDefinition } from '../types';

export const useTableColumnsByColumnIdRef = <DataType>() => {
  const tableColumnsByColumnId = useRef(
    new Map<string, UiAnalyticalTableColumnDefinition<DataType>>(),
  );

  const mapColumnsToRef = (columns: UiAnalyticalTableColumnDefinition<DataType>[]) =>
    (tableColumnsByColumnId.current = toMap(columns, 'id'));

  return { currentTableColumnsByColumnId: tableColumnsByColumnId.current, mapColumnsToRef };
};
