import styled from '@emotion/styled';
import { Input, InputType, Label, ValueState } from '@ui5/webcomponents-react';
import { ReactNode } from 'react';

import { GenericInputWrapper, commonInputProperties } from '../styles';
import { CommonProps, ElementSize } from '../../types';
import { useUiInputOnChangeHandler } from './useUiInputOnChangeHandler';

type Props = CommonProps & {
  size?: ElementSize;
  onChange?: (value?: string) => void;
  onBlur?: () => void;
  value?: string;
  placeholder?: string;
  className?: string;
  endContent?: ReactNode | ReactNode[];
  label?: string;
  valueState?: ValueState;
  valueStateMessage?: string;
  type?: InputType;
};

const StyledInput = styled(Input)`
  ${commonInputProperties()};
`;

export const UiInput = ({
  onChange,
  value,
  placeholder,
  className,
  size = ElementSize.SMALL,
  endContent,
  label,
  onBlur,
  type,
  valueState,
  valueStateMessage,
}: Props) => {
  const onChangeHandler = useUiInputOnChangeHandler(onChange);

  return (
    <GenericInputWrapper
      className={className}
      data-ui5-compact-size={size === ElementSize.SMALL ? true : undefined}
    >
      {label && <Label>{label}:</Label>}
      <StyledInput
        valueState={valueState}
        onBlur={onBlur}
        valueStateMessage={<span slot={valueState}>{valueStateMessage}</span>}
        type={type}
        value={value ?? ''}
        onInput={onChangeHandler}
        placeholder={placeholder}
        icon={!!endContent && <>{endContent}</>}
        data-no-dnd='true'
      />
    </GenericInputWrapper>
  );
};
