import styled from '@emotion/styled';
import { ValueState } from '@ui5/webcomponents-react';

import { useUiCommonTranslation } from '@GlobalProviders/i18n/hooks';

import { UiExportModalFooter } from './UiExportModalFooter';
import { UiExportModalHeader } from './UiExportModalHeader';
import { UiDialog } from '../../UiDialog/UiDialog';
import { UiProgressIndicator } from '../../UiProgressIndicator';

type Props = {
  isOpen: boolean;
  value: number;
  valueState?: keyof typeof ValueState;
  onCancel: () => void;
};

const StyledUiDialog = styled(UiDialog)`
  min-width: 450px;
`;

export const UiExportProgressModal = ({ isOpen, value, onCancel, valueState }: Props) => {
  const { t } = useUiCommonTranslation();

  const computedValueState = valueState || (value === 1 ? 'Success' : 'Information');

  return (
    <StyledUiDialog
      isOpen={isOpen}
      header={<UiExportModalHeader title={t('exportModal.progressBarTitle')} />}
      footer={<UiExportModalFooter onCancel={() => onCancel()} />}
    >
      <UiProgressIndicator value={value * 100} valueState={computedValueState} />
    </StyledUiDialog>
  );
};
