import { Navigate, RouteObject } from 'react-router-dom';

import { HIERARCHY_MANAGER_ROUTE } from '@Pages/DataManagement/dmRoutes';

import { DHBuildingFinancePage } from '../BuildingFinance/DHBuildingFinancePage';
import { DHCorporateFinancePage } from '../CorporateFinance/DHCorporateFinancePage';
import { GHGHierarchyManagerPage } from '../GHGHierarchyManagerPage';
import { DHPhysicalAssetFinancePage } from '../PhysicalAssetFinance/DHPhysicalAssetFinancePage';
import {
  HIERARCHY_MANAGER_CORPORATE_FINANCE_ROUTE,
  HIERARCHY_MANAGER_BUILDING_FINANCE_ROUTE,
  HIERARCHY_MANAGER_PHYSICAL_ASSET_FINANCE_ROUTE,
  HIERARCHY_PARAM_ID,
} from './routes';

export const GHGHierarchyManagerRouting: RouteObject = {
  path: HIERARCHY_MANAGER_ROUTE,
  element: <GHGHierarchyManagerPage />,
  children: [
    {
      index: true,
      element: <Navigate to={HIERARCHY_MANAGER_CORPORATE_FINANCE_ROUTE} />,
    },
    {
      path: HIERARCHY_MANAGER_CORPORATE_FINANCE_ROUTE,
      element: <DHCorporateFinancePage />,
    },
    {
      path: HIERARCHY_MANAGER_BUILDING_FINANCE_ROUTE,
      element: <DHBuildingFinancePage />,
    },
    {
      path: HIERARCHY_MANAGER_PHYSICAL_ASSET_FINANCE_ROUTE,
      element: <DHPhysicalAssetFinancePage />,
    },
    {
      path: `:${HIERARCHY_PARAM_ID}?`,
      children: [
        {
          index: true,
          element: <Navigate to={HIERARCHY_MANAGER_CORPORATE_FINANCE_ROUTE} />,
        },
        {
          path: HIERARCHY_MANAGER_CORPORATE_FINANCE_ROUTE,
          element: <DHCorporateFinancePage />,
        },
        {
          path: HIERARCHY_MANAGER_BUILDING_FINANCE_ROUTE,
          element: <DHBuildingFinancePage />,
        },
        {
          path: HIERARCHY_MANAGER_PHYSICAL_ASSET_FINANCE_ROUTE,
          element: <DHPhysicalAssetFinancePage />,
        },
      ],
    },
  ],
};
