import { ThemeProvider as SapThemeProvider } from '@ui5/webcomponents-react';
import { Global, css } from '@emotion/react';
import { ThemingParameters } from '@ui5/webcomponents-react-base';
import { setTheme } from '@ui5/webcomponents-base/dist/config/Theme';

import { CommonProps } from '@UiCommon/types';

import '@ui5/webcomponents-react/dist/Assets';

setTheme('sap_horizon');

const globalOverrides = css`
  html {
    height: 100%;
  }
  body {
    height: 100%;
    margin: 0;
    background-color: ${ThemingParameters.sapBackgroundColor};
    font-family: ${ThemingParameters.sapFontFamily};

    --_ui5_list_item_dropdown_base_height: 2.1rem !important;
  }
  #root {
    height: 100%;
  }
`;

export const ThemingProvider = ({ children }: CommonProps) => (
  <SapThemeProvider>
    <Global styles={globalOverrides} />
    {children}
  </SapThemeProvider>
);
