import { DateRangePickerDomRef, Ui5CustomEvent } from '@ui5/webcomponents-react';
import { DateRangePickerChangeEventDetail } from '@ui5/webcomponents/dist/DateRangePicker';
import { useCallback } from 'react';

export const useUiDateRangePickerOnChangeHandler = (callback?: (values: string[]) => void) =>
  useCallback(
    (event: Ui5CustomEvent<DateRangePickerDomRef, DateRangePickerChangeEventDetail>) => {
      if (!callback) return;

      const {
        target: { value },
      } = event;

      const output = value ? value.split(' - ') : [];

      callback(output);
    },
    [callback],
  );
