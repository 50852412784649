import { useCallback, useState } from 'react';

import { UiListItemType } from '../../../UiList/types';
import { UiAnalyticalTableColumnDefinition } from '../../types';
import { SettingsStateAdapter } from '../types';
import { useTableColumnsByColumnIdRef } from '../useTableColumnsByColumnIdRef';

const assignVisibilityToColumns = <DataType>(
  columnsById: Map<string, UiAnalyticalTableColumnDefinition<DataType>>,
  visibleColumnsIds: string[],
) => {
  columnsById.forEach((column) => {
    column.isVisible = false;
  });
  visibleColumnsIds.forEach((columnId) => {
    const selectedColumnDef = columnsById.get(columnId);
    // NOTE: optional chaining not allowed here (TS throws an error): see https://bobbyhadz.com/blog/typescript-left-hand-side-of-assignment-not-optional
    if (selectedColumnDef) {
      selectedColumnDef.isVisible = true;
    }
  });
};

type SettingsStateControls<DataType> = SettingsStateAdapter<
  UiAnalyticalTableColumnDefinition<DataType>[]
> & {
  selected: string[];
  setSelected: (selected: string[]) => void;
  listItems: UiListItemType[];
  setListItems: (selected: UiListItemType[]) => void;
};

export const useColumnsSettingsState = <DataType>(): SettingsStateControls<DataType> => {
  // INTERNAL STATE:
  const { currentTableColumnsByColumnId, mapColumnsToRef } =
    useTableColumnsByColumnIdRef<DataType>();
  const [selected, setSelected] = useState<string[]>([]);
  const [listItems, setListItems] = useState<UiListItemType[]>([]);

  // STATE METHODS:
  const reset = useCallback(() => {
    setSelected([]);
    setListItems([]);
  }, [setSelected, setListItems]);

  const initStateWithData = useCallback(
    (columns: UiAnalyticalTableColumnDefinition<DataType>[] = []) => {
      mapColumnsToRef(columns);
      const newSelectedColumns: string[] = [];
      const newColumnsListItem: UiListItemType[] = [];

      columns.forEach(({ label, id, isVisible }) => {
        newColumnsListItem.push({
          label,
          value: id,
        });

        if (isVisible) {
          newSelectedColumns.push(id);
        }
      });

      setSelected(newSelectedColumns);
      setListItems(newColumnsListItem);
    },
    [setSelected, setListItems],
  );

  const getDataFromState = useCallback(() => {
    assignVisibilityToColumns(currentTableColumnsByColumnId, selected);

    return listItems
      .map(({ value }) => currentTableColumnsByColumnId.get(value))
      .filter((item) => !!item) as UiAnalyticalTableColumnDefinition<DataType>[];
  }, [selected, setSelected, listItems, setListItems, currentTableColumnsByColumnId]);

  return {
    selected,
    setSelected,
    listItems,
    setListItems,
    initStateWithData,
    getDataFromState,
    reset,
  };
};
