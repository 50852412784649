import { useMemo } from 'react';
import { TextAlign } from '@ui5/webcomponents-react';

import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';
import { UiAnalyticalTableColumnDefinition } from '@UiCommon/Components';
import { UiCurrencyFormatter } from '@UiCommon/Components/UiFormatter/UiCurrencyFormatter';
import { UiNumberFormatter } from '@UiCommon/Components/UiFormatter/UiNumberFormatter';
import { TableInstance, UiTableColumnDataType } from '@UiCommon/Components/UiAnalyticalTable/types';
import { EmissionDto } from '@DataAccess/GHG/SingleExposure/singleExposure.types';
import { useSettingsStore } from '@GlobalProviders/SettingsProvider';

const getProject = (tableApi: TableInstance<EmissionDto>) => tableApi.row.original.project;

export const useProjectColumns = () => {
  const { t } = useGHGTranslation();
  const { currency } = useSettingsStore();

  return useMemo(() => {
    const output: UiAnalyticalTableColumnDefinition<EmissionDto>[] = [
      {
        label: t('singleExposure.table.project.projectId'),
        accessor: 'project.id',
        id: 'project.id',
        minWidth: 188,
        isVisible: true,
        beField: 'PROJECT_ID',
      },
      {
        label: t('singleExposure.table.project.name'),
        accessor: 'project.name',
        id: 'project.name',
        minWidth: 170,
        isVisible: true,
        beField: 'PROJECT_NAME',
      },
      {
        label: t('singleExposure.table.project.projectCategory'),
        accessor: 'project.category',
        id: 'project.category',
        minWidth: 212,
        isVisible: true,
        beField: 'PROJECT_CATEGORY',
      },
      {
        label: t('singleExposure.table.project.naceCode'),
        accessor: 'project.naceCode',
        id: 'project.naceCode',
        minWidth: 94,
        isVisible: true,
        beField: 'PROJECT_NACE_CODE',
      },
      {
        label: t('singleExposure.table.project.countryRegion'),
        accessor: 'project.countryRegion',
        id: 'project.countryRegion',
        minWidth: 70,
        isVisible: true,
        beField: 'PROJECT_COUNTRY_REGION',
      },
      {
        label: t('singleExposure.table.project.totalAssets', { currency }),
        accessor: 'project.totalAssets',
        id: 'project.totalAssets',
        minWidth: 70,
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'PROJECT_TOTAL_ASSETS',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiCurrencyFormatter value={getProject(table)?.totalAssets} />
        ),
      },
      {
        label: t('singleExposure.table.project.totalRevenue', { currency }),
        accessor: 'project.totalRevenue',
        id: 'project.totalRevenue',
        minWidth: 70,
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'PROJECT_TOTAL_REVENUE',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiCurrencyFormatter value={getProject(table)?.totalRevenue} />
        ),
      },
      {
        label: t('singleExposure.table.project.emissionsScope1'),
        accessor: 'project.emissionsScope1',
        id: 'project.emissionsScope1',
        minWidth: 140,
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'PROJECT_EMISSIONS_SCOPE_1',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiNumberFormatter value={getProject(table)?.emissionsScope1} />
        ),
      },
      {
        label: t('singleExposure.table.project.emissionsScope2'),
        accessor: 'project.emissionsScope2',
        id: 'project.emissionsScope2',
        minWidth: 140,
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'PROJECT_EMISSIONS_SCOPE_2',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiNumberFormatter value={getProject(table)?.emissionsScope2} />
        ),
      },
      {
        label: t('singleExposure.table.project.emissionsScope3'),
        accessor: 'project.emissionsScope3',
        id: 'project.emissionsScope3',
        minWidth: 140,
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'PROJECT_EMISSIONS_SCOPE_3',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiNumberFormatter value={getProject(table)?.emissionsScope3} />
        ),
      },
      {
        label: t('singleExposure.table.project.emissionsScope1Plus2'),
        accessor: 'project.emissionsScope1Plus2',
        id: 'project.emissionsScope1Plus2',
        minWidth: 140,
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'PROJECT_EMISSIONS_SCOPE_1_PLUS_2',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiNumberFormatter value={getProject(table)?.emissionsScope1Plus2} />
        ),
      },
      {
        label: t('singleExposure.table.project.emissionsVerifiedFlag'),
        accessor: 'project.emissionVerifiedFlag',
        id: 'project.emissionVerifiedFlag',
        minWidth: 135,
        isVisible: true,
        beField: 'PROJECT_EMISSION_VERIFIED_FLAG',
        type: UiTableColumnDataType.BOOLEAN,
      },
      {
        label: t('singleExposure.table.project.avoidedEmissionsFlag'),
        accessor: 'project.avoidedEmissionsFlag',
        id: 'project.avoidedEmissionsFlag',
        isVisible: true,
        type: UiTableColumnDataType.BOOLEAN,
        beField: 'PROJECT_AVOIDED_EMISSIONS_FLAG',
      },
      {
        label: t('singleExposure.table.project.avoidedEmissions'),
        accessor: 'project.avoidedEmissions',
        id: 'project.avoidedEmissions',
        minWidth: 210,
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'PROJECT_AVOIDED_EMISSIONS',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiNumberFormatter value={getProject(table)?.avoidedEmissions} />
        ),
      },
      {
        label: t('singleExposure.table.project.baselineEmissions'),
        accessor: 'project.baselineEmissions',
        id: 'project.baselineEmissions',
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'PROJECT_BASELINE_EMISSIONS',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiNumberFormatter value={getProject(table)?.baselineEmissions} />
        ),
      },
      {
        label: t('singleExposure.table.project.dataQualityScoreBaselineEmissions'),
        accessor: 'project.dataQualityScoreBaselineEmissions',
        id: 'project.dataQualityScoreBaselineEmissions',
        isVisible: true,
        beField: 'PROJECT_DATA_QUALITY_SCORE_BASELINE_EMISSIONS',
      },
      {
        label: t('singleExposure.table.project.dataQualityScoreOptionBaselineEmissions'),
        accessor: 'project.dataQualityScoreOptionBaselineEmissions',
        id: 'project.dataQualityScoreOptionBaselineEmissions',
        isVisible: true,
        beField: 'PROJECT_DATA_QUALITY_SCORE_OPTION_BASELINE_EMISSIONS',
      },
      {
        label: t('singleExposure.table.project.dataQualityScoreScope1'),
        accessor: 'project.dataQualityScoreScope1',
        id: 'project.dataQualityScoreScope1',
        minWidth: 140,
        isVisible: true,
        beField: 'PROJECT_DATA_QUALITY_SCORE_SCOPE_1',
      },
      {
        label: t('singleExposure.table.project.dataQualityScoreScope2'),
        accessor: 'project.dataQualityScoreScope2',
        id: 'project.dataQualityScoreScope2',
        minWidth: 140,
        isVisible: true,
        beField: 'PROJECT_DATA_QUALITY_SCORE_SCOPE_2',
      },
      {
        label: t('singleExposure.table.project.dataQualityScoreScope3'),
        accessor: 'project.dataQualityScoreScope3',
        id: 'project.dataQualityScoreScope3',
        minWidth: 140,
        isVisible: true,
        beField: 'PROJECT_DATA_QUALITY_SCORE_SCOPE_3',
      },
      {
        label: t('singleExposure.table.project.dataQualityScoreScope1Plus2'),
        accessor: 'project.dataQualityScoreScope1Plus2',
        id: 'project.dataQualityScoreScope1Plus2',
        minWidth: 140,
        isVisible: true,
        beField: 'PROJECT_DATA_QUALITY_SCORE_SCOPE_1_PLUS_2',
      },
      {
        label: t('singleExposure.table.project.dataQualityScoreOptionScope1'),
        accessor: 'project.dataQualityScoreOptionScope1',
        id: 'project.dataQualityScoreOptionScope1',
        minWidth: 210,
        isVisible: true,
        beField: 'PROJECT_DATA_QUALITY_SCORE_OPTION_SCOPE_1',
      },
      {
        label: t('singleExposure.table.project.dataQualityScoreOptionScope2'),
        accessor: 'project.dataQualityScoreOptionScope2',
        id: 'project.dataQualityScoreOptionScope2',
        minWidth: 210,
        isVisible: true,
        beField: 'PROJECT_DATA_QUALITY_SCORE_OPTION_SCOPE_2',
      },
      {
        label: t('singleExposure.table.project.dataQualityScoreOptionScope3'),
        accessor: 'project.dataQualityScoreOptionScope3',
        id: 'project.dataQualityScoreOptionScope3',
        minWidth: 210,
        isVisible: true,
        beField: 'PROJECT_DATA_QUALITY_SCORE_OPTION_SCOPE_3',
      },
      {
        label: t('singleExposure.table.project.dataQualityScoreOptionScope1Plus2'),
        accessor: 'project.dataQualityScoreOptionScope1Plus2',
        id: 'project.dataQualityScoreOptionScope1Plus2',
        minWidth: 210,
        isVisible: true,
        beField: 'PROJECT_DATA_QUALITY_SCORE_OPTION_SCOPE_1_PLUS_2',
      },
      {
        label: t('singleExposure.table.project.emissionFactorScope1'),
        accessor: 'project.emissionFactorScope1',
        id: 'project.emissionFactorScope1',
        minWidth: 210,
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'PROJECT_EMISSION_FACTOR_SCOPE_1',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiNumberFormatter value={getProject(table)?.emissionFactorScope1} />
        ),
      },
      {
        label: t('singleExposure.table.project.emissionFactorScope2'),
        accessor: 'project.emissionFactorScope2',
        id: 'project.emissionFactorScope2',
        minWidth: 210,
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'PROJECT_EMISSION_FACTOR_SCOPE_2',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiNumberFormatter value={getProject(table)?.emissionFactorScope2} />
        ),
      },
      {
        label: t('singleExposure.table.project.emissionFactorScope3'),
        accessor: 'project.emissionFactorScope3',
        id: 'project.emissionFactorScope3',
        minWidth: 210,
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'PROJECT_EMISSION_FACTOR_SCOPE_3',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiNumberFormatter value={getProject(table)?.emissionFactorScope3} />
        ),
      },
      {
        label: t('singleExposure.table.project.emissionFactorLocationReference'),
        accessor: 'project.emissionFactorLocationReference',
        id: 'project.emissionFactorLocationReference',
        isVisible: true,
        beField: 'PROJECT_EMISSION_FACTOR_LOCATION_REFERENCE',
      },
      {
        label: t('singleExposure.table.project.naceIndustry'),
        accessor: 'project.naceIndustry',
        id: 'project.naceIndustry',
        minWidth: 170,
        isVisible: true,
        beField: 'PROJECT_NACE_INDUSTRY',
      },
      {
        label: t('singleExposure.table.project.naceSection'),
        accessor: 'project.naceSection',
        id: 'project.naceSection',
        minWidth: 170,
        isVisible: true,
        beField: 'PROJECT_NACE_SECTION',
      },
      {
        label: t('singleExposure.table.project.directEmissionsDataSource'),
        accessor: 'project.directEmissionsDataSource',
        id: 'project.directEmissionsDataSource',
        isVisible: true,
        beField: 'PROJECT_DIRECT_EMISSIONS_DATA_SOURCE',
      },
      {
        label: t('singleExposure.table.project.financialsDataSource'),
        accessor: 'project.financialsDataSource',
        id: 'project.financialsDataSource',
        isVisible: true,
        beField: 'PROJECT_FINANCIALS_DATA_SOURCE',
      },
      {
        label: t('singleExposure.table.project.emissionFactorSectorDataSource'),
        accessor: 'project.emissionFactorSectorDataSource',
        id: 'project.emissionFactorSectorDataSource',
        isVisible: true,
        beField: 'PROJECT_EMISSION_FACTOR_SECTOR_DATA_SOURCE',
      },
    ];

    return output;
  }, [t]);
};
