import { useState } from 'react';

import { SettingsTab } from './useSettingsModalTabs';

export const useSettingsModalState = () => {
  const [tab, setTab] = useState<SettingsTab>();
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  const openSettingsTab = (tabId: SettingsTab) => {
    setTab(tabId);
    setIsSettingsModalOpen(true);
  };

  return {
    tab,
    setTab,
    isSettingsModalOpen,
    setIsSettingsModalOpen,
    openSettingsTab,
  };
};
