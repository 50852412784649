import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { ThemingParameters } from '@ui5/webcomponents-react-base';
import { HTMLAttributes } from 'react';

import { UiDialog } from '../../../UiDialog/UiDialog';

export const FilterSettingsList = styled.ul`
  padding: 0;
  margin: 0;
`;

const StyledFilterSettingsRow = styled.li`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 1rem;
  background-color: ${ThemingParameters.sapList_Background};
  border-bottom: 1px solid ${ThemingParameters.sapList_BorderColor};

  &:hover {
    background-color: ${ThemingParameters.sapList_Hover_Background};
    cursor: pointer;
  }
`;

export const stretchedUiInputClass = css`
  flex-grow: 1;
`;

export const ConditionsDialog = styled(UiDialog)`
  width: 80vw;
  min-width: 600px;
  max-width: 1000px;
`;

export const DialogContentWrapper = styled.section`
  height: 30vh;
`;

export const ConditionsWrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

export const AddRowButtonWrapper = styled.div`
  float: right;
  margin-top: 1rem;
`;

export const uiTextClass = css`
  flex-basis: calc(100% / 3);
`;

export const uiMultiInputClass = css`
  flex-basis: calc(100% / 3 * 2);
  max-width: 60%;
`;

export const ConditionRowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const uiSelectClass = css`
  width: 13.125rem;
`;

// make it focusable on click via tabIndex attribute
export const FilterSettingsRow = ({ tabIndex = 0, ...props }: HTMLAttributes<HTMLLIElement>) => (
  <StyledFilterSettingsRow tabIndex={tabIndex} {...props} />
);
