const translation = {
  navigationPanel: {
    links: {
      dataManagement: 'Data Management',
      overview: 'Overview',
      insights: 'Insights',
      singleExposure: 'Single Exposure',
      processManager: 'Upload & Process Manager',
      hierarchyManager: 'Hierarchy Manager',
      dataUpload: 'Data Upload',
      customization: 'Customization',
      splitScope: 'Split Scope 1+2',
      settings: 'Settings',
      physicalAsset: 'Physical Asset',
      sensitivityFlags: 'Sensitivity Flags',
    },
  },
  hierarchyManager: {
    title: 'Hierarchy Manager',
    genericToastError: 'Failed to update data hierarchy',
    subtitle:
      'On this page, you can select the hierarchy of the data sources used for your KPI calculations.',
    score: 'Score',
    addNewSource: 'Add new',
    corporateFinance: {
      title: 'Corporate Finance',
    },
    buildingFinance: {
      title: 'Building Finance',
    },
    physicalAssetFinance: {
      title: 'Physical Asset Finance',
    },
    subcategory: {
      DIRECT_EMISSIONS: 'Direct Emissions',
      ENERGY: 'Energy',
      ENERGY_EMISSION_FACTOR: 'Energy Emission Factor',
      PRODUCTION: 'Production',
      PRODUCTION_EMISSION_FACTOR: 'Production Emission Factor',
      CORE: 'Core',
      SECTOR_EMISSION_FACTOR: 'Sector Emission Factor',
      BUILDING: 'Building',
      BUILDING_CERTIFICATE_CLASS_EMISSION_FACTOR: 'Building Certificate Class Emission Factor',
      FLOOR_AREA_EMISSION_FACTOR: 'Floor Area Emission Factor',
      BUILDINGS_EMISSION_FACTOR: 'Buildings Emission Factor',
      PHYSICAL_ASSET: 'Physical Asset',
    },
    addSourceDialog: {
      title: 'Add Source',
      confirm: 'Ok',
      cancel: 'Cancel',
      inputLabel: 'Source Name',
    },
    editDialog: {
      title: 'Edit {{sourceName}}',
      enable: {
        title: 'Include in Calculation',
        subText:
          'If disabled, data can still be uploaded for the source, but will not be used in calculations.',
      },
      confirm: 'Update',
      cancel: 'Cancel',
      delete: 'Delete',
      name: 'Name',
    },
    inputErrors: {
      emptySource: 'Please enter source name',
      duplicateSource: 'Source already exists. Please enter a unique value.',
    },
    versioningMenu: {
      searchPlaceholder: 'Search',
      datePicker: {
        label: 'Date Range',
        placeholder: 'Select date range',
      },
      id: 'ID: {{id}}',
    },
  },
  overview: {
    table: {
      total: 'Total',
    },
    title: 'Overview',
    kpiInfo: {
      title: 'KPI Info',
      totalFinancedScope1: 'Total Financed Scope 1 Emissions tCO\u2082e',
      totalFinancedScope2: 'Total Financed Scope 2 Emissions tCO\u2082e',
      totalFinancedScope3: 'Total Financed Scope 3 Emissions tCO\u2082e',
      totalExposure: 'Total Exposure',
      exposureAssessed: 'Exposure Assessed',
      totalDataSets: 'Total Data Sets',
      totalFinancedAvoidedEmissions: 'Total Financed Avoided Emissions tCO\u2082e',
      totalAvoidedEmissionsDataSets: 'Total Data Sets Avoided Emissions',
      businessDate: 'Business Date',
      actualStartDate: 'Actual Start',
    },
    dataQualityOverview: {
      title: 'Data Quality Overview - Scope {{count}}',
      assetClass: 'PCAF Asset Class',
      dataQualityScore: 'Data Quality Score',
      dataSets: 'Data Sets',
      exposure: 'Exposure {{currency}}',
      totalFinanced: 'Financed Scope {{count}} Emissions tCO2e',
    },
    filters: {
      emissions: 'GHG Emissions',
      adapt: 'Adapt Filters {{count}}',
    },
    versioningMenu: {
      searchPlaceholder: 'Search',
      datePicker: {
        label: 'Business Date',
        placeholder: 'Select date',
      },
      actualStartDate: 'Actual Start:',
      businessDate: 'Business Date:',
      id: 'ID:',
    },
  },
  splitScope: {
    title: 'Split Scope 1+2',
    createRule: 'Create Rule',
    cancel: 'Cancel',
    deleteRule: 'Delete Rule',
    save: 'Save',
    ok: 'Ok',
    rules: 'Rules ({{amount}})',
    splitScopeValidationError: 'The sum of Scope 1 and Scope 2 Emissions must equal 100%',
    countryValidationError: 'Country needs to be exactly 2 characters long or *',
    duplicatedRuleError: 'Rule with the same data already exists',
    default: 'Default',
    defaultTooltip: 'Default rule will be executed if other rules fail',
    tabs: {
      businessPartner: 'Business Partner',
      project: 'Project',
      building: 'Building',
    },
    businessPartner: {
      ruleOrderNumber: 'Rule Order No.',
      naceCode: 'NACE Code',
      secondaryClassificationCode: 'Secondary Classification Code',
      country: 'Country',
      category: 'Category',
      splitScope1Emissions: 'Split Scope 1 Emissions',
      splitScope2Emissions: 'Split Scope 2 Emissions',
    },
    project: {
      ruleOrderNumber: 'Rule Order No.',
      naceCode: 'NACE Code',
      secondaryClassificationCode: 'Secondary Classification Code',
      country: 'Country',
      category: 'Category',
      splitScope1Emissions: 'Split Scope 1 Emissions',
      splitScope2Emissions: 'Split Scope 2 Emissions',
    },
    building: {
      ruleOrderNumber: 'Rule Order No.',
      realEstateType: 'Real Estate Type',
      country: 'Country',
      buildingCertificateClass: 'Building Certificate Class',
      splitScope1Emissions: 'Split Scope 1 Emissions',
      splitScope2Emissions: 'Split Scope 2 Emissions',
    },
  },
  singleExposure: {
    title: 'Single Exposure',
    emissions: 'GHG Emissions',
    dataSets: 'Data Sets ({{count}})',
    table: {
      processId: 'Process ID',
      processActualStartDate: 'Actual Start',
      dataHierarchy: 'Data Hierarchy',
      processBusinessDate: 'Business Date',
      processSystemDate: 'System Date Parameter',
      product: {
        financialProductId: 'Financial Product ID',
        productType: 'Product Type',
        naceCode: 'Product NACE Code',
        isin: 'ISIN',
        attributionFactor: 'Attribution Factor',
        outstandingAmount: 'Outstanding Amount {{currency}}',
        originationDate: 'Product Origination Date',
        bookValue: 'Book Value {{currency}}',
        exposure: 'Exposure {{currency}}',
        useOfProceeds: 'Use of Proceeds',
        pcafAssetClass: 'PCAF Asset Class',
        financedScope1Emissions: 'Financed Emissions Scope 1',
        financedScope2Emissions: 'Financed Emissions Scope 2',
        financedScope3Emissions: 'Financed Emissions Scope 3',
        financedAvoidedEmissions: 'Financed Avoided Emissions tCO2e',
        dataQualityScoreScope1: 'Product Data Quality Score Scope 1',
        dataQualityScoreScope2: 'Product Data Quality Score Scope 2',
        dataQualityScoreScope3: 'Product Data Quality Score Scope 3',
        dataQualityScoreOptionScope1: 'Product Data Quality Score Option Scope 1',
        dataQualityScoreOptionScope2: 'Product Data Quality Score Option Scope 2',
        dataQualityScoreOptionScope3: 'Product Data Quality Score Option Scope 3',
        emissionFactorScope1: 'Product Emission Factor Scope 1',
        emissionFactorScope2: 'Product Emission Factor Scope 2',
        emissionFactorScope3: 'Product Emission Factor Scope 3',
        emissionFactorLocationReference: 'Product Emission Factor Location Reference',
        emissionIntensityScope1: 'Product Emission Intensity Scope 1',
        emissionIntensityScope2: 'Product Emission Intensity Scope 2',
        emissionIntensityScope3: 'Product Emission Intensity Scope 3',
        naceIndustry: 'Product NACE Industry',
        naceSection: 'Product NACE Section',
        emissionFactorSectorDataSource: 'Product Emission Factor Sector Data Source',
      },
      businessPartner: {
        businessPartnerId: 'Business Partner ID',
        name: 'Business Partner Name',
        lei: 'LEI',
        businessPartnerCategory: 'Business Partner Category',
        naceCode: 'Business Partner NACE Code',
        listedCompanyFlag: 'Listed Company Flag',
        countryRegion: 'Business Partner Country/Region',
        evic: 'EVIC {{currency}}',
        emissionsVerifiedFlag: 'Business Partner Emissions Verified Flag',
        totalRevenue: 'Business Partner Total Revenue {{currency}}',
        totalAssets: 'Business Partner Total Assets {{currency}}',
        totalEmissions: 'Total Emissions',
        emissionsScope1: 'Business Partner Emissions Scope 1',
        emissionsScope2: 'Business Partner Emissions Scope 2',
        emissionsScope3: 'Business Partner Emissions Scope 3',
        emissionsScope1Plus2: 'Business Partner Emissions Scope 1+2',
        avoidedEmissionsFlag: 'Business Partner Avoided Emissions Flag',
        baselineEmissions: 'Business Partner Baseline Emissions',
        avoidedEmissions: 'Business Partner Avoided Emissions',
        dataQualityScoreBaselineEmissions: 'Business Partner Data Quality Score Baseline Emissions',
        dataQualityScoreOptionBaselineEmissions:
          'Business Partner Data Quality Score Option Baseline Emissions',
        dataQualityScoreScope1: 'Business Partner Data Quality Score Scope 1',
        dataQualityScoreScope2: 'Business Partner Data Quality Score Scope 2',
        dataQualityScoreScope3: 'Business Partner Data Quality Score Scope 3',
        dataQualityScoreScope1Plus2: 'Business Partner Data Quality Score Scope 1+2',
        dataQualityScoreOptionScope1: 'Business Partner Data Quality Score Option Scope 1',
        dataQualityScoreOptionScope2: 'Business Partner Data Quality Score Option Scope 2',
        dataQualityScoreOptionScope3: 'Business Partner Data Quality Score Option Scope 3',
        dataQualityScoreOptionScope1Plus2: 'Business Partner Data Quality Score Option Scope 1+2',
        emissionFactorScope1: 'Business Partner Emission Factor Scope 1',
        emissionFactorScope2: 'Business Partner Emission Factor Scope 2',
        emissionFactorScope3: 'Business Partner Emission Factor Scope 3',
        emissionFactorLocationReference: 'Business Partner Emission Factor Location Reference',
        revenueEmissionIntensityScope1: 'Business Partner Revenue Emission Intensity Scope 1',
        revenueEmissionIntensityScope2: 'Business Partner Revenue Emission Intensity Scope 2',
        revenueEmissionIntensityScope3: 'Business Partner Revenue Emission Intensity Scope 3',
        naceIndustry: 'Business Partner NACE Industry',
        naceSection: 'Business Partner NACE Section',
        financialsDataSource: 'Business Partner Core Data Source',
        directEmissionsDataSource: 'Business Emissions Data Source',
        emissionFactorSectorDataSource: 'Business Partner Emission Factor Sector Data Source',
      },
      project: {
        countryRegion: 'Project Country/Region',
        projectCategory: 'Project Category',
        projectId: 'Project ID',
        naceCode: 'Project NACE Code',
        name: 'Project Name',
        totalRevenue: 'Project Total Revenue {{currency}}',
        totalAssets: 'Project Total Assets {{currency}}',
        emissionsScope1: 'Project Emissions Scope 1',
        emissionsScope2: 'Project Emissions Scope 2',
        emissionsScope3: 'Project Emissions Scope 3',
        emissionsScope1Plus2: 'Project Emissions Scope 1+2',
        emissionsVerifiedFlag: 'Project Emissions Verified Flag',
        avoidedEmissionsFlag: 'Project Avoided Emissions Flag',
        avoidedEmissions: 'Project Avoided Emissions',
        dataQualityScoreScope1: 'Project Data Quality Score Scope 1',
        dataQualityScoreScope2: 'Project Data Quality Score Scope 2',
        dataQualityScoreScope3: 'Project Data Quality Score Scope 3',
        dataQualityScoreScope1Plus2: 'Project Data Quality Score Scope 1+2',
        dataQualityScoreOptionScope1: 'Project Data Quality Score Option Scope 1',
        dataQualityScoreOptionScope2: 'Project Data Quality Score Option Scope 2',
        dataQualityScoreOptionScope3: 'Project Data Quality Score Option Scope 3',
        dataQualityScoreOptionScope1Plus2: 'Project Data Quality Score Option Scope 1+2',
        emissionFactorScope1: 'Project Emission Factor Scope 1',
        emissionFactorScope2: 'Project Emission Factor Scope 2',
        emissionFactorScope3: 'Project Emission Factor Scope 3',
        emissionFactorLocationReference: 'Project Emission Factor Location Reference',
        naceIndustry: 'Project NACE Industry',
        naceSection: 'Project NACE Section',
        baselineEmissions: 'Project Baseline Emissions',
        dataQualityScoreBaselineEmissions: 'Project Data Quality Score Baseline Emissions',
        dataQualityScoreOptionBaselineEmissions:
          'Project Data Quality Score Option Baseline Emissions',
        directEmissionsDataSource: 'Project Emissions Data Source',
        financialsDataSource: 'Project Core Data Source',
        emissionFactorSectorDataSource: 'Project Emission Factor Sector Data Source',
      },
      physicalAsset: {
        physicalAssetId: 'Physical Asset ID',
        assetType: 'Physical Asset Type',
        realEstateType: 'Real Estate Type',
        availableFloorSpace: 'Available Floor Space',
        valueAtOrigination: 'Value At Origination {{currency}}',
        valuationDate: 'Valuation Date',
        country: 'Physical Asset Country/Region',
        numberOfBuildings: 'Number Of Buildings',
        certificateClass: 'Building Certificate Class',
        emissionsScope1: 'Physical Asset Emissions Scope 1',
        emissionsScope2: 'Physical Asset Emissions Scope 2',
        emissionsScope1Plus2: 'Physical Asset Emissions Scope 1+2',
        dataQualityScoreScope1: 'Physical Asset Data Quality Score Scope 1',
        dataQualityScoreScope2: 'Physical Asset Data Quality Score Scope 2',
        dataQualityScoreScope1Plus2: 'Physical Asset Data Quality Score Scope 1+2',
        dataQualityScoreOptionScope1: 'Physical Asset Data Quality Score Option Scope 1',
        dataQualityScoreOptionScope2: 'Physical Asset Data Quality Score Option Scope 2',
        dataQualityScoreOptionScope1Plus2: 'Physical Asset Data Quality Score Option Scope 1+2',
        dataSource: 'Physical Asset Data Source',
        valuationDataSource: 'Valuation Data Source',
        emissionFactorBuildingCertificateClassDataSource:
          'Emission Factor Building Certificate Class Data Source',
        emissionFactorBuildingFloorAreaDataSource:
          'Emission Factor Building Floor Area Data Source',
        emissionFactorNumberOfBuildingsDataSource:
          'Emission Factor Number of Buildings Data Source',
        emissionsDataSource: 'Physical Asset Emissions Data Source',
      },
      useOfProceedsLabel: {
        UNKNOWN_GENERAL_PURPOSE: 'Unknown/General Purpose',
        RESIDENTIAL_REAL_ESTATE_PURCHASE: 'Building Finance - Mortgage',
        COMMERCIAL_REAL_ESTATE_PURCHASE: 'Building Finance - Commercial real estate',
        VEHICLE_FINANCE: 'Vehicle Finance',
        PROJECT_FINANCING: 'Project Financing',
        SHIP_FINANCE: 'Ship Finance',
        AIRCRAFT_FINANCE: 'Aircraft Finance',
      },
    },
    filters: {
      hide: 'Hide filters',
      show: 'Show filters',
      filteredBy: 'Filtered By ({{count}})',
      adapt: 'Adapt filters ({{count}})',
      go: 'Go',
      productTypes: {
        label: 'Product Types',
        loan: 'Loan',
      },
      businessPartner: 'Business Partner',
    },
  },
  settings: {
    title: 'Settings',
    systemSettings: {
      title: 'System Settings',
      currencySection: {
        currency: 'Currency',
      },
      defaultUnitsSection: {
        title: 'Default Units',
        emissions: 'Emissions',
        energy: 'Energy',
        goods: 'Goods',
        floorArea: 'Floor Area',
      },
    },
  },
  processManager: {
    uploadModal: {
      message:
        'We recommend initializing the calculation process by uploading the files within Sections 1-2. Following this, for every new calculation iteration, it is important to upload all relevant files from Sections 3-7 to maintain computational accuracy.',
    },
  },
};

export default translation;
