export default {
  overview: 'Overview',
  physicalAsset: {
    title: 'Physical Asset',
    inputAddress: {
      countryRegion: 'Input Address Country/Region',
      street: 'Input Address Street',
      houseNr: 'Input Address House Number',
      city: 'Input Address City',
      zipCode: 'Input Address Zip Code',
      latitude: 'Input Address Latitude',
      longitude: 'Input Address Longitude',
    },
    outputAddress: {
      countryRegion: 'Output Address Country/Region',
      streetHouseNr: 'Output Address Street/House Number',
      city: 'Output Address City',
      zipCode: 'Output Address Zip Code',
    },
    shared: {
      physicalAssetId: 'Physical Asset ID',
      systemDate: 'System Date',
    },
    acute: {
      title: 'Acute',
      table: {
        processId: 'Process ID',
        geoquality: 'Geoquality',
        overallRiskScore: 'Overall Risk Score',
        overallRiskIndex: 'Overall Risk Index',
        earthquakeRiskScore: 'Earthquake Risk Score',
        earthquakeRiskIndex: 'Earthquake Risk Index',
        stormRiskScore: 'Storm Risk Score',
        stormRiskIndex: 'Storm Risk Index',
        floodRiskScore: 'Flood Risk Score',
        floodRiskIndex: 'Flood Risk Index',
        earthquakeHazardZone: 'Earthquake Hazard Zone',
        volcanoesHazardZone: 'Volcanoes Hazard Zone',
        tsunamiHazardZone: 'Tsunami Hazard Zone',
        tropicalCycloneHazardZone: 'Tropical Cyclone Hazard Zone',
        extratropicalStormHazardZone: 'Extratropical Storm Hazard Zone',
        hailHazardZone: 'Hail Hazard Zone',
        tornadoHazardZone: 'Tornado Hazard Zone',
        lightningHazardZone: 'Lightning Hazard Zone',
        riverFloodHazardZone: 'River Flood Hazard Zone',
        flashFloodHazardZone: 'Flash Flood Hazard Zone',
        stormSurgeHazardZone: 'Storm Surge Hazard Zone',
        wildfireHazardZone: 'Wildfire Hazard Zone',
        soilAndShakingHazardZone: 'Soil and Shaking Hazard Zone',
        activeFaultsDistanceInMeters: 'Active Faults Distance in Meters',
        elevationHeightInMetersASL: 'Elevation Height in Meters ASL',
        populationDensityClassificationPeoplePerSqm:
          'Population Density Classification (People per Sqm)',
        distanceToCoastInMeters: 'Distance to Coast in Meters',
        jbaFloodDefenseZonesStandardOfProtection: 'JBA Flood Defense Zones Standard of Protection',
      },
    },
    chronic: {
      title: 'Chronic',
      table: {
        processId: 'Process ID',
        geoquality: 'Geoquality',
        tropicalCycloneCurrentHazardZone: 'Tropical Cyclone Current Hazard Zone',
        tropicalCycloneRCP45Y2030HazardZone: 'Tropical Cyclone RCP45 Y2030 Hazard Zone',
        tropicalCycloneRCP45Y2050HazardZone: 'Tropical Cyclone RCP45 Y2050 Hazard Zone',
        tropicalCycloneRCP45Y2100HazardZone: 'Tropical Cyclone RCP45 Y2100 Hazard Zone',
        tropicalCycloneRCP85Y2030HazardZone: 'Tropical Cyclone RCP85 Y2030 Hazard Zone',
        tropicalCycloneRCP85Y2050HazardZone: 'Tropical Cyclone RCP85 Y2050 Hazard Zone',
        tropicalCycloneRCP85Y2100HazardZone: 'Tropical Cyclone RCP85 Y2100 Hazard Zone',
        riverFloodCurrentHazardZoneDefended: 'River Flood Current Hazard Zone (Defended)',
        riverFloodCurrentHazardZoneUndefended: 'River Flood Current Hazard Zone (Undefended)',
        riverFloodRCP45Y2030HazardZoneDefended: 'River Flood RCP45 Y2030 Hazard Zone (Defended)',
        riverFloodRCP45Y2030HazardZoneUndefended:
          'River Flood RCP45 Y2030 Hazard Zone (Undefended)',
        riverFloodRCP45Y2050HazardZoneDefended: 'River Flood RCP45 Y2050 Hazard Zone (Defended)',
        riverFloodRCP45Y2050HazardZoneUndefended:
          'River Flood RCP45 Y2050 Hazard Zone (Undefended)',
        riverFloodRCP45Y2100HazardZoneDefended: 'River Flood RCP45 Y2100 Hazard Zone (Defended)',
        riverFloodRCP45Y2100HazardZoneUndefended:
          'River Flood RCP45 Y2100 Hazard Zone (Undefended)',
        riverFloodRCP85Y2030HazardZoneDefended: 'River Flood RCP85 Y2030 Hazard Zone (Defended)',
        riverFloodRCP85Y2030HazardZoneUndefended:
          'River Flood RCP85 Y2030 Hazard Zone (Undefended)',
        riverFloodRCP85Y2050HazardZoneDefended: 'River Flood RCP85 Y2050 Hazard Zone (Defended)',
        riverFloodRCP85Y2050HazardZoneUndefended:
          'River Flood RCP85 Y2050 Hazard Zone (Undefended)',
        riverFloodRCP85Y2100HazardZoneDefended: 'River Flood RCP85 Y2100 Hazard Zone (Defended)',
        riverFloodRCP85Y2100HazardZoneUndefended:
          'River Flood RCP85 Y2100 Hazard Zone (Undefended)',
        seaLevelRiseRCP26Y2100HazardZone: 'Sea Level Rise RCP26 Y2100 Hazard Zone',
        seaLevelRiseRCP45Y2100HazardZone: 'Sea Level Rise RCP45 Y2100 Hazard Zone',
        seaLevelRiseRCP85Y2100HazardZone: 'Sea Level Rise RCP85 Y2100 Hazard Zone',
        fireWeatherStressIndexCurrentHazardZone: 'Fire Weather Stress Index Current Hazard Zone',
        fireWeatherStressIndexSSP126Y2030HazardZone:
          'Fire Weather Stress Index SSP126 Y2030 Hazard Zone',
        fireWeatherStressIndexSSP126Y2040HazardZone:
          'Fire Weather Stress Index SSP126 Y2040 Hazard Zone',
        fireWeatherStressIndexSSP126Y2050HazardZone:
          'Fire Weather Stress Index SSP126 Y2050 Hazard Zone',
        fireWeatherStressIndexSSP126Y2100HazardZone:
          'Fire Weather Stress Index SSP126 Y2100 Hazard Zone',
        fireWeatherStressIndexSSP245Y2030HazardZone:
          'Fire Weather Stress Index SSP245 Y2030 Hazard Zone',
        fireWeatherStressIndexSSP245Y2040HazardZone:
          'Fire Weather Stress Index SSP245 Y2040 Hazard Zone',
        fireWeatherStressIndexSSP245Y2050HazardZone:
          'Fire Weather Stress Index SSP245 Y2050 Hazard Zone',
        fireWeatherStressIndexSSP245Y2100HazardZone:
          'Fire Weather Stress Index SSP245 Y2100 Hazard Zone',
        fireWeatherStressIndexSSP370Y2030HazardZone:
          'Fire Weather Stress Index SSP370 Y2030 Hazard Zone',
        fireWeatherStressIndexSSP370Y2040HazardZone:
          'Fire Weather Stress Index SSP370 Y2040 Hazard Zone',
        fireWeatherStressIndexSSP370Y2050HazardZone:
          'Fire Weather Stress Index SSP370 Y2050 Hazard Zone',
        fireWeatherStressIndexSSP370Y2100HazardZone:
          'Fire Weather Stress Index SSP370 Y2100 Hazard Zone',
        fireWeatherStressIndexSSP585Y2030HazardZone:
          'Fire Weather Stress Index SSP585 Y2030 Hazard Zone',
        fireWeatherStressIndexSSP585Y2040HazardZone:
          'Fire Weather Stress Index SSP585 Y2040 Hazard Zone',
        fireWeatherStressIndexSSP585Y2050HazardZone:
          'Fire Weather Stress Index SSP585 Y2050 Hazard Zone',
        fireWeatherStressIndexSSP585Y2100HazardZone:
          'Fire Weather Stress Index SSP585 Y2100 Hazard Zone',
        droughtStressIndexCurrentHazardZone: 'Drought Stress Index Current Hazard Zone',
        droughtStressIndexSSP245Y2030HazardZone: 'Drought Stress Index SSP245 Y2030 Hazard Zone',
        droughtStressIndexSSP245Y2040HazardZone: 'Drought Stress Index SSP245 Y2040 Hazard Zone',
        droughtStressIndexSSP245Y2050HazardZone: 'Drought Stress Index SSP245 Y2050 Hazard Zone',
        droughtStressIndexSSP245Y2100HazardZone: 'Drought Stress Index SSP245 Y2100 Hazard Zone',
        droughtStressIndexSSP585Y2030HazardZone: 'Drought Stress Index SSP585 Y2030 Hazard Zone',
        droughtStressIndexSSP585Y2040HazardZone: 'Drought Stress Index SSP585 Y2040 Hazard Zone',
        droughtStressIndexSSP585Y2050HazardZone: 'Drought Stress Index SSP585 Y2050 Hazard Zone',
        droughtStressIndexSSP585Y2100HazardZone: 'Drought Stress Index SSP585 Y2100 Hazard Zone',
        heatStressIndexCurrentHazardZone: 'Heat Stress Index Current Hazard Zone',
        heatStressIndexSSP126Y2030HazardZone: 'Heat Stress Index SSP126 Y2030 Hazard Zone',
        heatStressIndexSSP126Y2040HazardZone: 'Heat Stress Index SSP126 Y2040 Hazard Zone',
        heatStressIndexSSP126Y2050HazardZone: 'Heat Stress Index SSP126 Y2050 Hazard Zone',
        heatStressIndexSSP126Y2100HazardZone: 'Heat Stress Index SSP126 Y2100 Hazard Zone',
        heatStressIndexSSP245Y2030HazardZone: 'Heat Stress Index SSP245 Y2030 Hazard Zone',
        heatStressIndexSSP245Y2040HazardZone: 'Heat Stress Index SSP245 Y2040 Hazard Zone',
        heatStressIndexSSP245Y2050HazardZone: 'Heat Stress Index SSP245 Y2050 Hazard Zone',
        heatStressIndexSSP245Y2100HazardZone: 'Heat Stress Index SSP245 Y2100 Hazard Zone',
        heatStressIndexSSP370Y2030HazardZone: 'Heat Stress Index SSP370 Y2030 Hazard Zone',
        heatStressIndexSSP370Y2040HazardZone: 'Heat Stress Index SSP370 Y2040 Hazard Zone',
        heatStressIndexSSP370Y2050HazardZone: 'Heat Stress Index SSP370 Y2050 Hazard Zone',
        heatStressIndexSSP370Y2100HazardZone: 'Heat Stress Index SSP370 Y2100 Hazard Zone',
        heatStressIndexSSP585Y2030HazardZone: 'Heat Stress Index SSP585 Y2030 Hazard Zone',
        heatStressIndexSSP585Y2040HazardZone: 'Heat Stress Index SSP585 Y2040 Hazard Zone',
        heatStressIndexSSP585Y2050HazardZone: 'Heat Stress Index SSP585 Y2050 Hazard Zone',
        heatStressIndexSSP585Y2100HazardZone: 'Heat Stress Index SSP585 Y2100 Hazard Zone',
        precipitationStressIndexCurrentHazardZone: 'Precipitation Stress Index Current Hazard Zone',
        precipitationStressIndexSSP126Y2030HazardZone:
          'Precipitation Stress Index SSP126 Y2030 Hazard Zone',
        precipitationStressIndexSSP126Y2040HazardZone:
          'Precipitation Stress Index SSP126 Y2040 Hazard Zone',
        precipitationStressIndexSSP126Y2050HazardZone:
          'Precipitation Stress Index SSP126 Y2050 Hazard Zone',
        precipitationStressIndexSSP126Y2100HazardZone:
          'Precipitation Stress Index SSP126 Y2100 Hazard Zone',
        precipitationStressIndexSSP245Y2030HazardZone:
          'Precipitation Stress Index SSP245 Y2030 Hazard Zone',
        precipitationStressIndexSSP245Y2040HazardZone:
          'Precipitation Stress Index SSP245 Y2040 Hazard Zone',
        precipitationStressIndexSSP245Y2050HazardZone:
          'Precipitation Stress Index SSP245 Y2050 Hazard Zone',
        precipitationStressIndexSSP245Y2100HazardZone:
          'Precipitation Stress Index SSP245 Y2100 Hazard Zone',
        precipitationStressIndexSSP370Y2030HazardZone:
          'Precipitation Stress Index SSP370 Y2030 Hazard Zone',
        precipitationStressIndexSSP370Y2040HazardZone:
          'Precipitation Stress Index SSP370 Y2040 Hazard Zone',
        precipitationStressIndexSSP370Y2050HazardZone:
          'Precipitation Stress Index SSP370 Y2050 Hazard Zone',
        precipitationStressIndexSSP370Y2100HazardZone:
          'Precipitation Stress Index SSP370 Y2100 Hazard Zone',
        precipitationStressIndexSSP585Y2030HazardZone:
          'Precipitation Stress Index SSP585 Y2030 Hazard Zone',
        precipitationStressIndexSSP585Y2040HazardZone:
          'Precipitation Stress Index SSP585 Y2040 Hazard Zone',
        precipitationStressIndexSSP585Y2050HazardZone:
          'Precipitation Stress Index SSP585 Y2050 Hazard Zone',
        precipitationStressIndexSSP585Y2100HazardZone:
          'Precipitation Stress Index SSP585 Y2100 Hazard Zone',
        coldStressIndexCurrentHazardZone: 'Cold Stress Index Current Hazard Zone',
        coldStressIndexSSP126Y2030HazardZone: 'Cold Stress Index SSP126 Y2030 Hazard Zone',
        coldStressIndexSSP126Y2040HazardZone: 'Cold Stress Index SSP126 Y2040 Hazard Zone',
        coldStressIndexSSP126Y2050HazardZone: 'Cold Stress Index SSP126 Y2050 Hazard Zone',
        coldStressIndexSSP126Y2100HazardZone: 'Cold Stress Index SSP126 Y2100 Hazard Zone',
        coldStressIndexSSP245Y2030HazardZone: 'Cold Stress Index SSP245 Y2030 Hazard Zone',
        coldStressIndexSSP245Y2040HazardZone: 'Cold Stress Index SSP245 Y2040 Hazard Zone',
        coldStressIndexSSP245Y2050HazardZone: 'Cold Stress Index SSP245 Y2050 Hazard Zone',
        coldStressIndexSSP245Y2100HazardZone: 'Cold Stress Index SSP245 Y2100 Hazard Zone',
        coldStressIndexSSP370Y2030HazardZone: 'Cold Stress Index SSP370 Y2030 Hazard Zone',
        coldStressIndexSSP370Y2040HazardZone: 'Cold Stress Index SSP370 Y2040 Hazard Zone',
        coldStressIndexSSP370Y2050HazardZone: 'Cold Stress Index SSP370 Y2050 Hazard Zone',
        coldStressIndexSSP370Y2100HazardZone: 'Cold Stress Index SSP370 Y2100 Hazard Zone',
        coldStressIndexSSP585Y2030HazardZone: 'Cold Stress Index SSP585 Y2030 Hazard Zone',
        coldStressIndexSSP585Y2040HazardZone: 'Cold Stress Index SSP585 Y2040 Hazard Zone',
        coldStressIndexSSP585Y2050HazardZone: 'Cold Stress Index SSP585 Y2050 Hazard Zone',
        coldStressIndexSSP585Y2100HazardZone: 'Cold Stress Index SSP585 Y2100 Hazard Zone',
      },
    },
  },
  hierarchyManager: 'Hierarchy Manager',
  sensitivityFlags: 'Sensitivity Flags',
  processManager: {
    uploadModal: {
      message: 'Uploading all files in the order show will ensure the best calculation results.',
    },
  },
};
