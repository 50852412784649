import { RouteObject } from 'react-router-dom';

import { PhysicalAssetPage } from '../PhysicalAssetPage';
import { PHYSICAL_ASSET_ROUTE } from './routes';

export const PhysicalAssetRouting: RouteObject = {
  path: PHYSICAL_ASSET_ROUTE,
  children: [
    {
      index: true,
      element: <PhysicalAssetPage />,
    },
  ],
};
