import styled from '@emotion/styled';

import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';
import { useKpiInfoQuery } from '@DataAccess/GHG/DataQuality/dataQuality.hooks';
import { HorizontalItem, UiCard, UiCardHeader, UiHorizontalList } from '@UiCommon/Components';
import { UiCurrencyFormatter } from '@UiCommon/Components/UiFormatter/UiCurrencyFormatter';
import { UiNumberFormatter } from '@UiCommon/Components/UiFormatter/UiNumberFormatter';
import { UiTonnesCo2Formatter } from '@UiCommon/Components/UiFormatter/UiTonnesCo2Formatter';
import { UiDateFormatter } from '@UiCommon/Components/UiFormatter/UiDateFormatter';
import { KpiInfoDto } from '@DataAccess/GHG/DataQuality/dataQuality.types';

type Props = {
  processId?: string;
};

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const noData: KpiInfoDto = {
  totalFinancedScope1Emissions: 0,
  totalFinancedScope2Emissions: 0,
  totalFinancedScope3Emissions: 0,
  totalFinancedAvoidedEmissions: 0,
  totalExposure: 0,
  assessedExposure: 0,
  totalDataSets: 0,
  totalAvoidedEmissionsDataSets: 0,
};

export const KpiInfo = ({ processId }: Props) => {
  const { t } = useGHGTranslation();

  const { data } = useKpiInfoQuery(processId);

  const inputData = data || noData;

  const horizontalDataRow1: HorizontalItem[] = [
    {
      term: t('overview.kpiInfo.totalFinancedScope1'),
      value: <UiTonnesCo2Formatter isPerAnnum value={inputData.totalFinancedScope1Emissions} />,
    },
    {
      term: t('overview.kpiInfo.totalFinancedScope2'),
      value: <UiTonnesCo2Formatter isPerAnnum value={inputData.totalFinancedScope2Emissions} />,
    },
    {
      term: t('overview.kpiInfo.totalFinancedScope3'),
      value: <UiTonnesCo2Formatter isPerAnnum value={inputData.totalFinancedScope3Emissions} />,
    },
    {
      term: t('overview.kpiInfo.totalFinancedAvoidedEmissions'),
      value: <UiTonnesCo2Formatter isPerAnnum value={inputData.totalFinancedAvoidedEmissions} />,
    },
  ];

  const horizontalDataRow2: HorizontalItem[] = [
    {
      term: t('overview.kpiInfo.totalExposure'),
      value: (
        <UiCurrencyFormatter style='currency' notation='compact' value={inputData.totalExposure} />
      ),
    },
    {
      term: t('overview.kpiInfo.exposureAssessed'),
      value: (
        <UiCurrencyFormatter
          style='currency'
          notation='compact'
          value={inputData.assessedExposure}
        />
      ),
    },
    {
      term: t('overview.kpiInfo.totalDataSets'),
      value: <UiNumberFormatter decimalPrecision={0} value={inputData.totalDataSets} />,
    },
    {
      term: t('overview.kpiInfo.totalAvoidedEmissionsDataSets'),
      value: (
        <UiNumberFormatter decimalPrecision={0} value={inputData.totalAvoidedEmissionsDataSets} />
      ),
    },
    {
      term: t('overview.kpiInfo.businessDate'),
      value: inputData.businessDate,
    },
    {
      term: t('overview.kpiInfo.actualStartDate'),
      value: inputData.actualStartDate ? (
        <UiDateFormatter value={inputData.actualStartDate} />
      ) : undefined,
    },
  ];

  return (
    <UiCard bodyPadding='1rem' header={<UiCardHeader titleText={t('overview.kpiInfo.title')} />}>
      <ListWrapper>
        <UiHorizontalList items={horizontalDataRow1} />
        <UiHorizontalList items={horizontalDataRow2} />
      </ListWrapper>
    </UiCard>
  );
};
