import { useMemo } from 'react';

import { useProcessManagerTranslation } from '@GlobalProviders/i18n/hooks';
import { UiTableColumnDataType } from '@UiCommon/Components/UiAnalyticalTable/types';

import { useCommonCollectedColumns } from '../useCommonCollectedColumns';

export const useCollectedProjectColumns = () => {
  const { t } = useProcessManagerTranslation();
  const commonColumns = useCommonCollectedColumns();

  return useMemo(() => {
    return [
      {
        label: t('processManager.manualUpload.project.table.projectId'),
        accessor: 'id',
        id: 'id',
        minWidth: 188,
        isVisible: true,
        beField: 'ID',
      },
      {
        label: t('processManager.manualUpload.project.table.financialProductId'),
        accessor: 'financialProductId',
        id: 'financialProductId',
        minWidth: 188,
        isVisible: true,
        beField: 'FINANCIAL_PRODUCT_ID',
      },
      {
        label: t('processManager.manualUpload.project.table.name'),
        accessor: 'name',
        id: 'name',
        minWidth: 170,
        isVisible: true,
        beField: 'NAME',
      },
      {
        label: t('processManager.manualUpload.project.table.category'),
        accessor: 'category',
        id: 'category',
        minWidth: 212,
        isVisible: true,
        beField: 'CATEGORY',
      },
      {
        label: t('processManager.manualUpload.project.table.naceCode'),
        accessor: 'naceCode',
        id: 'naceCode',
        minWidth: 94,
        isVisible: true,
        beField: 'NACE_CODE',
      },
      {
        label: t('processManager.manualUpload.project.table.countryRegion'),
        accessor: 'countryRegion',
        id: 'countryRegion',
        minWidth: 70,
        isVisible: true,
        beField: 'COUNTRY_REGION',
      },
      {
        label: t('processManager.manualUpload.project.table.totalAssets'),
        accessor: 'totalAssets',
        id: 'totalAssets',
        minWidth: 70,
        isVisible: true,
        type: UiTableColumnDataType.NUMBER,
        beField: 'TOTAL_ASSETS',
      },
      {
        label: t('processManager.manualUpload.project.table.totalRevenue'),
        accessor: 'totalRevenue',
        id: 'totalRevenue',
        minWidth: 70,
        isVisible: true,
        type: UiTableColumnDataType.NUMBER,
        beField: 'TOTAL_REVENUE',
      },
      {
        label: t('processManager.manualUpload.project.table.scope1Emissions'),
        accessor: 'scope1Emissions',
        id: 'scope1Emissions',
        minWidth: 140,
        isVisible: true,
        type: UiTableColumnDataType.NUMBER,
        beField: 'SCOPE_1_EMISSIONS',
      },
      {
        label: t('processManager.manualUpload.project.table.scope2Emissions'),
        accessor: 'scope2Emissions',
        id: 'scope2Emissions',
        minWidth: 140,
        isVisible: true,
        type: UiTableColumnDataType.NUMBER,
        beField: 'SCOPE_2_EMISSIONS',
      },
      {
        label: t('processManager.manualUpload.project.table.scope3Emissions'),
        accessor: 'scope3Emissions',
        id: 'scope3Emissions',
        minWidth: 140,
        isVisible: true,
        type: UiTableColumnDataType.NUMBER,
        beField: 'SCOPE_3_EMISSIONS',
      },
      {
        label: t('processManager.manualUpload.project.table.scope1Plus2Emissions'),
        accessor: 'scope1Plus2Emissions',
        id: 'scope1Plus2Emissions',
        minWidth: 140,
        isVisible: true,
        type: UiTableColumnDataType.NUMBER,
        beField: 'SCOPE_1_PLUS_2_EMISSIONS',
      },
      {
        label: t('processManager.manualUpload.project.table.emissionsVerifiedFlag'),
        accessor: 'isEmissionVerified',
        id: 'emissionVerifiedFlag',
        minWidth: 135,
        isVisible: true,
        type: UiTableColumnDataType.BOOLEAN,
        beField: 'IS_EMISSION_VERIFIED',
      },
      {
        label: t('processManager.manualUpload.project.table.dataQualityScoreScope1'),
        accessor: 'dataQualityScore',
        id: 'dataQualityScore',
        minWidth: 140,
        isVisible: true,
        beField: 'DATA_QUALITY_SCORE_SCOPE_1',
      },
      {
        label: t('processManager.manualUpload.project.table.dataQualityScoreScope2'),
        accessor: 'dataQualityScoreScope2',
        id: 'dataQualityScoreScope2',
        minWidth: 140,
        isVisible: true,
        beField: 'DATA_QUALITY_SCORE_SCOPE_2',
      },
      {
        label: t('processManager.manualUpload.project.table.dataQualityScoreScope3'),
        accessor: 'dataQualityScoreScope3',
        id: 'dataQualityScoreScope3',
        minWidth: 140,
        isVisible: true,
        beField: 'DATA_QUALITY_SCORE_SCOPE_3',
      },
      {
        label: t('processManager.manualUpload.project.table.dataQualityScoreScope1Plus2'),
        accessor: 'dataQualityScoreScope1Plus2',
        id: 'dataQualityScoreScope1Plus2',
        minWidth: 140,
        isVisible: true,
        beField: 'DATA_QUALITY_SCORE_SCOPE_1_PLUS_2',
      },
      {
        label: t('processManager.manualUpload.project.table.dataQualityScoreOptionScope1'),
        accessor: 'dataQualityScoreOptionScope1',
        id: 'dataQualityScoreOptionScope1',
        minWidth: 210,
        isVisible: true,
        beField: 'DATA_QUALITY_SCORE_OPTION_SCOPE_1',
      },
      {
        label: t('processManager.manualUpload.project.table.dataQualityScoreOptionScope2'),
        accessor: 'dataQualityScoreOptionScope2',
        id: 'dataQualityScoreOptionScope2',
        minWidth: 210,
        isVisible: true,
        beField: 'DATA_QUALITY_SCORE_OPTION_SCOPE_2',
      },
      {
        label: t('processManager.manualUpload.project.table.dataQualityScoreOptionScope3'),
        accessor: 'dataQualityScoreOptionScope3',
        id: 'dataQualityScoreOptionScope3',
        minWidth: 210,
        isVisible: true,
        beField: 'DATA_QUALITY_SCORE_OPTION_SCOPE_3',
      },
      {
        label: t('processManager.manualUpload.project.table.dataQualityScoreOptionScope1Plus2'),
        accessor: 'dataQualityScoreOptionScope1Plus2',
        id: 'dataQualityScoreOptionScope1Plus2',
        minWidth: 210,
        isVisible: true,
        beField: 'DATA_QUALITY_SCORE_OPTION_SCOPE_1_PLUS_2',
      },
      {
        label: t('processManager.manualUpload.project.table.avoidedEmissionsFlag'),
        accessor: 'avoidedEmissionsFlag',
        id: 'avoidedEmissionsFlag',
        isVisible: true,
        type: UiTableColumnDataType.BOOLEAN,
        beField: 'AVOIDED_EMISSIONS_FLAG',
      },
      {
        label: t('processManager.manualUpload.project.table.avoidedEmissions'),
        accessor: 'avoidedEmissions',
        id: 'avoidedEmissions',
        isVisible: true,
        type: UiTableColumnDataType.NUMBER,
        beField: 'AVOIDED_EMISSIONS',
      },
      {
        label: t('processManager.manualUpload.shared.year'),
        accessor: 'year',
        id: 'year',
        isVisible: true,
        beField: 'YEAR',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.shared.emissionsReportingDate'),
        accessor: 'emissionsReportingDate',
        id: 'emissionsReportingDate',
        isVisible: true,
        beField: 'EMISSIONS_REPORTING_DATE',
        type: UiTableColumnDataType.DATE,
      },
      {
        label: t('processManager.manualUpload.shared.fiscalPeriodEndDate'),
        accessor: 'fiscalPeriodEndDate',
        id: 'fiscalPeriodEndDate',
        isVisible: true,
        beField: 'FISCAL_PERIOD_END_DATE',
        type: UiTableColumnDataType.DATE,
      },
      ...commonColumns,
    ];
  }, [t, commonColumns]);
};
