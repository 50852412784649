import {
  DataQualityData,
  DataQualityOverviewDto,
  DataQualityOverviewMapped,
  DataQualityTotalEmissions,
} from '@DataAccess/GHG/DataQuality/dataQuality.types';
import { DataQuality, AssetType, ScopeType } from '@Types/types';
import { computeTotalRowData, getNumberFromString } from '@Utils/utils';

export const mapToDataQualityOverviewUi = (scopes: DataQualityOverviewDto) => {
  return Object.entries(scopes)
    .sort(([scopeA], [scopeB]) => scopeA.localeCompare(scopeB))
    .reduce((output, [scope, scores]) => {
      const sortedScoresWithDefaultValues = scores
        .map(({ dataQualityScore, financedEmissions, ...rest }) => {
          return {
            dataQualityScore: dataQualityScore ?? DataQuality.NONE,
            financedEmissions: financedEmissions ?? 0,
            ...rest,
          };
        })
        .sort((a, b) => a.dataQualityScore.localeCompare(b.dataQualityScore));

      const computedTotalFields: DataQualityTotalEmissions = computeTotalRowData(
        sortedScoresWithDefaultValues,
        ['dataSets', 'exposure', 'financedEmissions'],
      );

      const scoresPerAssetClass = sortedScoresWithDefaultValues.reduce((acc, curr) => {
        acc[curr.pcafAssetClass] = [...(acc[curr.pcafAssetClass] || []), curr];

        return acc;
      }, {} as Record<AssetType, DataQualityData[]>);

      const newScope: DataQualityOverviewMapped = {
        scoresPerAssetClass,
        computedTotalFields,
        scopeNumber: getNumberFromString(scope) ?? 0,
      };

      return {
        ...output,
        [scope]: newScope,
      };
    }, {} as Record<ScopeType, DataQualityOverviewMapped>);
};
