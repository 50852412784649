import { AxiosInstance } from 'axios';

import { SplitRatioDto } from './splitRatio.types';

const SPLIT_RATIO_ENDPOINT = '/split-ratio';

export const initialiseSplitRatioEndpoints = (api: AxiosInstance) => ({
  getSplitRatio() {
    return api.get<SplitRatioDto>(`${SPLIT_RATIO_ENDPOINT}`).then(({ data }) => data);
  },
  updateSplitRatio(rules: SplitRatioDto) {
    return api.put(`${SPLIT_RATIO_ENDPOINT}`, rules).then(({ data }) => data);
  },
});
