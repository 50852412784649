import { AxiosInstance } from 'axios';

import { initialiseProductsEndpoints } from './Products/products.api';
import { initialiseProcessMonitoringEndpoints } from './ProcessMonitoring/processMonitoring.api';
import { initialiseFileUploadEndpoints } from './FileUpload/fileUpload.api';

export const initialisePlatformEndpoints = (api: AxiosInstance) => ({
  products: initialiseProductsEndpoints(api),
  fileUpload: initialiseFileUploadEndpoints(api),
  processMonitoring: initialiseProcessMonitoringEndpoints(api),
});
