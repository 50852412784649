import { ProcessManagerPage } from '@Pages/DataManagement/ProcessManager/ProcessManagerPage';

import { usePRProcessMonitoringColumns } from './usePRProcessMonitoringColumns';
import { usePRUploadModalEntities } from './usePRUploadModalEntities';

export const PRProcessManagerPage = () => {
  const columns = usePRProcessMonitoringColumns();
  const entities = usePRUploadModalEntities();

  return <ProcessManagerPage entities={entities} columns={columns} disableCalculationButton />;
};
