import styled from '@emotion/styled';
import { ThemingParameters } from '@ui5/webcomponents-react-base';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { IconSet } from '@GlobalProviders/IconSetProvider';

import { UiIcon } from '../../../../Components/UiIcon';
import { useNavigationPanelContext } from '../NavigationPanelContext';
import { StyleProps } from '../types';
import { NavigationItemProps, NavigationItem } from './NavigationItem';

const Label = styled.span`
  font-family: ${ThemingParameters.sapFontFamily};
  font-weight: 700;
  font-size: ${ThemingParameters.sapFontSize};
  color: ${ThemingParameters.sapTextColor};
  margin: 0 auto 0 0.5rem;
`;

const ItemWrapper = styled.button<StyleProps>`
  margin-top: 1rem;
  background: transparent;
  justify-content: center;
  border: none;
  display: flex;
  align-items: center;
  height: 2rem;
  border-radius: 0.375rem;
  ${({ isExtended }) => isExtended && 'padding-right: 0.5rem; padding-left: 0.5rem;'}

  :hover {
    background-color: ${ThemingParameters.sapBackgroundColor};
  }

  :active {
    background-color: ${ThemingParameters.sapAccentBackgroundColor6};
  }

  ${({ isExtended, isActive }) =>
    !isExtended && isActive && `background-color: ${ThemingParameters.sapAccentBackgroundColor6}`}
`;

type ExpandableNavigationItemProps = {
  iconName: string;
  label: string;
  subItemsConfig: NavigationItemProps[];
};

export const NavigationExpandableItem = ({
  iconName,
  label,
  subItemsConfig,
}: ExpandableNavigationItemProps) => {
  const { isPanelExtended, togglePanel } = useNavigationPanelContext();
  const [isExpanded, setIsExpanded] = useState(true);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const firstItemPath = subItemsConfig[0].pathTo;
  const subcategoryRootPath = `/${firstItemPath.split('/')[0]}`;
  const isActive = pathname.startsWith(subcategoryRootPath);

  useEffect(() => {
    isPanelExtended && setIsExpanded(isPanelExtended);
  }, [isPanelExtended]);

  const toggleItemExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClick = () => {
    toggleItemExpansion();
    !isPanelExtended && togglePanel();
    !isPanelExtended && navigate(`/${firstItemPath}`);
  };

  return (
    <>
      <ItemWrapper isActive={isActive} isExtended={isPanelExtended} onClick={handleClick}>
        <UiIcon name={iconName} />
        {isPanelExtended && <Label>{label}</Label>}
        {isPanelExtended && (
          <UiIcon name={isExpanded ? IconSet.SLIM_ARROW_UP : IconSet.SLIM_ARROW_DOWN} />
        )}
      </ItemWrapper>
      {isExpanded &&
        isPanelExtended &&
        subItemsConfig.map(({ pathTo, label }) => (
          <NavigationItem key={pathTo} isSubItem pathTo={pathTo} label={label} />
        ))}
    </>
  );
};
