import { RouteObject } from 'react-router';

import { EmissionFactorNumberOfBuildingsPage } from '../EmissionFactorNumberOfBuildingsPage';
import { EMISSION_FACTOR_NUMBER_OF_BUILDINGS_ROUTE } from './routes';

export const EmissionFactorNumberOfBuildingsRouting: RouteObject = {
  path: EMISSION_FACTOR_NUMBER_OF_BUILDINGS_ROUTE,
  children: [
    {
      index: true,
      element: <EmissionFactorNumberOfBuildingsPage />,
    },
  ],
};
