import { useMultiAppStore } from '@GlobalProviders/MultiApp/MultiAppProvider';
import { usePRTranslation } from '@GlobalProviders/i18n/hooks';
import { TabConfig } from '@UiCommon/Components/UiTabSwitcher';
import { SectionObjectPage } from '@UiCommon/Layout/Components/SectionObjectPage';

import {
  INSIGHTS_PHYSICAL_ASSET_ACUTE_ROUTE,
  INSIGHTS_PHYSICAL_ASSET_CHRONIC_ROUTE,
} from './Routing/routes';

export const PRPhysicalAssetPage = () => {
  const { t } = usePRTranslation();
  const { translatedProductInUse } = useMultiAppStore();

  const tabsConfig: TabConfig[] = [
    {
      label: t('physicalAsset.acute.title'),
      id: INSIGHTS_PHYSICAL_ASSET_ACUTE_ROUTE,
    },
    {
      label: t('physicalAsset.chronic.title'),
      id: INSIGHTS_PHYSICAL_ASSET_CHRONIC_ROUTE,
    },
  ];

  return (
    <SectionObjectPage
      title={t('physicalAsset.title')}
      productName={translatedProductInUse}
      defaultRoute={INSIGHTS_PHYSICAL_ASSET_ACUTE_ROUTE}
      tabsConfig={tabsConfig}
    />
  );
};
