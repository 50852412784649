import { Link } from '@ui5/webcomponents-react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { CommonProps } from '../types';

type Props = CommonProps & {
  href: string;
  useNativeAnchor?: boolean;
  onClick?: () => void;
};

export const UiLink = ({ children, href, onClick, useNativeAnchor, className }: Props) => {
  if (useNativeAnchor) {
    return (
      <Link href={href} onClick={onClick} className={className}>
        {children}
      </Link>
    );
  }

  /* 
    This is a workaround for the SAP UI5 Link component which does not support React Router Link:
    - base url support
    - navigate to a different page without having to fully reload
  */
  return (
    <ReactRouterLink to={href} onClick={onClick}>
      <Link className={className}>{children}</Link>
    </ReactRouterLink>
  );
};
