import { RouteObject } from 'react-router-dom';

import { PhysicalAssetFinancialContractAssociationPage } from '../PhysicalAssetFinancialContractAssociationPage';
import { PHYSICAL_FINANCIAL_CONTRACT_ASSOCIATION_ROUTE } from './routes';

export const PhysicalAssetFinancialContractAssociationRouting: RouteObject = {
  path: PHYSICAL_FINANCIAL_CONTRACT_ASSOCIATION_ROUTE,
  children: [
    {
      index: true,
      element: <PhysicalAssetFinancialContractAssociationPage />,
    },
  ],
};
