import { MenuDomRef, Ui5CustomEvent } from '@ui5/webcomponents-react';
import MenuItem from '@ui5/webcomponents/dist/MenuItem';
import { useCallback } from 'react';

export const useOnItemClickHandler = () =>
  useCallback(
    ({
      detail: { item },
    }: Ui5CustomEvent<
      MenuDomRef,
      {
        item: MenuItem;
        text: string;
      }
    >) => {
      if (item instanceof HTMLElement) item?.click();
    },
    [],
  );
