import { css } from '@emotion/css';

import { Currency } from '@Types/types';
import { UiCard, UiCardHeader, UiSelect, UiSelectOption } from '@UiCommon/Components';
import { useGeneralSettingsMutation } from '@DataAccess/Core/Settings/settings.hooks';
import { useSettingsStore } from '@GlobalProviders/SettingsProvider';
import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';

const inputClassName = css`
  max-width: 13.75rem;
`;

export const CurrencySection = () => {
  const { t } = useGHGTranslation();

  const settings = useSettingsStore();
  const { mutate: updateGeneralSettings } = useGeneralSettingsMutation();

  const onCurrencyChange = (value: string | undefined) => {
    if (!value) return;

    updateGeneralSettings({ currency: value as Currency });
  };

  return (
    <UiCard
      bodyPadding='1rem'
      header={<UiCardHeader titleText={t('settings.systemSettings.currencySection.currency')} />}
    >
      <UiSelect
        isHorizontal
        inputClassName={inputClassName}
        label={t('settings.systemSettings.currencySection.currency')}
        onChange={onCurrencyChange}
      >
        {Object.values(Currency).map((currency) => (
          <UiSelectOption
            key={currency}
            value={currency}
            isSelected={settings?.currency === currency}
          >
            {currency}
          </UiSelectOption>
        ))}
      </UiSelect>
    </UiCard>
  );
};
