import { Button, ButtonPropTypes } from '@ui5/webcomponents-react';

import { CommonProps, ElementSize } from '../../types';

export type UiButtonProps = CommonProps &
  Pick<ButtonPropTypes, 'design' | 'disabled' | 'onClick' | 'icon' | 'slot'> & {
    size?: ElementSize;
    id?: string;
  };

export const UiButton = ({
  className,
  children,
  size = ElementSize.SMALL,
  ...props
}: UiButtonProps) => (
  <Button
    data-no-dnd='true'
    className={className}
    data-ui5-compact-size={size === ElementSize.SMALL ? true : undefined}
    {...props}
  >
    {children}
  </Button>
);
