import { Navigate } from 'react-router-dom';

import { KpiRouting } from '@GlobalProviders/MultiApp/KpiRouting';
import { KPIRoutingFactory } from '@Pages/Shared/Routing/KPIRoutingFactory';

import { SPLIT_SCOPE_ROUTE } from './SplitScope/Routing/routes';
import { SplitScopeRouting } from './SplitScope/Routing/SplitScopeRouting';

export const CustomizationGHGRouting = KPIRoutingFactory(
  [{ index: true, element: <Navigate to={SPLIT_SCOPE_ROUTE} /> }, SplitScopeRouting],
  KpiRouting.GHG,
);
