import styled from '@emotion/styled';
import { ObjectStatus, ObjectStatusPropTypes } from '@ui5/webcomponents-react';
import { ThemingParameters } from '@ui5/webcomponents-react-base';

import { CommonProps } from '../types';

type Props = CommonProps & Pick<ObjectStatusPropTypes, 'icon' | 'state'>;

const StyledObjectStatus = styled(ObjectStatus)`
  --sapFontBoldFamily: ${ThemingParameters.sapFontFamily};
  --sapFontSmallSize: ${ThemingParameters.sapFontSize};
  --sapIndicationColor_5_TextColor: ${ThemingParameters.sapAccentColor6};
  --sapIndicationColor_5: ${ThemingParameters.sapLegendBackgroundColor16};
  padding: 0.25rem 0.5rem !important;
`;

export const UiObjectStatus = ({ children, ...props }: Props) => (
  <StyledObjectStatus inverted {...props}>
    {children}
  </StyledObjectStatus>
);
