import { ReactNode } from 'react';

import { BackButton } from '../../Navigation/Buttons/BackButton';
import { GenericHeader } from './GenericHeader';

type Props = {
  title: string;
  productName: string;
  subtitle?: string;
  renderCustomActions?: () => ReactNode | ReactNode[];
};

export const SubPageHeader = ({ title, productName, subtitle, renderCustomActions }: Props) => (
  <GenericHeader
    title={title}
    productName={productName}
    subtitle={subtitle}
    renderCustomActions={renderCustomActions}
    renderNavigationElements={() => <BackButton />}
  />
);
