import { useCallback, useMemo } from 'react';
import { AnalyticalTableColumnDefinition } from '@ui5/webcomponents-react';

import { toMap } from '@Utils/utils';

import { UiAnalyticalTableColumnDefinition } from './types';

export const useOnColumnsReorderHandler = <DataType>(
  currentColumns: UiAnalyticalTableColumnDefinition<DataType>[],
  onColumnsOrderChange?: (newColumns: UiAnalyticalTableColumnDefinition<DataType>[]) => void,
) => {
  const columnsMap = useMemo(() => toMap(currentColumns, 'id'), [currentColumns]);
  const invisibleColumns = useMemo(
    () => currentColumns.filter(({ isVisible }) => !isVisible),
    [currentColumns],
  );

  return useCallback(
    (event?: CustomEvent<{ columnsNewOrder: unknown }>) => {
      if (!event || !onColumnsOrderChange) return;

      const {
        detail: { columnsNewOrder },
      } = event;
      // NOTE ui5 type is broken Docs claim columnsNewOrder to be string[] but in reality it's AnalyticalTableColumnDefinition[]
      const sortedColumns = (columnsNewOrder as AnalyticalTableColumnDefinition[])
        .map(({ id }) => (id ? columnsMap.get(id) : undefined))
        .filter((item) => !!item) as UiAnalyticalTableColumnDefinition<DataType>[];

      onColumnsOrderChange([...sortedColumns, ...invisibleColumns]);
    },
    [columnsMap],
  );
};
