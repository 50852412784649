import styled from '@emotion/styled';

import { CommonProps } from '../types';
import { TextVariant, FontWeight, fontWeights, typography } from '../typography';

type TextProps = CommonProps & {
  variant?: TextVariant;
  weight?: FontWeight;
  uppercase?: boolean;
  color?: string;
};

const TextStyled = styled.span<TextProps>`
  font-family: ${({ weight }) => fontWeights[weight as FontWeight]};
  ${({ variant }) => typography[variant as TextVariant]}
  ${({ uppercase }) => uppercase && 'text-transform: uppercase;'}
  ${({ color }) => color && `color: ${color};`}
`;

export const UiText = ({
  children,
  variant = 'caption2',
  weight = FontWeight.NORMAL,
  uppercase,
  color,
  className,
}: TextProps) => (
  <TextStyled
    className={className}
    variant={variant}
    weight={weight}
    uppercase={uppercase}
    color={color}
  >
    {children}
  </TextStyled>
);
