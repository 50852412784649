import { useState, useCallback, useMemo } from 'react';

import { SplitScope } from '@DataAccess/CoreLib/SplitRatio/splitRatio.types';

import { FormProps, validateCountry, validateEmissions } from '../Utils/validation';

export type SplitScopeState<T> = {
  list: T[];
  errors: {
    splitErrors: Set<number>;
    countryErrors: Set<number>;
  };
};

export type CellInfo = {
  id: string;
  value: string;
  index: number;
};

export type SplitScopeCell = {
  id: string;
  value: number;
  index: number;
};

export type SplitScopeStateReturnType<T> = {
  state: SplitScopeState<T>;
  actions: {
    updateCell: (payload: CellInfo) => void;
    updateCountryCell: (payload: CellInfo) => void;
    updateSplitScopeCell: (payload: SplitScopeCell) => void;
    addRule: (newItem: T) => void;
    setListItems: (newList: T[]) => void;
    moveListItem: (from: number, to: number) => void;
    reorderItems: (newList: T[]) => void;
  };
};

export const useSplitScopeState = <
  T extends FormProps & Pick<SplitScope, 'isDefault'>,
>(): SplitScopeStateReturnType<T> => {
  const [list, setList] = useState<T[]>([]);

  const [emissionsValidationErrorSet, setEmissionsValidationErrorSet] = useState<Set<number>>(
    new Set(),
  );
  const [countryValidationErrorSet, setCountryValidationErrorSet] = useState<Set<number>>(
    new Set(),
  );
  const errors = {
    splitErrors: emissionsValidationErrorSet,
    countryErrors: countryValidationErrorSet,
  };

  const defaultElement = useMemo(() => {
    return list.find((item) => item.isDefault);
  }, [list]);

  const updateCell = useCallback((payload: CellInfo) => {
    setList((currentList) => {
      return currentList.map((item, index) => {
        if (index === payload.index) {
          return { ...item, [payload.id]: payload.value };
        }

        return item;
      });
    });
  }, []);

  const moveListItem = useCallback((from: number, to: number) => {
    setList((currentList) => {
      const updatedList = [...currentList];
      const [removed] = updatedList.splice(from, 1);
      updatedList.splice(to, 0, removed);

      return updatedList;
    });
  }, []);

  const updateCountryCell = useCallback((payload: CellInfo) => {
    setList((currentList) => {
      const updatedList = currentList.map((item, index) => {
        if (index === payload.index) {
          const updatedItem = { ...item, [payload.id]: payload.value };
          if (!validateCountry(updatedItem.country)) {
            setCountryValidationErrorSet((currentSet) => {
              currentSet.add(payload.index);

              return currentSet;
            });
          } else {
            setCountryValidationErrorSet((currentSet) => {
              currentSet.delete(payload.index);

              return new Set(currentSet);
            });
          }

          return updatedItem;
        }

        return item;
      });

      return updatedList;
    });
  }, []);

  const updateSplitScopeCell = useCallback((payload: SplitScopeCell) => {
    setList((currentList) => {
      const updatedList = currentList.map((item, index) => {
        if (index === payload.index) {
          const updatedItem = { ...item, [payload.id]: payload.value };
          if (!validateEmissions(updatedItem)) {
            setEmissionsValidationErrorSet((currentSet) => {
              currentSet.add(payload.index);

              return currentSet;
            });
          } else {
            setEmissionsValidationErrorSet((currentSet) => {
              currentSet.delete(payload.index);

              return new Set(currentSet);
            });
          }

          return updatedItem;
        }

        return item;
      });

      return updatedList;
    });
  }, []);

  const addRule = useCallback((newItem: T) => {
    setList((currentList) => [newItem, ...currentList]);
  }, []);

  const setListItems = useCallback((newList: T[]) => {
    setList(newList);
  }, []);

  const reorderItems = useCallback(
    (newList: T[]) => {
      if (defaultElement) {
        setList([...newList, defaultElement]);
      }
    },
    [defaultElement],
  );

  return {
    state: { list, errors },
    actions: {
      updateCell,
      updateCountryCell,
      updateSplitScopeCell,
      addRule,
      setListItems,
      moveListItem,
      reorderItems,
    },
  };
};
