import styled from '@emotion/styled';
import { InputType, ValueState } from '@ui5/webcomponents-react';
import { useEffect, useMemo, useState } from 'react';

import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';
import { UiButton, UiDialog, UiDialogFooter, UiDialogHeader, UiInput } from '@UiCommon/Components';

import { HierarchyEnableSourceSwitch } from './HierarchyEnableSourceSwitch';
import { EditSourceForm, HandleEditParams } from './types';

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 320px;
`;

type Props = {
  isOpen: boolean;
  sourceForm: EditSourceForm | null;
  onConfirm: (sourceToEdit: HandleEditParams) => void;
  onDelete: (sourceToDelete: Partial<EditSourceForm>) => void;
  onClose: () => void;
  isInputInvalid: (name?: string) => boolean;
};

export const HierarchyEditSourceDialog = ({
  isOpen,
  sourceForm,
  onConfirm,
  onDelete,
  onClose,
  isInputInvalid,
}: Props) => {
  const { t } = useGHGTranslation();
  const [name, setName] = useState(sourceForm?.sourceName);
  const [isEnabled, setIsEnabled] = useState(sourceForm?.isEnabled);

  useEffect(() => {
    setName(sourceForm?.sourceName);
    setIsEnabled(sourceForm?.isEnabled);
  }, [sourceForm]);

  const errorMessage = useMemo(() => {
    if (sourceForm?.sourceName === name) return undefined;
    if (!name || name.trim().length === 0) return t('hierarchyManager.inputErrors.emptySource');
    if (isInputInvalid(name)) return t('hierarchyManager.inputErrors.duplicateSource');

    return undefined;
  }, [name, sourceForm]);

  return (
    <UiDialog
      isOpen={isOpen}
      onAfterClose={onClose}
      header={
        <UiDialogHeader
          startContent={t('hierarchyManager.editDialog.title', {
            sourceName: sourceForm?.sourceName,
          })}
        />
      }
      footer={
        <UiDialogFooter
          endContent={
            <>
              <UiButton
                disabled={!!errorMessage}
                design='Emphasized'
                onClick={() =>
                  onConfirm({
                    id: sourceForm?.id,
                    name,
                    isEnabled,
                    items: sourceForm?.items,
                    resourceId: sourceForm?.resourceId,
                  })
                }
              >
                {t('hierarchyManager.editDialog.confirm')}
              </UiButton>
              <UiButton
                design='Negative'
                onClick={() =>
                  onDelete({
                    id: sourceForm?.id,
                    items: sourceForm?.items,
                    resourceId: sourceForm?.resourceId,
                  })
                }
              >
                {t('hierarchyManager.editDialog.delete')}
              </UiButton>
              <UiButton design='Transparent' onClick={onClose}>
                {t('hierarchyManager.editDialog.cancel')}
              </UiButton>
            </>
          }
        />
      }
    >
      <FormWrapper>
        <UiInput
          value={name}
          label={t('hierarchyManager.editDialog.name')}
          type={InputType.Text}
          onChange={(value) => setName(value)}
          valueState={errorMessage ? ValueState.Error : undefined}
          valueStateMessage={errorMessage}
        ></UiInput>

        <HierarchyEnableSourceSwitch
          isChecked={isEnabled}
          onChange={(value) => setIsEnabled(value)}
        />
      </FormWrapper>
    </UiDialog>
  );
};
