import { TonnesCo2FormatterConfig } from '@Utils/Formatters/types';

import { DEFAULT_LOCALE } from './constants';

export const createTonnesCo2Formatter = ({
  lng = DEFAULT_LOCALE,
  decimalPrecision = 0,
  notation,
  displayOptions,
}: TonnesCo2FormatterConfig) => {
  const units = 'tCO\u2082';
  const unitsToBeDisplayed = displayOptions?.isPerAnnum ? `${units} p.a.` : units;

  const numberFormatter = new Intl.NumberFormat(lng, {
    style: 'unit',
    unit: 'meter',
    minimumFractionDigits: decimalPrecision,
    maximumFractionDigits: decimalPrecision,
    notation,
  });

  return {
    ...numberFormatter,
    format: (number: number) => {
      const output = numberFormatter.format(number).replace('m', unitsToBeDisplayed);

      return output;
    },
  };
};
