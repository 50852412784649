import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

class AxiosFactory {
  static instance: AxiosFactory | null = null;

  static getInstance = () => {
    if (!this.instance) {
      this.instance = new AxiosFactory();
    }

    return this.instance;
  };

  public token: string | null = null;

  public errorsHandler: ((status: number) => void) | null = null;

  private addAuthToken = (request: AxiosRequestConfig) => {
    if (this.token) {
      request.headers = {
        ...request.headers,
        Authorization: `Bearer ${this.token}`,
      };
    }
  };

  private handleErrors = (response: AxiosResponse) => {
    const { status } = response;
    this.errorsHandler?.(status);
  };

  newInstance = (config?: AxiosRequestConfig) => {
    const { baseURL, ...requestConfig } = config || {};

    const axiosInstance = axios.create({
      baseURL: `${import.meta.env.VITE_APP_API_URL}${baseURL || ''}`,
      ...requestConfig,
    });

    axiosInstance.interceptors.request.use((request) => {
      this.addAuthToken(request);

      return request;
    });

    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        this.handleErrors(error.response);

        return Promise.reject(error);
      },
    );

    return axiosInstance;
  };
}

export const axiosFactory = AxiosFactory.getInstance();
