import styled from '@emotion/styled';
import { debounce } from 'lodash';
import { useState } from 'react';

import { useEmissionsCalculationRunSearchQuery } from '@DataAccess/Platform/ProcessMonitoring/processMonitoring.hooks';
import { ProcessMonitoringDto } from '@DataAccess/Platform/ProcessMonitoring/processMonitoring.types';
import { EsgProduct } from '@DataAccess/Platform/Products/products.types';
import { IconSet } from '@GlobalProviders/IconSetProvider';
import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';
import { useProcessMonitoringColumns } from '@Pages/DataManagement/ProcessManager/Components/useProcessMonitoringColumns';
import { CommonVersionData, UiDatePicker, UiIcon, UiInput, UiText } from '@UiCommon/Components';
import { UiDateFormatter } from '@UiCommon/Components/UiFormatter/UiDateFormatter';
import { ElementSize } from '@UiCommon/types';
import { FontWeight } from '@UiCommon/typography';

const OptionsWrapper = styled.div`
  display: grid;
  grid-template-areas:
    'businessDate'
    'actualStartDate'
    'processId';
  grid-gap: 0.25rem;
`;

const Id = styled(UiText)`
  grid-area: 'processId';
`;

const ActualStartDate = styled(UiText)`
  grid-area: 'actualStartDate';
  display: flex;
`;

const BusinessDate = styled(UiText)`
  grid-area: 'businessDate';
`;

const Label = styled(UiText)`
  padding-right: 0.25rem;
`;

type DataToRender = CommonVersionData &
  Pick<ProcessMonitoringDto, 'actualStartDate' | 'businessDate'>;

export const useGHGOverviewVersioningMenu = (latestProcessId?: string) => {
  const { t } = useGHGTranslation();

  const [searchId, setSearchId] = useState<string | undefined>();
  const [businessDate, setBusinessDate] = useState<string | undefined>();

  const columns = useProcessMonitoringColumns({ productAccess: EsgProduct.GHG });

  const { runVersions, isLoading, isFetched, isFetching } = useEmissionsCalculationRunSearchQuery({
    columns,
    processIdFilterValue: searchId,
    businessDateFilterValue: businessDate,
  });

  const handleProcessIdSearch = (newId?: string) => {
    setSearchId(newId);
  };

  const handleBusinessDateChange = (date?: string) => {
    setBusinessDate(date);
  };

  const mappedVersionsToUi = runVersions.map<DataToRender>(
    ({ processId, businessDate, actualStartDate }) => ({
      id: processId,
      businessDate,
      actualStartDate,
      isLatest: latestProcessId === processId,
    }),
  );

  return {
    versions: mappedVersionsToUi,
    isFetched,
    isLoading: isLoading || isFetching,
    Options: ({ actualStartDate, businessDate, id }: DataToRender) => (
      <OptionsWrapper>
        <ActualStartDate>
          <Label weight={FontWeight.BOLD}>{t('overview.versioningMenu.actualStartDate')}</Label>
          <UiDateFormatter value={actualStartDate} />
        </ActualStartDate>
        <BusinessDate>
          <Label weight={FontWeight.BOLD}>{t('overview.versioningMenu.businessDate')}</Label>
          {businessDate}
        </BusinessDate>
        <Id>
          <Label weight={FontWeight.BOLD}>{t('overview.versioningMenu.id')}</Label>
          {id}
        </Id>
      </OptionsWrapper>
    ),
    Filters: () => (
      <>
        <UiInput
          onChange={debounce(handleProcessIdSearch, 500)}
          size={ElementSize.NORMAL}
          placeholder={t('overview.versioningMenu.searchPlaceholder')}
          endContent={<UiIcon name={IconSet.SEARCH} />}
        />
        <UiDatePicker
          label={t('overview.versioningMenu.datePicker.label')}
          size={ElementSize.NORMAL}
          placeholder={t('overview.versioningMenu.datePicker.placeholder')}
          onChange={handleBusinessDateChange}
        />
      </>
    ),
  };
};
