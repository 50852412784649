import { createPortal } from 'react-dom';
import { forwardRef } from 'react';
import { CustomListItem, PopoverDomRef } from '@ui5/webcomponents-react';
import styled from '@emotion/styled';

import { useUiCommonTranslation } from '@GlobalProviders/i18n/hooks';

import { IconSet } from '../../../../GlobalProviders/IconSetProvider';
import { UiPopover } from '../../UiPopover';
import { UiStaticList } from '../../UiStaticList/UiStaticList';
import { SettingsTab } from '../SettingsModal/useSettingsModalTabs';
import { SortingOrder } from '../types';
import { HeaderCustomListItem } from './HeaderCustomListItem';
import { HeaderSortItem } from './HeaderSortItem';

const Wrapper = styled.div`
  width: 25rem;
`;

const StyledPopover = styled(UiPopover)`
  ::part(content) {
    padding: 0;
  }
`;

const StyledCustomListItem = styled(CustomListItem)`
  :hover {
    background: transparent;
  }
`;

type Props = {
  label: string;
  id: string;
  handleListItemClick: (tab: SettingsTab) => void;
  onSortClick: (order: SortingOrder) => void;
  onAfterOpen?: () => void;
  disableSortBy?: boolean;
  disableFilters?: boolean;
};

export const HeaderPopover = forwardRef<PopoverDomRef, Props>(function HeaderPopover(
  { label, handleListItemClick, id, onSortClick, disableSortBy, disableFilters },
  ref,
) {
  const { t } = useUiCommonTranslation();

  return (
    <>
      {createPortal(
        <StyledPopover
          hideArrow
          placementType={'Bottom'}
          verticalAlign={'Bottom'}
          horizontalAlign={'Center'}
          ref={ref}
        >
          <Wrapper>
            <UiStaticList>
              {!disableSortBy && (
                <>
                  <StyledCustomListItem>
                    <HeaderSortItem label={label} id={id} onSortClick={onSortClick} />
                  </StyledCustomListItem>

                  <HeaderCustomListItem
                    onClick={() => handleListItemClick?.(SettingsTab.SORT)}
                    iconName={IconSet.SORT}
                    text={t('settingsModal.tabs.sort')}
                  />
                </>
              )}
              {!disableFilters && (
                <HeaderCustomListItem
                  onClick={() => handleListItemClick?.(SettingsTab.FILTER)}
                  iconName={IconSet.FILTER}
                  text={t('settingsModal.tabs.filter')}
                />
              )}
              <HeaderCustomListItem
                onClick={() => handleListItemClick?.(SettingsTab.COLUMNS)}
                iconName={IconSet.TABLE_COLUMN}
                text={t('settingsModal.tabs.columns')}
              />
            </UiStaticList>
          </Wrapper>
        </StyledPopover>,
        document.body,
      )}
    </>
  );
});
