import { AxiosInstance } from 'axios';

import { GeneralSettingsDto } from './settings.types';

const SETTINGS_GENERAL_ENDPOINT = '/settings/general';

export const initialiseSettingsEndpoints = (api: AxiosInstance) => ({
  getGeneral() {
    return api.get<GeneralSettingsDto>(SETTINGS_GENERAL_ENDPOINT).then(({ data }) => data);
  },
  updateGeneral(payload: GeneralSettingsDto) {
    return api.put<GeneralSettingsDto, void>(SETTINGS_GENERAL_ENDPOINT, payload);
  },
});
