import { VariantItem } from '@ui5/webcomponents-react';
import React from 'react';

type Props = {
  children: string;
  favorite?: boolean;
  global?: boolean;
  isDefault?: boolean;
  readOnly?: boolean;
  labelReadOnly?: boolean;
  selected?: boolean;
};

export const UiVariantItem = ({
  children,
  favorite,
  isDefault,
  readOnly,
  selected,
  labelReadOnly,
}: Props) => (
  <VariantItem
    favorite={favorite}
    labelReadOnly={labelReadOnly}
    isDefault={isDefault}
    readOnly={readOnly}
    selected={selected}
  >
    {children}
  </VariantItem>
);
