import { useMemo } from 'react';

import { useProcessManagerTranslation } from '@GlobalProviders/i18n/hooks';
import { UiTableColumnDataType } from '@UiCommon/Components/UiAnalyticalTable/types';

import { useCommonCollectedColumns } from '../useCommonCollectedColumns';

export const useBuildingColumns = () => {
  const { t } = useProcessManagerTranslation();
  const commonColumns = useCommonCollectedColumns();

  return useMemo(() => {
    return [
      {
        label: t('processManager.manualUpload.building.table.physicalAssetId'),
        accessor: 'physicalAssetId',
        id: 'physicalAssetId',
        isVisible: true,
        beField: 'PHYSICAL_ASSET_ID',
      },
      {
        label: t('processManager.manualUpload.building.table.realEstateType'),
        accessor: 'realEstateType',
        id: 'realEstateType',
        minWidth: 120,
        isVisible: true,
        beField: 'REAL_ESTATE_TYPE',
      },
      {
        label: t('processManager.manualUpload.building.table.availableFloorSpace'),
        accessor: 'availableFloorSpace',
        id: 'availableFloorSpace',
        minWidth: 120,
        isVisible: true,
        type: UiTableColumnDataType.NUMBER,
        beField: 'AVAILABLE_FLOOR_SPACE',
      },
      {
        label: t('processManager.manualUpload.building.table.countryRegion'),
        accessor: 'countryRegion',
        id: 'countryRegion',
        isVisible: true,
        beField: 'COUNTRY_REGION',
      },
      {
        label: t('processManager.manualUpload.building.table.numberOfBuildings'),
        accessor: 'numberOfBuildings',
        id: 'numberOfBuildings',
        minWidth: 120,
        isVisible: true,
        type: UiTableColumnDataType.NUMBER,
        beField: 'NUMBER_OF_BUILDINGS',
      },
      {
        label: t('processManager.manualUpload.building.table.certificateClass'),
        accessor: 'certificateClass',
        id: 'certificateClass',
        minWidth: 120,
        isVisible: true,
        beField: 'CERTIFICATE_CLASS',
      },
      ...commonColumns,
    ];
  }, [t, commonColumns]);
};
