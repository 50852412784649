import { useMemo } from 'react';

import { useProcessManagerTranslation } from '@GlobalProviders/i18n/hooks';
import { UiTableColumnDataType } from '@UiCommon/Components/UiAnalyticalTable/types';

import { useCommonCollectedColumns } from '../useCommonCollectedColumns';

export const useReportedEnergyColumns = () => {
  const { t } = useProcessManagerTranslation();
  const commonColumns = useCommonCollectedColumns();

  return useMemo(
    () => [
      {
        label: t('processManager.manualUpload.reportedEnergy.table.businessPartnerId'),
        accessor: 'businessPartnerId',
        id: 'businessPartnerId',
        isVisible: true,
        beField: 'BUSINESS_PARTNER_ID',
      },
      {
        label: t('processManager.manualUpload.reportedEnergy.table.projectId'),
        accessor: 'projectId',
        id: 'projectId',
        isVisible: true,
        beField: 'PROJECT_ID',
      },
      {
        label: t('processManager.manualUpload.reportedEnergy.table.energy'),
        accessor: 'energy',
        id: 'energy',
        isVisible: true,
        beField: 'ENERGY',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.reportedEnergy.table.countryRegion'),
        accessor: 'countryRegion',
        id: 'countryRegion',
        minWidth: 100,
        isVisible: true,
        beField: 'COUNTRY_REGION',
      },
      {
        label: t('processManager.manualUpload.reportedEnergy.table.purpose'),
        accessor: 'purpose',
        id: 'purpose',
        minWidth: 120,
        isVisible: true,
        beField: 'PURPOSE',
      },
      {
        label: t('processManager.manualUpload.reportedEnergy.table.supplier'),
        accessor: 'supplier',
        id: 'supplier',
        minWidth: 120,
        isVisible: true,
        beField: 'SUPPLIER',
      },
      {
        label: t('processManager.manualUpload.reportedEnergy.table.tariff'),
        accessor: 'tariff',
        id: 'tariff',
        minWidth: 104,
        isVisible: true,
        beField: 'TARIFF',
      },
      {
        label: t('processManager.manualUpload.reportedEnergy.table.source'),
        accessor: 'source',
        id: 'source',
        minWidth: 120,
        isVisible: true,
        beField: 'SOURCE',
      },
      {
        label: t('processManager.manualUpload.shared.year'),
        accessor: 'year',
        id: 'year',
        isVisible: true,
        beField: 'YEAR',
        type: UiTableColumnDataType.NUMBER,
      },
      ...commonColumns,
    ],
    [t, commonColumns],
  );
};
