import { useProcessMonitoringColumns } from '@Pages/DataManagement/ProcessManager/Components/useProcessMonitoringColumns';
import { useProcessManagerTranslation } from '@GlobalProviders/i18n/hooks';
import { ProcessMonitoringDto } from '@DataAccess/Platform/ProcessMonitoring/processMonitoring.types';
import { HIERARCHY_MANAGER_ROUTE } from '@Pages/DataManagement/dmRoutes';
import { TableInstance } from '@UiCommon/Components/UiAnalyticalTable/types';
import { EsgProduct } from '@DataAccess/Platform/Products/products.types';
import { UiLink } from '@UiCommon/Components';
import { KpiRouting } from '@GlobalProviders/MultiApp/KpiRouting';
import { DATA_MANAGEMENT_ROUTE } from '@Pages/DataManagement/Routing/routes';

export const useGHGProcessMonitoringColumns = () => {
  const { t } = useProcessManagerTranslation();

  return useProcessMonitoringColumns({
    productAccess: EsgProduct.GHG,
    additionalColumns: [
      {
        label: t('processManager.table.dataHierarchy'),
        accessor: 'dataHierarchyId',
        id: 'dataHierarchyId',
        isVisible: true,
        beField: 'DATA_HIERARCHY_ID',
        Cell: (table: TableInstance<ProcessMonitoringDto>) => {
          const { dataHierarchyId } = table.row.original;
          const url = `/${DATA_MANAGEMENT_ROUTE}/${KpiRouting.GHG}/${HIERARCHY_MANAGER_ROUTE}/${dataHierarchyId}`;

          return <UiLink href={url}>{dataHierarchyId}</UiLink>;
        },
      },
    ],
  });
};
