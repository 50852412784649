import { AxiosInstance } from 'axios';

import { PaginationResponse } from '@DataAccess/types';

import {
  DataHierarchyDto,
  DataHierarchySearchItemDto,
  DataHierarchySearchRequestDto,
} from './dataHierarchy.types';

const DATA_HIERARCHY_ENDPOINT = '/data-hierarchy';

export const initialiseDataHierarchyEndpoints = (api: AxiosInstance) => ({
  getDataHierarchy(dataHierarchyId?: string) {
    return api
      .get<DataHierarchyDto>(DATA_HIERARCHY_ENDPOINT, { params: { dataHierarchyId } })
      .then(({ data }) => data);
  },
  searchDataHierarchy(searchObject: DataHierarchySearchRequestDto, pageParam: number) {
    return api
      .post<PaginationResponse<DataHierarchySearchItemDto>>(`${DATA_HIERARCHY_ENDPOINT}/search`, {
        ...searchObject,
        page: pageParam,
      })
      .then(({ data }) => data);
  },
  updateDataHierarchy(payload: DataHierarchyDto) {
    return api.post<DataHierarchyDto, void>(DATA_HIERARCHY_ENDPOINT, payload);
  },
});
