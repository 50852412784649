import { useMemo } from 'react';

import { useProcessManagerTranslation } from '@GlobalProviders/i18n/hooks';
import { UiTableColumnDataType } from '@UiCommon/Components/UiAnalyticalTable/types';

import { useCommonCollectedColumns } from '../useCommonCollectedColumns';

export const useEmissionFactorEnergyColumns = () => {
  const { t } = useProcessManagerTranslation();
  const commonColumns = useCommonCollectedColumns();

  return useMemo(
    () => [
      {
        label: t('processManager.manualUpload.emissionFactorEnergy.table.countryRegion'),
        accessor: 'countryRegion',
        id: 'countryRegion',
        minWidth: 100,
        isVisible: true,
        beField: 'COUNTRY_REGION',
      },
      {
        label: t('processManager.manualUpload.emissionFactorEnergy.table.purpose'),
        accessor: 'purpose',
        id: 'purpose',
        minWidth: 120,
        isVisible: true,
        beField: 'PURPOSE',
      },
      {
        label: t('processManager.manualUpload.emissionFactorEnergy.table.supplier'),
        accessor: 'supplier',
        id: 'supplier',
        minWidth: 120,
        isVisible: true,
        beField: 'SUPPLIER',
      },
      {
        label: t('processManager.manualUpload.emissionFactorEnergy.table.tariff'),
        accessor: 'tariff',
        id: 'tariff',
        minWidth: 104,
        isVisible: true,
        beField: 'TARIFF',
      },
      {
        label: t('processManager.manualUpload.emissionFactorEnergy.table.source'),
        accessor: 'source',
        id: 'source',
        minWidth: 120,
        isVisible: true,
        beField: 'SOURCE',
      },
      {
        label: t('processManager.manualUpload.emissionFactorEnergy.table.emissionFactorScope1'),
        accessor: 'emissionFactorScope1',
        id: 'emissionFactorScope1',
        isVisible: true,
        beField: 'EMISSION_FACTOR_SCOPE_1',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.emissionFactorEnergy.table.emissionFactorScope2'),
        accessor: 'emissionFactorScope2',
        id: 'emissionFactorScope2',
        isVisible: true,
        beField: 'EMISSION_FACTOR_SCOPE_2',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.emissionFactorEnergy.table.emissionFactorScope3'),
        accessor: 'emissionFactorScope3',
        id: 'emissionFactorScope3',
        isVisible: true,
        beField: 'EMISSION_FACTOR_SCOPE_3',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.shared.year'),
        accessor: 'year',
        id: 'year',
        isVisible: true,
        beField: 'YEAR',
        type: UiTableColumnDataType.NUMBER,
      },
      ...commonColumns,
    ],
    [t, commonColumns],
  );
};
