import { UnsavedChangesDialog } from '@Pages/Shared/SmartComponents/UnsavedChangesDialog';

import { SplitScopeTable } from '../Components/SplitScopeTable';
import { useBusinessPartnerCustomizationColumns } from './useBusinessPartnerCustomizationColumns';
import { useSplitScopeContext } from '../Context/SplitScopeContext';
import { BusinessPartnerCreateRule } from './BusinessPartnerCreateRuleModal';
import { useSplitScopeTable } from '../Hooks/useSplitScopeTable';

export const SplitScopeBusinessPartnerTable = () => {
  const columns = useBusinessPartnerCustomizationColumns();
  const { businessPartner, isEditMode, handleSaveSplitRatios, resetState } = useSplitScopeContext();

  const {
    state: { list },
    actions,
  } = businessPartner;

  const { editableItems, setIsCreateRuleModalOpen, isCreateRuleModalOpen } =
    useSplitScopeTable(list);

  return (
    <>
      <SplitScopeTable
        onSaveClick={handleSaveSplitRatios}
        onAddClick={() => setIsCreateRuleModalOpen(true)}
        columns={columns}
        data={editableItems}
        onDragEnd={actions.reorderItems}
        totalElements={list.length}
      />
      <BusinessPartnerCreateRule
        isOpen={isCreateRuleModalOpen}
        onClose={() => setIsCreateRuleModalOpen(false)}
      />
      <UnsavedChangesDialog
        isEditing={isEditMode}
        onContinue={() => {
          resetState();
        }}
      />
    </>
  );
};
