import { RouteObject } from 'react-router-dom';

import { PROCESS_MANAGER_ROUTE } from '@Pages/DataManagement/dmRoutes';
import { ProcessManagerChildren } from '@Pages/DataManagement/ProcessManager/ProcessManagerRouting';

import { PRProcessManagerPage } from './PRProcessManagerPage';

export const PRProcessManagerRouting: RouteObject = {
  path: PROCESS_MANAGER_ROUTE,
  children: [
    {
      index: true,
      element: <PRProcessManagerPage />,
    },
    ...ProcessManagerChildren,
  ],
};
