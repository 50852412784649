import { RouteObject } from 'react-router-dom';

import { ProcessManagerChildren } from '@Pages/DataManagement/ProcessManager/ProcessManagerRouting';
import { PROCESS_MANAGER_ROUTE } from '@Pages/DataManagement/dmRoutes';

import { GHGProcessManagerPage } from './GHGProcessManagerPage';

export const GHGProcessManagerRouting: RouteObject = {
  path: PROCESS_MANAGER_ROUTE,
  children: [
    {
      index: true,
      element: <GHGProcessManagerPage />,
    },
    ...ProcessManagerChildren,
  ],
};
