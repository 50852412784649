import { RouteObject } from 'react-router-dom';

import { GHGSingleExposurePage } from '../GHGSingleExposurePage';
import { SINGLE_EXPOSURE_ROUTE } from './routes';

export const SingleExposureRouting: RouteObject = {
  path: SINGLE_EXPOSURE_ROUTE,
  children: [
    {
      index: true,
      element: <GHGSingleExposurePage />,
    },
  ],
};
