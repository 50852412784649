import { AxiosInstance } from 'axios';

import { initialiseSingleExposureEndpoints } from './SingleExposure/singleExposure.api';
import { initialiseDataQualityEndpoints } from './DataQuality/dataQuality.api';
import { initialiseGhgFilesEndpoints } from './GHGFiles/GHGFiles.api';

export const initialiseGhgEndpoints = (api: AxiosInstance) => ({
  singleExposure: initialiseSingleExposureEndpoints(api),
  dataQuality: initialiseDataQualityEndpoints(api),
  files: initialiseGhgFilesEndpoints(api),
});
