import styled from '@emotion/styled';
import { DateRangePicker, Label } from '@ui5/webcomponents-react';

import { CommonProps, ElementSize } from '../../types';
import { GenericInputWrapper, commonInputProperties } from '../styles';
import { useUiDateRangePickerOnChangeHandler } from './useUiDateRangePickerOnChangeHandler';
import { DATE_FORMAT, PRIMARY_CALENDAR_TYPE } from '../constants';

type Props = CommonProps & {
  label?: string;
  placeholder?: string;
  size?: ElementSize;
  value?: string;
  onChange?: (values: string[]) => void;
};

const StyledDateRangePicker = styled(DateRangePicker)`
  ${commonInputProperties()};
`;

export const UiDateRangePicker = ({
  value,
  size = ElementSize.SMALL,
  onChange,
  placeholder,
  label,
  className,
}: Props) => {
  const onChangeHandler = useUiDateRangePickerOnChangeHandler(onChange);

  return (
    <GenericInputWrapper
      className={className}
      data-ui5-compact-size={size === ElementSize.SMALL ? true : undefined}
    >
      {label && <Label>{label}:</Label>}
      <StyledDateRangePicker
        placeholder={placeholder}
        value={value ?? ''}
        onChange={onChangeHandler}
        formatPattern={DATE_FORMAT}
        primaryCalendarType={PRIMARY_CALENDAR_TYPE}
      />
    </GenericInputWrapper>
  );
};
