export type FormProps = {
  splitScope1Emissions: number;
  splitScope2Emissions: number;
};

export const validateEmissions = <T extends FormProps>(item: T) => {
  return item.splitScope1Emissions + item.splitScope2Emissions === 1;
};

export const validateCountry = (country: string) => {
  return /^[a-zA-Z]{2}$|\*$/.test(country);
};
