import { useMemo } from 'react';
import { isNumber } from 'lodash';

import { NumberFormatterConfig, TonnesCo2FormatterConfig } from '@Utils/Formatters/types';
import { createTonnesCo2Formatter } from '@Utils/Formatters/tonnesCo2';

import { CommonProps } from '../../types';
import { NumberFormatterWrapper } from './styles';

type Props = TonnesCo2FormatterConfig['displayOptions'] &
  NumberFormatterConfig & {
    className?: CommonProps['className'];
    value?: number;
  };

export const UiTonnesCo2Formatter = ({
  className,
  decimalPrecision,
  value,
  lng,
  notation,
  isPerAnnum,
}: Props) => {
  const displayOptions = { isPerAnnum };

  const formattedValue = useMemo(() => {
    const tonnesCo2Formatter = createTonnesCo2Formatter({
      lng,
      decimalPrecision,
      notation,
      displayOptions,
    });

    return isNumber(value) && tonnesCo2Formatter.format(value);
  }, [value, decimalPrecision, notation, displayOptions]);

  return (
    <NumberFormatterWrapper className={className} title={String(value)}>
      {formattedValue}
    </NumberFormatterWrapper>
  );
};
