import { ThemingParameters } from '@ui5/webcomponents-react-base';
import { Toaster } from 'react-hot-toast';

export const SapToaster = () => (
  <Toaster
    position='bottom-center'
    toastOptions={{
      duration: 3000,
      success: {
        iconTheme: {
          primary: ThemingParameters.sapSuccessColor,
          secondary: ThemingParameters.sapButton_Emphasized_TextColor,
        },
      },
      error: {
        iconTheme: {
          primary: ThemingParameters.sapNegativeElementColor,
          secondary: ThemingParameters.sapBaseColor,
        },
      },
      style: {
        padding: '1rem',
        border: `1px solid rgba(${ThemingParameters.sapAccentColor7}, 0.12)`,
        boxShadow: ThemingParameters.sapContent_Shadow2,
        borderRadius: '12px',
        maxWidth: '500px',
      },
    }}
  />
);
