import { useQuery } from '@tanstack/react-query';

import api from '@DataAccess/api';

import { productsKeys } from './products.keys';

export const useActivatedProductsQuery = () =>
  useQuery({
    queryKey: productsKeys.activatedProducts.index,
    queryFn: () => api.platform.products.getActiveProducts(),
  });
