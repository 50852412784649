import { EsgProduct } from '@DataAccess/Platform/Products/products.types';

export enum ProcessStatus {
  IN_PROGRESS = 'In Progress',
  SCHEDULED = 'Scheduled',
  COMPLETE = 'Complete',
  ERROR = 'Error',
  ABORTED = 'Aborted',
}

export enum ProcessType {
  CALCULATION = 'Calculation',
  INTEGRATION = 'Integration',
  MANUAL_UPLOAD = 'Manual Upload',
}

export enum ErrorType {
  CELL_CONTAINS_FORMULA = 'CELL_CONTAINS_FORMULA',
  INVALID_BOOLEAN = 'INVALID_BOOLEAN',
  INVALID_DATE = 'INVALID_DATE',
  INVALID_DECIMAL = 'INVALID_DECIMAL',
  INVALID_ENUM = 'INVALID_ENUM',
  INVALID_HEADER = 'INVALID_HEADER',
  INVALID_INTEGER = 'INVALID_INTEGER',
  INVALID_YEAR = 'INVALID_YEAR',
  MISSING_MANDATORY_VALUE = 'MISSING_MANDATORY_VALUE',
  UNNECESSARY_FIELD = 'UNNECESSARY_FIELD',
  TOO_LONG_STRING = 'TOO_LONG_STRING',
  INCONSISTENT_DATA = 'INCONSISTENT_DATA',
  INCONSISTENT_VALIDITY = 'INCONSISTENT_VALIDITY',
  FINANCIAL_PRODUCT_CALCULATION_NOT_PERFORMED = 'FINANCIAL_PRODUCT_CALCULATION_NOT_PERFORMED',
  SHIP_OR_AIRCRAFT_FINANCE_CALCULATION_NOT_PERFORMED = 'SHIP_OR_AIRCRAFT_FINANCE_CALCULATION_NOT_PERFORMED',
  BUILDING_FINANCE_CALCULATION_NOT_PERFORMED = 'BUILDING_FINANCE_CALCULATION_NOT_PERFORMED',
  MISSING_BUSINESS_IDENTIFIER = 'MISSING_BUSINESS_IDENTIFIER',
  INVALID_GEOCOORDINATE = 'INVALID_GEOCOORDINATE',
  MISSING_PCAF_ASSET_CLASS = 'MISSING_PCAF_ASSET_CLASS',
  LATITUDE_OUT_OF_RANGE = 'LATITUDE_OUT_OF_RANGE',
  LATITUDE_WITHOUT_LONGITUDE = 'LATITUDE_WITHOUT_LONGITUDE',
  LATITUDE_NOT_PRECISE = 'LATITUDE_NOT_PRECISE',
  LONGITUDE_WITHOUT_LATITUDE = 'LONGITUDE_WITHOUT_LATITUDE',
  LONGITUDE_OUT_OF_RANGE = 'LONGITUDE_OUT_OF_RANGE',
  LONGITUDE_NOT_PRECISE = 'LONGITUDE_NOT_PRECISE',
  GEOQUALITY_TOO_LOW = 'GEOQUALITY_TOO_LOW',
}

export enum ProcessItemType {
  ASSET_VALUATION = 'Physical Asset Valuation',
  BUILDING = 'Physical Asset - Building',
  BUSINESS_PARTNER = 'Business Partner',
  BUSINESS_PARTNER_ADDRESS = 'Business Partner Address Coordinates',
  COUNTRY_REGION = 'Country-Region',
  EMISSION_FACTOR_BUILDING_CERTIFICATE_CLASS = 'Emission Factor Building Certificate Class',
  EMISSION_FACTOR_BUILDING_FLOOR_AREA = 'Emission Factor Floor Area',
  EMISSION_FACTOR_ENERGY = 'Emission Factor Energy',
  EMISSION_FACTOR_GOODS = 'Emission Factor Goods',
  EMISSION_FACTOR_NUMBER_OF_BUILDINGS = 'Emission Factor Number of Buildings',
  EMISSION_FACTOR_SECTOR = 'Emission Factor Sector',
  FINANCIAL_CONTRACT = 'Financial Contract',
  FINANCIAL_INSTRUMENT = 'Financial Instrument',
  INDUSTRY_CLASSIFICATION_CODE = 'Industry Classification Code',
  PHYSICAL_ASSET = 'Physical Asset - General',
  PHYSICAL_ASSET_ADDRESS = 'Physical Asset Address Coordinates',
  PHYSICAL_ASSET_TO_FINANCIAL_CONTRACT = 'Physical Asset - Financial Contract',
  PROJECT = 'Project',
  PROJECT_ADDRESS = 'Project Address Coordinates',
  REPORTED_ENERGY = 'Reported Energy',
  REPORTED_GOODS = 'Reported Goods',
  ISS = 'ISS',
  FINANCED_EMISSIONS = 'Financed Emissions',
  MUNICH_RE = 'Munich Re',
}

export type ProcessEntityType = Exclude<
  ProcessItemType,
  ProcessItemType.ISS | ProcessItemType.FINANCED_EMISSIONS
>;

export type ProcessMonitoringDto = {
  processId: string;
  itemType: ProcessItemType;
  processType: ProcessType;
  parameters: string;
  triggeredStartDate: string;
  scheduledStartDate: string;
  actualStartDate: string;
  businessDate: string;
  systemDate: string;
  endDate: string;
  duration: number;
  totalDataSets: string;
  failedDataSets: string;
  status: ProcessStatus;
  errors: string;
  products: EsgProduct[];
  dataHierarchyId: string;
};

export type ValidationReportDto = {
  processId: string;
  errorType: ErrorType;
  entityType: ProcessEntityType;
  rowNumber: number;
  businessId: string;
  columnName: string;
  rawData: string;
  errorIndex: number;
};

export type ParametrizationProps = {
  businessDate: string;
  systemDate: string;
};
