import { createContext, useContext } from 'react';

import { useGeneralSettingsQuery } from '@DataAccess/Core/Settings/settings.hooks';
import { Currency } from '@Types/types';
import { CommonProps } from '@UiCommon/types';
import { GeneralSettingsDto } from '@DataAccess/Core/Settings/settings.types';

export const defaultSettings: GeneralSettingsDto = {
  currency: Currency.EUR,
};

const SettingsStoreContext = createContext<GeneralSettingsDto>(defaultSettings);

export const SettingsProvider = ({ children }: CommonProps) => {
  const { data: settings } = useGeneralSettingsQuery();

  return (
    <SettingsStoreContext.Provider value={settings ?? defaultSettings}>
      {children}
    </SettingsStoreContext.Provider>
  );
};

export const useSettingsStore = () => {
  const value = useContext(SettingsStoreContext);

  if (!value) {
    throw new Error('useSettingsStore must be used within SettingsProvider');
  }

  return value;
};
