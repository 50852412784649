import { CollectionQueryParams } from '@DataAccess/types';

export const processMonitoringKeys = {
  latestEmissionRunId: ['latestEmissionRunId'] as const,
  searchVersionList: (params: unknown) => ['searchVersionList', params] as const,
  list: (params: CollectionQueryParams) => ['processMonitoring', params] as const,
  details: (processId?: string) => ['details', processId] as const,
  validationReport: (processId: string, params: CollectionQueryParams) =>
    ['validationReport', { params, processId }] as const,
};
