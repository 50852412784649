import { useMemo } from 'react';

import { useProcessManagerTranslation } from '@GlobalProviders/i18n/hooks';
import { UiTableColumnDataType } from '@UiCommon/Components/UiAnalyticalTable/types';

import { useCommonCollectedColumns } from '../useCommonCollectedColumns';

export const useEmissionFactorSectorColumns = () => {
  const { t } = useProcessManagerTranslation();
  const commonColumns = useCommonCollectedColumns();

  return useMemo(
    () => [
      {
        label: t('processManager.manualUpload.emissionFactorSector.table.classificationSystem'),
        accessor: 'classificationSystem',
        id: 'classificationSystem',
        minWidth: 120,
        isVisible: true,
        beField: 'CLASSIFICATION_SYSTEM',
      },
      {
        label: t('processManager.manualUpload.emissionFactorSector.table.classificationCode'),
        accessor: 'classificationCode',
        id: 'classificationCode',
        minWidth: 104,
        isVisible: true,
        beField: 'CLASSIFICATION_CODE',
      },
      {
        label: t('processManager.manualUpload.emissionFactorSector.table.region'),
        accessor: 'region',
        id: 'region',
        minWidth: 120,
        isVisible: true,
        beField: 'REGION',
      },
      {
        label: t('processManager.manualUpload.emissionFactorSector.table.location'),
        accessor: 'location',
        id: 'location',
        minWidth: 100,
        isVisible: true,
        beField: 'LOCATION',
      },
      {
        label: t('processManager.manualUpload.emissionFactorSector.table.countryRegion'),
        accessor: 'countryRegion',
        id: 'countryRegion',
        minWidth: 120,
        isVisible: true,
        beField: 'COUNTRY_REGION',
      },
      {
        label: t('processManager.manualUpload.emissionFactorSector.table.dataQualityScore'),
        accessor: 'dataQualityScore',
        id: 'dataQualityScore',
        minWidth: 104,
        isVisible: true,
        beField: 'DATA_QUALITY_SCORE',
      },
      {
        label: t('processManager.manualUpload.emissionFactorSector.table.dataQualityScoreOption'),
        accessor: 'dataQualityScoreOption',
        id: 'dataQualityScoreOption',
        minWidth: 120,
        isVisible: true,
        beField: 'DATA_QUALITY_SCORE_OPTION',
      },
      {
        label: t('processManager.manualUpload.emissionFactorSector.table.emissionFactorScope1'),
        accessor: 'emissionFactorScope1',
        id: 'emissionFactorScope1',
        minWidth: 100,
        isVisible: true,
        type: UiTableColumnDataType.NUMBER,
        beField: 'EMISSION_FACTOR_SCOPE_1',
      },
      {
        label: t('processManager.manualUpload.emissionFactorSector.table.emissionFactorScope2'),
        accessor: 'emissionFactorScope2',
        id: 'emissionFactorScope2',
        minWidth: 120,
        isVisible: true,
        type: UiTableColumnDataType.NUMBER,
        beField: 'EMISSION_FACTOR_SCOPE_2',
      },
      {
        label: t('processManager.manualUpload.emissionFactorSector.table.emissionFactorScope3'),
        accessor: 'emissionFactorScope3',
        id: 'emissionFactorScope3',
        minWidth: 100,
        isVisible: true,
        type: UiTableColumnDataType.NUMBER,
        beField: 'EMISSION_FACTOR_SCOPE_3',
      },
      {
        label: t('processManager.manualUpload.shared.year'),
        accessor: 'year',
        id: 'year',
        isVisible: true,
        beField: 'YEAR',
        type: UiTableColumnDataType.NUMBER,
      },
      ...commonColumns,
    ],
    [t, commonColumns],
  );
};
