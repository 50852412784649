import { useUiCommonTranslation } from '@GlobalProviders/i18n/hooks';

import { ElementSize } from '../../../types';
import { UiButton } from '../../UiButton/UiButton';
import { UiDialogFooter } from '../../UiDialog/UiDialogFooter';

type Props = {
  onCancel: () => void;
  onConfirm: () => void;
};

export const UiSettingsModalFooter = ({ onConfirm, onCancel }: Props) => {
  const { t } = useUiCommonTranslation();

  return (
    <UiDialogFooter
      endContent={
        <>
          <UiButton size={ElementSize.NORMAL} design='Emphasized' onClick={onConfirm}>
            {t('settingsModal.ok')}
          </UiButton>
          <UiButton size={ElementSize.NORMAL} design='Transparent' onClick={onCancel}>
            {t('settingsModal.cancel')}
          </UiButton>
        </>
      }
    />
  );
};
