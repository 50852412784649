export const GHGFiles = {
  collectedFinancialContract: {
    list: (params: { size: number }) => ['collectedFinancialContract', params] as const,
  },
  collectedFinancialInstrument: {
    list: (params: { size: number }) => ['collectedFinancialInstrument', params] as const,
  },
  collectedBusinessPartner: {
    list: (params: { size: number }) => ['collectedBusinessPartner', params] as const,
  },
  collectedProject: {
    list: (params: { size: number }) => ['collectedProject', params] as const,
  },
  collectedBuilding: {
    list: (params: { size: number }) => ['collectedBuilding', params] as const,
  },
  collectedPhysicalAsset: {
    list: (params: { size: number }) => ['collectedPhysicalAsset', params] as const,
  },
  collectedIndustryClassificationCode: {
    list: (params: { size: number }) => ['collectedIndustryClassificationCode', params] as const,
  },
  collectedReportedGoods: {
    list: (params: { size: number }) => ['collectedReportedGoods', params] as const,
  },
  collectedEmissionBuildingCertificates: {
    list: (params: { size: number }) => ['collectedEmissionBuildingCertificates', params] as const,
  },
  collectedEmissionFactorNumberOfBuildings: {
    list: (params: { size: number }) =>
      ['collectedEmissionFactorNumberOfBuildings', params] as const,
  },
  collectedBuildingFinancialContractAssociation: {
    list: (params: { size: number }) =>
      ['collectedBuildingFinancialContractAssociation', params] as const,
  },
  collectedPhysicalAssetFinancialContractAssociation: {
    list: (params: { size: number }) =>
      ['collectedPhysicalAssetFinancialContractAssociation', params] as const,
  },
  collectedEmissionFactorFloorArea: {
    list: (params: { size: number }) => ['collectedEmissionFactorFloorArea', params] as const,
  },
  collectedEmissionFactorSector: {
    list: (params: { size: number }) => ['collectedEmissionFactorSector', params] as const,
  },
  collectedAssetValuation: {
    list: (params: { size: number }) => ['collectedAssetValuation', params] as const,
  },
  collectedReportedEnergy: {
    list: (params: { size: number }) => ['collectedReportedEnergy', params] as const,
  },
  collectedCountryRegion: {
    list: (params: { size: number }) => ['collectedCountryRegion', params] as const,
  },
  collectedEmissionFactorGoods: {
    list: (params: { size: number }) => ['collectedEmissionFactorGoods', params] as const,
  },
  collectedEmissionFactorEnergy: {
    list: (params: { size: number }) => ['collectedEmissionFactorEnergy', params] as const,
  },
  collectedBPAddress: {
    list: (params: { size: number }) => ['collectedBPAddress', params] as const,
  },
  collectedProjectAddress: {
    list: (params: { size: number }) => ['collectedProjectAddress', params] as const,
  },
  collectedPhysicalAssetAddress: {
    list: (params: { size: number }) => ['collectedPhysicalAssetAddress', params] as const,
  },
};
