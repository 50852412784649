import { Table } from '@tanstack/react-table';

import { StyledTd, StyledTr } from '@UiCommon/Components/UiDraggableTable/Styles';

import { DefaultIndicator } from './DefaultIndicator';
import {
  RULE_ORDER_NUMBER_COLUMN_ID,
  SPLIT_SCOPE_1_EMISSIONS_COLUMN_ID,
  SPLIT_SCOPE_2_EMISSIONS_COLUMN_ID,
} from '../Utils/constants';

type Props<T> = {
  table: Table<T>;
};

export const DefaultRow = <T,>({ table }: Props<T>) => {
  return (
    <StyledTr>
      {table.getAllLeafColumns().map((column) => {
        if (column.getIsVisible() === false) {
          return null;
        }
        if (column.id === RULE_ORDER_NUMBER_COLUMN_ID) {
          return (
            <StyledTd style={{ width: column.getSize() }} key={column.id}>
              <DefaultIndicator />
            </StyledTd>
          );
        }
        if (
          column.id === SPLIT_SCOPE_1_EMISSIONS_COLUMN_ID ||
          column.id === SPLIT_SCOPE_2_EMISSIONS_COLUMN_ID
        ) {
          return (
            <StyledTd style={{ width: column.getSize() }} key={column.id}>
              50%
            </StyledTd>
          );
        }

        return <StyledTd style={{ width: column.getSize() }} key={column.id} />;
      })}
    </StyledTr>
  );
};
