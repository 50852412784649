import { useUiCommonTranslation } from '@GlobalProviders/i18n/hooks';
import { IconSet } from '@GlobalProviders/IconSetProvider';

import { UiTableColumnDataType } from '../../../types';
import { ConditionType, FilterConditionsType } from '../../types';
import { ConditionRowWrapper, uiSelectClass } from '../Styles';
import { BooleanCondition } from './BooleanCondition';
import { DateCondition } from './DateCondition';
import { InputCondition } from './InputCondition';
import { mapFilterConditionsByColumnType } from './mapFilterConditionsByColumnType';
import { UiButton } from '../../../../UiButton/UiButton';
import { UiSelect } from '../../../../UiSelect/UiSelect';
import { UiSelectOption } from '../../../../UiSelect/UiSelectOption';
import { DateTimeCondition } from './DateTimeCondition';

type Props = {
  columnType: UiTableColumnDataType;
  condition: ConditionType;
  onFilterChange: (newColumnCondition: FilterConditionsType) => void;
  onValueChange: (newValue: ConditionType['value']) => void;
  onDelete: () => void;
};

export const ConditionRow = ({
  columnType,
  condition: { columnCondition, value },
  onFilterChange,
  onValueChange,
  onDelete,
}: Props) => {
  const { t } = useUiCommonTranslation();

  const hasInput = [UiTableColumnDataType.NUMBER, UiTableColumnDataType.TEXT].includes(columnType);
  const hasDate = columnType === UiTableColumnDataType.DATE;
  const hasBoolean = columnType === UiTableColumnDataType.BOOLEAN;
  const hasDateTime = columnType === UiTableColumnDataType.DATE_TIME;

  return (
    <ConditionRowWrapper>
      <UiSelect
        className={uiSelectClass}
        onChange={(value) => {
          if (!value) return;
          const newColumnCondition = value as FilterConditionsType;
          onFilterChange(newColumnCondition);
        }}
      >
        {mapFilterConditionsByColumnType(columnType).map((condition) => (
          <UiSelectOption
            key={condition}
            value={condition}
            isSelected={columnCondition === condition}
          >
            {t(`types.filterConditions.${condition}`)}
          </UiSelectOption>
        ))}
      </UiSelect>
      {hasInput && (
        <InputCondition columnType={columnType} value={value} onValueChange={onValueChange} />
      )}
      {hasBoolean && <BooleanCondition value={value} onValueChange={onValueChange} />}
      {hasDate && <DateCondition value={value} onValueChange={onValueChange} />}
      {hasDateTime && <DateTimeCondition value={value} onValueChange={onValueChange} />}
      <UiButton design='Transparent' icon={IconSet.DECLINE} onClick={onDelete} />
    </ConditionRowWrapper>
  );
};
