import styled from '@emotion/styled';
import { useEffect, useRef } from 'react';

import { BODY_PAGE_PADDING } from '@Pages/constants';
import { CommonProps } from '@UiCommon/types';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
`;

const MainContent = styled.div`
  flex-grow: 1;
`;

const SidebarWrapper = styled.div`
  display: flex;
  margin: -${BODY_PAGE_PADDING} -${BODY_PAGE_PADDING} -${BODY_PAGE_PADDING} ${BODY_PAGE_PADDING};
`;

type Props = {
  showSidebar?: boolean;
  sidebar: JSX.Element;
} & CommonProps;

export const PageWithSidebar = ({ className, children, sidebar, showSidebar }: Props) => {
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const mainContentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!mainContentRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      if (!sidebarRef.current || !mainContentRef.current) return;

      if (mainContentRef.current.clientHeight < window.innerHeight) {
        sidebarRef.current.style.height = '100vh';

        return;
      }

      sidebarRef.current.style.height = 'auto';
    });

    resizeObserver.observe(mainContentRef.current);

    return () => resizeObserver.disconnect();
  }, [showSidebar]);

  return (
    <Wrapper className={className}>
      <MainContent ref={mainContentRef}>{children}</MainContent>
      {showSidebar && <SidebarWrapper ref={sidebarRef}>{sidebar}</SidebarWrapper>}
    </Wrapper>
  );
};
