import { toast } from 'react-hot-toast';
import { TFunction } from 'i18next';

import { axiosFactory } from '@DataAccess/axiosFactory';
import { UiAnalyticalTableColumnDefinition } from '@UiCommon/Components';
import { UiColumnFiltersType } from '@UiCommon/Components/UiAnalyticalTable/types';
import { downloadFile } from '@Utils/utils';
import { FileExtensions } from '@UiCommon/types';
import { UiListItemType } from '@UiCommon/Components/UiList/types';
import { SortingListItemProps } from '@UiCommon/Components/UiAnalyticalTable/SettingsModal/SortSettings/useSortingSettingsState';

import {
  MainToWorkerPayloadCsvType,
  WorkerPayloadStatus,
  WorkerToMainPayloadCsvType,
} from './types';

type CsvExportParamsType<T> = {
  fileName: string;
  columns: UiAnalyticalTableColumnDefinition<T>[];
  tableId: string;
  apiPath: string;
  filters?: UiColumnFiltersType[];
  pathParameter?: string;
  sorting?: UiListItemType<SortingListItemProps>[];
  t: TFunction<'shared'>;
  totalElements?: number;
  onError?: () => void;
  onInit?: () => void;
  onProgress?: (progress: number) => void;
  onSuccess?: () => void;
};

export const handleCsvExport = <T>({
  fileName,
  totalElements,
  tableId,
  apiPath,
  pathParameter,
  columns,
  filters,
  sorting,
  t,
  onError,
  onInit,
  onProgress,
  onSuccess,
}: CsvExportParamsType<T>) => {
  if (!totalElements || !axiosFactory.token) return;

  const worker = new Worker(new URL('./generateCSV.worker.ts', import.meta.url), {
    type: 'module',
  });

  worker.onerror = (event) => {
    event.preventDefault();
    onError?.();
    toast.error(t('exportTableData.genericError'));
    worker.terminate();
  };

  const columnsInfo = columns.map(({ label, id, accessor, sorting, beField, isVisible }) => ({
    label,
    id,
    accessor,
    sorting,
    beField,
    isVisible,
  }));

  const mainToWorkerPayload: MainToWorkerPayloadCsvType<T> = {
    tableId,
    apiPath,
    totalElements,
    token: axiosFactory.token,
    columns: columnsInfo,
    filters,
    sorting,
    status: WorkerPayloadStatus.INIT_THREAD,
    pathParameter,
  };
  worker.postMessage(mainToWorkerPayload);

  onInit?.();

  worker.onmessage = ({
    data: { progress, urlFile },
  }: MessageEvent<WorkerToMainPayloadCsvType>) => {
    onProgress?.(progress);

    const refreshTokenPayload: Partial<MainToWorkerPayloadCsvType<T>> = {
      token: axiosFactory.token || undefined,
      status: WorkerPayloadStatus.REFRESH_TOKEN,
    };

    worker.postMessage(refreshTokenPayload);

    if (!urlFile) return;

    downloadFile({ urlFile, fileName, fileExtension: FileExtensions.CSV });

    setTimeout(() => onSuccess?.(), 300); // delayed effect to visualise Success

    worker.terminate();
  };
};
