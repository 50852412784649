import { useMemo } from 'react';

import { useProcessManagerTranslation } from '@GlobalProviders/i18n/hooks';

import { useCommonCollectedColumns } from '../useCommonCollectedColumns';

export const usePhysicalAssetFinancialContractAssociationColumns = () => {
  const { t } = useProcessManagerTranslation();
  const commonColumns = useCommonCollectedColumns();

  return useMemo(
    () => [
      {
        label: t(
          'processManager.manualUpload.physicalAssetFinancialContractAssociation.table.physicalAssetId',
        ),
        accessor: 'physicalAssetId',
        id: 'physicalAssetId',
        minWidth: 120,
        isVisible: true,
        beField: 'PHYSICAL_ASSET_ID',
      },
      {
        label: t(
          'processManager.manualUpload.physicalAssetFinancialContractAssociation.table.financialProductId',
        ),
        accessor: 'financialProductId',
        id: 'financialProductId',
        minWidth: 104,
        isVisible: true,
        beField: 'FINANCIAL_PRODUCT_ID',
      },
      ...commonColumns,
    ],
    [t, commonColumns],
  );
};
