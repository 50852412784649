import styled from '@emotion/styled';
import { ThemingParameters } from '@ui5/webcomponents-react-base';
import { useMemo } from 'react';
import { css } from '@emotion/css';
import { ButtonDesign } from '@ui5/webcomponents-react';

import { useUiCommonTranslation } from '@GlobalProviders/i18n/hooks';

import { UiButton } from '../../UiButton/UiButton';
import { UiText } from '../../UiText';
import { useAnalyticalTableContext } from '../AnalyticalTableContext';
import { SortingOrder } from '../types';

const Wrapper = styled.div`
  width: 100%;
  padding: 1rem 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  * {
    flex-grow: 1;
  }
`;

type Props = {
  label: string;
  id: string;
  onSortClick: (order: SortingOrder) => void;
};

const activeButtonStyles = css`
  border: 1px solid ${ThemingParameters.sapButton_Active_BorderColor};
`;

export const HeaderSortItem = ({ id, label, onSortClick }: Props) => {
  const { sortingItems } = useAnalyticalTableContext();
  const { t } = useUiCommonTranslation();
  const currentOrder = useMemo(() => {
    return sortingItems?.find((item) => item.value === id)?.data?.order;
  }, [sortingItems, id]);

  const activeButtonPropsFactor = (option: SortingOrder) => {
    if (currentOrder === option) {
      return {
        className: activeButtonStyles,
        design: ButtonDesign.Transparent,
      };
    }
  };

  return (
    <Wrapper>
      <UiText color={ThemingParameters.sapField_PlaceholderTextColor}>
        {t('sorting.sortBy', { value: label, interpolation: { escapeValue: false } })}
      </UiText>
      <ButtonsWrapper>
        <UiButton
          {...activeButtonPropsFactor(SortingOrder.ASC)}
          onClick={() => onSortClick(SortingOrder.ASC)}
        >
          {t('sorting.order.ASC')}
        </UiButton>
        <UiButton
          {...activeButtonPropsFactor(SortingOrder.DESC)}
          onClick={() => onSortClick(SortingOrder.DESC)}
        >
          {t('sorting.order.DESC')}
        </UiButton>
      </ButtonsWrapper>
    </Wrapper>
  );
};
