import 'swagger-ui-react/swagger-ui.css';

import { useAuth } from 'oidc-react';
import { useCallback } from 'react';
import SwaggerUI from 'swagger-ui-react';

export const SwaggerPage = () => {
  const SWAGGER_ENDPOINT = `${import.meta.env.VITE_APP_API_URL}/v3/api-docs`;

  const { userData } = useAuth();

  const reqInterceptorFn = useCallback(
    // eslint-disable-next-line
    (req: any) => ({
      ...req,
      headers: {
        ...req.headers,
        Authorization: `Bearer ${userData?.access_token}`,
      },
    }),
    [userData?.access_token],
  );

  return <SwaggerUI requestInterceptor={reqInterceptorFn} url={SWAGGER_ENDPOINT} />;
};
