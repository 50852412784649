import { createContext, useContext } from 'react';

import { ColumnFiltersType, FilterOption } from './types';
import { UiListItemType } from '../../UiList/types';
import { SortingOption } from './SortSettings/types';
import { SortingListItemProps } from './SortSettings/useSortingSettingsState';
import { CommonProps } from '../../../types';

type SettingsModalContextProps = {
  columns?: UiListItemType[];
  onColumnsReorder?: (columns: UiListItemType[]) => void;
  visibleColumns?: string[];
  onColumnsVisibilityChange?: (columns: string[]) => void;

  onSortingItemsChange?: (sortingItems: UiListItemType<SortingListItemProps>[]) => void;
  sortingItems?: UiListItemType<SortingListItemProps>[];
  sortingOptions?: SortingOption[];
  columnsToBeFiltered?: ColumnFiltersType[];
  filtersOptions?: FilterOption[];
  onColumnsToBeFiltered?: (selectedColumnFilters: ColumnFiltersType[]) => void;
};

const SettingsModalContext = createContext<SettingsModalContextProps>({});

export const useSettingsModalContext = () => useContext(SettingsModalContext);

type Props = CommonProps & SettingsModalContextProps;

export const SettingsModalContextProvider = ({ children, ...context }: Props) => (
  <SettingsModalContext.Provider value={context}>{children}</SettingsModalContext.Provider>
);
