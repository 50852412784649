export default {
  processManager: {
    title: 'Upload & Process Manager',
    subtitle: 'On this page, you can monitor the status of your KPI calculations.',
    duration: {
      lessThanOneSecond: '<1 second',
    },
    status: {
      error: 'Error',
      complete: 'Complete',
      inProgress: 'In Progress',
      scheduled: 'Scheduled',
    },
    processType: {
      CALCULATION: 'Calculation',
      MANUAL_UPLOAD: 'Manual Upload',
      INTEGRATION: 'Integration',
    },
    item: {
      FINANCIAL_CONTRACT: 'Financial Contract',
      FINANCIAL_INSTRUMENT: 'Financial Instrument',
      BUSINESS_PARTNER: 'Business Partner',
      REPORTED_GOODS: 'Reported Goods',
      PROJECT: 'Project',
      BUILDING: 'Physical Asset - Building',
      PHYSICAL_ASSET: 'Physical Asset - General',
      PHYSICAL_ASSET_TO_FINANCIAL_CONTRACT: 'Physical Asset - Financial Contract',
      EMISSION_FACTOR_BUILDING_CERTIFICATE_CLASS: 'Emission Factor Building Certificate Class',
      EMISSION_FACTOR_SECTOR: 'Emission Factor Sector',
      EMISSION_FACTOR_NUMBER_OF_BUILDINGS: 'Emission Factor Number of Buildings',
      EMISSION_FACTOR_BUILDING_FLOOR_AREA: 'Emission Factor Floor Area',
      INDUSTRY_CLASSIFICATION_CODES: 'Industry Classification Codes',
      ASSET_VALUATION: 'Physical Asset Valuation',
      REPORTED_ENERGY: 'Energy',
      EMISSION_FACTOR_ENERGY: 'Emission Factor Energy',
      COUNTRY_REGION: 'Country-Region',
      EMISSION_FACTOR_GOODS: 'Emission Factor Goods',
      BUSINESS_PARTNER_ADDRESS: 'Business Partner Address Coordinates',
      PROJECT_ADDRESS: 'Project Address Coordinates',
      PHYSICAL_ASSET_ADDRESS: 'Physical Asset Address Coordinates',
      ISS: 'ISS',
      FINANCED_EMISSIONS: 'Financed Emissions',
    },
    table: {
      dataHierarchy: 'Data Hierarchy',
      id: 'ID',
      item: 'Item',
      processType: 'Process',
      parameters: 'Parameters (Business System Date)',
      scheduledStartDate: 'Scheduled Start',
      triggeredStartDate: 'Triggered Start',
      actualStartDate: 'Actual Start',
      businessDate: 'Business Date',
      systemDate: 'System Date Parameter',
      endDate: 'Actual End',
      duration: 'Duration',
      totalDataSets: 'Total Data Sets',
      failedDataSets: 'Failed Data Sets',
      status: 'Status',
      errors: 'Errors',
    },
    runCalculationModal: {
      header: 'Run Calculation',
      businessDate: 'Business Date',
      systemDate: 'System Date',
      cancel: 'Cancel',
      runCalculation: 'Run Calculation',
    },
    manualUpload: {
      uploadFile: 'Upload File',
      triggerCalculation: 'Run Calculation',
      files: 'Files',
      errors: 'View Errors ({{amount}})',
      toast: {
        onSuccessUpload: 'File uploaded successfully',
        onSuccessfulComputedData: 'Data calculated successfully',
        onErroredComputedData: 'Data could not be calculated',
        onInvalidEntity: 'Invalid entity, please upload entity file matching your selection',
        onInvalidGeoCoordinates: 'Invalid coordinates. Please upload valid coordinates',
        onInvalidExtension: 'Incorrect file extension. Please upload the CSV file',
        onGeneralError: 'Something went wrong. Please try again',
        onGatewayTimeout: 'Sorry, the request took too long. Please try again later.',
      },
      shared: {
        processId: 'Process ID',
        dataSource: 'Data Source',
        systemDate: 'System Date',
        year: 'Year',
        emissionsReportingDate: 'Emissions Reporting Date',
        fiscalPeriodEndDate: 'Fiscal Period End Date',
        enabledFlag: 'Enabled Flag',
        businessValidFrom: 'Business Validity From',
        businessValidTo: 'Business Validity To',
      },
      uploadDialog: {
        title: 'Data Upload',
        ok: 'Ok',
        confirm: 'Confirm',
        entities: 'Entities ({{count}})',
        uploadBtnLabel: 'Select File',
        table: {
          classifications: 'Classifications',
          statisticalData: 'Statistical Data',
          productionConsumption: 'Production Consumption',
          ghgEmittingObjects: 'GHG Emitting Objects',
          valuations: 'Valuations',
          financialProducts: 'Financial Products',
          associations: 'Associations',
          address: 'Address',
        },
      },
      emptyScreen: {
        noFilesYet: 'No files yet',
        tryUploadingAFile: 'Try uploading a file to get started',
        uploadFile: 'Upload File',
      },
      physicalAsset: {
        tableTitle: 'Physical Asset - General ({{amount}})',
        title: 'Physical Asset - General',
        subtitle: 'This table contains data for all the uploaded Physical Assets - General',
        table: {
          physicalAssetId: 'Physical Asset ID',
          type: 'Type',
          valueAtOrigination: 'Value At Origination',
          emissionsScope1: 'Scope 1 Emissions',
          emissionsScope2: 'Scope 2 Emissions',
          emissionsScope1Plus2: 'Emissions Scope 1+2',
          dataQualityScoreScope1: 'Data Quality Score (Scope 1)',
          dataQualityScoreScope2: 'Data Quality Score (Scope 2)',
          dataQualityScoreScope1Plus2: 'Data Quality Score (Scope 1+2)',
          dataQualityScoreOptionScope1: 'Data Quality Score Option (Scope 1)',
          dataQualityScoreOptionScope2: 'Data Quality Score Option (Scope 2)',
          dataQualityScoreOptionScope1Plus2: 'Data Quality Score Option (Scope 1+2)',
        },
      },
      financialContract: {
        tableTitle: 'Financial Contract ({{amount}})',
        title: 'Financial Contract',
        subtitle: 'This table contains data for all the uploaded Financial Contracts',
        table: {
          businessPartnerId: 'Business Partner ID',
          productType: 'Product Type',
          financialProductId: 'Financial Product ID',
          outstandingAmount: 'Outstanding Amount',
          originationDate: 'Origination Date',
          naceCode: 'NACE Code',
          useOfProceeds: 'Use of Proceeds (GHG)',
          pcafAssetClass: 'PCAF Asset Class',
        },
      },
      financialInstrument: {
        tableTitle: 'Financial Instrument ({{amount}})',
        title: 'Financial Instrument',
        subtitle: 'This table contains data for all the uploaded Financial Instruments',
        table: {
          financialProductId: 'Financial Product ID',
          businessPartnerId: 'Business Partner ID',
          isin: 'ISIN',
          productType: 'Product Type',
          naceCode: 'NACE Code',
          bookValue: 'Book Value',
          pcafAssetClass: 'PCAF Asset Class',
        },
      },
      businessPartner: {
        tableTitle: 'Business Partner ({{amount}})',
        title: 'Business Partner',
        subtitle: 'This table contains data from all uploaded Business Partners',
        table: {
          businessPartnerId: 'ID',
          name: 'Name',
          lei: 'LEI',
          businessPartnerCategory: 'Category',
          naceCode: 'NACE Code',
          listedCompanyFlag: 'Listed Company Flag',
          countryRegion: 'Country/Region',
          evic: 'EVIC',
          totalRevenue: 'Total Revenue',
          totalAssets: 'Total Assets',
          totalEmissions: 'Total Emissions',
          emissionsScope1: 'Emissions Scope 1',
          emissionsScope2: 'Emissions Scope 2',
          emissionsScope3: 'Emissions Scope 3',
          emissionsScope1Plus2: 'Emissions Scope 1+2',
          emissionsVerifiedFlag: 'Emissions Verified Flag',
          dataQualityScoreScope1: 'Data Quality Score (Scope 1)',
          dataQualityScoreScope2: 'Data Quality Score (Scope 2)',
          dataQualityScoreScope3: 'Data Quality Score (Scope 3)',
          dataQualityScoreScope1Plus2: 'Data Quality Score (Scope 1+2)',
          dataQualityScoreOptionScope1: 'Data Quality Score Option (Scope 1)',
          dataQualityScoreOptionScope2: 'Data Quality Score Option (Scope 2)',
          dataQualityScoreOptionScope3: 'Data Quality Score Option (Scope 3)',
          dataQualityScoreOptionScope1Plus2: 'Data Quality Score Option (Scope 1+2)',
          avoidedEmissionsFlag: 'Avoided Emissions Flag',
          avoidedEmissions: 'Avoided Emissions',
        },
      },
      project: {
        tableTitle: 'Project ({{amount}})',
        title: 'Project',
        subtitle: 'This table contains data from all uploaded Projects',
        table: {
          projectId: 'Project ID',
          financialProductId: 'Financial Product ID',
          name: 'Name',
          category: 'Category',
          naceCode: 'NACE Code',
          listedIdentifier: 'Listed Identifier',
          countryRegion: 'Country/Region',
          totalRevenue: 'Total Revenue',
          totalAssets: 'Total Assets',
          totalEmissions: 'Total Emissions',
          scope1Emissions: 'Scope 1 Emissions',
          scope2Emissions: 'Scope 2 Emissions',
          scope3Emissions: 'Scope 3 Emissions',
          scope1Plus2Emissions: 'Scope 1+2 Emissions',
          emissionsVerifiedFlag: 'Emissions Verified Flag',
          dataQualityScoreScope1: 'Data Quality Score (Scope 1)',
          dataQualityScoreScope2: 'Data Quality Score (Scope 2)',
          dataQualityScoreScope3: 'Data Quality Score (Scope 3)',
          dataQualityScoreScope1Plus2: 'Data Quality Score (Scope 1+2)',
          dataQualityScoreOptionScope1: 'Data Quality Score Option (Scope 1)',
          dataQualityScoreOptionScope2: 'Data Quality Score Option (Scope 2)',
          dataQualityScoreOptionScope3: 'Data Quality Score Option (Scope 3)',
          dataQualityScoreOptionScope1Plus2: 'Data Quality Score Option (Scope 1+2)',
          avoidedEmissionsFlag: 'Avoided Emissions Flag',
          avoidedEmissions: 'Avoided Emissions',
        },
      },
      building: {
        tableTitle: 'Physical Asset - Building ({{amount}})',
        title: 'Physical Asset - Building',
        subtitle: 'This table contains data from all Physical Assets - Building',
        table: {
          physicalAssetId: 'Physical Asset ID',
          realEstateType: 'Real Estate Type',
          availableFloorSpace: 'Available Floor Space',
          countryRegion: 'Country/Region',
          numberOfBuildings: 'Number Of Buildings',
          certificateClass: 'Certificate Class',
        },
      },
      industryClassificationCode: {
        tableTitle: 'Industry Classification Code ({{amount}})',
        title: 'Industry Classification Code',
        subtitle: 'This table contains data from all uploaded Industry Classification Code',
        table: {
          classificationSystem: 'Classification System',
          classificationCode: 'Classification Code',
          classificationLevel1: 'Classification Level 1',
          classificationLevel2: 'Classification Level 2',
        },
      },
      reportedGoods: {
        tableTitle: 'Reported Goods ({{amount}})',
        title: 'Reported Goods',
        subtitle: 'This table contains data from all uploaded Reported Goods',
        table: {
          businessPartnerId: 'Business Partner ID',
          projectId: 'Project ID',
          purpose: 'Purpose',
          goodsType: 'Goods Type',
          amount: 'Amount',
        },
      },
      emissionBuildingCertificates: {
        tableTitle: 'Emission Building Certificate Class ({{amount}})',
        title: 'Emission Building Certificate Class',
        subtitle:
          'This table contains data from all uploaded Emission Building Certificate Classes',
        table: {
          countryRegion: 'Country/Region',
          realEstateType: 'Real Estate Type',
          certificateClass: 'Certificate Class',
          emissionFactor: 'Emission Factor',
        },
      },
      emissionFactorNumberOfBuildings: {
        tableTitle: 'Emission Factor Number of Buildings ({{amount}})',
        title: 'Emission Factor Number of Buildings',
        subtitle: 'This table contains data from all uploaded Emission Factor Numbers of Buildings',
        table: {
          countryRegion: 'Country/Region',
          realEstateType: 'Real Estate Type',
          emissionFactor: 'Emission Factor',
        },
      },
      countryRegion: {
        tableTitle: 'Country-Region ({{amount}})',
        title: 'Country-Region',
        subtitle: 'This table contains data from all uploaded Country-Region',
        table: {
          countryRegion: 'Country/Region',
          region: 'Region',
          location: 'Location',
        },
      },
      physicalAssetFinancialContractAssociation: {
        tableTitle: 'Physical Asset - Financial Contract ({{amount}})',
        title: 'Physical Asset - Financial Contract',
        subtitle: 'This table contains data from all uploaded Physical Asset - Financial Contract',
        table: {
          physicalAssetId: 'Physical Asset ID',
          financialProductId: 'Financial Product ID',
        },
      },
      emissionFactorFloorArea: {
        tableTitle: 'Emission Factor Floor Area ({{amount}})',
        title: 'Emission Factor Floor Area',
        subtitle: 'This table contains data from all uploaded Emission Factor Floor Areas',
        table: {
          countryRegion: 'Country/Region',
          realEstateType: 'Real Estate Type',
          emissionFactor: 'Emission Factor',
        },
      },
      emissionFactorSector: {
        tableTitle: 'Emission Factor Sector ({{amount}})',
        title: 'Emission Factor Sector',
        subtitle: 'This table contains data from all uploaded Emission Factor Sectors',
        table: {
          classificationSystem: 'Classification System',
          classificationCode: 'Classification Code',
          countryRegion: 'Country/Region',
          region: 'Region',
          location: 'Location',
          dataQualityScore: 'Data Quality Score',
          dataQualityScoreOption: 'Data Quality Score Option',
          emissionFactorScope1: 'Emission Factor Scope 1',
          emissionFactorScope2: 'Emission Factor Scope 2',
          emissionFactorScope3: 'Emission Factor Scope 3',
        },
      },
      assetValuation: {
        tableTitle: 'Physical Asset Valuation ({{amount}})',
        title: 'Physical Asset Valuation',
        subtitle: 'This table contains data from all uploaded Physical Asset Valuations',
        table: {
          physicalAssetId: 'Physical Asset ID',
          valuationDate: 'Valuation Date',
          value: 'Value',
        },
      },
      reportedEnergy: {
        tableTitle: 'Reported Energy ({{amount}})',
        title: 'Reported Energy',
        subtitle: 'This table contains data from uploaded Reported Energies',
        table: {
          businessPartnerId: 'Business Partner ID',
          projectId: 'Project ID',
          energy: 'Energy',
          countryRegion: 'Country/Region',
          purpose: 'Purpose',
          supplier: 'Supplier',
          tariff: 'Tariff',
          source: 'Source',
        },
      },
      emissionFactorEnergy: {
        tableTitle: 'Emission Factor Energy ({{amount}})',
        title: 'Emission Factor Energy',
        subtitle: 'This table contains data from uploaded Emission Factor Energies',
        table: {
          emissionFactorScope1: 'Emission Factor Scope 1',
          emissionFactorScope2: 'Emission Factor Scope 2',
          emissionFactorScope3: 'Emission Factor Scope 3',
          countryRegion: 'Country/Region',
          purpose: 'Purpose',
          supplier: 'Supplier',
          tariff: 'Tariff',
          source: 'Source',
        },
      },
      emissionFactorGoods: {
        tableTitle: 'Emission Factor Goods ({{amount}})',
        title: 'Emission Factor Goods',
        subtitle: 'This table contains data from all uploaded Emission Factor Goods',
        table: {
          goodsType: 'Goods Type',
          purpose: 'Purpose',
          emissionFactor: 'Goods Emission Factor',
        },
      },
      bpAddress: {
        tableTitle: 'Business Partner Address Coordinates ({{amount}})',
        title: 'Business Partner Address Coordinates',
        subtitle: 'This table contains data from all uploaded Business Partner Address Coordinates',
        table: {
          businessPartnerId: 'Business Partner ID',
          countryRegion: 'Business Partner Address Country/Region',
          subregion: 'Business Partner Address Subregion',
          postalCode: 'Business Partner Postal Code',
          city: 'Business Partner City',
          street: 'Business Partner Street',
          houseNumber: 'Business Partner House Number',
          streetAndHouseNumber: 'Business Partner Street and House Number',
          latitude: 'Business Partner Latitude',
          longitude: 'Business Partner Longitude',
        },
      },
      projectAddress: {
        tableTitle: 'Project Address Coordinates ({{amount}})',
        title: 'Project Address Coordinates',
        subtitle: 'This table contains data from all uploaded Project Address Coordinates',
        table: {
          projectId: 'Project ID',
          countryRegion: 'Project Address Country/Region',
          subregion: 'Project Address Subregion',
          postalCode: 'Project Postal Code',
          city: 'Project City',
          street: 'Project Street',
          houseNumber: 'Project House Number',
          streetAndHouseNumber: 'Project Street and House Number',
          latitude: 'Project Latitude',
          longitude: 'Project Longitude',
        },
      },
      paAddress: {
        tableTitle: 'Physical Asset Address Coordinates ({{amount}})',
        title: 'Physical Asset Address Coordinates',
        subtitle: 'This table contains data from all uploaded Physical Asset Address Coordinates',
        table: {
          physicalAssetId: 'Physical Asset ID',
          countryRegion: 'Physical Asset Address Country/Region',
          subregion: 'Physical Asset Address Subregion',
          postalCode: 'Physical Asset Postal Code',
          city: 'Physical Asset City',
          street: 'Physical Asset Street',
          houseNumber: 'Physical Asset House Number',
          streetAndHouseNumber: 'Physical Asset Street and House Number',
          latitude: 'Physical Asset Latitude',
          longitude: 'Physical Asset Longitude',
        },
      },
      locationScore: {
        tableTitle: 'Munich RE ({{amount}})',
        title: 'Munich RE',
        subtitle: 'This table contains data from Munich RE',
        table: {
          businessIdentifier: 'Business Identifier',
          streetHouseNr: 'Street House Number',
          zipCode: 'Zip Code',
          city: 'City',
          countryRegion: 'Country Region',
          geoquality: 'Geoquality',
          elevation: 'Elevation',
          distanceToCoast: 'Distance to Coast',
          populationDensity: 'Population Density',
          earthquake: 'Earthquake',
          volcanoes: 'Volcanoes',
          tsunami: 'Tsunami',
          tropicalCyclone: 'Tropical Cyclone',
          extratropicalStorm: 'Extratropical Storm',
          hail: 'Hail',
          tornado: 'Tornado',
          lightning: 'Lightning',
          wildfire: 'Wildfire',
          riverFlood: 'River Flood',
          flashFlood: 'Flash Flood',
          stormSurge: 'Storm Surge',
          activeFaults: 'Active Faults',
          soilAndShaking: 'Soil and Shaking',
          overallRiskScore: 'Overall Risk Score',
          overallRiskIndex: 'Overall Risk Index',
          overallPositionInClass: 'Overall Position in Class',
          earthquakeRiskScore: 'Earthquake Risk Score',
          earthquakeRiskIndex: 'Earthquake Risk Index',
          earthquakePositionInClass: 'Earthquake Position in Class',
          stormRiskScore: 'Storm Risk Score',
          stormRiskIndex: 'Storm Risk Index',
          stormPositionInClass: 'Storm Position in Class',
          floodRiskScore: 'Flood Risk Score',
          floodRiskIndex: 'Flood Risk Index',
          floodPositionInClass: 'Flood Position in Class',
          floodDefenseLevelOfProtection: 'Flood Defense Level of Protection',
          floodDefenseStandardOfProtection: 'Flood Defense Standard of Protection',
          tropicalCycloneCurrent: 'Tropical Cyclone Current',
          tropicalCycloneRcp452030: 'Tropical Cyclone RCP 4.5 2030',
          tropicalCycloneRcp452050: 'Tropical Cyclone RCP 4.5 2050',
          tropicalCycloneRcp452100: 'Tropical Cyclone RCP 4.5 2100',
          tropicalCycloneRcp852030: 'Tropical Cyclone RCP 8.5 2030',
          tropicalCycloneRcp852050: 'Tropical Cyclone RCP 8.5 2050',
          tropicalCycloneRcp852100: 'Tropical Cyclone RCP 8.5 2100',
          riverFloodCurrentUndefended: 'River Flood Current Undefended',
          riverFloodCurrentDefended: 'River Flood Current Defended',
          riverFloodRcp452030Undefended: 'River Flood RCP 4.5 2030 Undefended',
          riverFloodRcp452030Defended: 'River Flood RCP 4.5 2030 Defended',
          riverFloodRcp452050Undefended: 'River Flood RCP 4.5 2050 Undefended',
          riverFloodRcp452050Defended: 'River Flood RCP 4.5 2050 Defended',
          riverFloodRcp452100Undefended: 'River Flood RCP 4.5 2100 Undefended',
          riverFloodRcp452100Defended: 'River Flood RCP 4.5 2100 Defended',
          riverFloodRcp852030Undefended: 'River Flood RCP 8.5 2030 Undefended',
          riverFloodRcp852030Defended: 'River Flood RCP 8.5 2030 Defended',
          riverFloodRcp852050Undefended: 'River Flood RCP 8.5 2050 Undefended',
          riverFloodRcp852050Defended: 'River Flood RCP 8.5 2050 Defended',
          riverFloodRcp852100Undefended: 'River Flood RCP 8.5 2100 Undefended',
          riverFloodRcp852100Defended: 'River Flood RCP 8.5 2100 Defended',
          seaLevelRiseRcp26: 'Sea Level Rise RCP 2.6',
          seaLevelRiseRcp45: 'Sea Level Rise RCP 4.5',
          seaLevelRiseRcp85: 'Sea Level Rise RCP 8.5',
          fireWeatherCurrent: 'Current Fire Weather',
          fireWeather1262030: 'Fire Weather RCP 1.2 2030',
          fireWeather1262040: 'Fire Weather RCP 1.2 2040',
          fireWeather1262050: 'Fire Weather RCP 1.2 2050',
          fireWeather1262100: 'Fire Weather RCP 1.2 2100',
          fireWeather2452030: 'Fire Weather RCP 2.4 2030',
          fireWeather2452040: 'Fire Weather RCP 2.4 2040',
          fireWeather2452050: 'Fire Weather RCP 2.4 2050',
          fireWeather2452100: 'Fire Weather RCP 2.4 2100',
          fireWeather3702030: 'Fire Weather RCP 3.7 2030',
          fireWeather3702040: 'Fire Weather RCP 3.7 2040',
          fireWeather3702050: 'Fire Weather RCP 3.7 2050',
          fireWeather3702100: 'Fire Weather RCP 3.7 2100',
          fireWeather5852030: 'Fire Weather RCP 5.8 2030',
          fireWeather5852040: 'Fire Weather RCP 5.8 2040',
          fireWeather5852050: 'Fire Weather RCP 5.8 2050',
          fireWeather5852100: 'Fire Weather RCP 5.8 2100',
          droughtCurrent: 'Current Drought',
          drought2452030: 'Drought RCP 2.4 2030',
          drought2452040: 'Drought RCP 2.4 2040',
          drought2452050: 'Drought RCP 2.4 2050',
          drought2452100: 'Drought RCP 2.4 2100',
          drought5852030: 'Drought RCP 5.8 2030',
          drought5852040: 'Drought RCP 5.8 2040',
          drought5852050: 'Drought RCP 5.8 2050',
          drought5852100: 'Drought RCP 5.8 2100',
          heatCurrent: 'Current Heat',
          heat1262030: 'Heat RCP 1.2 2030',
          heat1262040: 'Heat RCP 1.2 2040',
          heat1262050: 'Heat RCP 1.2 2050',
          heat1262100: 'Heat RCP 1.2 2100',
          heat2452030: 'Heat RCP 2.4 2030',
          heat2452040: 'Heat RCP 2.4 2040',
          heat2452050: 'Heat RCP 2.4 2050',
          heat2452100: 'Heat RCP 2.4 2100',
          heat3702030: 'Heat RCP 3.7 2030',
          heat3702040: 'Heat RCP 3.7 2040',
          heat3702050: 'Heat RCP 3.7 2050',
          heat3702100: 'Heat RCP 3.7 2100',
          heat5852030: 'Heat RCP 5.8 2030',
          heat5852040: 'Heat RCP 5.8 2040',
          heat5852050: 'Heat RCP 5.8 2050',
          heat5852100: 'Heat RCP 5.8 2100',
          precipitationCurrent: 'Current Precipitation',
          precipitation1262030: 'Precipitation RCP 1.2 2030',
          precipitation1262040: 'Precipitation RCP 1.2 2040',
          precipitation1262050: 'Precipitation RCP 1.2 2050',
          precipitation1262100: 'Precipitation RCP 1.2 2100',
          precipitation2452030: 'Precipitation RCP 2.4 2030',
          precipitation2452040: 'Precipitation RCP 2.4 2040',
          precipitation2452050: 'Precipitation RCP 2.4 2050',
          precipitation2452100: 'Precipitation RCP 2.4 2100',
          precipitation3702030: 'Precipitation RCP 3.7 2030',
          precipitation3702040: 'Precipitation RCP 3.7 2040',
          precipitation3702050: 'Precipitation RCP 3.7 2050',
          precipitation3702100: 'Precipitation RCP 3.7 2100',
          precipitation5852030: 'Precipitation RCP 5.8 2030',
          precipitation5852040: 'Precipitation RCP 5.8 2040',
          precipitation5852050: 'Precipitation RCP 5.8 2050',
          precipitation5852100: 'Precipitation RCP 5.8 2100',
          coldCurrent: 'Current Cold',
          cold1262030: 'Cold RCP 1.2 2030',
          cold1262040: 'Cold RCP 1.2 2040',
          cold1262050: 'Cold RCP 1.2 2050',
          cold1262100: 'Cold RCP 1.2 2100',
          cold2452030: 'Cold RCP 2.4 2030',
          cold2452040: 'Cold RCP 2.4 2040',
          cold2452050: 'Cold RCP 2.4 2050',
          cold2452100: 'Cold RCP 2.4 2100',
          cold3702030: 'Cold RCP 3.7 2030',
          cold3702040: 'Cold RCP 3.7 2040',
          cold3702050: 'Cold RCP 3.7 2050',
          cold3702100: 'Cold RCP 3.7 2100',
          cold5852030: 'Cold RCP 5.8 2030',
          cold5852040: 'Cold RCP 5.8 2040',
          cold5852050: 'Cold RCP 5.8 2050',
          cold5852100: 'Cold RCP 5.8 2100',
        },
      },
    },
    errorLog: {
      title: '{{id}} - Error Log',
      infoSection: {
        processId: 'Process ID',
        item: 'Item',
        actualStart: 'Actual Start',
        actualEnd: 'Actual End',
        status: 'Status',
        duration: 'Duration',
        totalDataSets: 'Total Data Sets',
        failedDataSets: 'Failed Data Sets',
      },
      table: {
        rows: '{{value}} Rows',
        title: 'Errors ({{amount}})',
        error: 'Error',
        entity: 'Entity',
        identifier: 'Identifier',
        attribute: 'Attribute',
        value: 'Value',
        errorMessage: 'Error Message',
      },
      error: {
        CELL_CONTAINS_FORMULA: 'Value contains a formula',
        INVALID_BOOLEAN: 'Value is not in boolean format (true/false)',
        INVALID_DATE: 'Date is invalid',
        INVALID_DECIMAL: 'Decimal is invalid',
        INVALID_ENUM: 'Invalid input value',
        INVALID_HEADER: 'Headers are incorrect for the file',
        INVALID_INTEGER: 'Integer is invalid',
        INVALID_YEAR: 'Year is invalid',
        MISSING_MANDATORY_VALUE: 'Data required for KPI calculations',
        UNNECESSARY_FIELD: 'Data not required',
        TOO_LONG_STRING: 'Text exceeds expected length for field',
        INCONSISTENT_DATA: 'Data mismatch with related entity',
        INCONSISTENT_VALIDITY: 'Business validity period is not consistent',
        FINANCIAL_PRODUCT_CALCULATION_NOT_PERFORMED:
          'Unable to calculate KPI for this asset class. For at least score 5, provide industry sector classification.',
        SHIP_OR_AIRCRAFT_FINANCE_CALCULATION_NOT_PERFORMED:
          'Unable to calculate KPI for this asset class. Provide physical asset valuations and emissions.',
        BUILDING_FINANCE_CALCULATION_NOT_PERFORMED:
          'Unable to calculate KPI for this asset class. For at least score 5, provide property value and building information.',
        MISSING_BUSINESS_IDENTIFIER: 'Primary identifier is not provided',
        INVALID_GEOCOORDINATE: 'Geocoordinate is invalid',
        MISSING_PCAF_ASSET_CLASS: 'Invalid input value',
        LATITUDE_OUT_OF_RANGE: 'Latitude is out of range',
        LATITUDE_WITHOUT_LONGITUDE: 'Longitude is missing',
        LATITUDE_NOT_PRECISE: 'Latitude is not precise',
        LONGITUDE_WITHOUT_LATITUDE: 'Latitude is missing',
        LONGITUDE_OUT_OF_RANGE: 'Longitude is out of range',
        LONGITUDE_NOT_PRECISE: 'Longitude is not precise',
        GEOQUALITY_TOO_LOW: 'Geoquality is too low. Unable to generate physical risk assessment',
      },
    },
  },
};
