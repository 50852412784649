import { UiText } from '@UiCommon/Components';
import { shortSyntaxDuration } from '@Utils/shortSyntaxDuration';

export type Props = {
  seconds: number;
};

export const Duration = ({ seconds }: Props) => {
  const formattedDuration = shortSyntaxDuration(seconds);

  return <UiText>{formattedDuration}</UiText>;
};
