import { useMemo } from 'react';

import { useProcessManagerTranslation } from '@GlobalProviders/i18n/hooks';
import { UiTableColumnDataType } from '@UiCommon/Components/UiAnalyticalTable/types';

import { useCommonCollectedColumns } from '../useCommonCollectedColumns';

export const useAssetValuationColumns = () => {
  const { t } = useProcessManagerTranslation();
  const commonColumns = useCommonCollectedColumns({ hideBusinessValidity: true });

  return useMemo(
    () => [
      {
        label: t('processManager.manualUpload.assetValuation.table.physicalAssetId'),
        accessor: 'physicalAssetId',
        id: 'physicalAssetId',
        minWidth: 120,
        isVisible: true,
        beField: 'PHYSICAL_ASSET_ID',
      },
      {
        label: t('processManager.manualUpload.assetValuation.table.valuationDate'),
        accessor: 'valuationDate',
        id: 'valuationDate',
        minWidth: 104,
        isVisible: true,
        type: UiTableColumnDataType.DATE,
        beField: 'VALUATION_DATE',
      },
      {
        label: t('processManager.manualUpload.assetValuation.table.value'),
        accessor: 'value',
        id: 'value',
        minWidth: 104,
        isVisible: true,
        type: UiTableColumnDataType.NUMBER,
        beField: 'VALUE',
      },
      ...commonColumns,
    ],
    [t, commonColumns],
  );
};
