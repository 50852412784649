import styled from '@emotion/styled';
import { Icon, IconPropTypes } from '@ui5/webcomponents-react';
import { ThemingParameters } from '@ui5/webcomponents-react-base';

import { CommonProps } from '../types';

type Props = CommonProps &
  Pick<IconPropTypes, 'design' | 'name' | 'showTooltip' | 'accessibleName'>;

const StyledIcon = styled(Icon)`
  --sapInformativeElementColor: ${ThemingParameters.sapAccentColor6};
`;

export const UiIcon = (props: Props) => <StyledIcon {...props} />;
