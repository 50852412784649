import { isNil } from 'lodash';
import { format, parseISO } from 'date-fns';

import { DATE_TIME_FORMAT } from '@Utils/Formatters/date';

import { ConditionType, FilterConditionsType } from './SettingsModal/types';
import { UiToken } from '../UiToken';
import { UiTableColumnDataType } from './types';

type FilterPrintersType = { [key in FilterConditionsType]: (value: string) => string };

const FILTER_PRINTERS: FilterPrintersType = {
  [FilterConditionsType.EQUAL]: (value) => `=${value}`,
  [FilterConditionsType.NOT_EQUAL]: (value) => `!=${value}`,
  [FilterConditionsType.STARTS_WITH]: (value) => `${value}*`,
  [FilterConditionsType.NOT_STARTS_WITH]: (value) => `!(${value}*)`,
  [FilterConditionsType.ENDS_WITH]: (value) => `*${value}`,
  [FilterConditionsType.NOT_ENDS_WITH]: (value) => `!(*${value})`,
  [FilterConditionsType.CONTAINS]: (value) => `*${value}*`,
  [FilterConditionsType.NOT_CONTAINS]: (value) => `!(*${value}*)`,
  [FilterConditionsType.GREATER_THAN]: (value) => `>${value}`,
  [FilterConditionsType.GREATER_THAN_OR_EQUAL]: (value) => `>=${value}`,
  [FilterConditionsType.LESS_THAN]: (value) => `<${value}`,
  [FilterConditionsType.LESS_THAN_OR_EQUAL]: (value) => `<=${value}`,
};

export const generateFilterTokens = (conditions: ConditionType[], type?: UiTableColumnDataType) =>
  conditions
    .filter(({ value }) => !isNil(value))
    .map(({ columnCondition, value }) => {
      if (type === UiTableColumnDataType.DATE_TIME) {
        return { columnCondition, value: format(parseISO(value as string), DATE_TIME_FORMAT) };
      }

      return { columnCondition, value };
    })
    .map(({ columnCondition, value }) => FILTER_PRINTERS[columnCondition](value!.toString()))
    .map((filterText, idx) => <UiToken key={idx} text={filterText} readonly />);
