import { ValueState } from '@ui5/webcomponents-react';

import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';
import { generateUUID } from '@Utils/utils';
import { UiInput } from '@UiCommon/Components/UiInput/UiInput';
import { ProjectSplitScope } from '@DataAccess/CoreLib/SplitRatio/splitRatio.types';

import { CreateRuleModal, CreateRuleModalProps } from '../Components/CreateRuleModal';
import { useSplitScopeContext } from '../Context/SplitScopeContext';
import { SplitScopeForm } from '../Components/SplitScopeForm';
import { useSplitScopeForm } from '../Hooks/useSplitScopeForm';

const initialState = () => ({
  id: generateUUID(),
  naceCode: '*',
  secondaryClassificationCode: '*',
  country: '*',
  category: '*',
  splitScope1Emissions: 0.5,
  splitScope2Emissions: 0.5,
  isDefault: false,
});

export const ProjectCreateRule = ({ isOpen, onClose }: CreateRuleModalProps) => {
  const { handleSaveSplitRatios } = useSplitScopeContext();
  const { t } = useGHGTranslation();
  const {
    handleChange,
    setFormData,
    formData,
    setSplitRatioError,
    splitRatioError,
    countryError,
    hasError,
    handleCountryChange,
  } = useSplitScopeForm<ProjectSplitScope>(initialState());

  const onSubmit = () => {
    handleSaveSplitRatios({ projectSplitRatios: [formData] });
    setFormData(initialState());
    onClose?.();
  };

  return (
    <CreateRuleModal error={hasError} isOpen={isOpen} onSubmit={onSubmit} onClose={onClose}>
      <UiInput
        label={t('splitScope.project.naceCode')}
        value={formData.naceCode}
        onChange={(value) => handleChange('naceCode', value)}
      />
      <UiInput
        label={t('splitScope.project.secondaryClassificationCode')}
        value={formData.secondaryClassificationCode}
        onChange={(value) => handleChange('secondaryClassificationCode', value)}
      />
      <UiInput
        label={t('splitScope.project.country')}
        value={formData.country}
        valueState={countryError ? ValueState.Error : ValueState.None}
        valueStateMessage={countryError}
        onChange={handleCountryChange}
      />
      <UiInput
        label={t('splitScope.project.category')}
        value={formData.category}
        onChange={(value) => handleChange('category', value)}
      />
      <SplitScopeForm
        formData={formData}
        onChange={setFormData}
        error={splitRatioError}
        onError={setSplitRatioError}
      />
    </CreateRuleModal>
  );
};
