import { useMemo } from 'react';
import { TextAlign } from '@ui5/webcomponents-react';

import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';
import {
  TableInstance,
  UiAnalyticalTableColumnDefinition,
  UiTableColumnDataType,
} from '@UiCommon/Components/UiAnalyticalTable/types';
import { UiCurrencyFormatter } from '@UiCommon/Components/UiFormatter/UiCurrencyFormatter';
import { UiNumberFormatter } from '@UiCommon/Components/UiFormatter/UiNumberFormatter';
import { UiPercentageFormatter } from '@UiCommon/Components/UiFormatter/UiPercentageFormatter';
import { EmissionDto } from '@DataAccess/GHG/SingleExposure/singleExposure.types';
import { useSettingsStore } from '@GlobalProviders/SettingsProvider';

const getProduct = (tableApi: TableInstance<EmissionDto>) => tableApi.row.original.product;

export const useProductColumns = () => {
  const { t } = useGHGTranslation();
  const { currency } = useSettingsStore();

  return useMemo(() => {
    const output: UiAnalyticalTableColumnDefinition<EmissionDto>[] = [
      {
        label: t('singleExposure.table.product.financialProductId'),
        accessor: 'product.id',
        id: 'product.id',
        minWidth: 120,
        isVisible: true,
        beField: 'PRODUCT_ID',
      },
      {
        label: t('singleExposure.table.product.productType'),
        accessor: 'product.type',
        id: 'product.type',
        minWidth: 104,
        isVisible: true,
        beField: 'PRODUCT_TYPE',
      },
      {
        label: t('singleExposure.table.product.naceCode'),
        accessor: 'product.naceCode',
        id: 'product.naceCode',
        minWidth: 94,
        isVisible: true,
        beField: 'PRODUCT_NACE_CODE',
      },
      {
        label: t('singleExposure.table.product.originationDate'),
        accessor: 'product.originationDate',
        id: 'product.originationDate',
        minWidth: 100,
        isVisible: true,
        type: UiTableColumnDataType.DATE,
        beField: 'PRODUCT_ORIGINATION_DATE',
      },
      {
        label: t('singleExposure.table.product.isin'),
        accessor: 'product.isinCode',
        id: 'product.isinCode',
        minWidth: 94,
        isVisible: true,
        beField: 'PRODUCT_ISIN_CODE',
      },
      {
        label: t('singleExposure.table.product.attributionFactor'),
        accessor: 'product.attributionFactor',
        id: 'product.attributionFactor',
        minWidth: 134,
        isVisible: true,
        beField: 'PRODUCT_ATTRIBUTION_FACTOR',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiPercentageFormatter
            decimalPrecision={12}
            value={getProduct(table).attributionFactor}
          />
        ),
      },
      {
        label: t('singleExposure.table.product.outstandingAmount', { currency }),
        accessor: 'product.outstandingAmount',
        id: 'product.outstandingAmount',
        minWidth: 180,
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'PRODUCT_OUTSTANDING_AMOUNT',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiCurrencyFormatter value={getProduct(table).outstandingAmount} />
        ),
      },
      {
        label: t('singleExposure.table.product.bookValue', { currency }),
        accessor: 'product.bookValue',
        id: 'product.bookValue',
        minWidth: 180,
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'PRODUCT_BOOK_VALUE',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiCurrencyFormatter value={getProduct(table).bookValue} />
        ),
      },
      {
        label: t('singleExposure.table.product.exposure', { currency }),
        accessor: 'product.exposure',
        id: 'product.exposure',
        minWidth: 124,
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'PRODUCT_EXPOSURE',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiCurrencyFormatter value={getProduct(table).exposure} />
        ),
      },
      {
        label: t('singleExposure.table.product.useOfProceeds'),
        accessor: 'product.useOfProceeds',
        id: 'product.useOfProceeds',
        minWidth: 190,
        isVisible: true,
        beField: 'PRODUCT_USE_OF_PROCEEDS',
      },
      {
        label: t('singleExposure.table.product.pcafAssetClass'),
        accessor: 'product.pcafAssetClass',
        id: 'product.pcafAssetClass',
        minWidth: 133,
        isVisible: true,
        beField: 'PRODUCT_PCAF_ASSET_CLASS',
      },
      {
        label: t('singleExposure.table.product.financedScope1Emissions'),
        accessor: 'product.financedEmissionsScope1',
        id: 'product.financedEmissionsScope1',
        minWidth: 270,
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'PRODUCT_FINANCED_EMISSIONS_SCOPE_1',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiNumberFormatter value={getProduct(table).financedEmissionsScope1} />
        ),
      },
      {
        label: t('singleExposure.table.product.financedScope2Emissions'),
        accessor: 'product.financedEmissionsScope2',
        id: 'product.financedEmissionsScope2',
        minWidth: 270,
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'PRODUCT_FINANCED_EMISSIONS_SCOPE_2',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiNumberFormatter value={getProduct(table).financedEmissionsScope2} />
        ),
      },
      {
        label: t('singleExposure.table.product.financedScope3Emissions'),
        accessor: 'product.financedEmissionsScope3',
        id: 'product.financedEmissionsScope3',
        minWidth: 270,
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'PRODUCT_FINANCED_EMISSIONS_SCOPE_3',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiNumberFormatter value={getProduct(table).financedEmissionsScope3} />
        ),
      },
      {
        label: t('singleExposure.table.product.financedAvoidedEmissions'),
        accessor: 'product.financedAvoidedEmissions',
        id: 'product.financedAvoidedEmissions',
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'PRODUCT_FINANCED_AVOIDED_EMISSIONS',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiNumberFormatter value={getProduct(table).financedAvoidedEmissions} />
        ),
      },
      {
        label: t('singleExposure.table.product.dataQualityScoreScope1'),
        accessor: 'product.dataQualityScoreScope1',
        id: 'product.dataQualityScoreScope1',
        minWidth: 140,
        isVisible: true,
        beField: 'PRODUCT_DATA_QUALITY_SCORE_SCOPE_1',
      },
      {
        label: t('singleExposure.table.product.dataQualityScoreScope2'),
        accessor: 'product.dataQualityScoreScope2',
        id: 'product.dataQualityScoreScope2',
        minWidth: 140,
        isVisible: true,
        beField: 'PRODUCT_DATA_QUALITY_SCORE_SCOPE_2',
      },
      {
        label: t('singleExposure.table.product.dataQualityScoreScope3'),
        accessor: 'product.dataQualityScoreScope3',
        id: 'product.dataQualityScoreScope3',
        minWidth: 140,
        isVisible: true,
        beField: 'PRODUCT_DATA_QUALITY_SCORE_SCOPE_3',
      },
      {
        label: t('singleExposure.table.product.dataQualityScoreOptionScope1'),
        accessor: 'product.dataQualityScoreOptionScope1',
        id: 'product.dataQualityScoreOptionScope1',
        minWidth: 210,
        isVisible: true,
        beField: 'PRODUCT_DATA_QUALITY_SCORE_OPTION_SCOPE_1',
      },
      {
        label: t('singleExposure.table.product.dataQualityScoreOptionScope2'),
        accessor: 'product.dataQualityScoreOptionScope2',
        id: 'product.dataQualityScoreOptionScope2',
        minWidth: 210,
        isVisible: true,
        beField: 'PRODUCT_DATA_QUALITY_SCORE_OPTION_SCOPE_2',
      },
      {
        label: t('singleExposure.table.product.dataQualityScoreOptionScope3'),
        accessor: 'product.dataQualityScoreOptionScope3',
        id: 'product.dataQualityScoreOptionScope3',
        minWidth: 210,
        isVisible: true,
        beField: 'PRODUCT_DATA_QUALITY_SCORE_OPTION_SCOPE_3',
      },
      {
        label: t('singleExposure.table.product.emissionFactorScope1'),
        accessor: 'product.emissionFactorScope1',
        id: 'product.emissionFactorScope1',
        minWidth: 210,
        isVisible: true,
        hAlign: TextAlign.Right,
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiNumberFormatter value={getProduct(table).emissionFactorScope1} />
        ),
        beField: 'PRODUCT_EMISSION_FACTOR_SCOPE_1',
      },
      {
        label: t('singleExposure.table.product.emissionFactorScope2'),
        accessor: 'product.emissionFactorScope2',
        id: 'product.emissionFactorScope2',
        minWidth: 210,
        isVisible: true,
        hAlign: TextAlign.Right,
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiNumberFormatter value={getProduct(table).emissionFactorScope2} />
        ),
        beField: 'PRODUCT_EMISSION_FACTOR_SCOPE_2',
      },
      {
        label: t('singleExposure.table.product.emissionFactorScope3'),
        accessor: 'product.emissionFactorScope3',
        id: 'product.emissionFactorScope3',
        minWidth: 210,
        isVisible: true,
        hAlign: TextAlign.Right,
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiNumberFormatter value={getProduct(table).emissionFactorScope3} />
        ),
        beField: 'PRODUCT_EMISSION_FACTOR_SCOPE_3',
      },
      {
        label: t('singleExposure.table.product.emissionFactorLocationReference'),
        accessor: 'product.emissionFactorLocationReference',
        id: 'product.emissionFactorLocationReference',
        isVisible: true,
        beField: 'PRODUCT_EMISSION_FACTOR_LOCATION_REFERENCE',
      },
      {
        label: t('singleExposure.table.product.emissionIntensityScope1'),
        accessor: 'product.emissionIntensityScope1',
        id: 'product.emissionIntensityScope1',
        minWidth: 210,
        isVisible: true,
        hAlign: TextAlign.Right,
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiNumberFormatter value={getProduct(table).emissionIntensityScope1} />
        ),
        beField: 'PRODUCT_EMISSION_INTENSITY_SCOPE_1',
      },
      {
        label: t('singleExposure.table.product.emissionIntensityScope2'),
        accessor: 'product.emissionIntensityScope2',
        id: 'product.emissionIntensityScope2',
        minWidth: 210,
        isVisible: true,
        hAlign: TextAlign.Right,
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiNumberFormatter value={getProduct(table).emissionIntensityScope2} />
        ),
        beField: 'PRODUCT_EMISSION_INTENSITY_SCOPE_2',
      },
      {
        label: t('singleExposure.table.product.emissionIntensityScope3'),
        accessor: 'product.emissionIntensityScope3',
        id: 'product.emissionIntensityScope3',
        minWidth: 210,
        isVisible: true,
        hAlign: TextAlign.Right,
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiNumberFormatter value={getProduct(table).emissionIntensityScope3} />
        ),
        beField: 'PRODUCT_EMISSION_INTENSITY_SCOPE_3',
      },
      {
        label: t('singleExposure.table.product.naceIndustry'),
        accessor: 'product.naceIndustry',
        id: 'product.naceIndustry',
        minWidth: 170,
        isVisible: true,
        beField: 'PRODUCT_NACE_INDUSTRY',
      },
      {
        label: t('singleExposure.table.product.naceSection'),
        accessor: 'product.naceSection',
        id: 'product.naceSection',
        minWidth: 170,
        isVisible: true,
        beField: 'PRODUCT_NACE_SECTION',
      },
      {
        label: t('singleExposure.table.product.emissionFactorSectorDataSource'),
        accessor: 'product.emissionFactorSectorDataSource',
        id: 'product.emissionFactorSectorDataSource',
        isVisible: true,
        beField: 'PRODUCT_EMISSION_FACTOR_SECTOR_DATA_SOURCE',
      },
    ];

    return output;
  }, [t]);
};
