import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import toast from 'react-hot-toast';

import { useMultiAppStore } from '@GlobalProviders/MultiApp/MultiAppProvider';
import { useProcessManagerTranslation } from '@GlobalProviders/i18n/hooks';
import { SmartAnalyticalTableCard } from '@Pages/Shared/SmartComponents/SmartAnalyticalTableCard/SmartAnalyticalTableCard';
import { UiAnalyticalTableColumnDefinition, UiButton } from '@UiCommon/Components';
import { PageHeader } from '@UiCommon/Layout';
import {
  ParametrizationProps,
  ProcessMonitoringDto,
} from '@DataAccess/Platform/ProcessMonitoring/processMonitoring.types';
import { useTriggerCalculationMutation } from '@DataAccess/Platform/ProcessMonitoring/processMonitoring.hooks';
import { processMonitoringKeys } from '@DataAccess/Platform/ProcessMonitoring/processMonitoring.keys';

import { RunCalculationModal } from './ManualUpload/Components/RunCalculationModal';
import { EntityGroup, UploadDialog } from './ManualUpload/Components/UploadDialog/UploadDialog';
import { useProcessManagerTable } from './useProcessManagerTable';

type Props = {
  columns: UiAnalyticalTableColumnDefinition<ProcessMonitoringDto>[];
  entities: EntityGroup[];
  // ONLY TEMP SOLUTION. It will be removed after we will provide functionality for PR Run calculation
  disableCalculationButton?: boolean;
};

export const ProcessManagerPage = ({ columns, entities, disableCalculationButton }: Props) => {
  const { t } = useProcessManagerTranslation();
  const { translatedProductInUse } = useMultiAppStore();

  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [isRunCalculationModalOpen, setIsRunCalculationModalOpen] = useState(false);

  const queryClient = useQueryClient();

  const {
    TABLE_ID,
    smartAnalyticalTableData: {
      data,
      fetchNextPage,
      totalElements,
      sorting,
      filters,
      setFilters,
      setSorting,
      pageParams,
    },
  } = useProcessManagerTable(columns);

  const invalidateProcessMonitoringQuery = () => {
    queryClient.invalidateQueries({
      queryKey: processMonitoringKeys.list(pageParams),
    });
  };

  const { mutate: triggerCalculation } = useTriggerCalculationMutation({
    onSuccess: () => {
      invalidateProcessMonitoringQuery();
    },
    onError: () => toast.error(t('processManager.manualUpload.toast.onErroredComputedData')),
  });

  const handleRunCalculationModalClose = () => {
    setIsRunCalculationModalOpen(false);
  };

  const handleRunCalculationModalConfirm = ({ businessDate, systemDate }: ParametrizationProps) => {
    handleRunCalculationModalClose();
    triggerCalculation({ systemDate, businessDate });
  };

  const handleUploadDialogClose = () => {
    invalidateProcessMonitoringQuery();
    setIsUploadDialogOpen(false);
  };

  return (
    <>
      <PageHeader
        title={t('processManager.title')}
        productName={translatedProductInUse}
        renderCustomActions={() => (
          <>
            <UiButton design='Emphasized' onClick={() => setIsUploadDialogOpen(true)}>
              {t('processManager.manualUpload.uploadFile')}
            </UiButton>
            <UiButton
              disabled={disableCalculationButton}
              onClick={() => setIsRunCalculationModalOpen(true)}
              design='Transparent'
            >
              {t('processManager.manualUpload.triggerCalculation')}
            </UiButton>
          </>
        )}
      />
      {isUploadDialogOpen && (
        <UploadDialog
          entities={entities}
          onClose={handleUploadDialogClose}
          isOpen={isUploadDialogOpen}
        />
      )}
      <RunCalculationModal
        onSubmit={handleRunCalculationModalConfirm}
        isOpen={isRunCalculationModalOpen}
        onClose={handleRunCalculationModalClose}
      />
      <SmartAnalyticalTableCard
        columns={columns}
        data={data}
        title={t('processManager.title')}
        onLoadMore={fetchNextPage}
        totalNumberOfItems={totalElements}
        hasSettingsModal
        tableId={TABLE_ID}
        sorting={sorting}
        filters={filters}
        onSorting={setSorting}
        onFilters={setFilters}
        apiPath='platform.processMonitoring.getProcess'
      />
    </>
  );
};
