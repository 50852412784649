import { useEffect, useState } from 'react';

import { useUiCommonTranslation } from '@GlobalProviders/i18n/hooks';

import { TabConfig } from '../../UiTabSwitcher';
import { ColumnsSettings } from './ColumnsSettings/ColumnsSettings';
import { FilterSettings } from './FilterSettings/FilterSettings';
import { SortSettings } from './SortSettings/SortSettings';

export enum SettingsTab {
  COLUMNS = 'columns',
  SORT = 'sort',
  FILTER = 'filter',
}

const useTabsConfiguration = (): TabConfig[] => {
  const { t } = useUiCommonTranslation();

  return [
    {
      label: t('settingsModal.tabs.columns'),
      id: SettingsTab.COLUMNS,
    },
    {
      label: t('settingsModal.tabs.sort'),
      id: SettingsTab.SORT,
    },
    {
      label: t('settingsModal.tabs.filter'),
      id: SettingsTab.FILTER,
    },
  ];
};

const tabContents = {
  [SettingsTab.COLUMNS]: <ColumnsSettings />,
  [SettingsTab.SORT]: <SortSettings />,
  [SettingsTab.FILTER]: <FilterSettings />,
};

export const useSettingsModalTabs = (selectedTab?: SettingsTab) => {
  const tabsConfig = useTabsConfiguration();
  const firstItemId = tabsConfig[0].id as SettingsTab;
  const [activeTab, setActiveTab] = useState<SettingsTab>(SettingsTab.COLUMNS);
  useEffect(() => {
    selectedTab && setActiveTab(selectedTab);
  }, [selectedTab]);
  const handleTabClick = (id: string) => setActiveTab(id as SettingsTab);

  const resetTabs = () => {
    setActiveTab(selectedTab || firstItemId);
  };

  return {
    tabsConfig,
    handleTabClick,
    tabContents,
    activeTab,
    resetTabs,
  };
};
