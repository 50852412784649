import { MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import { MouseEvent } from 'react';

class CustomMouseSensor extends MouseSensor {
  static readonly activators = [
    {
      eventName: 'onMouseDown' as const,
      handler: ({ nativeEvent: event }: MouseEvent) =>
        shouldHandleEvent(event.target as HTMLElement),
    },
  ];
}

function shouldHandleEvent(element: HTMLElement | null) {
  let currentHtmlElement = element;

  while (currentHtmlElement) {
    if (currentHtmlElement?.dataset?.noDnd) {
      return false;
    }
    currentHtmlElement = currentHtmlElement.parentElement;
  }

  return true;
}

export const useCustomSensors = () => {
  const mouseSensor = useSensor(CustomMouseSensor);

  return useSensors(mouseSensor);
};
