import { useMemo } from 'react';

import { usePRTranslation } from '@GlobalProviders/i18n/hooks';
import { UiAnalyticalTableColumnDefinition } from '@UiCommon/Components';

export const usePhysicalAssetOutputAddressColumns = () => {
  const { t } = usePRTranslation();

  return useMemo<UiAnalyticalTableColumnDefinition<any>[]>(() => {
    return [
      {
        label: t('physicalAsset.outputAddress.countryRegion'),
        accessor: 'outputAddress.countryRegion',
        id: 'outputAddress.countryRegion',
        isVisible: true,
        beField: 'OUTPUT_ADDRESS_COUNTRY_REGION',
      },
      {
        label: t('physicalAsset.outputAddress.streetHouseNr'),
        accessor: 'outputAddress.streetHouseNr',
        id: 'outputAddress.streetHouseNr',
        isVisible: true,
        beField: 'OUTPUT_ADDRESS_STREET_HOUSE_NR',
      },
      {
        label: t('physicalAsset.outputAddress.city'),
        accessor: 'outputAddress.city',
        id: 'outputAddress.city',
        isVisible: true,
        beField: 'OUTPUT_ADDRESS_CITY',
      },
      {
        label: t('physicalAsset.outputAddress.zipCode'),
        accessor: 'outputAddress.zipCode',
        id: 'outputAddress.zipCode',
        isVisible: true,
        beField: 'OUTPUT_ADDRESS_ZIP_CODE',
      },
    ];
  }, [t]);
};
