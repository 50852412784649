import { useNavigate } from 'react-router-dom';

import { IconSet } from '@GlobalProviders/IconSetProvider';
import { useUiCommonTranslation } from '@GlobalProviders/i18n/hooks';

import { UiButton } from '../../../Components/UiButton/UiButton';

// TODO: should be moved to Dashboard domain and used within smart breadcrumbs component
// rule is to use back button within every 3rd level or more:
// https://www.figma.com/file/LsiUr6p9PV0TRjyWgKV6kb?node-id=2194:27121#401887536
export const BackButton = () => {
  const { t } = useUiCommonTranslation();
  const navigate = useNavigate();

  const goBackToParent = () => navigate('..');

  return (
    <UiButton icon={IconSet.ARROW_LEFT} design='Transparent' onClick={goBackToParent}>
      {t('buttons.back')}
    </UiButton>
  );
};
