import { useState } from 'react';

import { SplitScope } from '@DataAccess/CoreLib/SplitRatio/splitRatio.types';

export const useSplitScopeTable = <T extends Pick<SplitScope, 'isDefault'>>(list: T[]) => {
  const [isCreateRuleModalOpen, setIsCreateRuleModalOpen] = useState(false);

  const editableItems = list.filter((item) => !item.isDefault);

  return {
    isCreateRuleModalOpen,
    setIsCreateRuleModalOpen,
    editableItems,
  };
};
