import { ButtonDesign } from '@ui5/webcomponents-react';
import { useState } from 'react';
import styled from '@emotion/styled';

import { IconSet } from '@GlobalProviders/IconSetProvider';
import { UiButton, UiIcon } from '@UiCommon/Components';

import { DeleteConfirmationModal } from './DeleteConfirmationModal';

const NavigationItem = styled(UiButton)`
  --_ui5_button_base_padding: 0.25rem !important;
  --_ui5_button_base_min_width: 1rem !important;
`;

const ActionsWrapper = styled.div`
  display: inline-flex;
  gap: 0.125rem;
`;

type Props = {
  onDelete: () => void;
  onUpClick?: () => void;
  onDownClick?: () => void;
  isUpwardDisabled?: boolean;
  isDownwardDisabled?: boolean;
};

export const UiActions = ({
  onDelete,
  onDownClick,
  onUpClick,
  isDownwardDisabled,
  isUpwardDisabled,
}: Props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleDelete = () => {
    onDelete();
    setIsDialogOpen(false);
  };

  const handleIconClick = () => {
    setIsDialogOpen(true);
  };

  return (
    <>
      <ActionsWrapper>
        <NavigationItem disabled={isUpwardDisabled} design='Transparent' onClick={onUpClick}>
          <UiIcon name={IconSet.NAVIGATION_UP_ARROW} design='Information' />
        </NavigationItem>
        <NavigationItem design='Transparent' onClick={onDownClick} disabled={isDownwardDisabled}>
          <UiIcon name={IconSet.NAVIGATION_DOWN_ARROW} design='Information' />
        </NavigationItem>
        <UiButton
          design={ButtonDesign.Transparent}
          icon={IconSet.DECLINE}
          onClick={handleIconClick}
        />
      </ActionsWrapper>
      <DeleteConfirmationModal
        isOpen={isDialogOpen}
        onConfirm={handleDelete}
        onCancel={() => setIsDialogOpen(false)}
      />
    </>
  );
};
