import styled from '@emotion/styled';
import { useMemo } from 'react';
import { findLast } from 'lodash';

import { SortingListItem } from './SortingListItem/SortingListItem';
import { PlaceholderSortingListItem } from './SortingListItem/PlaceholderSortingListItem';
import { UiList } from '../../../UiList/UiList';
import { useSettingsModalContext } from '../SettingsModalContext';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ListWrapper = styled.div`
  flex-grow: 1;
  overflow: auto;
`;

export const SortSettings = () => {
  const { sortingItems, onSortingItemsChange } = useSettingsModalContext();

  const { firstItemValue, lastItemValue } = useMemo(
    () => ({
      firstItemValue: sortingItems?.[0]?.value,
      lastItemValue: findLast(sortingItems)?.value,
    }),
    [sortingItems],
  );

  return (
    <Wrapper>
      <ListWrapper>
        <UiList
          items={sortingItems}
          renderItemContent={({ moveElement, focusedItemValue, item: { data, value } }) => (
            <SortingListItem
              order={data?.order}
              value={value}
              onNavigate={moveElement}
              focusedItemValue={focusedItemValue}
              isFirstActiveItem={firstItemValue === value}
              isLastActiveItem={lastItemValue === value}
            />
          )}
          onItemsOrderChange={onSortingItemsChange}
          isDragAndDropActive
        >
          <PlaceholderSortingListItem />
        </UiList>
      </ListWrapper>
    </Wrapper>
  );
};
