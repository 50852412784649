import { useMemo } from 'react';

import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';
import { CellProps } from '@UiCommon/Components/UiDraggableTable/UiDraggableTable';
import { BuildingSplitScope } from '@DataAccess/CoreLib/SplitRatio/splitRatio.types';

import { BuildingCell } from './BuildingCell';
import { BuildingSplitScopeCell } from './BuildingSplitScopeCell';
import { useSplitScopeContext } from '../Context/SplitScopeContext';
import { BuildingActions } from './BuildingActions';
import {
  RULE_ORDER_NUMBER_COLUMN_ID,
  SPLIT_SCOPE_1_EMISSIONS_COLUMN_ID,
  SPLIT_SCOPE_2_EMISSIONS_COLUMN_ID,
} from '../Utils/constants';

export const useBuildingCustomizationColumns = () => {
  const { t } = useGHGTranslation();
  const { building } = useSplitScopeContext();
  const { state } = building;

  return useMemo(
    () => [
      {
        id: RULE_ORDER_NUMBER_COLUMN_ID,
        header: 'Rule Order No.',
        cell: (props: CellProps<BuildingSplitScope>) => {
          return <>{props.row.index + 1}</>;
        },
      },
      {
        id: 'realEstateType',
        header: t('splitScope.building.realEstateType'),
        accessorKey: 'realEstateType',
        cell: (props: CellProps<BuildingSplitScope>) => {
          return (
            <BuildingCell
              isEditable={!props.row.original.isDefault}
              value={props.row.original.realEstateType}
              index={props.row.index}
              id='realEstateType'
            />
          );
        },
      },
      {
        id: 'country',
        header: t('splitScope.building.country'),
        accessorKey: 'country',
        cell: (props: CellProps<BuildingSplitScope>) => {
          return (
            <BuildingCell
              isEditable={!props.row.original.isDefault}
              value={props.row.original.country}
              index={props.row.index}
              id='country'
            />
          );
        },
      },
      {
        id: 'buildingCertificateClass',
        header: t('splitScope.building.buildingCertificateClass'),
        accessorKey: 'buildingCertificateClass',
        cell: (props: CellProps<BuildingSplitScope>) => {
          return (
            <BuildingCell
              isEditable={!props.row.original.isDefault}
              value={props.row.original.buildingCertificateClass}
              index={props.row.index}
              id='buildingCertificateClass'
            />
          );
        },
      },
      {
        id: SPLIT_SCOPE_1_EMISSIONS_COLUMN_ID,
        header: t('splitScope.building.splitScope1Emissions'),
        accessorKey: 'splitScope1Emissions',
        cell: (props: CellProps<BuildingSplitScope>) => {
          return (
            <BuildingSplitScopeCell
              isEditable={!props.row.original.isDefault}
              value={props.row.original.splitScope1Emissions}
              index={props.row.index}
              id={SPLIT_SCOPE_1_EMISSIONS_COLUMN_ID}
            />
          );
        },
      },
      {
        id: SPLIT_SCOPE_2_EMISSIONS_COLUMN_ID,
        header: t('splitScope.building.splitScope2Emissions'),
        accessorKey: 'splitScope2Emissions',
        cell: (props: CellProps<BuildingSplitScope>) => {
          return (
            <BuildingSplitScopeCell
              isEditable={!props.row.original.isDefault}
              value={props.row.original.splitScope2Emissions}
              index={props.row.index}
              id={SPLIT_SCOPE_2_EMISSIONS_COLUMN_ID}
            />
          );
        },
      },
      {
        header: '',
        id: 'actions',
        size: 50,
        enableHiding: true,
        cell: (props: CellProps<BuildingSplitScope>) => <BuildingActions index={props.row.index} />,
      },
    ],
    [t, state],
  );
};
