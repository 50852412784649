import { UiListItemType } from '../../../UiList/types';
import { SortingListItemProps } from './useSortingSettingsState';

type UpdateSortingItemProps = {
  sortingListItems: UiListItemType<SortingListItemProps>[];
  valueToUpdate: string;
  updatedItem: Partial<UiListItemType<SortingListItemProps>>;
};

export const updateSortingListItem = ({
  sortingListItems,
  valueToUpdate,
  updatedItem,
}: UpdateSortingItemProps) =>
  sortingListItems.map((item) => {
    if (item.value === valueToUpdate) {
      item.data = updatedItem.data || item.data;
      item.value = updatedItem.value || item.value;
      item.label = updatedItem.value || item.value;
    }

    return item;
  });
