import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';
import { SectionObjectPage } from '@UiCommon/Layout/Components/SectionObjectPage';
import { useMultiAppStore } from '@GlobalProviders/MultiApp/MultiAppProvider';

import { SplitScopeProvider } from './Context/SplitScopeContext';
import {
  SPLIT_SCOPE_BUSINESS_PARTNER_ROUTE,
  SPLIT_SCOPE_PROJECT_ROUTE,
  SPLIT_SCOPE_BUILDING_ROUTE,
} from './Routing/routes';

export const GHGSplitScopePage = () => {
  const { t } = useGHGTranslation();
  const { translatedProductInUse } = useMultiAppStore();

  const tabsConfig = [
    {
      label: t('splitScope.tabs.businessPartner'),
      id: SPLIT_SCOPE_BUSINESS_PARTNER_ROUTE,
    },
    { label: t('splitScope.tabs.project'), id: SPLIT_SCOPE_PROJECT_ROUTE },
    { label: t('splitScope.tabs.building'), id: SPLIT_SCOPE_BUILDING_ROUTE },
  ];

  return (
    <SplitScopeProvider>
      <SectionObjectPage
        productName={translatedProductInUse}
        title={t('splitScope.title')}
        defaultRoute={SPLIT_SCOPE_BUSINESS_PARTNER_ROUTE}
        tabsConfig={tabsConfig}
      />
    </SplitScopeProvider>
  );
};
