import { Bar } from '@ui5/webcomponents-react';

import { CommonProps } from '../../types';

type Props = {
  slot?: string;

  startContent?: CommonProps['children'];
  middleContent?: CommonProps['children'];
  endContent?: CommonProps['children'];
};

export const UiDialogHeader = ({ slot, endContent, startContent, middleContent }: Props) => (
  <Bar
    slot={slot}
    design='Header'
    startContent={<div>{startContent}</div>}
    endContent={<div>{endContent}</div>}
  >
    {middleContent}
  </Bar>
);
