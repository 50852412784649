import { useReducer } from 'react';

import { ViewsDictionary } from './types';

type ViewsState<DataType> = {
  views: ViewsDictionary<DataType>;
  defaultView: string;
  selectedView: string;
};

enum ActionType {
  UPDATE_VIEWS = 'UPDATE_VIEWS',
  SET_DEFAULT_VIEW = 'SET_DEFAULT_VIEW',
  SET_SELECTED_VIEW = 'SET_SELECTED_VIEW',
  SET_STATE = 'SET_STATE',
}

export const updateViews = <DataType>(payload: ViewsDictionary<DataType>) => ({
  type: ActionType.UPDATE_VIEWS as const,
  payload,
});

export const setDefaultView = (payload: string) => ({
  type: ActionType.SET_DEFAULT_VIEW as const,
  payload,
});

export const setSelectedView = (payload: string) => ({
  type: ActionType.SET_SELECTED_VIEW as const,
  payload,
});

export const setState = <DataType>(payload: Partial<ViewsState<DataType>>) => ({
  type: ActionType.SET_STATE as const,
  payload,
});

type ViewsAction<DataType> =
  | ReturnType<typeof setState<DataType>>
  | ReturnType<typeof updateViews<DataType>>
  | ReturnType<typeof setSelectedView>
  | ReturnType<typeof setDefaultView>;

const reducer = <DataType>(state: ViewsState<DataType>, action: ViewsAction<DataType>) => {
  switch (action.type) {
    case ActionType.UPDATE_VIEWS: {
      return { ...state, views: { ...state.views, ...action.payload } };
    }
    case ActionType.SET_DEFAULT_VIEW: {
      state.defaultView = action.payload;

      return state;
    }
    case ActionType.SET_SELECTED_VIEW: {
      state.selectedView = action.payload;

      return state;
    }
    case ActionType.SET_STATE: {
      return {
        defaultView: action.payload.defaultView || state.defaultView,
        selectedView: action.payload.selectedView || state.selectedView,
        views: action.payload.views || state.views,
      };
    }
    default:
      return state;
  }
};

export const useViewsReducer = <DataType>() => {
  return useReducer(reducer<DataType>, {
    views: {},
    defaultView: '',
    selectedView: '',
  });
};
