import { useCollectedBuildingQuery } from '@DataAccess/GHG/GHGFiles/GHGFiles.hooks';
import { useProcessManagerTranslation } from '@GlobalProviders/i18n/hooks';
import { SmartAnalyticalTableCard } from '@Pages/Shared/SmartComponents/SmartAnalyticalTableCard/SmartAnalyticalTableCard';
import { useSmartAnalyticalTableCard } from '@Pages/Shared/SmartComponents/SmartAnalyticalTableCard/useSmartAnalyticalTableCard';
import { SubPageHeader } from '@UiCommon/Layout/Components/Headers/SubPageHeader';
import { useMultiAppStore } from '@GlobalProviders/MultiApp/MultiAppProvider';

import { useBuildingColumns } from './useBuildingColumns';

export const BuildingPage = () => {
  const { t } = useProcessManagerTranslation();
  const { translatedProductInUse } = useMultiAppStore();

  const TABLE_ID = 'collectedBuilding';
  const columns = useBuildingColumns();
  const { sorting, filters, data, fetchNextPage, totalElements, setFilters, setSorting } =
    useSmartAnalyticalTableCard({
      columns,
      queryHook: useCollectedBuildingQuery,
    });

  return (
    <>
      <SubPageHeader
        productName={translatedProductInUse}
        title={t('processManager.manualUpload.building.title')}
        subtitle={t('processManager.manualUpload.building.subtitle')}
      />
      <SmartAnalyticalTableCard
        columns={columns}
        data={data}
        title={t('processManager.manualUpload.building.tableTitle', { amount: totalElements })}
        onLoadMore={fetchNextPage}
        totalNumberOfItems={totalElements}
        tableId={TABLE_ID}
        hasSettingsModal
        hasExportCSV
        sorting={sorting}
        filters={filters}
        onSorting={setSorting}
        onFilters={setFilters}
        apiPath='ghg.files.getCollectedBuilding'
      />
    </>
  );
};
