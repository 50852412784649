import { css } from '@emotion/react';
import { ThemingParameters } from '@ui5/webcomponents-react-base';

export enum FontWeight {
  LIGHT = 'LIGHT',
  NORMAL = 'NORMAL',
  BOLD = 'BOLD',
  HEADER = 'HEADER',
}

const caption1Styles = css`
  font-size: ${ThemingParameters.sapFontSmallSize};
  line-height: 1rem;
  color: ${ThemingParameters.sapTextColor};
`;

const caption2Styles = css`
  font-size: ${ThemingParameters.sapFontSize};
  line-height: 1rem;
  color: ${ThemingParameters.sapTextColor};
`;

const caption3Styles = css`
  font-size: ${ThemingParameters.sapFontLargeSize};
  line-height: 1.125rem;
  color: ${ThemingParameters.sapTextColor};
`;

const largeCaptionStyles = css`
  font-size: ${ThemingParameters.sapFontHeader3Size};
  line-height: 1.75rem;
  color: ${ThemingParameters.sapTextColor};
`;

export const fontWeights = {
  [FontWeight.BOLD]: ThemingParameters.sapFontBoldFamily,
  [FontWeight.NORMAL]: ThemingParameters.sapFontFamily,
  [FontWeight.LIGHT]: ThemingParameters.sapFontLightFamily,
  [FontWeight.HEADER]: ThemingParameters.sapFontHeaderFamily,
};

export const typography = {
  caption1: caption1Styles,
  caption2: caption2Styles,
  caption3: caption3Styles,
  largeCaption: largeCaptionStyles,
};

export type TextVariant = keyof typeof typography;
