import styled from '@emotion/styled';
import { t } from 'i18next';

import { IconSet } from '@GlobalProviders/IconSetProvider';
import { UiIcon } from '@UiCommon/Components';

const Wrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const DefaultIndicator = () => {
  return (
    <Wrapper>
      {t('splitScope.default')}
      <UiIcon
        showTooltip
        design='Information'
        accessibleName={t('splitScope.defaultTooltip')}
        name={IconSet.INFORMATION}
      />
    </Wrapper>
  );
};
