import { useState, useEffect, ReactNode } from 'react';
import { ColumnDef, OnChangeFn, VisibilityState } from '@tanstack/react-table';

import {
  UiDraggableTable,
  UniqueIdentifiedType,
} from '@UiCommon/Components/UiDraggableTable/UiDraggableTable';
import { UiCard } from '@UiCommon/Components';
import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';

import { SplitScopeTableActions } from './SplitScopeTableActions';
import { DefaultRow } from './DefaultRow';
import { useSplitScopeContext } from '../Context/SplitScopeContext';

export type SplitScopeTableProps<T> = {
  columns: ColumnDef<T>[];
  data: T[];
  onSaveClick: () => void;
  onAddClick: () => void;
  onDragEnd: (newList: T[]) => void;
  children?: ReactNode;
  totalElements?: number;
};

export const SplitScopeTable = <T extends UniqueIdentifiedType>({
  columns: columnsValue,
  data = [],
  onSaveClick,
  onAddClick,
  onDragEnd,
  children,
  totalElements,
}: SplitScopeTableProps<T>) => {
  const { t } = useGHGTranslation();
  const [columns, setColumns] = useState<any[]>(columnsValue);
  const { isEditMode, setIsEditMode } = useSplitScopeContext();
  const [columnVisibility, setColumnVisibility] = useState({
    actions: false,
  });

  useEffect(() => {
    columnsValue && setColumns(columnsValue);
  }, [columnsValue]);

  useEffect(() => {
    setColumnVisibility({ actions: isEditMode });
  }, [isEditMode]);

  useEffect(() => {
    return () => {
      setIsEditMode(false);
    };
  }, []);

  return (
    <div>
      <UiCard>
        <UiDraggableTable
          ActionsComponent={
            <SplitScopeTableActions
              onAddClick={onAddClick}
              isEditableItem={data.length > 0}
              onSaveClick={onSaveClick}
            />
          }
          onColumnVisibilityChange={setColumnVisibility as OnChangeFn<VisibilityState>}
          columnVisibility={columnVisibility}
          columns={columns}
          isDragAndDropActive={isEditMode}
          data={data}
          title={t('splitScope.rules', { amount: totalElements })}
          onDragEnd={onDragEnd}
          renderAdditionalContent={(table) => <DefaultRow table={table} />}
        >
          {children}
        </UiDraggableTable>
      </UiCard>
    </div>
  );
};
