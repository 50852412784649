import styled from '@emotion/styled';

import { MoveDirection } from '@Types/types';
import { IconSet } from '@GlobalProviders/IconSetProvider';

import { CommonProps } from '../../types';
import { UiButton } from '../UiButton/UiButton';
import { UiIcon } from '../UiIcon';

const Wrapper = styled.div`
  display: inline-flex;
  gap: 0.125rem;
  padding: 0 2rem;
`;

const NavigationItem = styled(UiButton)`
  --_ui5_button_base_padding: 0.25rem !important;
  --_ui5_button_base_min_width: 1rem !important;
`;

type Props = CommonProps & {
  onNavigate?: (direction: MoveDirection) => void;
  hideTop?: boolean;
  hideBottom?: boolean;
  isUpwardDisabled?: boolean;
  isDownwardDisabled?: boolean;
};

export const UiListNavigationItem = ({
  className,
  onNavigate,
  hideTop,
  hideBottom,
  isUpwardDisabled,
  isDownwardDisabled,
}: Props) => (
  <Wrapper className={className} data-no-dnd='true'>
    {!hideTop && (
      <NavigationItem
        design='Transparent'
        onClick={() => onNavigate?.(MoveDirection.TOP)}
        disabled={isUpwardDisabled}
      >
        <UiIcon name={IconSet.COLLAPSE_GROUP} design='Information' />
      </NavigationItem>
    )}
    <NavigationItem
      design='Transparent'
      onClick={() => onNavigate?.(MoveDirection.UP)}
      disabled={isUpwardDisabled}
    >
      <UiIcon name={IconSet.NAVIGATION_UP_ARROW} design='Information' />
    </NavigationItem>
    <NavigationItem
      design='Transparent'
      onClick={() => onNavigate?.(MoveDirection.DOWN)}
      disabled={isDownwardDisabled}
    >
      <UiIcon name={IconSet.NAVIGATION_DOWN_ARROW} design='Information' />
    </NavigationItem>
    {!hideBottom && (
      <NavigationItem
        design='Transparent'
        onClick={() => onNavigate?.(MoveDirection.BOTTOM)}
        disabled={isDownwardDisabled}
      >
        <UiIcon name={IconSet.EXPAND_GROUP} design='Information' />
      </NavigationItem>
    )}
  </Wrapper>
);
