import { RouteObject } from 'react-router-dom';

import { EmissionFactorFloorAreaPage } from '../EmissionFactorFloorAreaPage';
import { EMISSION_FACTOR_FLOOR_AREA_ROUTE } from './routes';

export const EmissionFactorFloorAreaRouting: RouteObject = {
  path: EMISSION_FACTOR_FLOOR_AREA_ROUTE,
  children: [
    {
      index: true,
      element: <EmissionFactorFloorAreaPage />,
    },
  ],
};
