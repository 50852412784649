import { RouteObject } from 'react-router-dom';

import { OVERVIEW_ROUTE } from './routes';
import { OverviewGHGRouting } from '../GHG/Routing/OverviewGHGRouting';
import { OverviewPRRouting } from '../PR/OverviewPRRouting';

export const OverviewRouting: RouteObject = {
  path: OVERVIEW_ROUTE,
  children: [...OverviewGHGRouting, ...OverviewPRRouting],
};
