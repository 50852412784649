import { RouteObject } from 'react-router-dom';

import { IndustryClassificationCodePage } from '../IndustryClassificationCodePage';
import { INDUSTRY_CLASSIFICATION_CODE_ROUTE } from './routes';

export const IndustryClassificationCodeRouting: RouteObject = {
  path: INDUSTRY_CLASSIFICATION_CODE_ROUTE,
  children: [
    {
      index: true,
      element: <IndustryClassificationCodePage />,
    },
  ],
};
