import { RouteObject } from 'react-router-dom';

import { ProjectAddressCoordinatesPage } from '../ProjectAddressCoordinatesPage';
import { PROJECT_ADDRESS_COORDINATES_ROUTE } from './routes';

export const ProjectAddressCoordinatesRouting: RouteObject = {
  path: PROJECT_ADDRESS_COORDINATES_ROUTE,
  children: [
    {
      index: true,
      element: <ProjectAddressCoordinatesPage />,
    },
  ],
};
