import { InputType } from '@ui5/webcomponents-react';

import { UiTableColumnDataType } from '../../../types';

const mapper = {
  [UiTableColumnDataType.NUMBER]: InputType.Number,
  [UiTableColumnDataType.TEXT]: InputType.Text,
};

export const mapColumnTypeToInputType = (columnType: UiTableColumnDataType) => {
  if (columnType !== UiTableColumnDataType.TEXT && columnType !== UiTableColumnDataType.NUMBER)
    return;

  return mapper[columnType];
};
