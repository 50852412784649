import { createContext, useContext, useMemo } from 'react';
import toast from 'react-hot-toast';
import styled from '@emotion/styled';

import { useActivatedProductsQuery } from '@DataAccess/Platform/Products/products.hooks';
import { EsgProduct } from '@DataAccess/Platform/Products/products.types';
import { UiBusyIndicator } from '@UiCommon/Components';
import { CommonProps } from '@UiCommon/types';
import { useSharedTranslation } from '@GlobalProviders/i18n/hooks';

import {
  getProductInUseViaURL,
  getProductInUseViaSessionStorage,
  setProductInUseInSessionStorage,
} from './utils';

const CenteredContentOnPage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;

type MultiAppStore = {
  productInUse: EsgProduct;
  activatedProducts: EsgProduct[];
  translatedProductInUse: string;
};

const MultiAppContext = createContext<MultiAppStore | undefined>(undefined);

export const MultiAppProvider = ({ children }: CommonProps) => {
  const { t } = useSharedTranslation();
  const { data: activatedProducts, isLoading, isError } = useActivatedProductsQuery();

  const contextValue: MultiAppStore = useMemo(() => {
    const defaultProduct = activatedProducts?.[0];

    const productInUse =
      getProductInUseViaURL(activatedProducts) ??
      getProductInUseViaSessionStorage(activatedProducts) ??
      defaultProduct;

    const output: MultiAppStore = {
      activatedProducts: activatedProducts ?? [],
      productInUse: productInUse ?? EsgProduct.GHG,
      translatedProductInUse: t(`types.appProducts.${productInUse ?? EsgProduct.GHG}`),
    };

    setProductInUseInSessionStorage(output.productInUse);

    return output;
  }, [activatedProducts]);

  if (isLoading)
    return (
      <CenteredContentOnPage>
        <UiBusyIndicator isActive />
      </CenteredContentOnPage>
    );

  if (isError) {
    toast.error(t('general.errorFetchingActivatedProducts'));

    return null;
  }

  return <MultiAppContext.Provider value={contextValue}>{children}</MultiAppContext.Provider>;
};

export const useMultiAppStore = () => {
  const value = useContext(MultiAppContext);

  if (!value) {
    throw new Error('useMultiAppStore: no value provided');
  }

  return value;
};
