import { Option } from '@ui5/webcomponents-react';

import { CommonProps } from '../../types';

type Props = CommonProps & {
  value?: string;
  isDisabled?: boolean;
  isSelected?: boolean;
};

export const UiSelectOption = ({ value, children, className, isDisabled, isSelected }: Props) => (
  <Option
    value={value}
    data-value={value}
    className={className}
    disabled={isDisabled}
    selected={isSelected}
  >
    {children}
  </Option>
);
