import styled from '@emotion/styled';
import { debounce } from 'lodash';
import { useState } from 'react';

import { useDataHierarchySearchQuery } from '@DataAccess/Core/DataHierarchy/dataHierarchy.hooks';
import { DataHierarchySearchItemDto } from '@DataAccess/Core/DataHierarchy/dataHierarchy.types';
import { IconSet } from '@GlobalProviders/IconSetProvider';
import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';
import {
  CommonVersionData,
  UiDateRangePicker,
  UiIcon,
  UiInput,
  UiText,
} from '@UiCommon/Components';
import { UiDateFormatter } from '@UiCommon/Components/UiFormatter/UiDateFormatter';
import { ElementSize } from '@UiCommon/types';
import { FontWeight } from '@UiCommon/typography';

const OptionsWrapper = styled.div`
  display: grid;
  grid-template-areas:
    'createdDate'
    'id';
  grid-gap: 0.25rem;
`;

const Id = styled(UiText)`
  grid-area: 'id';
`;

const CreatedDate = styled(UiText)`
  grid-area: 'createdDate';
`;

type DataToRender = CommonVersionData &
  Pick<DataHierarchySearchItemDto, 'createdDate' | 'id' | 'isLatest'>;

export const useDhVersioningMenu = () => {
  const { t } = useGHGTranslation();

  const [searchId, setSearchId] = useState<string | undefined>();
  const [fromDate, setFromDate] = useState<string | undefined>();
  const [toDate, setToDate] = useState<string | undefined>();

  const { dhVersions, isFetching, isFetched, isLoading } = useDataHierarchySearchQuery({
    dataHierarchyId: searchId,
    rangeFrom: fromDate,
    rangeTo: toDate,
    size: 20,
  });

  const handleInputHierarchyIdSearch = (newId?: string) => {
    setSearchId(newId);
  };

  const handleDateRangeChange = (dates: string[]) => {
    const [from, to] = dates;
    setFromDate(from);
    setToDate(to);
  };

  const mappedVersionsToUi = dhVersions.map<DataToRender>(({ id, createdDate, isLatest }) => ({
    id,
    createdDate,
    isLatest,
  }));

  return {
    dhVersions: mappedVersionsToUi,
    isFetched,
    isLoading: isLoading || isFetching,
    Options: ({ createdDate, id }: DataToRender) => (
      <OptionsWrapper>
        <CreatedDate weight={FontWeight.BOLD}>
          <UiDateFormatter value={createdDate} />
        </CreatedDate>
        <Id>{t('hierarchyManager.versioningMenu.id', { id })}</Id>
      </OptionsWrapper>
    ),
    Filters: () => (
      <>
        <UiInput
          onChange={debounce(handleInputHierarchyIdSearch, 500)}
          size={ElementSize.NORMAL}
          placeholder={t('hierarchyManager.versioningMenu.searchPlaceholder')}
          endContent={<UiIcon name={IconSet.SEARCH} />}
        />
        <UiDateRangePicker
          label={t('hierarchyManager.versioningMenu.datePicker.label')}
          size={ElementSize.NORMAL}
          placeholder={t('hierarchyManager.versioningMenu.datePicker.placeholder')}
          onChange={handleDateRangeChange}
        />
      </>
    ),
  };
};
