import { Ui5CustomEvent } from '@ui5/webcomponents-react';
import { SelectedVariant } from '@ui5/webcomponents-react/wrappers/internal/VariantManagementContext';
import { VariantItemPropTypes } from '@ui5/webcomponents-react/dist/components/VariantManagement/VariantItem';
import { useCallback } from 'react';

interface UpdatedVariant extends SelectedVariant {
  prevVariant?: VariantItemPropTypes;
}
type ManagementCallbacks = {
  onSelect?: (name: string) => void;
  onSaveManageViews?: ({
    variants,
    deletedVariants,
    updatedVariants,
    prevVariants,
  }: SaveManageViewsProps) => void;
  onSaveAs?: ({ name, isDefault }: { name: string; isDefault?: boolean }) => void;
  onSave?: (name: string) => void;
};

export type SaveManageViewsProps = {
  deletedVariants: VariantItemPropTypes[];
  prevVariants: VariantItemPropTypes[];
  updatedVariants: UpdatedVariant[];
  variants: SelectedVariant[];
};

export const useUiVariantManagementCallbacks = ({
  onSelect,
  onSaveManageViews,
  onSaveAs,
  onSave,
}: ManagementCallbacks) => {
  const onSelectHandler = useCallback(
    (
      event: Ui5CustomEvent<
        HTMLElement,
        {
          selectedVariant: SelectedVariant;
          selectedItems: unknown[];
          previouslySelectedItems: unknown[];
        }
      >,
    ) => {
      if (!onSelect) return;

      const {
        detail: {
          selectedVariant: { children },
        },
      } = event;

      onSelect?.(children);
    },
    [onSelect],
  );

  const onSaveHandler = useCallback(
    (event: CustomEvent<SelectedVariant>) => {
      if (!onSave) return;
      const {
        detail: { children },
      } = event;
      onSave?.(children);
    },
    [onSave],
  );

  const onSaveAsHandler = useCallback(
    (event: CustomEvent<SelectedVariant>) => {
      if (!onSaveAs) return;
      const {
        detail: { children, isDefault },
      } = event;
      let defaultValue = isDefault;
      // Ui5 in specific case is returning "false" instead of false 🤦‍♂️
      if (typeof isDefault === 'string') {
        defaultValue = isDefault === 'true';
      }
      onSaveAs?.({ name: children, isDefault: defaultValue });
    },
    [onSaveAs],
  );

  const onSaveManageViewsHandler = useCallback(
    (event: CustomEvent<SaveManageViewsProps>) => {
      if (!onSaveManageViews) return;
      const { detail } = event;
      onSaveManageViews?.(detail);
    },
    [onSaveManageViews],
  );

  return { onSelectHandler, onSaveHandler, onSaveAsHandler, onSaveManageViewsHandler };
};
