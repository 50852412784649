import { useCallback, useState } from 'react';

import { UiAnalyticalTableColumnDefinition } from '@UiCommon/Components';
import { UiColumnFiltersType } from '@UiCommon/Components/UiAnalyticalTable/types';
import { UiListItemType } from '@UiCommon/Components/UiList/types';
import { SortingListItemProps } from '@UiCommon/Components/UiAnalyticalTable/SettingsModal/SortSettings/useSortingSettingsState';
import { useSharedTranslation } from '@GlobalProviders/i18n/hooks';

import { handleCsvExport } from '../Workers/handleCsvExport';

type UseExportCsvType<T = Record<string, unknown>> = {
  tableId: string;
  apiPath?: string;
  pathParameter?: string;
  totalElements?: number;
  columns: UiAnalyticalTableColumnDefinition<T>[];
  filters?: UiColumnFiltersType[];
  sorting?: UiListItemType<SortingListItemProps>[];
};

export const useExportCsv = <T>({
  tableId,
  apiPath = '',
  columns,
  filters,
  sorting,
  totalElements,
  pathParameter,
}: UseExportCsvType<T>) => {
  const { t } = useSharedTranslation();

  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isProgressModalOpen, setIsProgressModalOpen] = useState(false);
  const [progressValue, setProgressValue] = useState(0);

  const onSubmitExport = useCallback(
    (fileName: string) => {
      const onError = () => {
        setIsExportModalOpen(false);
        setIsProgressModalOpen(false);
      };

      const onInit = () => {
        setProgressValue(0);
        setIsExportModalOpen(false);
        setIsProgressModalOpen(true);
      };

      const onProgress = (progress: number) => setProgressValue(progress);

      const onSuccess = () => setIsProgressModalOpen(false);

      return handleCsvExport({
        tableId,
        apiPath,
        fileName,
        totalElements,
        columns,
        filters,
        sorting,
        t,
        onError,
        onInit,
        onProgress,
        onSuccess,
        pathParameter,
      });
    },
    [
      apiPath,
      totalElements,
      columns,
      filters,
      t,
      setIsExportModalOpen,
      setIsProgressModalOpen,
      setProgressValue,
    ],
  );

  const onExportClick = () => setIsExportModalOpen(true);
  const onExportModalCancel = () => setIsExportModalOpen(false);
  const onProgressModalCancel = () => {
    setIsProgressModalOpen(false);
    window.location.reload();
  };
  const onExportModalClose = () => setIsExportModalOpen(false);

  return {
    isExportModalOpen,
    isProgressModalOpen,
    progressValue,
    onSubmitExport,
    onExportClick,
    onExportModalCancel,
    onProgressModalCancel,
    onExportModalClose,
  };
};
