import { useSplitScopeContext } from '../Context/SplitScopeContext';
import { SplitScopeActions } from '../Components/SplitScopeActions';

type Props = {
  index: number;
};

export const BuildingActions = ({ index }: Props) => {
  const { building } = useSplitScopeContext();

  return <SplitScopeActions categoryState={building} index={index} />;
};
