import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';
import { IconSet } from '@GlobalProviders/IconSetProvider';
import { KpiRouting } from '@GlobalProviders/MultiApp/KpiRouting';
import { NavigationExpandableItem, NavigationItem } from '@UiCommon/Layout';
import { HIERARCHY_MANAGER_ROUTE, PROCESS_MANAGER_ROUTE } from '@Pages/DataManagement/dmRoutes';
import { DATA_MANAGEMENT_ROUTE } from '@Pages/DataManagement/Routing/routes';
import { OVERVIEW_ROUTE } from '@Pages/Overview/Routing/routes';
import { CUSTOMIZATION_ROUTE } from '@Pages/Customization/Routing/routes';
import { SENSITIVITY_FLAGS_ROUTE } from '@Pages/Customization/PR/SensitivityFlags/Routing/routes';
import { INSIGHTS_ROUTE } from '@Pages/Insights/Routing/routes';
import { INSIGHTS_PHYSICAL_ASSET_ROUTE } from '@Pages/Insights/PR/PhysicalAsset/Routing/routes';

export const PRSidebarNavigation = () => {
  const { t } = useGHGTranslation(); // TODO: to be changed once we have agreed on a convention (probably shared?)

  return (
    <>
      <NavigationItem
        label={t('navigationPanel.links.overview')}
        pathTo={`${OVERVIEW_ROUTE}/${KpiRouting.PHYSICAL_RISK}`}
        iconName={IconSet.HOME}
      />
      <NavigationExpandableItem
        label={t('navigationPanel.links.insights')}
        iconName={IconSet.MANAGER_INSIGHT}
        subItemsConfig={[
          {
            label: t('navigationPanel.links.physicalAsset'),
            pathTo: `${INSIGHTS_ROUTE}/${KpiRouting.PHYSICAL_RISK}/${INSIGHTS_PHYSICAL_ASSET_ROUTE}`,
          },
        ]}
      />
      <NavigationExpandableItem
        label={t('navigationPanel.links.dataManagement')}
        iconName={IconSet.COMPETITOR}
        subItemsConfig={[
          {
            label: t('navigationPanel.links.hierarchyManager'),
            pathTo: `${DATA_MANAGEMENT_ROUTE}/${KpiRouting.PHYSICAL_RISK}/${HIERARCHY_MANAGER_ROUTE}`,
          },
          {
            label: t('navigationPanel.links.processManager'),
            pathTo: `${DATA_MANAGEMENT_ROUTE}/${KpiRouting.PHYSICAL_RISK}/${PROCESS_MANAGER_ROUTE}`,
          },
        ]}
      />
      <NavigationExpandableItem
        label={t('navigationPanel.links.customization')}
        iconName={IconSet.SIMULATE}
        subItemsConfig={[
          {
            label: t('navigationPanel.links.sensitivityFlags'),
            pathTo: `${CUSTOMIZATION_ROUTE}/${KpiRouting.PHYSICAL_RISK}/${SENSITIVITY_FLAGS_ROUTE}`,
          },
        ]}
      />
    </>
  );
};
