import { InitOptions, use } from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationGHG from './locales/en/translationGHG';
import translationPR from './locales/en/translationPR';
import shared from './locales/en/shared';
import processManager from './locales/en/processManager';
import uiCommon from './locales/en/uiCommon';
import { i18nNameSpaces } from './types';

export const defaultNS = i18nNameSpaces.GHG;

export const resources = {
  en: {
    translationGHG,
    translationPR,
    shared,
    processManager,
    uiCommon,
  },
} as const;

export const i18nInit = <T>(overrideConfig?: InitOptions<T>) =>
  use(initReactI18next).init({
    ns: Object.values(i18nNameSpaces),
    defaultNS,
    fallbackLng: 'en',
    debug: true,
    react: {
      useSuspense: true,
    },
    resources,
    ...overrideConfig,
  });
