import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import api from '@DataAccess/api';

import { splitRatioKeys } from './splitRatio.keys';
import {
  BpSplitScope,
  BpSplitScopeDto,
  BuildingSplitScope,
  BuildingSplitScopeDto,
  ProjectSplitScope,
  ProjectSplitScopeDto,
  SplitRatio,
} from './splitRatio.types';

export const useSplitRatioQuery = () =>
  useQuery({
    queryKey: splitRatioKeys.splitRatio.all,
    queryFn: () => api.coreLib.splitRatio.getSplitRatio(),
    select: (data) =>
      ({
        buildingSplitRatios: data.buildingSplitRatiosDto.map(
          (item: BuildingSplitScopeDto) =>
            ({
              id: item.id,
              realEstateType: item.realEstateType,
              country: item.country,
              buildingCertificateClass: item.buildingCertificateClass,
              splitScope1Emissions: item.splitRatio,
              splitScope2Emissions: 1 - item.splitRatio,
              isDefault: item.isDefault,
            } as BuildingSplitScope),
        ),
        businessPartnerSplitRatios: data.businessPartnerSplitRatiosDto.map(
          (item: BpSplitScopeDto) =>
            ({
              id: item.id,
              naceCode: item.naceCode,
              secondaryClassificationCode: item.secondaryClassificationCode,
              country: item.country,
              category: item.category,
              splitScope1Emissions: item.splitRatio,
              splitScope2Emissions: 1 - item.splitRatio,
              isDefault: item.isDefault,
            } as BpSplitScope),
        ),
        projectSplitRatios: data.projectSplitRatiosDto.map(
          (item: ProjectSplitScopeDto) =>
            ({
              id: item.id,
              naceCode: item.naceCode,
              secondaryClassificationCode: item.secondaryClassificationCode,
              country: item.country,
              category: item.category,
              splitScope1Emissions: item.splitRatio,
              splitScope2Emissions: 1 - item.splitRatio,
              isDefault: item.isDefault,
            } as ProjectSplitScope),
        ),
      } as SplitRatio),
  });

export const useSplitRatioMutation = (onError?: (error: Error) => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (rules: SplitRatio) =>
      api.coreLib.splitRatio.updateSplitRatio({
        businessPartnerSplitRatiosDto: normalizeBpOrProjectSplitScope(
          rules.businessPartnerSplitRatios,
        ),
        buildingSplitRatiosDto: normalizeBuildingSplitScope(rules.buildingSplitRatios),
        projectSplitRatiosDto: normalizeBpOrProjectSplitScope(rules.projectSplitRatios),
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: splitRatioKeys.splitRatio.all,
      });
    },
    onError: (error) => {
      onError?.(error);
    },
  });
};

// Unified it because sonar cloud marked two of them as issue
const normalizeBpOrProjectSplitScope = (rules: BpSplitScope[]) =>
  rules.map((rule) => ({
    naceCode: rule.naceCode || '*',
    secondaryClassificationCode: rule.secondaryClassificationCode || '*',
    country: rule.country || '*',
    category: rule.category || '*',
    isDefault: rule.isDefault,
    id: rule.id,
    splitRatio: rule.splitScope1Emissions,
  }));

const normalizeBuildingSplitScope = (rules: BuildingSplitScope[]) =>
  rules.map((rule) => ({
    realEstateType: rule.realEstateType || '*',
    country: rule.country || '*',
    buildingCertificateClass: rule.buildingCertificateClass || '*',
    isDefault: rule.isDefault,
    id: rule.id,
    splitRatio: rule.splitScope1Emissions,
  }));
