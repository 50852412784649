import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { ThemingParameters } from '@ui5/webcomponents-react-base';

import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';
import { UiButton, UiList, UiText } from '@UiCommon/Components';
import { UiListItemType } from '@UiCommon/Components/UiList/types';
import { FontWeight } from '@UiCommon/typography';
import { IconSet } from '@GlobalProviders/IconSetProvider';

import { HierarchyItemList } from './HierarchyItemList';
import { AllowedScoring, DataHierarchyData } from './types';

const uiListClass = css`
  width: 13.75rem;
`;

type SelectableClassProps = {
  isEditMode?: boolean;
};

const createSelectableItemStyles = ({ isEditMode }: SelectableClassProps) => css`
  margin: 0 0 0.5rem;
  padding: 0.125rem;
  border-radius: 0.5rem;

  &:has(> div.disabled) {
    background-color: ${ThemingParameters.sapContent_DisabledOpacity};

    &:hover {
      background-color: ${isEditMode
        ? ThemingParameters.sapList_Hover_Background
        : ThemingParameters.sapContent_DisabledOpacity};
    }
  }

  &:hover {
    cursor: ${isEditMode ? 'grab' : 'default'};
    background-color: ${isEditMode
      ? ThemingParameters.sapList_Hover_Background
      : ThemingParameters.sapList_Background};
  }
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

const AddNewSourceWrapper = styled.div`
  margin-bottom: 8px;
`;

const AddNewButtonClassName = css`
  max-width: fit-content;
`;

export type HierarchyDraggableColumnProps = {
  resourceId: string;
  subcategoryTitle: string;
  scoreNumbering: AllowedScoring[];
  items: UiListItemType<DataHierarchyData>[];
  onOrderChange: (items: UiListItemType<DataHierarchyData>[], resourceId?: string) => void;
  isEditMode?: boolean;
  onEditSource: (id: string) => void;
  onAddSource: (resourceId: string) => void;
};

export const HierarchyDraggableColumn = ({
  items,
  onOrderChange,
  scoreNumbering,
  subcategoryTitle,
  isEditMode,
  resourceId,
  onEditSource,
  onAddSource,
}: HierarchyDraggableColumnProps) => {
  const { t } = useGHGTranslation();

  return (
    <ListWrapper>
      <ListHeader>
        <UiText weight={FontWeight.HEADER}>{subcategoryTitle}</UiText>
        <UiText color={ThemingParameters.sapObjectHeader_Subtitle_TextColor}>
          {t('hierarchyManager.score')} {scoreNumbering.join(', ')}
        </UiText>
      </ListHeader>
      {isEditMode && (
        <AddNewSourceWrapper>
          <UiButton
            onClick={() => onAddSource(resourceId)}
            className={AddNewButtonClassName}
            design='Transparent'
            icon={IconSet.ADD}
          >
            {t('hierarchyManager.addNewSource')}
          </UiButton>
        </AddNewSourceWrapper>
      )}
      <UiList
        resourceId={resourceId}
        className={uiListClass}
        selectableItemClassName={createSelectableItemStyles({ isEditMode })}
        items={items}
        isDragAndDropActive={isEditMode}
        onItemsOrderChange={onOrderChange}
        renderItemContent={({ item }) => (
          <HierarchyItemList
            className={!item.data?.isEnabled ? 'disabled' : undefined}
            item={item}
            onEditSource={onEditSource}
            isEditing={isEditMode}
          />
        )}
      />
    </ListWrapper>
  );
};
