import { useMemo } from 'react';

import { EmissionDto } from '@DataAccess/GHG/SingleExposure/singleExposure.types';
import { KpiRouting } from '@GlobalProviders/MultiApp/KpiRouting';
import { HIERARCHY_MANAGER_ROUTE } from '@Pages/DataManagement/dmRoutes';
import { UiAnalyticalTableColumnDefinition, UiLink } from '@UiCommon/Components';
import { TableInstance, UiTableColumnDataType } from '@UiCommon/Components/UiAnalyticalTable/types';
import { UiDateFormatter } from '@UiCommon/Components/UiFormatter/UiDateFormatter';
import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';
import { DATA_MANAGEMENT_ROUTE } from '@Pages/DataManagement/Routing/routes';

export const useSingleExposureColumns = () => {
  const { t } = useGHGTranslation();

  return useMemo(() => {
    const output: UiAnalyticalTableColumnDefinition<EmissionDto>[] = [
      {
        label: t('singleExposure.table.processId'),
        accessor: 'processId',
        id: 'processId',
        isVisible: true,
        beField: 'PROCESS_ID',
      },
      {
        label: t('singleExposure.table.processActualStartDate'),
        accessor: 'processActualStartDate',
        id: 'processActualStartDate',
        isVisible: true,
        type: UiTableColumnDataType.DATE_TIME,
        beField: 'PROCESS_ACTUAL_START_DATE',
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiDateFormatter value={table.row.original.processActualStartDate} />
        ),
      },
      {
        label: t('singleExposure.table.dataHierarchy'),
        accessor: 'dataHierarchyId',
        id: 'dataHierarchyId',
        isVisible: true,
        beField: 'DATA_HIERARCHY_ID',
        Cell: (table: TableInstance<EmissionDto>) => {
          const { dataHierarchyId } = table.row.original;

          const url = `/${DATA_MANAGEMENT_ROUTE}/${KpiRouting.GHG}/${HIERARCHY_MANAGER_ROUTE}/${dataHierarchyId}`;

          return <UiLink href={url}>{dataHierarchyId}</UiLink>;
        },
      },
      {
        label: t('singleExposure.table.processBusinessDate'),
        accessor: 'processBusinessDate',
        id: 'processBusinessDate',
        isVisible: true,
        type: UiTableColumnDataType.DATE,
        beField: 'PROCESS_BUSINESS_DATE',
      },
      {
        label: t('singleExposure.table.processSystemDate'),
        accessor: 'processSystemDate',
        id: 'processSystemDate',
        isVisible: true,
        type: UiTableColumnDataType.DATE_TIME,
        beField: 'PROCESS_SYSTEM_DATE',
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiDateFormatter value={table.row.original.processSystemDate} />
        ),
      },
    ];

    return output;
  }, [t]);
};
