import styled from '@emotion/styled';
import { useCallback, useRef } from 'react';

import { MoveDirection } from '@Types/types';
import { useOnHoverElementSupport } from '@Utils/useOnHoverElementSupport';

import { UiListItemType, OnNavigateCallback } from '../../../UiList/types';
import { UiListNavigationItem } from '../../../UiList/UiListNavigationItem';

const ContentWrapper = styled.div`
  display: flex;
  height: 2.75rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
type Props<T> = {
  item: UiListItemType<T>;
  isFirstActiveItem: boolean;
  isLastActiveItem: boolean;
  isSelected: boolean;
  isSearching: boolean;
  onNavigate: OnNavigateCallback;
  focusedItemValue?: string;
};

export const ColumnListItem = <T,>({
  item,
  focusedItemValue,
  isFirstActiveItem,
  isLastActiveItem,
  isSelected,
  isSearching,
  onNavigate,
}: Props<T>) => {
  const ref = useRef(null);
  const onNavigateHandler = useCallback(
    (direction: MoveDirection) => onNavigate(direction, ref.current),
    [onNavigate, ref.current],
  );
  const { isHovered, eventListeners } = useOnHoverElementSupport();

  const isOtherFocused = focusedItemValue && focusedItemValue !== item.value;
  const isFocused = focusedItemValue === item.value;

  const isNavigationActive =
    !isSearching && isSelected && !isOtherFocused && (isFocused || isHovered);

  return (
    <ContentWrapper ref={ref} {...eventListeners}>
      {item.label}
      {isNavigationActive && (
        <UiListNavigationItem
          onNavigate={onNavigateHandler}
          isUpwardDisabled={isFirstActiveItem}
          isDownwardDisabled={isLastActiveItem}
        />
      )}
    </ContentWrapper>
  );
};
