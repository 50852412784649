import { useMultiAppStore } from '@GlobalProviders/MultiApp/MultiAppProvider';
import { useProcessManagerTranslation } from '@GlobalProviders/i18n/hooks';
import { SmartAnalyticalTableCard } from '@Pages/Shared/SmartComponents/SmartAnalyticalTableCard/SmartAnalyticalTableCard';
import { SubPageHeader } from '@UiCommon/Layout/Components/Headers/SubPageHeader';

import { useErrorLogTable } from './useErrorLogTable';
import { ErrorLogDetails } from './ErrorLogDetails/ErrorLogDetails';

const TABLE_ID = 'errorLog';

export const ErrorLogPage = () => {
  const { translatedProductInUse } = useMultiAppStore();
  const { t } = useProcessManagerTranslation();
  const {
    columns,
    sorting,
    filters,
    data,
    totalElements,
    processId,
    setFilters,
    setSorting,
    fetchNextPage,
  } = useErrorLogTable();

  return (
    <>
      <SubPageHeader
        productName={translatedProductInUse}
        title={t('processManager.errorLog.title', { id: processId })}
      />
      <ErrorLogDetails />
      <SmartAnalyticalTableCard
        columns={columns}
        pathParameter={processId}
        hasExportCSV
        hasSettingsModal
        data={data}
        title={t('processManager.errorLog.table.title', { amount: totalElements })}
        infiniteScroll
        onFilters={setFilters}
        onSorting={setSorting}
        filters={filters}
        sorting={sorting}
        onLoadMore={fetchNextPage}
        totalNumberOfItems={totalElements}
        tableId={TABLE_ID}
        apiPath='platform.processMonitoring.getProcessValidationReport'
        rowHeight={60}
      />
    </>
  );
};
