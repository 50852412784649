import { useUiCommonTranslation } from '@GlobalProviders/i18n/hooks';

import { UiButton } from '../../UiButton/UiButton';
import { UiDialogFooter } from '../../UiDialog/UiDialogFooter';

type Props = {
  onExport?: () => void;
  onCancel: () => void;
  isSubmitDisabled?: boolean;
};

export const UiExportModalFooter = ({ onExport, onCancel, isSubmitDisabled }: Props) => {
  const { t } = useUiCommonTranslation();

  return (
    <UiDialogFooter
      endContent={
        <>
          {onExport && (
            <UiButton disabled={isSubmitDisabled} design='Emphasized' onClick={onExport}>
              {t('exportModal.export')}
            </UiButton>
          )}
          <UiButton design='Transparent' onClick={onCancel}>
            {t('exportModal.cancel')}
          </UiButton>
        </>
      }
    />
  );
};
