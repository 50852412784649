import { Navigate, RouteObject } from 'react-router-dom';

import { SettingsPage } from '../SettingsPage';
import { SETTINGS_ROUTE, SYSTEM_SETTINGS } from './routes';
import { SystemSettingsPage } from '../SystemSettings/SystemSettingsPage';

export const SettingsRouting: RouteObject = {
  path: SETTINGS_ROUTE,
  element: <SettingsPage />,
  children: [
    {
      index: true,
      element: <Navigate to={SYSTEM_SETTINGS} />,
    },
    {
      path: SYSTEM_SETTINGS,
      element: <SystemSettingsPage />,
    },
  ],
};
