import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { CommonProps } from '../../types';

type Props = CommonProps & {
  isDragActive?: boolean;
  id: string;
};

export const UiDraggableContainer = ({ isDragActive, id, className, children }: Props) => {
  const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
  });

  const dndAttributes = isDragActive
    ? {
        ...attributes,
        ...listeners,
      }
    : {};

  return (
    <div
      ref={setNodeRef}
      className={className}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        position: 'relative',
        zIndex: isDragging ? 1 : 0,
      }}
      {...dndAttributes}
    >
      {children}
    </div>
  );
};
