import styled from '@emotion/styled';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useMultiAppStore } from '@GlobalProviders/MultiApp/MultiAppProvider';
import { defaultAppRouting } from '@GlobalProviders/MultiApp/defaultAppRouting';

import { NavigationSideBar } from './Components/NavigationSideBar/NavigationSideBar';
import { BODY_PAGE_PADDING } from './constants';

const DashboardLayout = styled.div`
  width: 100%;
  padding: ${BODY_PAGE_PADDING};
  overflow: auto;
`;

const Page = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: hidden;
`;

export const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { productInUse } = useMultiAppStore();

  useEffect(() => {
    if (location.pathname === '/') {
      navigate(defaultAppRouting(productInUse));
    }
  }, [navigate, location, productInUse]);

  return (
    <Page>
      <NavigationSideBar />
      <DashboardLayout>
        <Outlet />
      </DashboardLayout>
    </Page>
  );
};
