import { Navigate, Outlet, RouteObject } from 'react-router-dom';

import { KpiRouting } from '@GlobalProviders/MultiApp/KpiRouting';
import { useMultiAppStore } from '@GlobalProviders/MultiApp/MultiAppProvider';
import { getProductViaKpiRoute } from '@GlobalProviders/MultiApp/utils';

type props = {
  kpiRoute: KpiRouting;
};

const ProtectedRoute = ({ kpiRoute }: props) => {
  const { productInUse } = useMultiAppStore();

  if (productInUse === getProductViaKpiRoute(kpiRoute)) {
    return <Outlet />;
  }

  return <Navigate to='/' />;
};

export const KPIRoutingFactory = (children: RouteObject[], kpiRoute: KpiRouting): RouteObject[] => [
  { index: true, element: <Navigate to={kpiRoute} /> },
  { path: kpiRoute, element: <ProtectedRoute kpiRoute={kpiRoute} />, children },
];
