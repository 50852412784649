import styled from '@emotion/styled';
import { ThemingParameters } from '@ui5/webcomponents-react-base';

import { UiTitle } from './UiTitle';
import { CommonProps } from '../types';

const Wrapper = styled.div`
  width: fit-content;
  display: flex;
  gap: 2.5rem;
`;

type StyleProps = { isActive: boolean };

const Label = styled(UiTitle)<StyleProps>`
  padding-bottom: 0.5rem;
  line-height: 1.25rem;
  cursor: pointer;
  color: ${({ isActive }) =>
    isActive
      ? ThemingParameters.sapButton_Emphasized_Active_BorderColor
      : ThemingParameters.sapTextColor};
`;

const ActiveIndicator = styled.div`
  height: 3px;
  background: ${ThemingParameters.sapButton_Emphasized_Active_BorderColor};
  border-radius: 2px 2px 0 0;
`;

const TabWrapper = styled.div<StyleProps>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
`;

export type TabConfig = {
  label: string;
  id: string;
  onClick?: (id: string) => void;
};

type Props = CommonProps & {
  tabsConfig: TabConfig[];
  onTabChange: (id: string) => void;
  selectedTab: string;
};

export const UiTabSwitcher = ({ tabsConfig, selectedTab, onTabChange, className }: Props) => {
  const handleItemClick = ({ onClick, id }: TabConfig) => {
    onTabChange(id);
    onClick?.(id);
  };

  return (
    <Wrapper className={className}>
      {tabsConfig.map((item) => {
        const isActive = item.id === selectedTab;

        return (
          <TabWrapper isActive={isActive} onClick={() => handleItemClick(item)} key={item.label}>
            <Label isActive={isActive} level={'H6'}>
              {item.label}
            </Label>
            {isActive && <ActiveIndicator />}
          </TabWrapper>
        );
      })}
    </Wrapper>
  );
};
