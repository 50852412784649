import { RouteObject } from 'react-router-dom';

import { BusinessPartnerAddressCoordinatesPage } from '../BusinessPartnerAddressCoordinatesPage';
import { BP_ADDRESS_COORDINATES_ROUTE } from './routes';

export const BusinessPartnerAddressCoordinatesRouting: RouteObject = {
  path: BP_ADDRESS_COORDINATES_ROUTE,
  children: [
    {
      index: true,
      element: <BusinessPartnerAddressCoordinatesPage />,
    },
  ],
};
