import { useState } from 'react';

import { CustomPaginatedDataQueryOutput, PaginatedRqHookProps } from '@DataAccess/types';
import { SortingListItemProps } from '@UiCommon/Components/UiAnalyticalTable/SettingsModal/SortSettings/useSortingSettingsState';
import {
  UiAnalyticalTableColumnDefinition,
  UiColumnFiltersType,
} from '@UiCommon/Components/UiAnalyticalTable/types';
import { UiListItemType } from '@UiCommon/Components/UiList/types';

import { useSearchParamsSettings } from './Hooks/useSearchParamsSettings';

type UseSmartAnalyticalTableProps<T = Record<string, unknown>> = {
  columns: UiAnalyticalTableColumnDefinition<T>[];
  queryHook: (rest: PaginatedRqHookProps<T>) => CustomPaginatedDataQueryOutput<T>;
  mapper?: PaginatedRqHookProps<T>['mapper'];
  defaultSorting?: UiListItemType<SortingListItemProps>[];
};

export const useSmartAnalyticalTableCard = <T>({
  columns,
  queryHook,
  mapper,
  defaultSorting = [],
}: UseSmartAnalyticalTableProps<T>) => {
  const [sorting, setSorting] = useState<UiListItemType<SortingListItemProps>[]>(defaultSorting);
  const [filters, setFilters] = useState<UiColumnFiltersType[]>([]);

  const { externalFiltersApplied, isQueryReady } = useSearchParamsSettings({
    handleFiltersUpdate: setFilters,
  });

  const {
    mappedData: data,
    fetchNextPage,
    totalElements,
    pageParams,
  } = queryHook({ columns, sorting, filters, mapper, enabled: isQueryReady });

  return {
    filters,
    sorting,
    setSorting,
    setFilters,
    data,
    fetchNextPage,
    totalElements,
    pageParams,
    externalFiltersApplied,
  };
};
