import { useParams } from 'react-router-dom';
import { useState } from 'react';

import { SortingListItemProps } from '@UiCommon/Components/UiAnalyticalTable/SettingsModal/SortSettings/useSortingSettingsState';
import { UiColumnFiltersType } from '@UiCommon/Components/UiAnalyticalTable/types';
import { UiListItemType } from '@UiCommon/Components/UiList/types';
import { useValidationReportQuery } from '@DataAccess/Platform/ProcessMonitoring/processMonitoring.hooks';

import { useErrorLogColumns } from './useErrorLogColumns';
import { mapErrorLog } from './mapErrorLog';

export const useErrorLogTable = () => {
  const columns = useErrorLogColumns();
  const { processId } = useParams();

  const [sorting, setSorting] = useState<UiListItemType<SortingListItemProps>[]>([]);
  const [filters, setFilters] = useState<UiColumnFiltersType[]>([]);
  const {
    mappedData: data,
    totalElements,
    fetchNextPage,
  } = useValidationReportQuery(processId ?? '', {
    sorting,
    columns,
    filters,
    mapper: mapErrorLog,
  });

  return {
    columns,
    data,
    totalElements,
    processId,
    setFilters,
    setSorting,
    fetchNextPage,
    sorting,
    filters,
  };
};
