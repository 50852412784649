import {
  Duration,
  FormatDurationOptions,
  Locale,
  formatDuration,
  intervalToDuration,
} from 'date-fns';

/**
 * Duration in short syntax using "date-fns" library
 * @param duration in seconds
 * @param locale optional locale
 */
export const shortSyntaxDuration = (duration: number, locale?: Pick<Locale, 'formatDistance'>) => {
  const formatOptions: FormatDurationOptions = {
    locale,
    format: ['days', 'hours', 'minutes', 'seconds'],
    zero: true,
  };

  const dateFnsDuration =
    duration === 0
      ? ({ seconds: 0 } as Duration)
      : intervalToDuration({ start: 0, end: duration * 1e3 });

  const bigSyntaxDuration = formatDuration(dateFnsDuration, formatOptions);

  /**
   * match "<integer> <unit>" and replace with "<integer><unitFirstLetter>"
   * e.g. 6 minutes 40 seconds => 6m 40s
   * */
  const output = bigSyntaxDuration.replace(
    /(\d{1,2})\s([a-zа-я]{1,10})/gi,
    (_match, number, unit) => {
      return number + (unit as string).charAt(0).toLowerCase();
    },
  );

  return output;
};
