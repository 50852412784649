import { RouteObject } from 'react-router-dom';

import { EmissionFactorEnergyPage } from '../EmissionFactorEnergyPage';
import { EMISSION_FACTOR_ENERGY_ROUTE } from './routes';

export const EmissionFactorEnergyRouting: RouteObject = {
  path: EMISSION_FACTOR_ENERGY_ROUTE,
  children: [
    {
      index: true,
      element: <EmissionFactorEnergyPage />,
    },
  ],
};
