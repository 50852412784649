import { useMemo } from 'react';

import { useProcessManagerTranslation } from '@GlobalProviders/i18n/hooks';
import { UiTableColumnDataType } from '@UiCommon/Components/UiAnalyticalTable/types';

import { useCommonCollectedColumns } from '../useCommonCollectedColumns';

export const useEmissionFactorGoodsColumns = () => {
  const { t } = useProcessManagerTranslation();
  const commonColumns = useCommonCollectedColumns();

  return useMemo(
    () => [
      {
        label: t('processManager.manualUpload.emissionFactorGoods.table.goodsType'),
        id: 'goodsType',
        accessor: 'goodsType',
        isVisible: true,
        beField: 'GOODS_TYPE',
      },
      {
        label: t('processManager.manualUpload.emissionFactorGoods.table.purpose'),
        id: 'purpose',
        accessor: 'purpose',
        isVisible: true,
        beField: 'PURPOSE',
      },
      {
        label: t('processManager.manualUpload.emissionFactorGoods.table.emissionFactor'),
        id: 'emissionFactor',
        accessor: 'emissionFactor',
        isVisible: true,
        beField: 'EMISSION_FACTOR',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.shared.year'),
        accessor: 'year',
        id: 'year',
        isVisible: true,
        beField: 'YEAR',
        type: UiTableColumnDataType.NUMBER,
      },
      ...commonColumns,
    ],
    [t, commonColumns],
  );
};
