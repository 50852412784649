import { RouteObject } from 'react-router-dom';

import { LocationScorePage } from '../LocationScorePage';
import { LOCATION_SCORE_ROUTE } from './routes';

export const MunichReRouting: RouteObject = {
  path: LOCATION_SCORE_ROUTE,
  children: [
    {
      index: true,
      element: <LocationScorePage />,
    },
  ],
};
