import { StandardListItem } from '@ui5/webcomponents-react';

import { CommonProps } from '@UiCommon/types';

type Props = CommonProps & {
  isSelected?: boolean;
  onClick?: () => void;
};

export const UiStandardListItem = ({ children, className, isSelected, onClick }: Props) => {
  return (
    <StandardListItem selected={isSelected} className={className} onClick={onClick}>
      {children}
    </StandardListItem>
  );
};
