import { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';

import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';
import { CellProps } from '@UiCommon/Components/UiDraggableTable/UiDraggableTable';
import { BpSplitScope } from '@DataAccess/CoreLib/SplitRatio/splitRatio.types';

import { BusinessPartnerCell } from './BusinessPartnerCell';
import { BusinessPartnerSplitScopeCell } from './BusinessPartnerSplitScopeCell';
import { BusinessPartnerActions } from './BusinessPartnerActions';
import {
  RULE_ORDER_NUMBER_COLUMN_ID,
  SPLIT_SCOPE_1_EMISSIONS_COLUMN_ID,
  SPLIT_SCOPE_2_EMISSIONS_COLUMN_ID,
} from '../Utils/constants';

export const useBusinessPartnerCustomizationColumns = () => {
  const { t } = useGHGTranslation();

  return useMemo(
    (): ColumnDef<BpSplitScope>[] => [
      {
        id: RULE_ORDER_NUMBER_COLUMN_ID,
        header: 'Rule Order No.',
        cell: (props: CellProps<BpSplitScope>) => {
          return <>{props.row.index + 1}</>;
        },
      },
      {
        id: 'naceCode',
        header: t('splitScope.businessPartner.naceCode'),
        cell: (props: CellProps<BpSplitScope>) => {
          return (
            <BusinessPartnerCell
              isEditable={!props.row.original.isDefault}
              value={props.row.original.naceCode}
              index={props.row.index}
              id='naceCode'
            />
          );
        },
      },

      {
        id: 'secondaryClassificationCode',
        header: t('splitScope.businessPartner.secondaryClassificationCode'),
        accessorKey: 'secondaryClassificationCode',
        cell: (props: CellProps<BpSplitScope>) => {
          return (
            <BusinessPartnerCell
              isEditable={!props.row.original.isDefault}
              value={props.row.original.secondaryClassificationCode}
              index={props.row.index}
              id='secondaryClassificationCode'
            />
          );
        },
      },
      {
        id: 'country',
        header: t('splitScope.businessPartner.country'),
        accessorKey: 'country',
        cell: (props: CellProps<BpSplitScope>) => {
          return (
            <BusinessPartnerCell
              isEditable={!props.row.original.isDefault}
              value={props.row.original.country}
              index={props.row.index}
              id='country'
            />
          );
        },
      },
      {
        id: 'category',
        header: t('splitScope.businessPartner.category'),
        accessorKey: 'category',
        cell: (props: CellProps<BpSplitScope>) => {
          return (
            <BusinessPartnerCell
              isEditable={!props.row.original.isDefault}
              value={props.row.original.category}
              index={props.row.index}
              id='category'
            />
          );
        },
      },
      {
        id: SPLIT_SCOPE_1_EMISSIONS_COLUMN_ID,
        header: t('splitScope.businessPartner.splitScope1Emissions'),
        accessorKey: 'splitScope1Emissions',
        cell: (props: CellProps<BpSplitScope>) => {
          return (
            <BusinessPartnerSplitScopeCell
              isEditable={!props.row.original.isDefault}
              value={props.row.original.splitScope1Emissions}
              index={props.row.index}
              id={SPLIT_SCOPE_1_EMISSIONS_COLUMN_ID}
            />
          );
        },
      },
      {
        id: SPLIT_SCOPE_2_EMISSIONS_COLUMN_ID,
        header: t('splitScope.businessPartner.splitScope2Emissions'),
        accessorKey: 'splitScope2Emissions',
        cell: (props: CellProps<BpSplitScope>) => {
          return (
            <BusinessPartnerSplitScopeCell
              isEditable={!props.row.original.isDefault}
              value={props.row.original.splitScope2Emissions}
              index={props.row.index}
              id={SPLIT_SCOPE_2_EMISSIONS_COLUMN_ID}
            />
          );
        },
      },
      {
        header: '',
        id: 'actions',
        size: 50,
        cell: (props: CellProps<BpSplitScope>) => (
          <BusinessPartnerActions index={props.row.index} />
        ),
      },
    ],
    [t],
  );
};
