import { useEffect } from 'react';
import toast from 'react-hot-toast';
import i18next from 'i18next';

import { axiosFactory } from '@DataAccess/axiosFactory';
import { NestedKeyOf } from '@Types/types';
import translation from '@GlobalProviders/i18n/locales/en/shared';

import { useSharedTranslation } from './i18n/hooks';

type TranslationKeyType = NestedKeyOf<typeof translation>;

export const InjectHttpErrorDictionary = () => {
  const { t } = useSharedTranslation();

  useEffect(() => {
    axiosFactory.errorsHandler = (status) => {
      const tHttpErrorKey =
        `general.httpErrorDictionary.${status.toString()}` as TranslationKeyType;

      if (i18next.exists(tHttpErrorKey)) {
        const message = t(tHttpErrorKey);
        toast.error(message as string);
      }
    };
  }, [t]);

  return null;
};
