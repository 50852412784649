import styled from '@emotion/styled';
import { ThemingParameters } from '@ui5/webcomponents-react-base';

import { useUiCommonTranslation } from '@GlobalProviders/i18n/hooks';
import { FontWeight } from '@UiCommon/typography';
import { CommonProps } from '@UiCommon/types';

import { UiTitle } from '../UiTitle';
import { UiVersionList, VersionListProps } from './UiVersionList';
import { CommonVersionData } from './UiVersionOption';

type Props<T extends CommonVersionData> = CommonProps &
  VersionListProps<T> & {
    renderFilters?: () => JSX.Element;
  };

const VersionMenu = styled.section`
  max-width: 17rem;
  min-width: 17rem;
  background-color: ${ThemingParameters.sapBaseColor};
  border: ${ThemingParameters.sapList_BorderWidth} solid ${ThemingParameters.sapList_BorderColor};
`;

const MenuHeader = styled.div`
  padding: 1.5rem 1rem 0;
`;

const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 0.75rem;
`;

const MenuBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UiVersionMenu = <T extends CommonVersionData>({
  versions,
  isFetched,
  isLoading,
  renderData,
  renderFilters,
  paramId,
  className,
}: Props<T>) => {
  const { t } = useUiCommonTranslation();

  return (
    <VersionMenu className={className}>
      <MenuHeader>
        <UiTitle level='H5' weight={FontWeight.HEADER}>
          {t('versionMenu.title')}
        </UiTitle>
        <FiltersWrapper>{renderFilters?.()}</FiltersWrapper>
      </MenuHeader>
      <MenuBody>
        <UiVersionList
          renderData={renderData}
          versions={versions}
          isFetched={isFetched}
          isLoading={isLoading}
          paramId={paramId}
        />
      </MenuBody>
    </VersionMenu>
  );
};
