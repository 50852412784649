import { useMemo } from 'react';

import { useProcessManagerTranslation } from '@GlobalProviders/i18n/hooks';
import {
  TableInstance,
  UiAnalyticalTableColumnDefinition,
  UiTableColumnDataType,
} from '@UiCommon/Components/UiAnalyticalTable/types';
import { UiDateFormatter } from '@UiCommon/Components/UiFormatter/UiDateFormatter';
import { conditionalArrayElements } from '@Utils/utils';
import { CommonCollectedDto } from '@DataAccess/types';

type Props = {
  hideDataSource?: boolean;
  hideBusinessValidity?: boolean;
};

type TableColumnType = UiAnalyticalTableColumnDefinition<any>;

export const useCommonCollectedColumns = (input: Props = {}) => {
  const { t } = useProcessManagerTranslation();
  const { hideDataSource, hideBusinessValidity } = input;

  const conditionalDataSource = conditionalArrayElements<TableColumnType>(!hideDataSource, [
    {
      label: t('processManager.manualUpload.shared.dataSource'),
      accessor: 'dataSource',
      id: 'dataSource',
      isVisible: true,
      beField: 'DATA_SOURCE',
    },
  ]);

  const conditionalBusinessValidity = conditionalArrayElements<TableColumnType>(
    !hideBusinessValidity,
    [
      {
        label: t('processManager.manualUpload.shared.businessValidFrom'),
        accessor: 'businessValidFrom',
        id: 'businessValidFrom',
        isVisible: true,
        beField: 'BUSINESS_VALID_FROM',
        type: UiTableColumnDataType.DATE,
      },
      {
        label: t('processManager.manualUpload.shared.businessValidTo'),
        accessor: 'businessValidTo',
        id: 'businessValidTo',
        isVisible: true,
        beField: 'BUSINESS_VALID_TO',
        type: UiTableColumnDataType.DATE,
      },
    ],
  );

  return useMemo<TableColumnType[]>(
    () => [
      ...conditionalDataSource,
      ...conditionalBusinessValidity,
      {
        label: t('processManager.manualUpload.shared.systemDate'),
        accessor: 'systemDate',
        id: 'systemDate',
        isVisible: true,
        beField: 'SYSTEM_DATE',
        type: UiTableColumnDataType.DATE_TIME,
        Cell: (table: TableInstance<CommonCollectedDto>) => (
          <UiDateFormatter value={table.row.original.systemDate} />
        ),
      },
      {
        id: 'processId',
        accessor: 'processId',
        label: t('processManager.manualUpload.shared.processId'),
        isVisible: true,
        beField: 'PROCESS_ID',
      },
      {
        id: 'enabledFlag',
        accessor: 'enabledFlag',
        label: t('processManager.manualUpload.shared.enabledFlag'),
        isVisible: true,
        beField: 'ENABLED_FLAG',
        type: UiTableColumnDataType.BOOLEAN,
      },
    ],
    [conditionalDataSource, t],
  );
};
