import { useMemo } from 'react';

import { useProcessManagerTranslation } from '@GlobalProviders/i18n/hooks';
import { UiTableColumnDataType } from '@UiCommon/Components/UiAnalyticalTable/types';

import { useCommonCollectedColumns } from '../useCommonCollectedColumns';

export const useFinancialInstrumentColumns = () => {
  const { t } = useProcessManagerTranslation();
  const commonColumns = useCommonCollectedColumns();

  return useMemo(
    () => [
      {
        label: t('processManager.manualUpload.financialInstrument.table.financialProductId'),
        accessor: 'financialProductId',
        id: 'financialProductId',
        minWidth: 150,
        isVisible: true,
        beField: 'FINANCIAL_PRODUCT_ID',
      },
      {
        label: t('processManager.manualUpload.financialInstrument.table.businessPartnerId'),
        accessor: 'businessPartnerId',
        id: 'businessPartnerId',
        minWidth: 150,
        isVisible: true,
        beField: 'BUSINESS_PARTNER_ID',
      },
      {
        label: t('processManager.manualUpload.financialInstrument.table.isin'),
        accessor: 'isin',
        id: 'isin',
        minWidth: 150,
        isVisible: true,
        beField: 'ISIN',
      },
      {
        label: t('processManager.manualUpload.financialInstrument.table.productType'),
        accessor: 'productType',
        id: 'productType',
        minWidth: 150,
        isVisible: true,
        beField: 'PRODUCT_TYPE',
      },
      {
        label: t('processManager.manualUpload.financialInstrument.table.naceCode'),
        accessor: 'naceCode',
        id: 'naceCode',
        minWidth: 150,
        isVisible: true,
        beField: 'NACE_CODE',
      },
      {
        label: t('processManager.manualUpload.financialInstrument.table.bookValue'),
        accessor: 'bookValue',
        id: 'bookValue',
        minWidth: 150,
        isVisible: true,
        beField: 'BOOK_VALUE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.financialInstrument.table.pcafAssetClass'),
        accessor: 'pcafAssetClass',
        id: 'pcafAssetClass',
        minWidth: 150,
        isVisible: true,
        beField: 'PCAF_ASSET_CLASS',
      },
      ...commonColumns,
    ],
    [t, commonColumns],
  );
};
