import { AxiosInstance } from 'axios';

import { CollectionQueryParams, PaginatedTableParams, PaginationResponse } from '@DataAccess/types';

import {
  ParametrizationProps,
  ProcessMonitoringDto,
  ValidationReportDto,
} from './processMonitoring.types';

export const initialiseProcessMonitoringEndpoints = (api: AxiosInstance) => ({
  getProcess({
    tableParams,
    pageParam,
  }: PaginatedTableParams): Promise<PaginationResponse<ProcessMonitoringDto>> {
    return api
      .post<PaginationResponse<ProcessMonitoringDto>>('/process', {
        page: pageParam,
        ...tableParams,
      })
      .then(({ data }) => data);
  },
  getProcessValidationReport({
    pathParameter: processId,
    tableParams,
    pageParam,
  }: {
    pathParameter: string;
    tableParams?: CollectionQueryParams;
    pageParam?: number;
  }): Promise<PaginationResponse<ValidationReportDto>> {
    return api
      .post<PaginationResponse<ValidationReportDto>>(`/validation-report/${processId}`, {
        page: pageParam,
        ...tableParams,
      })
      .then(({ data }) => data);
  },
  getProcessDetails(processId?: string): Promise<ProcessMonitoringDto> {
    return api.get<ProcessMonitoringDto>(`/process/${processId}`).then(({ data }) => data);
  },
  runCalculation({ businessDate, systemDate }: ParametrizationProps) {
    return api.post('/ghg/calculation/run', { businessDate, systemDate });
  },
  getLatestEmissionRunCalculationProcessId() {
    return api.get<string>('/process/latest-financed-emissions').then(({ data }) => data);
  },
});
