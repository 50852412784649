import { ReactNode } from 'react';

import { IconSet } from '@GlobalProviders/IconSetProvider';

import { GenericHeader } from './GenericHeader';

export type BreadCrumbItem = {
  label: string;
  icon?: IconSet;
  route?: string;
  isDisabled?: boolean;
};

type Props = {
  title: string;
  productName: string;
  subtitle?: string;
  renderCustomActions?: () => ReactNode | ReactNode[];
};

export const PageHeader = ({ title, productName, subtitle, renderCustomActions }: Props) => (
  <GenericHeader
    productName={productName}
    title={title}
    subtitle={subtitle}
    renderCustomActions={renderCustomActions}
  />
);
