import { SplitScopeStateReturnType } from '../Context/useSplitScopeState';
import { UiActions } from './UiActions';

type Props<T> = {
  categoryState: SplitScopeStateReturnType<T>;
  index: number;
};

export const SplitScopeActions = <T,>({ categoryState, index }: Props<T>) => {
  const { state, actions } = categoryState;

  const handleDelete = () => {
    actions.setListItems(state.list.filter((_, i) => i !== index));
  };

  const lastEditableElementIndex = state.list.length - 2;

  return (
    <UiActions
      isDownwardDisabled={lastEditableElementIndex === index}
      onDownClick={() => actions.moveListItem(index, index + 1)}
      onUpClick={() => actions.moveListItem(index, index - 1)}
      isUpwardDisabled={index === 0}
      onDelete={handleDelete}
    />
  );
};
