import { isNil } from 'lodash';

import { useUiCommonTranslation } from '@GlobalProviders/i18n/hooks';

import { stretchedUiInputClass } from '../Styles';
import { ConditionType } from '../../types';
import { UiSelect } from '../../../../UiSelect/UiSelect';
import { UiSelectOption } from '../../../../UiSelect/UiSelectOption';

type Props = {
  value: ConditionType['value'];
  onValueChange: (newValue: ConditionType['value']) => void;
};

export const BooleanCondition = ({ value, onValueChange }: Props) => {
  const { t } = useUiCommonTranslation();

  return (
    <UiSelect
      className={stretchedUiInputClass}
      onChange={(changedValue) => {
        if (isNil(changedValue)) return;
        const newValue = changedValue === 'true';
        onValueChange(newValue);
      }}
    >
      <UiSelectOption isSelected={isNil(value)} />
      <UiSelectOption value='true' isSelected={value === true}>
        {t('types.booleanOptions.true')}
      </UiSelectOption>
      <UiSelectOption value='false' isSelected={value === false}>
        {t('types.booleanOptions.false')}
      </UiSelectOption>
    </UiSelect>
  );
};
