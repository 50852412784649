function findParentWithScroll(element: HTMLElement): HTMLElement | undefined {
  const parent = element.parentElement;

  if (!parent) return;

  return parent.scrollHeight > parent.clientHeight ? parent : findParentWithScroll(parent);
}

type UseEnsureElementScrollVisibilityConfig = {
  closestTargetElement?: string;
  scrollTimeout?: number;
};

export const useEnsureElementScrollVisibility =
  ({ closestTargetElement, scrollTimeout = 200 }: UseEnsureElementScrollVisibilityConfig = {}) =>
  (element: HTMLElement) => {
    const targetElement = closestTargetElement
      ? (element.closest(closestTargetElement) as HTMLElement)
      : element;

    if (!targetElement) return;

    const scrollableParent = findParentWithScroll(targetElement);

    if (!scrollableParent) return;

    setTimeout(() => {
      const { top, bottom } = targetElement.getBoundingClientRect();
      const { top: parentTop, bottom: parentBottom } = scrollableParent.getBoundingClientRect();
      const isScrollNeeded = top < parentTop || bottom > parentBottom;

      isScrollNeeded &&
        targetElement.scrollIntoView({
          behavior: 'smooth',
        });
    }, scrollTimeout);
  };
