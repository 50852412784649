import { useMemo } from 'react';

import { useProcessManagerTranslation } from '@GlobalProviders/i18n/hooks';

import { useCommonCollectedColumns } from '../useCommonCollectedColumns';

export const useIndustryClassificationCodeColumns = () => {
  const { t } = useProcessManagerTranslation();
  const commonColumns = useCommonCollectedColumns({ hideBusinessValidity: true });

  return useMemo(
    () => [
      {
        label: t(
          'processManager.manualUpload.industryClassificationCode.table.classificationSystem',
        ),
        accessor: 'classificationSystem',
        id: 'classificationSystem',
        minWidth: 120,
        isVisible: true,
        beField: 'CLASSIFICATION_SYSTEM',
      },
      {
        label: t('processManager.manualUpload.industryClassificationCode.table.classificationCode'),
        accessor: 'classificationCode',
        id: 'classificationCode',
        minWidth: 104,
        isVisible: true,
        beField: 'CLASSIFICATION_CODE',
      },
      {
        label: t(
          'processManager.manualUpload.industryClassificationCode.table.classificationLevel1',
        ),
        accessor: 'classificationLevel1',
        id: 'classificationLevel1',
        minWidth: 120,
        isVisible: true,
        beField: 'CLASSIFICATION_LEVEL_1',
      },
      {
        label: t(
          'processManager.manualUpload.industryClassificationCode.table.classificationLevel2',
        ),
        accessor: 'classificationLevel2',
        id: 'classificationLevel2',
        minWidth: 100,
        isVisible: true,
        beField: 'CLASSIFICATION_LEVEL_2',
      },
      ...commonColumns,
    ],
    [t, commonColumns],
  );
};
