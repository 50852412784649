import { RouteObject } from 'react-router-dom';

import { BUSINESS_PARTNER_ROUTE } from './routes';
import { BusinessPartnerPage } from '../BusinessPartnerPage';

export const BusinessPartnerRouting: RouteObject = {
  path: BUSINESS_PARTNER_ROUTE,
  children: [
    {
      index: true,
      element: <BusinessPartnerPage />,
    },
  ],
};
