import { InputType, ValueState } from '@ui5/webcomponents-react';
import { useMemo, useState } from 'react';

import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';
import { UiButton, UiDialog, UiDialogFooter, UiDialogHeader } from '@UiCommon/Components';
import { UiInput } from '@UiCommon/Components/UiInput/UiInput';

type Props = {
  isOpen: boolean;
  onConfirm: (name?: string) => void;
  onClose: () => void;
  isInputInvalid: (name?: string) => boolean;
};

export const HierarchyAddSourceDialog = ({ isOpen, onConfirm, onClose, isInputInvalid }: Props) => {
  const { t } = useGHGTranslation();
  const [sourceName, setSourceName] = useState<string | undefined>(undefined);

  const errorMessage = useMemo(() => {
    if (sourceName?.trim().length === 0) return t('hierarchyManager.inputErrors.emptySource');
    if (isInputInvalid(sourceName)) return t('hierarchyManager.inputErrors.duplicateSource');

    return undefined;
  }, [sourceName]);

  const extendedCloseFn = () => {
    setSourceName(undefined);
    onClose();
  };

  return (
    <UiDialog
      isOpen={isOpen}
      onAfterClose={extendedCloseFn}
      header={<UiDialogHeader startContent={t('hierarchyManager.addSourceDialog.title')} />}
      footer={
        <UiDialogFooter
          endContent={
            <>
              <UiButton
                disabled={!!errorMessage || !sourceName}
                design='Emphasized'
                onClick={() => onConfirm(sourceName)}
              >
                {t('hierarchyManager.addSourceDialog.confirm')}
              </UiButton>
              <UiButton design='Transparent' onClick={extendedCloseFn}>
                {t('hierarchyManager.addSourceDialog.cancel')}
              </UiButton>
            </>
          }
        />
      }
    >
      <UiInput
        value={sourceName}
        onChange={(value) => setSourceName(value ?? '')}
        label={t('hierarchyManager.addSourceDialog.inputLabel')}
        type={InputType.Text}
        valueState={errorMessage ? ValueState.Error : undefined}
        valueStateMessage={errorMessage}
      />
    </UiDialog>
  );
};
