import { ComponentProps } from 'react';
import { useParams } from 'react-router-dom';
import { css } from '@emotion/css';

import { useLatestEmissionRunCalculationProcessIdQuery } from '@DataAccess/Platform/ProcessMonitoring/processMonitoring.hooks';
import { useMultiAppStore } from '@GlobalProviders/MultiApp/MultiAppProvider';
import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';
import { UiVersionMenu } from '@UiCommon/Components';
import { PageHeader, PageWithSidebar } from '@UiCommon/Layout';
import { SubPageHeader } from '@UiCommon/Layout/Components/Headers/SubPageHeader';
import { PageContentWrapper } from '@UiCommon/Layout/Components/PageContentWrapper';

import { DataQualityOverview } from './Components/DataQualityOverview/DataQualityOverview';
import { KpiInfo } from './Components/KpiInfo/KpiInfo';
import { OverviewHeaderButtons } from './Components/OverviewHeaderButtons';
import { ProcessParams } from './Routing/routes';
import { useGHGOverviewVersioningMenu } from './useGHGOverviewVersioningMenu';

const uiVersionMenuClass = css({
  '&&': {
    maxWidth: '20rem',
    minWidth: '20rem',
  },
});

export const GHGOverviewPage = () => {
  const { t } = useGHGTranslation();
  const { translatedProductInUse } = useMultiAppStore();

  const { processId: processIdParam } = useParams<ProcessParams>();
  const { data: latestRunProcessId } = useLatestEmissionRunCalculationProcessIdQuery();

  const { versions, Options, Filters, isFetched, isLoading } =
    useGHGOverviewVersioningMenu(latestRunProcessId);

  const isSubpage = !!processIdParam;
  const headerProps: ComponentProps<typeof PageHeader | typeof SubPageHeader> = {
    title: t('overview.title'),
    productName: translatedProductInUse,
    renderCustomActions: () => (
      <>
        {!isSubpage && (
          <OverviewHeaderButtons
            latestProcessId={latestRunProcessId}
            isDisabled={!latestRunProcessId}
          />
        )}
      </>
    ),
  };

  const processIdToUse = processIdParam ?? latestRunProcessId;

  return (
    <PageWithSidebar
      showSidebar={!!processIdParam}
      sidebar={
        <UiVersionMenu
          className={uiVersionMenuClass}
          paramId={processIdParam}
          renderData={Options}
          versions={versions}
          isFetched={isFetched}
          isLoading={isLoading}
          renderFilters={Filters}
        />
      }
    >
      {isSubpage ? <SubPageHeader {...headerProps} /> : <PageHeader {...headerProps} />}
      <PageContentWrapper>
        <KpiInfo processId={processIdToUse} />
        <DataQualityOverview processId={processIdToUse} />
      </PageContentWrapper>
    </PageWithSidebar>
  );
};
