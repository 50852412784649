import { sortBy } from 'lodash';

import { ColumnFiltersType } from './SettingsModal/types';
import { UiAnalyticalTableColumnDefinition, UiColumnFiltersType } from './types';

export const sortColumnsByVisibility = <DataType>(
  columns: UiAnalyticalTableColumnDefinition<DataType>[],
) => sortBy(columns, ({ isVisible }) => (isVisible ? -1 : 1));

export const mapToFiltersWithLabels = <DataType>(
  filters: UiColumnFiltersType[],
  columns: UiAnalyticalTableColumnDefinition<DataType>[],
): ColumnFiltersType[] => {
  const columnMap = new Map<string, UiAnalyticalTableColumnDefinition<DataType>>();

  columns.forEach((c) => columnMap.set(c.id, c));

  return filters.map(({ value, conditions, type }) => {
    const column = columnMap.get(value);

    return { value, label: column?.label || '', conditions, type };
  });
};

const SETTINGS_KEY = 'analytical-table-view-settings:';

export const getViewsLocalStorageKey = (tableId: string) => `${SETTINGS_KEY}${tableId}`;
