import { RouteObject } from 'react-router';

import { EmissionBuildingCertificatesPage } from '../EmissionBuildingCertificatesPage';
import { EMISSION_BUILDING_CERTIFICATES_ROUTE } from './routes';

export const EmissionBuildingCertificatesRouting: RouteObject = {
  path: EMISSION_BUILDING_CERTIFICATES_ROUTE,
  children: [
    {
      index: true,
      element: <EmissionBuildingCertificatesPage />,
    },
  ],
};
