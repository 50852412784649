import { Category } from '@DataAccess/Core/DataHierarchy/dataHierarchy.types';

import { HierarchyManager } from '../Components/HierarchyManager';
import { useHierarchy } from '../useHierarchy';

export const DHCorporateFinancePage = () => {
  const { subcategories, handleItemsUpdate, isEditMode } = useHierarchy(Category.CORPORATE_FINANCE);

  return (
    <HierarchyManager
      subcategories={subcategories}
      handleItemsUpdate={handleItemsUpdate}
      isEditMode={isEditMode}
    />
  );
};
