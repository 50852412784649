import styled from '@emotion/styled';
import { ThemingParameters } from '@ui5/webcomponents-react-base';
import { IconDesign, TitleLevel } from '@ui5/webcomponents-react';

import { useProcessManagerTranslation } from '@GlobalProviders/i18n/hooks';
import { UiIcon, UiTitle } from '@UiCommon/Components';
import { IconSet } from '@GlobalProviders/IconSetProvider';
import { ProcessStatus } from '@DataAccess/Platform/ProcessMonitoring/processMonitoring.types';

type Props = {
  status: ProcessStatus;
};

const Wrapper = styled.span`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(UiIcon)`
  margin-right: 0.5rem;
`;
const StyledTitle = styled(UiTitle)<{ color: string }>`
  color: ${({ color }) => color};
`;

export const ProcessStatusInfo = ({ status }: Props) => {
  const { t } = useProcessManagerTranslation();

  const processStatuses = {
    [ProcessStatus.COMPLETE]: {
      color: ThemingParameters.sapPositiveTextColor,
      label: t('processManager.status.complete'),
      iconName: IconSet.STATUS_POSITIVE,
      iconDesign: IconDesign.Positive,
    },
    [ProcessStatus.IN_PROGRESS]: {
      color: ThemingParameters.sapBrandColor,
      label: t('processManager.status.inProgress'),
      iconName: IconSet.INFORMATION,
      iconDesign: IconDesign.Information,
    },
    [ProcessStatus.SCHEDULED]: {
      color: ThemingParameters.sapNeutralTextColor,
      label: t('processManager.status.scheduled'),
      iconName: IconSet.PENDING,
      iconDesign: IconDesign.Neutral,
    },
    [ProcessStatus.ERROR]: {
      color: ThemingParameters.sapNegativeTextColor,
      label: t('processManager.status.error'),
      iconName: IconSet.STATUS_NEGATIVE,
      iconDesign: IconDesign.Negative,
    },
    [ProcessStatus.ABORTED]: {
      color: ThemingParameters.sapNegativeTextColor,
      label: t('processManager.status.error'),
      iconName: IconSet.STATUS_NEGATIVE,
      iconDesign: IconDesign.Negative,
    },
  };

  if (!processStatuses[status]) {
    return null;
  }

  const { color, label, iconName, iconDesign } = processStatuses[status];

  return (
    <Wrapper>
      <StyledIcon design={iconDesign} name={iconName} />
      <StyledTitle level={TitleLevel.H6} color={color}>
        {label}
      </StyledTitle>
    </Wrapper>
  );
};
