import '@ui5/webcomponents-icons/dist/slim-arrow-left.js';
import '@ui5/webcomponents-icons/dist/slim-arrow-right.js';
import '@ui5/webcomponents-icons/dist/slim-arrow-down.js';
import '@ui5/webcomponents-icons/dist/slim-arrow-up.js';
import '@ui5/webcomponents-icons/dist/competitor.js';
import '@ui5/webcomponents-icons/dist/log.js';
import '@ui5/webcomponents-icons/dist/manager-insight.js';
import '@ui5/webcomponents-icons/dist/home.js';
import '@ui5/webcomponents-icons/dist/excel-attachment.js';
import '@ui5/webcomponents-icons/dist/navigation-down-arrow.js';
import '@ui5/webcomponents-icons/dist/navigation-up-arrow.js';
import '@ui5/webcomponents-icons/dist/future.js';
import '@ui5/webcomponents-icons/dist/arrow-left';
import '@ui5/webcomponents-icons/dist/group.js';
import '@ui5/webcomponents-icons/dist/building.js';
import '@ui5/webcomponents-icons/dist/car-rental.js';
import '@ui5/webcomponents-icons/dist/arrow-bottom.js';
import '@ui5/webcomponents-icons/dist/arrow-right.js';
import '@ui5/webcomponents-icons/dist/arrow-top.js';
import '@ui5/webcomponents-icons/dist/overflow.js';
import '@ui5/webcomponents-icons/dist/action-settings.js';
import '@ui5/webcomponents-icons/dist/expand-group.js';
import '@ui5/webcomponents-icons/dist/collapse-group.js';
import '@ui5/webcomponents-icons/dist/decline.js';
import '@ui5/webcomponents-icons/dist/value-help.js';
import '@ui5/webcomponents-icons/dist/sort-ascending.js';
import '@ui5/webcomponents-icons/dist/sort-descending.js';
import '@ui5/webcomponents-icons/dist/search.js';
import '@ui5/webcomponents-icons/dist/dropdown.js';
import '@ui5/webcomponents-icons/dist/sort.js';
import '@ui5/webcomponents-icons/dist/filter.js';
import '@ui5/webcomponents-icons/dist/table-column.js';
import '@ui5/webcomponents-icons/dist/upload.js';
import '@ui5/webcomponents-icons/dist/hint.js';
import '@ui5/webcomponents-icons/dist/status-critical.js';
import '@ui5/webcomponents-icons/dist/status-positive.js';
import '@ui5/webcomponents-icons/dist/status-negative.js';
import '@ui5/webcomponents-icons/dist/simulate.js';
import '@ui5/webcomponents-icons/dist/warning.js';
import '@ui5/webcomponents-icons/dist/alert.js';
import '@ui5/webcomponents-icons/dist/edit.js';
import '@ui5/webcomponents-icons/dist/add.js';
import '@ui5/webcomponents-icons/dist/information.js';
import '@ui5/webcomponents-icons/dist/pending.js';
import '@ui5/webcomponents-icons/dist/grid.js';
import '@ui5/webcomponents-icons/dist/history.js';
import '@ui5/webcomponents-icons/dist/horizontal-grip.js';

export enum IconSet {
  SLIM_ARROW_LEFT = 'slim-arrow-left',
  SLIM_ARROW_RIGHT = 'slim-arrow-right',
  SLIM_ARROW_DOWN = 'slim-arrow-down',
  SLIM_ARROW_UP = 'slim-arrow-up',
  SEARCH = 'search',
  COMPETITOR = 'competitor',
  LOG = 'log',
  MANAGER_INSIGHT = 'manager-insight',
  HOME = 'home',
  EXCEL_ATTACHMENT = 'excel-attachment',
  NAVIGATION_DOWN_ARROW = 'navigation-down-arrow',
  NAVIGATION_UP_ARROW = 'navigation-up-arrow',
  FUTURE = 'future',
  ARROW_LEFT = 'arrow-left',
  GROUP = 'group',
  BUILDING = 'building',
  CAR_RENTAL = 'car-rental',
  ARROW_BOTTOM = 'arrow-bottom',
  ARROW_RIGHT = 'arrow-right',
  ARROW_TOP = 'arrow-top',
  OVERFLOW = 'overflow',
  ACTION_SETTINGS = 'action-settings',
  EXPAND_GROUP = 'expand-group',
  COLLAPSE_GROUP = 'collapse-group',
  DECLINE = 'decline',
  VALUE_HELP = 'value-help',
  SORT_DESCENDING = 'sort-descending',
  SORT_ASCENDING = 'sort-ascending',
  DROPDOWN = 'dropdown',
  TABLE_COLUMN = 'table-column',
  SORT = 'sort',
  FILTER = 'filter',
  UPLOAD = 'upload',
  HINT = 'hint',
  STATUS_CRITICAL = 'status-critical',
  STATUS_POSITIVE = 'status-positive',
  STATUS_NEGATIVE = 'status-negative',
  SIMULATE = 'simulate',
  WARNING = 'warning',
  ALERT = 'alert',
  EDIT = 'edit',
  ADD = 'add',
  INFORMATION = 'information',
  PENDING = 'pending',
  GRID = 'grid',
  HISTORY = 'history',
  HORIZONTAL_GRIP = 'horizontal-grip',
}
