import { RouteObject } from 'react-router-dom';

import { ASSET_VALUATION_ROUTE } from './routes';
import { AssetValuationPage } from '../AssetValuationPage';

export const AssetValuationRouting: RouteObject = {
  path: ASSET_VALUATION_ROUTE,
  children: [
    {
      index: true,
      element: <AssetValuationPage />,
    },
  ],
};
