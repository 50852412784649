import { RouteObject } from 'react-router';

import { COUNTRY_REGION_ROUTE } from './routes';
import { CountryRegionPage } from '../CountryRegionPage';

export const CountryRegionRouting: RouteObject = {
  path: COUNTRY_REGION_ROUTE,
  children: [
    {
      index: true,
      element: <CountryRegionPage />,
    },
  ],
};
