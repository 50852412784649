import { useQueryClient } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  useDataHierarchyMutation,
  useDataHierarchyQuery,
} from '@DataAccess/Core/DataHierarchy/dataHierarchy.hooks';
import { dataHierarchyKeys } from '@DataAccess/Core/DataHierarchy/dataHierarchy.keys';
import { useMultiAppStore } from '@GlobalProviders/MultiApp/MultiAppProvider';
import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';
import { UnsavedChangesDialog } from '@Pages/Shared/SmartComponents/UnsavedChangesDialog';
import { UiVersionMenu } from '@UiCommon/Components';
import { TabConfig } from '@UiCommon/Components/UiTabSwitcher';
import { PageWithSidebar, SectionObjectPage } from '@UiCommon/Layout';

import { HierarchyHeaderEditButtons } from './Components/HierarchyHeaderEditButtons';
import { DataHierarchy, HierarchyContext } from './Components/types';
import { useDhVersioningMenu } from './Hooks/useDhVersioningMenu';
import {
  HIERARCHY_MANAGER_BUILDING_FINANCE_ROUTE,
  HIERARCHY_MANAGER_CORPORATE_FINANCE_ROUTE,
  HIERARCHY_MANAGER_PHYSICAL_ASSET_FINANCE_ROUTE,
  HierarchyParams,
} from './Routing/routes';

const prependHierarchyId = (url: string, hierarchyId?: string) => {
  return hierarchyId ? `${hierarchyId}/${url}` : url;
};

export const GHGHierarchyManagerPage = () => {
  const { t } = useGHGTranslation();
  const { translatedProductInUse } = useMultiAppStore();
  const [isEditMode, setIsEditMode] = useState(false);
  const [draftCategories, setDraftCategories] = useState<Partial<DataHierarchy>>({});

  const { hierarchyId } = useParams<HierarchyParams>();

  const queryClient = useQueryClient();
  const { data: categories, isFetched, isRefetching } = useDataHierarchyQuery(hierarchyId);
  const { mutate: updateDH } = useDataHierarchyMutation();

  const tabsConfig: TabConfig[] = [
    {
      label: t('hierarchyManager.corporateFinance.title'),
      id: prependHierarchyId(HIERARCHY_MANAGER_CORPORATE_FINANCE_ROUTE, hierarchyId),
    },
    {
      label: t('hierarchyManager.buildingFinance.title'),
      id: prependHierarchyId(HIERARCHY_MANAGER_BUILDING_FINANCE_ROUTE, hierarchyId),
    },
    {
      label: t('hierarchyManager.physicalAssetFinance.title'),
      id: prependHierarchyId(HIERARCHY_MANAGER_PHYSICAL_ASSET_FINANCE_ROUTE, hierarchyId),
    },
  ];

  const hierarchyContext: HierarchyContext = {
    isFetched,
    isRefetching,
    categories,
    isEditMode,
    updateDraftCategories: (draftCategories) => setDraftCategories(draftCategories),
  };

  const isDataChanged = useMemo(() => {
    return !isEqual(categories, draftCategories);
  }, [categories, draftCategories]);

  const {
    Options,
    Filters,
    dhVersions,
    isFetched: isDhVersionMenuFetched,
    isLoading,
  } = useDhVersioningMenu();

  return (
    <>
      <PageWithSidebar
        showSidebar={!!hierarchyId}
        sidebar={
          <UiVersionMenu
            renderData={Options}
            paramId={hierarchyId}
            renderFilters={Filters}
            versions={dhVersions}
            isFetched={isDhVersionMenuFetched}
            isLoading={isLoading}
          />
        }
      >
        <SectionObjectPage
          isSubpage={!!hierarchyId}
          productName={translatedProductInUse}
          outletContext={hierarchyContext}
          defaultRoute={HIERARCHY_MANAGER_CORPORATE_FINANCE_ROUTE}
          title={t('hierarchyManager.title')}
          subtitle={t('hierarchyManager.subtitle')}
          renderCustomActions={() => (
            <HierarchyHeaderEditButtons
              isSubpage={!!hierarchyId}
              isEditMode={isEditMode}
              handleEdit={() => setIsEditMode(true)}
              handleSave={() => {
                isDataChanged && updateDH(draftCategories);
                setIsEditMode(false);
              }}
              onCancel={() => {
                queryClient.invalidateQueries({
                  queryKey: dataHierarchyKeys.orderOfUsage.all(hierarchyId),
                });
                setIsEditMode(false);
              }}
            />
          )}
          tabsConfig={tabsConfig}
        />
      </PageWithSidebar>
      <UnsavedChangesDialog isEditing={isEditMode} onContinue={() => setIsEditMode(false)} />
    </>
  );
};
