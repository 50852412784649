import { useMemo } from 'react';

import { createNumberFormatter } from '@Utils/Formatters/number';
import { NumberFormatterConfig } from '@Utils/Formatters/types';

import { CommonProps } from '../../types';
import { NumberFormatterWrapper } from './styles';

type Props = NumberFormatterConfig & {
  className?: CommonProps['className'];
  value?: number;
};

export const UiNumberFormatter = ({ className, decimalPrecision, notation, value }: Props) => {
  const formattedValue = useMemo(() => {
    const numberFormatter = createNumberFormatter({ decimalPrecision, notation });

    return value && numberFormatter.format(value);
  }, [value, decimalPrecision, notation]);

  return (
    <NumberFormatterWrapper className={className} title={String(value)}>
      {formattedValue}
    </NumberFormatterWrapper>
  );
};
