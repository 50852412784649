import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SearchParamsKeys } from '@DataAccess/types';
import { getSearchParam } from '@Utils/searchParamsUtils';
import { UiColumnFiltersType } from '@UiCommon/Components/UiAnalyticalTable/types';

type Props = {
  handleFiltersUpdate: (filters: UiColumnFiltersType[]) => void;
};

export const useSearchParamsSettings = ({ handleFiltersUpdate }: Props) => {
  const [externalFiltersApplied, setExternalFiltersApplied] = useState(false);
  const [isQueryReady, setIsQueryReady] = useState(false);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const filteringSearchParams = getSearchParam(SearchParamsKeys.FILTERS, searchParams);
    if (filteringSearchParams) {
      handleFiltersUpdate(filteringSearchParams);
      setExternalFiltersApplied(true);
    }
    setIsQueryReady(true);
  }, [searchParams]);

  return { externalFiltersApplied, isQueryReady };
};
