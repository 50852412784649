import styled from '@emotion/styled';
import { ButtonDesign } from '@ui5/webcomponents-react';

import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';
import { UiButton, UiDialog, UiDialogFooter, UiDialogHeader } from '@UiCommon/Components';
import { CommonProps } from '@UiCommon/types';

export type CreateRuleModalProps = {
  isOpen: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
  error?: string;
  children?: CommonProps['children'];
};
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1rem;
`;

export const CreateRuleModal = ({
  isOpen,
  onClose,
  onSubmit,
  error,
  children,
}: CreateRuleModalProps) => {
  const { t } = useGHGTranslation();

  return (
    <UiDialog
      isOpen={isOpen}
      header={<UiDialogHeader startContent={t('splitScope.createRule')}></UiDialogHeader>}
      footer={
        <UiDialogFooter
          endContent={
            <>
              <UiButton onClick={onSubmit} disabled={!!error} design={ButtonDesign.Emphasized}>
                {t('splitScope.save')}
              </UiButton>
              <UiButton onClick={onClose} design={ButtonDesign.Transparent}>
                {t('splitScope.cancel')}
              </UiButton>
            </>
          }
        ></UiDialogFooter>
      }
    >
      <ContentWrapper>{children}</ContentWrapper>
    </UiDialog>
  );
};
