import { useCallback, useState } from 'react';

import { UiListItemType } from '../../../UiList/types';
import { SortingOrder, UiAnalyticalTableColumnDefinition } from '../../types';
import { SortingOption } from './types';

export type SortingListItemProps = {
  order?: SortingOrder;
};

type SettingsStateControls<DataType> = {
  options: SortingOption[];
  sortingListItems: UiListItemType<SortingListItemProps>[];
  setSortingListItems: (sortingItems: UiListItemType<SortingListItemProps>[]) => void;
  getDataFromState: () => UiListItemType<SortingListItemProps>[];
  reset: () => void;
  initStateWithData: (
    columns: UiAnalyticalTableColumnDefinition<DataType>[],
    sortingItems?: UiListItemType<SortingListItemProps>[],
  ) => void;
};

export const useSortingSettingsState = <DataType>(): SettingsStateControls<DataType> => {
  const [sortingListItems, setSortingListItems] = useState<UiListItemType<SortingListItemProps>[]>(
    [],
  );

  const [options, setOptions] = useState<SortingOption[]>([]);

  // STATE METHODS:
  const reset = useCallback(() => {
    setSortingListItems([]);
  }, [setSortingListItems]);

  const initStateWithData = useCallback(
    (
      columns: UiAnalyticalTableColumnDefinition<DataType>[] = [],
      sortingItems: UiListItemType<SortingListItemProps>[] = [],
    ) => {
      const columnItems = columns
        .filter((item) => !item.disableSortBy)
        .map((item) => ({ label: item.label, value: item.id }));
      setSortingListItems(sortingItems);
      setOptions(columnItems);
    },
    [setOptions, setSortingListItems],
  );

  const getDataFromState = useCallback(() => sortingListItems, [sortingListItems]);

  return {
    options,
    initStateWithData,
    sortingListItems,
    getDataFromState,
    setSortingListItems,
    reset,
  };
};
