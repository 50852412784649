import { useMemo } from 'react';

import { useProcessManagerTranslation } from '@GlobalProviders/i18n/hooks';

import { useCommonCollectedColumns } from '../useCommonCollectedColumns';

export const useCountryRegionColumns = () => {
  const { t } = useProcessManagerTranslation();
  const commonColumns = useCommonCollectedColumns({ hideBusinessValidity: true });

  return useMemo(
    () => [
      {
        label: t('processManager.manualUpload.countryRegion.table.countryRegion'),
        accessor: 'countryRegion',
        id: 'countryRegion',
        isVisible: true,
        beField: 'COUNTRY_REGION',
      },
      {
        label: t('processManager.manualUpload.countryRegion.table.region'),
        accessor: 'region',
        id: 'region',
        isVisible: true,
        beField: 'REGION',
      },
      {
        label: t('processManager.manualUpload.countryRegion.table.location'),
        accessor: 'location',
        id: 'location',
        isVisible: true,
        beField: 'LOCATION',
      },
      ...commonColumns,
    ],
    [t, commonColumns],
  );
};
