import { Navigate } from 'react-router-dom';

import { KpiRouting } from '@GlobalProviders/MultiApp/KpiRouting';
import { KPIRoutingFactory } from '@Pages/Shared/Routing/KPIRoutingFactory';

import { PRSensitivityFlagsRouting } from './SensitivityFlags/Routing/PRSensitivityFlagsRouting';
import { SENSITIVITY_FLAGS_ROUTE } from './SensitivityFlags/Routing/routes';

export const CustomizationPRRouting = KPIRoutingFactory(
  [{ index: true, element: <Navigate to={SENSITIVITY_FLAGS_ROUTE} /> }, PRSensitivityFlagsRouting],
  KpiRouting.PHYSICAL_RISK,
);
