import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { ThemingParameters } from '@ui5/webcomponents-react-base';

import { FontWeight } from '@UiCommon/typography';

import { CommonProps } from '../../../types';
import { UiText } from '../../../Components/UiText';
import { UiTitle } from '../../../Components/UiTitle';

const HostWrapper = styled.header`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1.375rem;
  margin-bottom: 2rem;
`;
const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const NavigationArea = styled.div``;

const TitleArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 8px;
`;

const ActionsArea = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
`;

type Props = CommonProps & {
  title: string;
  productName: string;
  subtitle?: string;
  renderCustomActions?: () => ReactNode | ReactNode[];
  renderNavigationElements?: () => ReactNode | ReactNode[];
};

export const GenericHeader = ({
  title,
  productName,
  subtitle,
  renderCustomActions,
  renderNavigationElements,
}: Props) => (
  <HostWrapper>
    <NavigationArea>{renderNavigationElements?.()}</NavigationArea>
    <MainContentWrapper>
      <TitleArea>
        <UiText
          color={ThemingParameters.sapContent_LabelColor}
          variant='caption1'
          weight={FontWeight.BOLD}
        >
          {productName.toUpperCase()}
        </UiText>
        <UiTitle>{title}</UiTitle>
        <UiText>{subtitle}</UiText>
      </TitleArea>
      <ActionsArea>{renderCustomActions?.()}</ActionsArea>
    </MainContentWrapper>
  </HostWrapper>
);
