import styled from '@emotion/styled';

import { UiListItemType } from '@UiCommon/Components/UiList/types';
import { CommonProps } from '@UiCommon/types';

import { HierarchyAddSourceDialog } from './HierarchyAddSourceDialog';
import { HierarchyDraggableColumn } from './HierarchyDraggableColumn';
import { HierarchyEditSourceDialog } from './HierarchyEditSourceDialog';
import { DataHierarchyData, Subcategories, SubcategoryProps } from './types';
import { useDHSourceActions } from './useDHSourceActions';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
`;

type Props = CommonProps & {
  subcategories?: Subcategories;
  handleItemsUpdate: (items: UiListItemType<DataHierarchyData>[], resourceId?: string) => void;
  isEditMode?: boolean;
};

export const HierarchyManager = ({ subcategories, handleItemsUpdate, isEditMode }: Props) => {
  const {
    sourceForm,
    resourceIdToAdd,
    onCloseAddSourceDialog,
    onCloseEditSourceDialog,
    handleAddSource,
    handleEdit,
    handleEditSource,
    handleNewDataSource,
    handleOnDelete,
    checkIfSourceAlreadyExists,
  } = useDHSourceActions({
    subcategories,
    handleItemsUpdate,
  });

  return (
    <>
      <Wrapper>
        {Object.entries<SubcategoryProps>(subcategories ?? {}).map(([key, subcategory]) => {
          return (
            <HierarchyDraggableColumn
              key={key}
              resourceId={key}
              subcategoryTitle={subcategory.subcategoryTitle}
              scoreNumbering={subcategory.scoreNumbering}
              items={subcategory.items}
              onOrderChange={handleItemsUpdate}
              isEditMode={isEditMode}
              onEditSource={handleEditSource(subcategory.items, key)}
              onAddSource={handleAddSource}
            />
          );
        })}
      </Wrapper>
      <HierarchyAddSourceDialog
        isOpen={!!resourceIdToAdd}
        onConfirm={handleNewDataSource}
        onClose={onCloseAddSourceDialog}
        isInputInvalid={checkIfSourceAlreadyExists}
      />
      <HierarchyEditSourceDialog
        isOpen={!!sourceForm}
        sourceForm={sourceForm}
        onConfirm={handleEdit}
        onDelete={handleOnDelete}
        onClose={onCloseEditSourceDialog}
        isInputInvalid={checkIfSourceAlreadyExists}
      />
    </>
  );
};
