import { RouteObject } from 'react-router-dom';

import { ProjectPage } from '../ProjectPage';
import { PROJECT_ROUTE } from './routes';

export const ProjectRouting: RouteObject = {
  path: PROJECT_ROUTE,
  children: [
    {
      index: true,
      element: <ProjectPage />,
    },
  ],
};
