import styled from '@emotion/styled';
import { CustomListItem } from '@ui5/webcomponents-react';
import { ThemingParameters } from '@ui5/webcomponents-react-base';
import { useMemo } from 'react';

import { UiListItemType } from '../../../../UiList/types';
import { UiSelect } from '../../../../UiSelect/UiSelect';
import { UiSelectOption } from '../../../../UiSelect/UiSelectOption';
import { SortingOrder } from '../../../types';
import { useSettingsModalContext } from '../../SettingsModalContext';
import { SortingListItemProps } from '../useSortingSettingsState';
import { SortingButtons } from './SortingButtons';
import { ContentWrapper, SortingItemWrapper, smallSelectClass } from './styles';

const StyledCustomListItem = styled(CustomListItem)`
  --sapContent_FocusWidth: 0;
  --_ui5_checkbox_focus_outline_display: none !important;

  border-bottom: 1px solid ${ThemingParameters.sapList_BorderColor};
`;

export const PlaceholderSortingListItem = () => {
  const { sortingOptions, sortingItems, onSortingItemsChange } = useSettingsModalContext();
  const handleNewItemAdd = (value?: string) => {
    if (value && sortingItems) {
      const newSortingItem: UiListItemType<SortingListItemProps> = {
        value,
        label: value,
        data: { order: SortingOrder.ASC },
      };
      onSortingItemsChange?.([...sortingItems, newSortingItem]);
    }
  };

  const filteredOptions = useMemo(
    () =>
      sortingOptions?.filter(
        (option) => !sortingItems?.some((item) => item.value === option.value),
      ),
    [sortingItems, sortingOptions],
  );

  return (
    <StyledCustomListItem>
      <SortingItemWrapper>
        <ContentWrapper>
          <UiSelect className={smallSelectClass} onChange={handleNewItemAdd}>
            <UiSelectOption value={undefined}>(none)</UiSelectOption>
            {filteredOptions?.map((item) => (
              <UiSelectOption key={item.value} value={item.value}>
                {item.label}
              </UiSelectOption>
            ))}
          </UiSelect>
          <SortingButtons disabled />
        </ContentWrapper>
      </SortingItemWrapper>
    </StyledCustomListItem>
  );
};
