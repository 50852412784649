import { format } from 'date-fns';

interface DateTimeFormatterConfig {
  pattern?: string;
}

export const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm';

export const createDateFormatter = ({
  pattern = DATE_TIME_FORMAT,
}: DateTimeFormatterConfig = {}) => {
  return {
    format: (date: Date | number) => format(date, pattern),
  };
};
