import { RouteObject } from 'react-router-dom';

import { FinancialInstrumentPage } from '../FinancialInstrumentPage';
import { FINANCIAL_INSTRUMENT_ROUTE } from './routes';

export const FinancialInstrumentRouting: RouteObject = {
  path: FINANCIAL_INSTRUMENT_ROUTE,
  children: [
    {
      index: true,
      element: <FinancialInstrumentPage />,
    },
  ],
};
