import { AxiosInstance } from 'axios';

import { PaginatedTableParams, PaginationResponse } from '../../types';
import {
  CollectedBusinessPartnerDto,
  CollectedProjectDto,
  CollectedBuildingDto,
  CollectedFinancialContractDto,
  CollectedFinancialInstrumentDto,
  CollectedPhysicalAssetDto,
  CollectedIndustryClassificationCodeDto,
  CollectedReportedGoodsDto,
  CollectedEmissionBuildingCertificatesDto,
  CollectedEmissionFactorNumberOfBuildingsDto,
  CollectedBuildingFinancialContractAssociationDto,
  CollectedPhysicalAssetFinancialContractAssociationDto,
  CollectedEmissionFactorFloorAreaDto,
  CollectedEmissionFactorSectorDto,
  CollectedAssetValuationDto,
  CollectedReportedEnergyDto,
  CollectedCountryRegionDto,
  CollectedEmissionFactorGoodsDto,
  CollectedEmissionFactorEnergyDto,
  CollectedBPAddressDto,
  CollectedProjectAddressDto,
  CollectedPAAddressDto,
} from './GHGFiles.types';

export const initialiseGhgFilesEndpoints = (api: AxiosInstance) => ({
  getCollectedBusinessPartner({
    tableParams,
    pageParam,
  }: PaginatedTableParams): Promise<PaginationResponse<CollectedBusinessPartnerDto>> {
    return api
      .post('collected/business-partner', {
        page: pageParam,
        ...tableParams,
      })
      .then(({ data }) => data);
  },
  getCollectedProject({
    tableParams,
    pageParam,
  }: PaginatedTableParams): Promise<PaginationResponse<CollectedProjectDto>> {
    return api
      .post('collected/project', {
        page: pageParam,
        ...tableParams,
      })
      .then(({ data }) => data);
  },
  getCollectedBuilding({
    tableParams,
    pageParam,
  }: PaginatedTableParams): Promise<PaginationResponse<CollectedBuildingDto>> {
    return api
      .post('collected/building', {
        page: pageParam,
        ...tableParams,
      })
      .then(({ data }) => data);
  },
  getCollectedFinancialContract({
    tableParams,
    pageParam,
  }: PaginatedTableParams): Promise<PaginationResponse<CollectedFinancialContractDto>> {
    return api
      .post('collected/financial-contract', {
        page: pageParam,
        ...tableParams,
      })
      .then(({ data }) => data);
  },
  getCollectedFinancialInstrument({
    tableParams,
    pageParam,
  }: PaginatedTableParams): Promise<PaginationResponse<CollectedFinancialInstrumentDto>> {
    return api
      .post('collected/financial-instrument', {
        page: pageParam,
        ...tableParams,
      })
      .then(({ data }) => data);
  },
  getCollectedPhysicalAsset({ tableParams, pageParam }: PaginatedTableParams) {
    return api
      .post<PaginationResponse<CollectedPhysicalAssetDto>>('collected/physical-asset', {
        page: pageParam,
        ...tableParams,
      })
      .then(({ data }) => data);
  },
  getCollectedIndustryClassificationCode({ tableParams, pageParam }: PaginatedTableParams) {
    return api
      .post<PaginationResponse<CollectedIndustryClassificationCodeDto>>(
        'collected/industry-classification-code',
        {
          page: pageParam,
          ...tableParams,
        },
      )
      .then(({ data }) => data);
  },
  getCollectedReportedGoods({ tableParams, pageParam }: PaginatedTableParams) {
    return api
      .post<PaginationResponse<CollectedReportedGoodsDto>>('collected/reported-goods', {
        page: pageParam,
        ...tableParams,
      })
      .then(({ data }) => data);
  },
  getCollectedEmissionBuildingCertificates({ tableParams, pageParam }: PaginatedTableParams) {
    return api
      .post<PaginationResponse<CollectedEmissionBuildingCertificatesDto>>(
        'collected/emission-factor-building-certificate-class',
        {
          page: pageParam,
          ...tableParams,
        },
      )
      .then(({ data }) => data);
  },
  getCollectedEmissionFactorNumberOfBuildings({ tableParams, pageParam }: PaginatedTableParams) {
    return api
      .post<PaginationResponse<CollectedEmissionFactorNumberOfBuildingsDto>>(
        'collected/emission-factor-number-of-buildings',
        {
          page: pageParam,
          ...tableParams,
        },
      )
      .then(({ data }) => data);
  },
  getCollectedBuildingFinancialContractAssociation({
    tableParams,
    pageParam,
  }: PaginatedTableParams) {
    return api
      .post<PaginationResponse<CollectedBuildingFinancialContractAssociationDto>>(
        'collected/building-to-financial-contract',
        {
          page: pageParam,
          ...tableParams,
        },
      )
      .then(({ data }) => data);
  },
  getCollectedPhysicalAssetFinancialContractAssociation({
    tableParams,
    pageParam,
  }: PaginatedTableParams) {
    return api
      .post<PaginationResponse<CollectedPhysicalAssetFinancialContractAssociationDto>>(
        'collected/physical-asset-to-financial-contract',
        {
          page: pageParam,
          ...tableParams,
        },
      )
      .then(({ data }) => data);
  },
  getCollectedEmissionFactorFloorArea({ tableParams, pageParam }: PaginatedTableParams) {
    return api
      .post<PaginationResponse<CollectedEmissionFactorFloorAreaDto>>(
        'collected/emission-factor-floor-area',
        {
          page: pageParam,
          ...tableParams,
        },
      )
      .then(({ data }) => data);
  },
  getCollectedEmissionFactorSector({ tableParams, pageParam }: PaginatedTableParams) {
    return api
      .post<PaginationResponse<CollectedEmissionFactorSectorDto>>(
        'collected/emission-factor-sector',
        {
          page: pageParam,
          ...tableParams,
        },
      )
      .then(({ data }) => data);
  },
  getCollectedAssetValuation({
    tableParams,
    pageParam,
  }: PaginatedTableParams): Promise<PaginationResponse<CollectedAssetValuationDto>> {
    return api
      .post('collected/asset-valuation', {
        page: pageParam,
        ...tableParams,
      })
      .then(({ data }) => data);
  },
  getCollectedReportedEnergy({ tableParams, pageParam }: PaginatedTableParams) {
    return api
      .post<PaginationResponse<CollectedReportedEnergyDto>>('collected/reported-energy', {
        page: pageParam,
        ...tableParams,
      })
      .then(({ data }) => data);
  },
  getCollectedCountryRegion({ tableParams, pageParam }: PaginatedTableParams) {
    return api
      .post<PaginationResponse<CollectedCountryRegionDto>>('collected/country-region', {
        page: pageParam,
        ...tableParams,
      })
      .then(({ data }) => data);
  },
  getCollectedEmissionFactorGoods({ tableParams, pageParam }: PaginatedTableParams) {
    return api
      .post<PaginationResponse<CollectedEmissionFactorGoodsDto>>(
        'collected/emission-factor-goods',
        {
          page: pageParam,
          ...tableParams,
        },
      )
      .then(({ data }) => data);
  },
  getCollectedEmissionFactorEnergy({ tableParams, pageParam }: PaginatedTableParams) {
    return api
      .post<PaginationResponse<CollectedEmissionFactorEnergyDto>>(
        'collected/emission-factor-energy',
        {
          page: pageParam,
          ...tableParams,
        },
      )
      .then(({ data }) => data);
  },
  getCollectedBPAddress({ tableParams, pageParam }: PaginatedTableParams) {
    return api
      .post<PaginationResponse<CollectedBPAddressDto>>('collected/business-partner-address', {
        page: pageParam,
        ...tableParams,
      })
      .then(({ data }) => data);
  },
  getCollectedProjectAddress({ tableParams, pageParam }: PaginatedTableParams) {
    return api
      .post<PaginationResponse<CollectedProjectAddressDto>>('collected/project-address', {
        page: pageParam,
        ...tableParams,
      })
      .then(({ data }) => data);
  },
  getCollectedPhysicalAssetAddress({ tableParams, pageParam }: PaginatedTableParams) {
    return api
      .post<PaginationResponse<CollectedPAAddressDto>>('collected/physical-asset-address', {
        page: pageParam,
        ...tableParams,
      })
      .then(({ data }) => data);
  },
});
