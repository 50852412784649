import { useAuth } from 'oidc-react';
import { useEffect } from 'react';

import { axiosFactory } from '@DataAccess/axiosFactory';
import { CommonProps } from '@UiCommon/types';

type Props = CommonProps & {
  disabled?: boolean;
};

export const AppGuard = ({ children, disabled }: Props) => {
  const App = <>{children}</>;

  const auth = useAuth();

  useEffect(() => {
    auth.userManager.events.addUserLoaded((user) => {
      axiosFactory.token = user.access_token;
    });

    const onActiveWindow = () => {
      if (document.hidden) return;

      auth.userManager.signinSilent().catch(() => auth.signOut());
    };

    document.addEventListener('visibilitychange', onActiveWindow);
  }, []);

  useEffect(() => {
    axiosFactory.token = auth.userData?.access_token || null;
  }, [auth.userData?.access_token]);

  if (disabled) return App;

  if (!auth || !auth.userData) return null;

  return App;
};
