import { useMemo } from 'react';
import { chain, cloneDeep, isEqual } from 'lodash';

import { SaveManageViewsProps } from '../../../UiVariantManagement/useUiVariantManagementCallbacks';
import { UiAnalyticalTableColumnDefinition } from '../../types';
import { ViewsShape, ViewSettings, ViewsDictionary, VariantProps } from '../types';
import { useViewsInitializer } from '../useViewsInitializer';
import { useViewsReducer, setState, updateViews, setSelectedView } from '../useViewsReducer';

const setLocalStorageValue = <DataType>(key: string, value: ViewsShape<DataType>) => {
  localStorage.setItem(key, JSON.stringify(value));
};

type ChangeStateHandlerProps<DataType> = {
  views: ViewsDictionary<DataType>;
  defaultView: string;
  selectedView: string;
  settings?: ViewSettings<DataType>;
};

export type ViewsManagementProps<DataType> = {
  settings: ViewSettings<DataType>;
  defaultColumns: UiAnalyticalTableColumnDefinition<DataType>[];
  onSettingsChange: ({ filters, columns, sorting }: ViewSettings<DataType>) => void;
  onLoadInitialize: ({ filters, columns, sorting }: ViewSettings<DataType>) => void;
  localStorageKey: string;
  loadStandardView?: boolean;
};

export const useViewsManagement = <DataType>({
  localStorageKey,
  settings,
  onSettingsChange,
  onLoadInitialize,
  defaultColumns,
  loadStandardView,
}: ViewsManagementProps<DataType>) => {
  const [state, dispatch] = useViewsReducer<DataType>();
  const { views, defaultView, selectedView } = state;

  const handleInitialize = ({
    views,
    defaultView,
    selectedView,
    settings,
  }: ChangeStateHandlerProps<DataType>) => {
    dispatch(
      setState({
        views,
        defaultView,
        selectedView,
      }),
    );
    settings && onLoadInitialize(cloneDeep(settings));
  };

  useViewsInitializer({
    localStorageKey,
    defaultColumns,
    onStorageLoad: handleInitialize,
    loadStandardView,
  });

  const variants = useMemo((): VariantProps[] => {
    return Object.values(views).map((item) => ({
      favorite: item.favorite,
      global: item.global,
      name: item.name,
      readOnly: item.readOnly,
      labelReadOnly: item.labelReadOnly,
    }));
  }, [views]);

  const getViewByName = (name?: string) => {
    if (name && views) return views[name];
  };

  const handleSaveAs = ({ name, isDefault }: { name: string; isDefault?: boolean }) => {
    const newView = {
      [name]: {
        name,
        favorite: isDefault,
        settings: cloneDeep(settings),
      },
    };
    let defaultViewName = defaultView;
    if (isDefault) {
      defaultViewName = name;
    }
    dispatch(
      setState({
        selectedView: name,
        defaultView: defaultViewName,
        views: { ...views, ...newView },
      }),
    );

    setLocalStorageValue(localStorageKey, {
      views: { ...views, ...newView },
      defaultView: defaultViewName,
    });
  };
  const handleSave = (name: string) => {
    const savedItem = getViewByName(name);
    const newView = { [name]: { ...savedItem, name, settings: cloneDeep(settings) } };
    dispatch(updateViews(newView));

    setLocalStorageValue(localStorageKey, {
      views: {
        ...views,
        ...newView,
      },
      defaultView,
    });
  };
  const handleSaveManageViews = ({
    variants,
    deletedVariants,
    updatedVariants,
  }: SaveManageViewsProps) => {
    const viewDictionary = chain(variants)
      .map((variant) => ({
        name: variant.children,
        readOnly: variant.readOnly,
        labelReadOnly: variant.labelReadOnly,
        favorite: variant.favorite || variant.isDefault,
        global: variant.global,
        settings: getViewByName(variant.children)?.settings,
      }))
      .keyBy('name')
      .value();

    let defaultViewName = variants.find((variant) => variant.isDefault)?.children || defaultView;
    let selectedViewName = selectedView;

    if (deletedVariants.length > 0) {
      const deletedSelectedItem = deletedVariants.find((item) => item.selected);
      selectedViewName = deletedSelectedItem?.isDefault ? variants[0].children : defaultView;
      defaultViewName = deletedSelectedItem?.isDefault ? variants[0].children : defaultViewName;
    }

    if (updatedVariants.length > 0) {
      updatedVariants.forEach((item) => {
        if (item.selected) {
          selectedViewName = item.children;
          defaultViewName = item.isDefault ? item.children : defaultViewName;
        }
        viewDictionary[item.children].settings = getViewByName(
          item.prevVariant?.children,
        )?.settings;
      });
    }

    dispatch(
      setState({
        selectedView: selectedViewName,
        defaultView: defaultViewName,
        views: viewDictionary,
      }),
    );

    const selectedViewSettings = viewDictionary[selectedViewName].settings;
    selectedViewSettings && onSettingsChange(cloneDeep(selectedViewSettings));
    setLocalStorageValue(localStorageKey, {
      views: viewDictionary,
      defaultView: defaultViewName,
    });
  };
  const handleSelect = (name: string) => {
    dispatch(setSelectedView(name));
    const viewSettings = getViewByName(name)?.settings;
    viewSettings && onSettingsChange(viewSettings);
  };

  const isDirty = useMemo(() => {
    return !isEqual(getViewByName(selectedView)?.settings, settings);
  }, [settings, selectedView]);

  return {
    views,
    dispatch,
    variants,
    selectedView,
    defaultView,
    getViewByName,
    handleSave,
    handleSaveAs,
    handleSaveManageViews,
    handleSelect,
    isDirty,
  };
};
