import api from '../../api';
import { PaginatedRqHookProps } from '../../types';
import { useTablePaginatedQuery } from '../../utils';
import { PRFiles } from './PRFiles.keys';
import { CollectedLocationScoreDto } from './PRFiles.types';

export const useCollectedLocationScoreQuery = (
  rest: PaginatedRqHookProps<CollectedLocationScoreDto> = {},
) => {
  return useTablePaginatedQuery<CollectedLocationScoreDto>({
    apiCall: api.physicalRisk.files.getCollectedLocationScore,
    key: PRFiles.collectedLocationScore.list,
    ...rest,
  });
};
