import styled from '@emotion/styled';

import { UiFiltersBar, UiViewsManagement } from '@UiCommon/Components';
import { getViewsLocalStorageKey } from '@UiCommon/Components/UiAnalyticalTable/utils';
import { ViewSettings } from '@UiCommon/Components/UiAnalyticalTable/ViewsManagement/types';
import { useViewsManagement } from '@UiCommon/Components/UiAnalyticalTable/ViewsManagement/UseViewsManagement/useViewsManagement';

import { SmartAnalyticalProps } from '../SmartAnalyticalTableCard';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

type Props<T = Record<string, unknown>> = {
  tableId: string;
  defaultColumns: SmartAnalyticalProps<T>['columns'];
  columns: SmartAnalyticalProps<T>['columns'];
  onFilterBarClick: () => void;
  onSettingsChange: ({ filters, columns, sorting }: ViewSettings<T>) => void;
  filters?: SmartAnalyticalProps<T>['filters'];
  sorting?: SmartAnalyticalProps<T>['sorting'];
  hasViewsManager?: boolean;
  isFiltersBarVisible?: boolean;
  loadStandardView?: boolean;
  onLoadInitialize: ({ filters, columns, sorting }: ViewSettings<T>) => void;
};

export const SmartCardHeader = <T,>({
  tableId,
  defaultColumns,
  columns,
  onFilterBarClick,
  onSettingsChange,
  onLoadInitialize,
  filters,
  sorting,
  hasViewsManager,
  isFiltersBarVisible,
  loadStandardView,
}: Props<T>) => {
  const {
    variants,
    defaultView,
    selectedView,
    handleSave,
    handleSaveAs,
    handleSaveManageViews,
    handleSelect,
    isDirty,
  } = useViewsManagement({
    localStorageKey: getViewsLocalStorageKey(tableId),
    defaultColumns,
    settings: { columns, sorting, filters },
    onSettingsChange,
    onLoadInitialize,
    loadStandardView,
  });

  return (
    <Wrapper>
      {hasViewsManager && (
        <UiViewsManagement
          variants={variants}
          defaultView={defaultView}
          selectedView={selectedView}
          handleSave={handleSave}
          handleSaveAs={handleSaveAs}
          handleSaveManageViews={handleSaveManageViews}
          handleSelect={handleSelect}
          isDirty={isDirty}
        />
      )}
      {isFiltersBarVisible && (
        <Wrapper>
          <UiFiltersBar columns={columns} filters={filters} onClickHandler={onFilterBarClick} />
        </Wrapper>
      )}
    </Wrapper>
  );
};
