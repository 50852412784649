import { useMemo } from 'react';

import { usePRTranslation } from '@GlobalProviders/i18n/hooks';

import { usePhysicalAssetInputAddressColumns } from '../../../Hooks/Columns/usePhysicalAssetInputAddressColumns';
import { usePhysicalAssetOutputAddressColumns } from '../../../Hooks/Columns/usePhysicalAssetOutputAddressColumns';
import { usePhysicalAssetChronicRootColumns } from './usePhysicalAssetChronicRootColumns';

export const usePhysicalAssetAcuteColumns = () => {
  const { t } = usePRTranslation();

  const rootColumns = usePhysicalAssetChronicRootColumns();
  const inputAddressColumns = usePhysicalAssetInputAddressColumns();
  const outputAddressColumns = usePhysicalAssetOutputAddressColumns();

  return useMemo(() => {
    return [...inputAddressColumns, ...outputAddressColumns, ...rootColumns];
  }, [t]);
};
