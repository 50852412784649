import { NumberFormatterConfig } from '@Utils/Formatters/types';

import { DEFAULT_LOCALE } from './constants';

export const createNumberFormatter = ({
  lng = DEFAULT_LOCALE,
  decimalPrecision = 6,
  notation,
}: NumberFormatterConfig) => {
  return new Intl.NumberFormat(lng, {
    minimumFractionDigits: decimalPrecision,
    maximumFractionDigits: decimalPrecision,
    notation,
  });
};
