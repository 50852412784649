import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { CommonProps } from '@ui5/webcomponents-react';

import {
  BpSplitScope,
  BuildingSplitScope,
  ProjectSplitScope,
  SplitRatio,
} from '@DataAccess/CoreLib/SplitRatio/splitRatio.types';
import {
  useSplitRatioQuery,
  useSplitRatioMutation,
} from '@DataAccess/CoreLib/SplitRatio/splitRatio.hooks';

import { SplitScopeStateReturnType, useSplitScopeState } from './useSplitScopeState';

interface SplitScopeContextProps {
  businessPartner: SplitScopeStateReturnType<BpSplitScope>;
  project: SplitScopeStateReturnType<ProjectSplitScope>;
  building: SplitScopeStateReturnType<BuildingSplitScope>;
  isEditMode: boolean;
  hasErrors: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  handleSaveSplitRatios: (newItems?: Partial<SplitRatio>) => void;
  resetState: () => void;
}

const initialState = {
  state: {
    list: [],
    errors: { splitErrors: new Set<number>(), countryErrors: new Set<number>() },
  },

  actions: {
    updateCell: () => {},
    updateSplitScopeCell: () => {},
    updateCountryCell: () => {},
    addRule: () => {},
    setListItems: () => {},
    moveListItem: () => {},
    reorderItems: () => {},
  },
};

const SplitScopeContext = createContext<SplitScopeContextProps>({
  businessPartner: initialState,
  project: initialState,
  building: initialState,
  hasErrors: false,
  isEditMode: false,
  setIsEditMode: () => {},
  handleSaveSplitRatios: () => {},
  resetState: () => {},
});

const SplitScopeProvider = ({ children }: CommonProps) => {
  const businessPartner = useSplitScopeState<BpSplitScope>();
  const project = useSplitScopeState<ProjectSplitScope>();
  const building = useSplitScopeState<BuildingSplitScope>();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const { data } = useSplitRatioQuery();
  const { mutate: updateSplitRatio } = useSplitRatioMutation();

  const setInitialState = (data?: SplitRatio) => {
    businessPartner.actions.setListItems(data?.businessPartnerSplitRatios ?? []);
    project.actions.setListItems(data?.projectSplitRatios ?? []);
    building.actions.setListItems(data?.buildingSplitRatios ?? []);
  };

  useEffect(() => {
    setInitialState(data);
  }, [data]);

  const hasErrors = useMemo(() => {
    return (
      businessPartner.state.errors.splitErrors.size > 0 ||
      project.state.errors.splitErrors.size > 0 ||
      building.state.errors.splitErrors.size > 0 ||
      businessPartner.state.errors.countryErrors.size > 0 ||
      project.state.errors.countryErrors.size > 0 ||
      building.state.errors.countryErrors.size > 0
    );
  }, [businessPartner, project, building]);

  const handleSaveSplitRatios = (newItems?: Partial<SplitRatio>) => {
    updateSplitRatio({
      buildingSplitRatios: [...(newItems?.buildingSplitRatios ?? []), ...building.state.list],
      businessPartnerSplitRatios: [
        ...(newItems?.businessPartnerSplitRatios ?? []),
        ...businessPartner.state.list,
      ],
      projectSplitRatios: [...(newItems?.projectSplitRatios ?? []), ...project.state.list],
    });
    setIsEditMode(false);
  };

  const contextValue = useMemo(
    () => ({
      businessPartner,
      hasErrors,
      project,
      building,
      setIsEditMode,
      isEditMode,
      handleSaveSplitRatios,
      resetState: () => setInitialState(data),
    }),
    [businessPartner, project, building, isEditMode, setIsEditMode],
  );

  return <SplitScopeContext.Provider value={contextValue}>{children}</SplitScopeContext.Provider>;
};

const useSplitScopeContext = () => {
  const context = useContext(SplitScopeContext);
  if (context === undefined) {
    throw new Error('useSplitScopeContext must be used within a SplitScopeProvider');
  }

  return context;
};

export { SplitScopeContext, SplitScopeProvider, useSplitScopeContext };
