import { useCallback, useMemo, useState } from 'react';
import { debounce } from 'lodash';

import { UiListItemType } from '../../../UiList/types';

export const useColumnsSettingsFilter = (columns?: UiListItemType[]) => {
  const [filterPhrase, setFilterPhrase] = useState<string>();

  const filter = useCallback(
    debounce((phrase?: string) => setFilterPhrase(phrase), 500),
    [setFilterPhrase],
  );

  const clearFilter = useCallback(() => setFilterPhrase(undefined), [setFilterPhrase]);

  const filteredColumns = useMemo(
    () =>
      filterPhrase
        ? columns?.filter(({ label }) => label.toLowerCase().includes(filterPhrase.toLowerCase()))
        : columns,
    [filterPhrase, columns],
  );

  return {
    filteredColumns,
    filter,
    filterPhrase,
    clearFilter,
  };
};
