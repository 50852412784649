import { CustomListItem } from '@ui5/webcomponents-react';
import React from 'react';
import { ThemingParameters } from '@ui5/webcomponents-react-base';
import styled from '@emotion/styled';

import { IconSet } from '@GlobalProviders/IconSetProvider';

import { UiIcon } from '../../UiIcon';
import { UiText } from '../../UiText';

const FlexWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;

const CustomListWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const StyledIcon = styled(UiIcon)`
  color: ${ThemingParameters.sapNeutralColor};
`;

type Props = {
  onClick: () => void;
  text: string;
  iconName: IconSet;
};

export const HeaderCustomListItem = ({ onClick, iconName, text }: Props) => {
  return (
    <CustomListItem onClick={onClick}>
      <CustomListWrapper>
        <FlexWrapper>
          <StyledIcon name={iconName} />
          <UiText>{text}</UiText>
        </FlexWrapper>
        <StyledIcon name={IconSet.SLIM_ARROW_RIGHT} />
      </CustomListWrapper>
    </CustomListItem>
  );
};
