import { isNil } from 'lodash';
import i18next, { TFunction } from 'i18next';

import { MoveDirection, NestedKeyOf } from '@Types/types';
import { UiAnalyticalTableColumnDefinition, UiTableColumnProps } from '@UiCommon/Components';
import { FileExtensions } from '@UiCommon/types';
import translation from '@GlobalProviders/i18n/locales/en/translationGHG';

export const CSV_BLOB_TYPE = 'text/csv;charset=utf-8;';

export const getNumberFromString = (text: string) => {
  const regexMatch = text.match(/\d+/);
  if (!regexMatch) return null;

  const [numberToConvert] = regexMatch;

  return parseInt(numberToConvert);
};

export const computeTotalRowData = <T extends string>(
  data: Record<string, unknown>[],
  fieldsToSum: T[],
) => {
  const out = fieldsToSum.reduce((acc, field) => {
    const sum = data.reduce(
      (totalField, rowObject) => totalField + ((rowObject[field] as number) ?? 0),
      0,
    );
    acc[field] = sum;

    return acc;
  }, {} as Record<T, number>);

  return out;
};

export const toMap = <DataType, Prop extends keyof DataType>(
  collection: DataType[],
  keyProp: Prop,
) => {
  const map = new Map<DataType[Prop], DataType>();

  return collection.reduce((acc, nextElement) => {
    acc.set(nextElement[keyProp], nextElement);

    return acc;
  }, map);
};

export const moveListItem =
  <DataType, Prop extends keyof DataType>(collection: DataType[], findByProp: Prop) =>
  (propValue: DataType[Prop], direction: MoveDirection) => {
    const elementIndex = collection.findIndex((el) => el[findByProp] === propValue);

    if (elementIndex === -1) return collection;

    const element = collection[elementIndex];
    const newCollection = [...collection];

    switch (direction) {
      case MoveDirection.UP: {
        newCollection[elementIndex] = newCollection[elementIndex - 1];
        newCollection[elementIndex - 1] = element;

        return newCollection;
      }
      case MoveDirection.DOWN: {
        newCollection[elementIndex] = newCollection[elementIndex + 1];
        newCollection[elementIndex + 1] = element;

        return newCollection;
      }
      case MoveDirection.TOP: {
        newCollection.splice(elementIndex, 1);

        return [element, ...newCollection];
      }
      case MoveDirection.BOTTOM: {
        newCollection.splice(elementIndex, 1);
        newCollection.push(element);

        return newCollection;
      }
    }
  };

export const toCSV = <T extends object>(
  columns: Partial<UiAnalyticalTableColumnDefinition<T>>[] | UiTableColumnProps[],
  data: (string | number)[][],
) => {
  const headers = columns.map(({ label }) => label as string);
  const csvArray = [headers, ...data];

  return csvArray
    .map(
      (row) =>
        row
          .map((v) => (isNil(v) ? '' : v)) // handle null/undefined
          .map(String) // convert every value to String
          .map((v) => v.replaceAll('"', '""')) // escape double colons
          .map((v) => `"${v}"`) // quote it
          .join(','), // comma-separated
    )
    .join('\r\n'); // rows starting on new lines
};

export const downloadFile = ({
  urlFile,
  fileName,
  fileExtension,
}: {
  urlFile: string;
  fileName: string;
  fileExtension: FileExtensions;
}) => {
  const link = document.createElement('a');
  link.setAttribute('href', urlFile!);
  link.setAttribute('download', fileName + fileExtension);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(urlFile);
};

type TranslationKeyType = NestedKeyOf<typeof translation>;

export const guardTranslation = (t: TFunction, key: string) => {
  if (key.match(/undefined|null/g)) return undefined;

  if (i18next.exists(key)) return t(key as TranslationKeyType);

  const commaSeparatedStrings = key.split('.');
  const lastCommaSeparatedKeyAsFallback = commaSeparatedStrings.at(-1);

  return lastCommaSeparatedKeyAsFallback;
};

export const escapeFormatOnFilename = (filename: string) => {
  const lastIndex = filename.lastIndexOf('.');

  if (lastIndex <= 0) {
    return filename;
  }

  return filename.substring(0, lastIndex);
};

export const generateUUID = () => {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);

    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });

  return uuid;
};

export const openNewTabWithBaseURL = (url: string) => {
  const baseURL = import.meta.env.BASE_URL;
  const hasBaseURL = !!baseURL && baseURL !== '/';

  const urlToOpen = hasBaseURL ? `${baseURL}${url}` : url;
  window.open(urlToOpen, '_blank');
};

export const conditionalArrayElements = <T>(condition: boolean, elements: T[]) =>
  condition ? [...elements] : [];
