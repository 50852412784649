import { Navigate } from 'react-router';

import { KpiRouting } from '@GlobalProviders/MultiApp/KpiRouting';
import { KPIRoutingFactory } from '@Pages/Shared/Routing/KPIRoutingFactory';

import { HIERARCHY_MANAGER_ROUTE } from '../dmRoutes';
import { GHGProcessManagerRouting } from './GHGProcessManager/GHGProcessManagerRouting';
import { GHGHierarchyManagerRouting } from './HierarchyManager/Routing/GHGHierarchyManagerRouting';

export const DmGHGRouting = KPIRoutingFactory(
  [
    { index: true, element: <Navigate to={HIERARCHY_MANAGER_ROUTE} /> },
    GHGHierarchyManagerRouting,
    GHGProcessManagerRouting,
  ],
  KpiRouting.GHG,
);
