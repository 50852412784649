import { PaginatedRqHookProps } from '@DataAccess/types';
import api from '@DataAccess/api';
import { useTablePaginatedQuery } from '@DataAccess/utils';

import { AcuteAssetDto } from './singleAsset.types';
import { acuteKeys, chronicKeys } from './singleAsset.keys';

export const useAcuteQuery = (rest: PaginatedRqHookProps<AcuteAssetDto>) => {
  return useTablePaginatedQuery<AcuteAssetDto>({
    apiCall: api.physicalRisk.singleAsset.getAcuteRisks,
    key: acuteKeys.list,
    ...rest,
  });
};

export const useChronicQuery = (rest: PaginatedRqHookProps<AcuteAssetDto>) => {
  return useTablePaginatedQuery<AcuteAssetDto>({
    apiCall: api.physicalRisk.singleAsset.getChronicRisks,
    key: chronicKeys.list,
    ...rest,
  });
};
