import { UnsavedChangesDialog } from '@Pages/Shared/SmartComponents/UnsavedChangesDialog';

import { SplitScopeTable } from '../Components/SplitScopeTable';
import { useSplitScopeContext } from '../Context/SplitScopeContext';
import { ProjectCreateRule } from './ProjectCreateRuleModal';
import { useProjectCustomizationColumns } from './useProjectCustomizationColumns';
import { useSplitScopeTable } from '../Hooks/useSplitScopeTable';

export const SplitScopeProjectTable = () => {
  const columns = useProjectCustomizationColumns();
  const { project, handleSaveSplitRatios, isEditMode, resetState } = useSplitScopeContext();
  const {
    state: { list },
    actions,
  } = project;
  const { editableItems, setIsCreateRuleModalOpen, isCreateRuleModalOpen } =
    useSplitScopeTable(list);

  return (
    <>
      <SplitScopeTable
        onSaveClick={handleSaveSplitRatios}
        onAddClick={() => setIsCreateRuleModalOpen(true)}
        columns={columns}
        data={editableItems}
        onDragEnd={actions.reorderItems}
        totalElements={list.length}
      />
      <ProjectCreateRule
        isOpen={isCreateRuleModalOpen}
        onClose={() => setIsCreateRuleModalOpen(false)}
      />

      <UnsavedChangesDialog
        isEditing={isEditMode}
        onContinue={() => {
          resetState();
        }}
      />
    </>
  );
};
