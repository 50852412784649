import { FileUploader } from '@ui5/webcomponents-react';

import { FileExtensions } from '../../types';
import { UiButtonProps, UiButton } from './UiButton';
import { useFileUploadButtonOnChangeHandler } from './useFileUploadButtonOnChangeHandler';

type Props = UiButtonProps & {
  acceptedExtensions?: FileExtensions[];
  isMultiple?: boolean;
  onChange?: (files: FileList | null) => void;
};

export const UiFileUploadButton = ({
  children,
  acceptedExtensions,
  isMultiple,
  onChange,
  ...buttonProps
}: Props) => {
  const onChangeHandler = useFileUploadButtonOnChangeHandler(onChange);
  const accept = acceptedExtensions?.join();

  return (
    <FileUploader hideInput accept={accept} multiple={isMultiple} onChange={onChangeHandler}>
      <UiButton {...buttonProps}>{children}</UiButton>
    </FileUploader>
  );
};
