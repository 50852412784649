import { useCallback, useMemo } from 'react';

import { UiListItemChangeEvent } from './UiSelectableListItem';
import { ListOnChangeEvent } from './types';

const changeEventFactory = (selectedSet: Set<string>, listItemEvent: UiListItemChangeEvent) => ({
  selectedItems: Array.from(selectedSet),
  listItemEvent,
});

export const useListSelectionStateHelper = (
  selectedItems: string[] = [],
  onChange?: (event: ListOnChangeEvent) => void,
) => {
  const selectionSet = useMemo(() => new Set(selectedItems), [selectedItems]);

  const onChangeHandler = useCallback(
    (event: UiListItemChangeEvent) => {
      const { isSelected, itemValue } = event;

      isSelected ? selectionSet.add(itemValue) : selectionSet.delete(itemValue);

      onChange?.(changeEventFactory(selectionSet, event));
    },
    [selectionSet, onChange],
  );

  return {
    selectionSet,
    onChangeHandler,
  };
};
