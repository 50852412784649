import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { DashboardRouting } from '@Pages/DashboardRouting';
import { SwaggerRouting } from '@Pages/Swagger/SwaggerRouting';

const router = createBrowserRouter([DashboardRouting, SwaggerRouting], {
  basename: import.meta.env.BASE_URL,
});

export const RoutingProvider = () => <RouterProvider router={router} />;
