import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { createURLWithSearchParam } from '@Utils/searchParamsUtils';
import { UiTableColumnDataType } from '@UiCommon/Components/UiAnalyticalTable/types';
import { UiLink } from '@UiCommon/Components';
import { INSIGHTS_ROUTE } from '@Pages/Insights/Routing/routes';
import { SearchParamsKeys } from '@DataAccess/types';
import {
  ProcessType,
  ProcessItemType,
  ProcessEntityType,
} from '@DataAccess/Platform/ProcessMonitoring/processMonitoring.types';
import { KpiRouting } from '@GlobalProviders/MultiApp/KpiRouting';
import { SINGLE_EXPOSURE_ROUTE } from '@Pages/Insights/GHG/SingleExposure/Routing/routes';

import { ASSET_VALUATION_ROUTE } from '../ManualUpload/Entities/AssetValuation/Routing/routes';
import { BUILDING_ROUTE } from '../ManualUpload/Entities/Building/Routing/routes';
import { BUSINESS_PARTNER_ROUTE } from '../ManualUpload/Entities/BusinessPartner/Routing/routes';
import { BP_ADDRESS_COORDINATES_ROUTE } from '../ManualUpload/Entities/BusinessPartnerAddressCoordinates/Routing/routes';
import { COUNTRY_REGION_ROUTE } from '../ManualUpload/Entities/CountryRegion/Routing/routes';
import { EMISSION_BUILDING_CERTIFICATES_ROUTE } from '../ManualUpload/Entities/EmissionBuildingCertificates/Routing/routes';
import { EMISSION_FACTOR_ENERGY_ROUTE } from '../ManualUpload/Entities/EmissionFactorEnergy/Routing/routes';
import { EMISSION_FACTOR_FLOOR_AREA_ROUTE } from '../ManualUpload/Entities/EmissionFactorFloorArea/Routing/routes';
import { EMISSION_FACTOR_GOODS_ROUTE } from '../ManualUpload/Entities/EmissionFactorGoods/Routing/routes';
import { EMISSION_FACTOR_NUMBER_OF_BUILDINGS_ROUTE } from '../ManualUpload/Entities/EmissionFactorNumberOfBuildings/Routing/routes';
import { EMISSION_FACTOR_SECTOR_ROUTE } from '../ManualUpload/Entities/EmissionFactorSector/Routing/routes';
import { FINANCIAL_CONTRACT_ROUTE } from '../ManualUpload/Entities/FinancialContract/Routing/routes';
import { FINANCIAL_INSTRUMENT_ROUTE } from '../ManualUpload/Entities/FinancialInstrument/Routing/routes';
import { INDUSTRY_CLASSIFICATION_CODE_ROUTE } from '../ManualUpload/Entities/IndustryClassificationCode/Routing/routes';
import { LOCATION_SCORE_ROUTE } from '../ManualUpload/Entities/LocationScore/Routing/routes';
import { PHYSICAL_ASSET_ROUTE } from '../ManualUpload/Entities/PhysicalAsset/Routing/routes';
import { PA_ADDRESS_COORDINATES_ROUTE } from '../ManualUpload/Entities/PhysicalAssetAddressCoordinates/Routing/routes';
import { PHYSICAL_FINANCIAL_CONTRACT_ASSOCIATION_ROUTE } from '../ManualUpload/Entities/PhysicalAssetFinancialContractAssociation/Routing/routes';
import { REPORTED_GOODS_ROUTE } from '../ManualUpload/Entities/ProducedConsumedGoods/Routing/routes';
import { PROJECT_ROUTE } from '../ManualUpload/Entities/Project/Routing/routes';
import { PROJECT_ADDRESS_COORDINATES_ROUTE } from '../ManualUpload/Entities/ProjectAddressCoordinates/Routing/routes';
import { REPORTED_ENERGY_ROUTE } from '../ManualUpload/Entities/ReportedEnergy/Routing/routes';

type Props = {
  processType: ProcessType;
  itemType: ProcessItemType;
  processId: string;
  isLinkDisabled: boolean;
};

export const PROCESS_ID_KEY = 'processId';

const linkMap: Record<ProcessEntityType, string> = {
  [ProcessItemType.BUILDING]: BUILDING_ROUTE,
  [ProcessItemType.FINANCIAL_CONTRACT]: FINANCIAL_CONTRACT_ROUTE,
  [ProcessItemType.FINANCIAL_INSTRUMENT]: FINANCIAL_INSTRUMENT_ROUTE,
  [ProcessItemType.PROJECT]: PROJECT_ROUTE,
  [ProcessItemType.BUSINESS_PARTNER]: BUSINESS_PARTNER_ROUTE,
  [ProcessItemType.PHYSICAL_ASSET]: PHYSICAL_ASSET_ROUTE,
  [ProcessItemType.INDUSTRY_CLASSIFICATION_CODE]: INDUSTRY_CLASSIFICATION_CODE_ROUTE,
  [ProcessItemType.REPORTED_GOODS]: REPORTED_GOODS_ROUTE,
  [ProcessItemType.EMISSION_FACTOR_BUILDING_CERTIFICATE_CLASS]:
    EMISSION_BUILDING_CERTIFICATES_ROUTE,
  [ProcessItemType.EMISSION_FACTOR_NUMBER_OF_BUILDINGS]: EMISSION_FACTOR_NUMBER_OF_BUILDINGS_ROUTE,
  [ProcessItemType.PHYSICAL_ASSET_TO_FINANCIAL_CONTRACT]:
    PHYSICAL_FINANCIAL_CONTRACT_ASSOCIATION_ROUTE,
  [ProcessItemType.EMISSION_FACTOR_SECTOR]: EMISSION_FACTOR_SECTOR_ROUTE,
  [ProcessItemType.EMISSION_FACTOR_BUILDING_FLOOR_AREA]: EMISSION_FACTOR_FLOOR_AREA_ROUTE,
  [ProcessItemType.ASSET_VALUATION]: ASSET_VALUATION_ROUTE,
  [ProcessItemType.REPORTED_ENERGY]: REPORTED_ENERGY_ROUTE,
  [ProcessItemType.COUNTRY_REGION]: COUNTRY_REGION_ROUTE,
  [ProcessItemType.EMISSION_FACTOR_ENERGY]: EMISSION_FACTOR_ENERGY_ROUTE,
  [ProcessItemType.EMISSION_FACTOR_GOODS]: EMISSION_FACTOR_GOODS_ROUTE,
  [ProcessItemType.BUSINESS_PARTNER_ADDRESS]: BP_ADDRESS_COORDINATES_ROUTE,
  [ProcessItemType.PROJECT_ADDRESS]: PROJECT_ADDRESS_COORDINATES_ROUTE,
  [ProcessItemType.PHYSICAL_ASSET_ADDRESS]: PA_ADDRESS_COORDINATES_ROUTE,
  [ProcessItemType.MUNICH_RE]: LOCATION_SCORE_ROUTE,
};

export const ItemLink = ({ itemType, processId, processType, isLinkDisabled }: Props) => {
  const { pathname } = useLocation();
  const filters = [
    {
      value: PROCESS_ID_KEY,
      conditions: [{ value: processId, columnCondition: 'EQUAL' }],
      type: UiTableColumnDataType.TEXT,
    },
  ];

  const url = useMemo(() => {
    if (isLinkDisabled) return undefined;
    // In the future we might think about some mapper here
    if (processType === ProcessType.CALCULATION) {
      return createURLWithSearchParam({
        data: filters,
        urlPath: `/${INSIGHTS_ROUTE}/${KpiRouting.GHG}/${SINGLE_EXPOSURE_ROUTE}`,
        key: SearchParamsKeys.FILTERS,
      });
    }

    if (processType === ProcessType.MANUAL_UPLOAD) {
      return createURLWithSearchParam({
        data: filters,
        urlPath: `${pathname}/${linkMap[itemType as ProcessEntityType]}`,
        key: SearchParamsKeys.FILTERS,
      });
    }

    if (itemType === ProcessItemType.ISS) {
      return createURLWithSearchParam({
        data: filters,
        urlPath: `${pathname}/${linkMap[ProcessItemType.BUSINESS_PARTNER]}`,
        key: SearchParamsKeys.FILTERS,
      });
    }

    if (itemType === ProcessItemType.MUNICH_RE) {
      return createURLWithSearchParam({
        data: filters,
        urlPath: `${pathname}/${linkMap[itemType as ProcessEntityType]}`,
        key: SearchParamsKeys.FILTERS,
      });
    }

    return undefined;
  }, [processType, processId]);

  if (!url) return <div>{itemType}</div>;

  return <UiLink href={url.href}>{itemType}</UiLink>;
};
