import { useCallback } from 'react';
import { SelectDomRef, Ui5CustomEvent } from '@ui5/webcomponents-react';

export const useUiSelectOnChangeHandler = (callback?: (value?: string) => void) =>
  useCallback(
    (event: Ui5CustomEvent<SelectDomRef, { selectedOption: HTMLElement }>) => {
      if (!callback) return;

      const {
        detail: {
          selectedOption: {
            dataset: { value },
          },
        },
      } = event;

      callback(value);
    },
    [callback],
  );
