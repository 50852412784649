import { useSplitScopeContext } from '../Context/SplitScopeContext';
import { SplitScopeActions } from '../Components/SplitScopeActions';

type Props = {
  index: number;
  isEditable?: boolean;
};

export const BusinessPartnerActions = ({ index }: Props) => {
  const { businessPartner } = useSplitScopeContext();

  return <SplitScopeActions categoryState={businessPartner} index={index} />;
};
