import { RouteObject } from 'react-router-dom';

import { AssetValuationRouting } from './ManualUpload/Entities/AssetValuation/Routing/AssetValuationRouting';
import { BuildingRouting } from './ManualUpload/Entities/Building/Routing/BuildingRouting';
import { BusinessPartnerRouting } from './ManualUpload/Entities/BusinessPartner/Routing/BusinessPartnerRouting';
import { BusinessPartnerAddressCoordinatesRouting } from './ManualUpload/Entities/BusinessPartnerAddressCoordinates/Routing/BusinessPartnerAddressCoordinatesRouting';
import { CountryRegionRouting } from './ManualUpload/Entities/CountryRegion/Routing/CountryRegionRouting';
import { EmissionBuildingCertificatesRouting } from './ManualUpload/Entities/EmissionBuildingCertificates/Routing/EmissionBuildingCertificatesRouting';
import { EmissionFactorEnergyRouting } from './ManualUpload/Entities/EmissionFactorEnergy/Routing/EmissionFactorEnergyRouting';
import { EmissionFactorFloorAreaRouting } from './ManualUpload/Entities/EmissionFactorFloorArea/Routing/EmissionFactorFloorAreaRouting';
import { EmissionFactorGoodsRouting } from './ManualUpload/Entities/EmissionFactorGoods/Routing/EmissionFactorGoodsRouting';
import { EmissionFactorNumberOfBuildingsRouting } from './ManualUpload/Entities/EmissionFactorNumberOfBuildings/Routing/EmissionFactorNumberOfBuildingsRouting';
import { EmissionFactorSectorRouting } from './ManualUpload/Entities/EmissionFactorSector/Routing/EmissionFactorSectorRouting';
import { FinancialContractRouting } from './ManualUpload/Entities/FinancialContract/Routing/FinancialContractRouting';
import { FinancialInstrumentRouting } from './ManualUpload/Entities/FinancialInstrument/Routing/FinancialInstrumentRouting';
import { IndustryClassificationCodeRouting } from './ManualUpload/Entities/IndustryClassificationCode/Routing/IndustryClassificationCodeRouting';
import { MunichReRouting } from './ManualUpload/Entities/LocationScore/Routing/LocationScoreRouting';
import { PhysicalAssetRouting } from './ManualUpload/Entities/PhysicalAsset/Routing/PhysicalAssetRouting';
import { PhysicalAssetAddressCoordinatesRouting } from './ManualUpload/Entities/PhysicalAssetAddressCoordinates/Routing/PhysicalAssetAddressCoordinatesRouting';
import { PhysicalAssetFinancialContractAssociationRouting } from './ManualUpload/Entities/PhysicalAssetFinancialContractAssociation/Routing/PhysicalAssetFinancialContractAssociationRouting';
import { ReportedGoodsRouting } from './ManualUpload/Entities/ProducedConsumedGoods/Routing/ReportedGoodsRouting';
import { ProjectRouting } from './ManualUpload/Entities/Project/Routing/ProjectRouting';
import { ProjectAddressCoordinatesRouting } from './ManualUpload/Entities/ProjectAddressCoordinates/Routing/ProjectAddressCoordinatesRouting';
import { ReportedEnergyRouting } from './ManualUpload/Entities/ReportedEnergy/Routing/ReportedEnergyRouting';
import { ErrorLogRouting } from './ErrorLog/Routing/ErrorLogRouting';

export const ProcessManagerChildren: RouteObject[] = [
  FinancialContractRouting,
  FinancialInstrumentRouting,
  BusinessPartnerRouting,
  ProjectRouting,
  BuildingRouting,
  PhysicalAssetRouting,
  IndustryClassificationCodeRouting,
  ReportedGoodsRouting,
  EmissionBuildingCertificatesRouting,
  EmissionFactorNumberOfBuildingsRouting,
  PhysicalAssetFinancialContractAssociationRouting,
  EmissionFactorFloorAreaRouting,
  EmissionFactorSectorRouting,
  ReportedEnergyRouting,
  AssetValuationRouting,
  CountryRegionRouting,
  EmissionFactorGoodsRouting,
  EmissionFactorEnergyRouting,
  BusinessPartnerAddressCoordinatesRouting,
  ProjectAddressCoordinatesRouting,
  PhysicalAssetAddressCoordinatesRouting,
  ErrorLogRouting,
  MunichReRouting,
];
