import { UiTableColumnDataType } from '../types';

export type SettingsStateAdapter<InputData> = {
  initStateWithData: (data: InputData) => void;
  getDataFromState: () => InputData;
  reset: () => void;
};

export type ConditionType = {
  columnCondition: FilterConditionsType;
  value?: string | number | boolean;
};

export type ColumnFiltersType = {
  value: string;
  label: string;
  type: UiTableColumnDataType;
  conditions: ConditionType[];
};

export type FilterOption = {
  value?: string;
  label?: string;
};

export enum FilterConditionsType {
  NOT_ENDS_WITH = 'NOT_ENDS_WITH',
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
  NOT_CONTAINS = 'NOT_CONTAINS',
  ENDS_WITH = 'ENDS_WITH',
  LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
  GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
  STARTS_WITH = 'STARTS_WITH',
  CONTAINS = 'CONTAINS',
  NOT_STARTS_WITH = 'NOT_STARTS_WITH',
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
}
