import { DEFAULT_LOCALE } from './constants';
import { NumberFormatterConfig } from './types';

export const createPercentageFormatter = ({
  lng = DEFAULT_LOCALE,
  decimalPrecision = 2,
}: NumberFormatterConfig) => {
  return new Intl.NumberFormat(lng, {
    style: 'percent',
    minimumFractionDigits: decimalPrecision,
    maximumFractionDigits: decimalPrecision,
  });
};
