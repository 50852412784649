import { ProcessManagerPage } from '@Pages/DataManagement/ProcessManager/ProcessManagerPage';

import { useGHGProcessMonitoringColumns } from './useGHGProcessMonitoringColumns';
import { useGHGUploadModalEntities } from './useGHGUploadModalEntities';

export const GHGProcessManagerPage = () => {
  const columns = useGHGProcessMonitoringColumns();
  const entities = useGHGUploadModalEntities();

  return <ProcessManagerPage columns={columns} entities={entities} />;
};
