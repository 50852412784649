import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';
import { SmartAnalyticalTableCard } from '@Pages/Shared/SmartComponents/SmartAnalyticalTableCard/SmartAnalyticalTableCard';
import { useSmartAnalyticalTableCard } from '@Pages/Shared/SmartComponents/SmartAnalyticalTableCard/useSmartAnalyticalTableCard';
import { useEmissionQuery } from '@DataAccess/GHG/SingleExposure/singleExposure.hooks';

import { useEmissionsTableColumns } from './Hooks/Columns/useEmissionsTableColumns';

export const Emissions = () => {
  const { t } = useGHGTranslation();

  const TABLE_ID = 'emissions';
  const columns = useEmissionsTableColumns();
  const {
    sorting,
    filters,
    data,
    fetchNextPage,
    totalElements,
    setFilters,
    setSorting,
    externalFiltersApplied,
  } = useSmartAnalyticalTableCard({
    columns,
    queryHook: useEmissionQuery,
  });

  return (
    <SmartAnalyticalTableCard
      columns={columns}
      data={data}
      title={t('singleExposure.dataSets', { count: totalElements })}
      onLoadMore={fetchNextPage}
      totalNumberOfItems={totalElements}
      tableId={TABLE_ID}
      hasViewsManager
      hasSettingsModal
      hasExportCSV
      sorting={sorting}
      filters={filters}
      externalFiltersApplied={externalFiltersApplied}
      onSorting={setSorting}
      onFilters={setFilters}
      apiPath='ghg.singleExposure.getEmissions'
    />
  );
};
