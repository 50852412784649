import { DataHierarchySearchRequestDto } from './dataHierarchy.types';

const SEARCH_LIST = 'hierarchySearchList';

export const dataHierarchyKeys = {
  orderOfUsage: {
    all: (hierarchyId?: string) => ['hierarchy', hierarchyId] as const,
    fullSearchList: [SEARCH_LIST] as const,
    searchList: (params: DataHierarchySearchRequestDto) => [SEARCH_LIST, params] as const,
  } as const,
};
