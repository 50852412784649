import styled from '@emotion/styled';
import { useMemo } from 'react';
import { css } from '@emotion/css';

import { IconSet } from '@GlobalProviders/IconSetProvider';

import { generateFilterTokens } from './generateFilterTokens';
import { greyColorUiButtonClass } from './styles';
import { UiAnalyticalTableColumnDefinition, UiColumnFiltersType } from './types';
import { mapToFiltersWithLabels } from './utils';
import { UiButton } from '../UiButton/UiButton';
import { UiMultiInput } from '../UiMultiInput/UiMultiInput';

type Props<DataType> = {
  columns: UiAnalyticalTableColumnDefinition<DataType>[];
  filters?: UiColumnFiltersType[];
  onClickHandler: () => void;
};

const Wrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

const inputClass = css`
  width: 18rem;
`;

export const UiFiltersBar = <DataType,>({
  filters = [],
  columns,
  onClickHandler,
}: Props<DataType>) => {
  const filtersWithLabels = useMemo(
    () => mapToFiltersWithLabels(filters, columns),
    [columns, filters],
  );

  return (
    <Wrapper>
      {filtersWithLabels.map(({ value, label, type, conditions }) => (
        <UiMultiInput
          key={value}
          onClick={onClickHandler}
          className={inputClass}
          label={`${label}`}
          tokens={generateFilterTokens(conditions, type)}
          endContent={
            <UiButton
              className={greyColorUiButtonClass}
              design='Transparent'
              icon={IconSet.VALUE_HELP}
            />
          }
        />
      ))}
    </Wrapper>
  );
};
