import { useState } from 'react';
import { css } from '@emotion/css';

import { IconSet } from '@GlobalProviders/IconSetProvider';
import { useUiCommonTranslation } from '@GlobalProviders/i18n/hooks';

import { CommonProps } from '../../types';
import { UiButton } from '../UiButton/UiButton';
import { UiIcon } from '../UiIcon';
import { UiMenu } from '../UiMenu/UiMenu';
import { UiMenuItem } from '../UiMenu/UiMenuItem';

type Props = CommonProps & {
  onSettingsClick?: () => void;
  onExportClick?: () => void;
};

const dropDownIcon = css`
  margin-left: 0.375rem;
  transform: translateY(-20%);
`;

export const TableHeaderActions = ({ onSettingsClick, onExportClick }: Props) => {
  const { t } = useUiCommonTranslation();
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  return (
    <>
      {!!onSettingsClick && (
        <UiButton icon={IconSet.ACTION_SETTINGS} design='Transparent' onClick={onSettingsClick} />
      )}
      {!!onExportClick && (
        <UiButton
          id='openMenuBtn'
          icon={IconSet.EXCEL_ATTACHMENT}
          design='Transparent'
          onClick={() => {
            setMenuIsOpen(true);
          }}
        >
          <UiIcon className={dropDownIcon} design='Information' name={IconSet.DROPDOWN} />
          <UiMenu
            opener={'openMenuBtn'}
            open={menuIsOpen}
            onAfterClose={() => {
              setMenuIsOpen(false);
            }}
          >
            <UiMenuItem onClick={onExportClick} text={t('exportModal.exportAsOption')} />
          </UiMenu>
        </UiButton>
      )}
    </>
  );
};
