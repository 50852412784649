import { useCallback } from 'react';
import { CheckBoxDomRef, Ui5CustomEvent } from '@ui5/webcomponents-react';

export const useUiCheckboxOnChangeHandler = (callback?: (value: boolean) => void) =>
  useCallback(
    (event: Ui5CustomEvent<CheckBoxDomRef, never>) => {
      if (!callback) return;

      const {
        target: { checked },
      } = event;

      callback(!!checked);
    },
    [callback],
  );
