import styled from '@emotion/styled';
import { ThemingParameters } from '@ui5/webcomponents-react-base';

import { UiDialog } from '../../UiDialog/UiDialog';
import { useFilterSettingsState } from './FilterSettings/useFilterSettingsState';
import { UiAnalyticalTableColumnDefinition, UiColumnFiltersType } from '../types';
import { UiListItemType } from '../../UiList/types';
import { UiTabSwitcher } from '../../UiTabSwitcher';
import { sortColumnsByVisibility } from '../utils';
import { useColumnsSettingsState } from './ColumnsSettings/useColumnsSettingsState';
import { SettingsModalContextProvider } from './SettingsModalContext';
import {
  SortingListItemProps,
  useSortingSettingsState,
} from './SortSettings/useSortingSettingsState';
import { UiSettingsModalFooter } from './UiSettingsModalFooter';
import { UiSettingsModalHeader } from './UiSettingsModalHeader';
import { SettingsTab, useSettingsModalTabs } from './useSettingsModalTabs';

const StyledTabSwitcher = styled(UiTabSwitcher)`
  box-shadow: ${ThemingParameters.sapContent_HeaderShadow};
  padding-top: 0.625rem;
  padding-left: 2.25rem;
  width: calc(100% - 2.25rem);
  position: sticky;
`;

const TabArea = styled.div``;

const TabContentArea = styled.div`
  overflow: auto;
`;

const ContentWrapper = styled.div`
  background-color: ${ThemingParameters.sapShell_Background};
  width: 40rem;
  height: 37.5rem;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const StyledDialog = styled(UiDialog)`
  ::part(content) {
    padding: 0;
  }
  ::part(header) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

type Props<DataType> = {
  columns?: UiAnalyticalTableColumnDefinition<DataType>[];
  filters?: UiColumnFiltersType[];
  sorting?: UiListItemType<SortingListItemProps>[];
  onConfirm?: (result: {
    columns: UiAnalyticalTableColumnDefinition<DataType>[];
    sorting: UiListItemType<SortingListItemProps>[];
    filters: UiColumnFiltersType[];
  }) => void;
  isOpen: boolean;
  onClose: () => void;
  selectedTab?: SettingsTab;
};

export const UiSettingsModal = <DataType,>({
  isOpen,
  selectedTab,
  onClose,
  onConfirm,
  sorting = [],
  columns = [],
  filters = [],
}: Props<DataType>) => {
  const { tabsConfig, tabContents, activeTab, handleTabClick, resetTabs } =
    useSettingsModalTabs(selectedTab);
  const COLUMNS = useColumnsSettingsState<DataType>();
  const SORTING = useSortingSettingsState<DataType>();
  const FILTERS = useFilterSettingsState<DataType>();

  return (
    <StyledDialog
      isOpen={isOpen}
      header={<UiSettingsModalHeader />}
      footer={
        <UiSettingsModalFooter
          onConfirm={() => {
            onConfirm?.({
              columns: COLUMNS.getDataFromState(),
              sorting: SORTING.getDataFromState(),
              filters: FILTERS.getDataFromState(),
            });
          }}
          onCancel={onClose}
        />
      }
      onBeforeOpen={() => {
        COLUMNS.initStateWithData(sortColumnsByVisibility(columns));
        SORTING.initStateWithData(columns, sorting);
        FILTERS.initStateWithData(filters, columns);
      }}
      onAfterClose={() => {
        COLUMNS.reset();
        SORTING.reset();
        FILTERS.reset();
        resetTabs();
        onClose();
      }}
    >
      <SettingsModalContextProvider
        key={String(isOpen)}
        columns={COLUMNS.listItems}
        visibleColumns={COLUMNS.selected}
        onColumnsVisibilityChange={COLUMNS.setSelected}
        sortingOptions={SORTING.options}
        sortingItems={SORTING.sortingListItems}
        onSortingItemsChange={SORTING.setSortingListItems}
        onColumnsReorder={COLUMNS.setListItems}
        filtersOptions={FILTERS.options}
        columnsToBeFiltered={FILTERS.columnsToBeFiltered}
        onColumnsToBeFiltered={FILTERS.onColumnsToBeFiltered(columns)}
      >
        <ContentWrapper>
          <TabArea>
            <StyledTabSwitcher
              selectedTab={activeTab}
              tabsConfig={tabsConfig}
              onTabChange={handleTabClick}
            />
          </TabArea>
          <TabContentArea>{tabContents[activeTab]}</TabContentArea>
        </ContentWrapper>
      </SettingsModalContextProvider>
    </StyledDialog>
  );
};
