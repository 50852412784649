import styled from '@emotion/styled';
import { ButtonDesign } from '@ui5/webcomponents-react';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';

import { useProcessManagerTranslation } from '@GlobalProviders/i18n/hooks';
import {
  UiButton,
  UiDatePicker,
  UiDialog,
  UiDialogFooter,
  UiDialogHeader,
} from '@UiCommon/Components';
import { UiDateTimePicker } from '@UiCommon/Components/UiDateTimePicker/UiDateTimePicker';
import { ParametrizationProps } from '@DataAccess/Platform/ProcessMonitoring/processMonitoring.types';
import { DATE_FORMAT } from '@UiCommon/Components/constants';

export type CreateRuleModalProps = {
  isOpen: boolean;
  onClose?: () => void;
  onSubmit?: (parametrizationProps: ParametrizationProps) => void;
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem 0;
`;

const StyledUiDialog = styled(UiDialog)`
  min-width: 21.5rem;
  ::part(footer) {
    padding-right: 0;
  }
`;

export const RunCalculationModal = ({ isOpen, onClose, onSubmit }: CreateRuleModalProps) => {
  const { t } = useProcessManagerTranslation();
  const todayDate = new Date();
  const formattedTodayDate = format(todayDate, DATE_FORMAT);
  const isoFormattedTodayDate = todayDate.toISOString();
  const [businessDate, setBusinessDate] = useState(formattedTodayDate);
  const [systemDate, setSystemDate] = useState(isoFormattedTodayDate);

  useEffect(() => {
    if (isOpen) {
      setBusinessDate(formattedTodayDate);
      setSystemDate(isoFormattedTodayDate);
    }
  }, [isOpen]);

  return (
    <StyledUiDialog
      isOpen={isOpen}
      header={
        <UiDialogHeader
          startContent={t('processManager.runCalculationModal.header')}
        ></UiDialogHeader>
      }
      footer={
        <UiDialogFooter
          endContent={
            <>
              <UiButton
                onClick={() => onSubmit?.({ businessDate, systemDate })}
                design={ButtonDesign.Emphasized}
              >
                {t('processManager.runCalculationModal.runCalculation')}
              </UiButton>
              <UiButton onClick={onClose} design={ButtonDesign.Transparent}>
                {t('processManager.runCalculationModal.cancel')}
              </UiButton>
            </>
          }
        ></UiDialogFooter>
      }
    >
      <ContentWrapper>
        {
          <>
            <UiDatePicker
              label={t('processManager.runCalculationModal.businessDate')}
              value={businessDate}
              onChange={(value) => value && setBusinessDate(value)}
            />
            <UiDateTimePicker
              label={t('processManager.runCalculationModal.systemDate')}
              value={systemDate}
              onChange={(value) => value && setSystemDate(value)}
            />
          </>
        }
      </ContentWrapper>
    </StyledUiDialog>
  );
};
