import { useMemo } from 'react';

import { useProcessManagerTranslation } from '@GlobalProviders/i18n/hooks';
import { UiTableColumnDataType } from '@UiCommon/Components/UiAnalyticalTable/types';

import { useCommonCollectedColumns } from '../useCommonCollectedColumns';

export const useCollectedBusinessPartnerColumns = () => {
  const { t } = useProcessManagerTranslation();
  const commonColumns = useCommonCollectedColumns();

  return useMemo(() => {
    return [
      {
        label: t('processManager.manualUpload.businessPartner.table.businessPartnerId'),
        accessor: 'id',
        id: 'id',
        minWidth: 188,
        isVisible: true,
        beField: 'ID',
      },
      {
        label: t('processManager.manualUpload.businessPartner.table.name'),
        accessor: 'name',
        id: 'name',
        minWidth: 170,
        isVisible: true,
        beField: 'NAME',
      },
      {
        label: t('processManager.manualUpload.businessPartner.table.lei'),
        accessor: 'lei',
        id: 'lei',
        minWidth: 170,
        isVisible: true,
        beField: 'LEI',
      },
      {
        label: t('processManager.manualUpload.businessPartner.table.businessPartnerCategory'),
        accessor: 'category',
        id: 'category',
        minWidth: 212,
        isVisible: true,
        beField: 'CATEGORY',
      },
      {
        label: t('processManager.manualUpload.businessPartner.table.naceCode'),
        accessor: 'naceCode',
        id: 'naceCode',
        minWidth: 94,
        isVisible: true,
        beField: 'NACE_CODE',
      },
      {
        label: t('processManager.manualUpload.businessPartner.table.listedCompanyFlag'),
        accessor: 'listedCompanyFlag',
        id: 'listedCompanyFlag',
        minWidth: 120,
        isVisible: true,
        type: UiTableColumnDataType.BOOLEAN,
        beField: 'LISTED_COMPANY_FLAG',
      },
      {
        label: t('processManager.manualUpload.businessPartner.table.countryRegion'),
        accessor: 'countryRegion',
        id: 'countryRegion',
        minWidth: 70,
        isVisible: true,
        beField: 'COUNTRY_REGION',
      },
      {
        label: t('processManager.manualUpload.businessPartner.table.evic'),
        accessor: 'evic',
        id: 'evic',
        minWidth: 50,
        isVisible: true,
        type: UiTableColumnDataType.NUMBER,
        beField: 'EVIC',
      },
      {
        label: t('processManager.manualUpload.businessPartner.table.totalAssets'),
        accessor: 'totalAssets',
        id: 'totalAssets',
        minWidth: 70,
        isVisible: true,
        type: UiTableColumnDataType.NUMBER,
        beField: 'TOTAL_ASSETS',
      },
      {
        label: t('processManager.manualUpload.businessPartner.table.totalRevenue'),
        accessor: 'totalRevenue',
        id: 'totalRevenue',
        minWidth: 70,
        isVisible: true,
        type: UiTableColumnDataType.NUMBER,
        beField: 'TOTAL_REVENUE',
      },
      {
        label: t('processManager.manualUpload.businessPartner.table.emissionsScope1'),
        accessor: 'emissionsScope1',
        id: 'emissionsScope1',
        minWidth: 140,
        isVisible: true,
        type: UiTableColumnDataType.NUMBER,
        beField: 'EMISSIONS_SCOPE_1',
      },
      {
        label: t('processManager.manualUpload.businessPartner.table.emissionsScope2'),
        accessor: 'emissionsScope2',
        id: 'emissionsScope2',
        minWidth: 140,
        isVisible: true,
        type: UiTableColumnDataType.NUMBER,
        beField: 'EMISSIONS_SCOPE_2',
      },
      {
        label: t('processManager.manualUpload.businessPartner.table.emissionsScope3'),
        accessor: 'emissionsScope3',
        id: 'emissionsScope3',
        minWidth: 140,
        isVisible: true,
        type: UiTableColumnDataType.NUMBER,
        beField: 'EMISSIONS_SCOPE_3',
      },
      {
        label: t('processManager.manualUpload.businessPartner.table.emissionsScope1Plus2'),
        accessor: 'emissionsScope1Plus2',
        id: 'emissionsScope1Plus2',
        minWidth: 140,
        isVisible: true,
        type: UiTableColumnDataType.NUMBER,
        beField: 'EMISSIONS_SCOPE_1_PLUS_2',
      },
      {
        label: t('processManager.manualUpload.businessPartner.table.emissionsVerifiedFlag'),
        accessor: 'emissionsVerifiedFlag',
        id: 'emissionsVerifiedFlag',
        minWidth: 135,
        isVisible: true,
        type: UiTableColumnDataType.BOOLEAN,
        beField: 'EMISSIONS_VERIFIED_FLAG',
      },
      {
        label: t('processManager.manualUpload.businessPartner.table.dataQualityScoreScope1'),
        accessor: 'dataQualityScoreScope1',
        id: 'dataQualityScoreScope1',
        minWidth: 140,
        isVisible: true,
        beField: 'DATA_QUALITY_SCORE_SCOPE_1',
      },
      {
        label: t('processManager.manualUpload.businessPartner.table.dataQualityScoreScope2'),
        accessor: 'dataQualityScoreScope2',
        id: 'dataQualityScoreScope2',
        minWidth: 140,
        isVisible: true,
        beField: 'DATA_QUALITY_SCORE_SCOPE_2',
      },
      {
        label: t('processManager.manualUpload.businessPartner.table.dataQualityScoreScope3'),
        accessor: 'dataQualityScoreScope3',
        id: 'dataQualityScoreScope3',
        minWidth: 140,
        isVisible: true,
        beField: 'DATA_QUALITY_SCORE_SCOPE_3',
      },
      {
        label: t('processManager.manualUpload.businessPartner.table.dataQualityScoreScope1Plus2'),
        accessor: 'dataQualityScoreScope1Plus2',
        id: 'dataQualityScoreScope1Plus2',
        minWidth: 140,
        isVisible: true,
        beField: 'DATA_QUALITY_SCORE_SCOPE_1_PLUS_2',
      },
      {
        label: t('processManager.manualUpload.businessPartner.table.dataQualityScoreOptionScope1'),
        accessor: 'dataQualityScoreOptionScope1',
        id: 'dataQualityScoreOptionScope1',
        minWidth: 210,
        isVisible: true,
        beField: 'DATA_QUALITY_SCORE_OPTION_SCOPE_1',
      },
      {
        label: t('processManager.manualUpload.businessPartner.table.dataQualityScoreOptionScope2'),
        accessor: 'dataQualityScoreOptionScope2',
        id: 'dataQualityScoreOptionScope2',
        minWidth: 210,
        isVisible: true,
        beField: 'DATA_QUALITY_SCORE_OPTION_SCOPE_2',
      },
      {
        label: t('processManager.manualUpload.businessPartner.table.dataQualityScoreOptionScope3'),
        accessor: 'dataQualityScoreOptionScope3',
        id: 'dataQualityScoreOptionScope3',
        minWidth: 210,
        isVisible: true,
        beField: 'DATA_QUALITY_SCORE_OPTION_SCOPE_3',
      },
      {
        label: t(
          'processManager.manualUpload.businessPartner.table.dataQualityScoreOptionScope1Plus2',
        ),
        accessor: 'dataQualityScoreOptionScope1Plus2',
        id: 'dataQualityScoreOptionScope1Plus2',
        minWidth: 210,
        isVisible: true,
        beField: 'DATA_QUALITY_SCORE_OPTION_SCOPE_1_PLUS_2',
      },
      {
        label: t('processManager.manualUpload.businessPartner.table.avoidedEmissionsFlag'),
        accessor: 'avoidedEmissionsFlag',
        id: 'avoidedEmissionsFlag',
        isVisible: true,
        type: UiTableColumnDataType.BOOLEAN,
        beField: 'AVOIDED_EMISSIONS_FLAG',
      },
      {
        label: t('processManager.manualUpload.businessPartner.table.avoidedEmissions'),
        accessor: 'avoidedEmissions',
        id: 'avoidedEmissions',
        isVisible: true,
        type: UiTableColumnDataType.NUMBER,
        beField: 'AVOIDED_EMISSIONS',
      },
      {
        label: t('processManager.manualUpload.shared.year'),
        accessor: 'year',
        id: 'year',
        isVisible: true,
        beField: 'YEAR',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.shared.emissionsReportingDate'),
        accessor: 'emissionsReportingDate',
        id: 'emissionsReportingDate',
        isVisible: true,
        beField: 'EMISSIONS_REPORTING_DATE',
        type: UiTableColumnDataType.DATE,
      },
      {
        label: t('processManager.manualUpload.shared.fiscalPeriodEndDate'),
        accessor: 'fiscalPeriodEndDate',
        id: 'fiscalPeriodEndDate',
        isVisible: true,
        beField: 'FISCAL_PERIOD_END_DATE',
        type: UiTableColumnDataType.DATE,
      },
      ...commonColumns,
    ];
  }, [t, commonColumns]);
};
