import { RouteObject } from 'react-router-dom';

import { EMISSION_FACTOR_SECTOR_ROUTE } from './routes';
import { EmissionFactorSectorPage } from '../EmissionFactorSectorPage';

export const EmissionFactorSectorRouting: RouteObject = {
  path: EMISSION_FACTOR_SECTOR_ROUTE,
  children: [
    {
      index: true,
      element: <EmissionFactorSectorPage />,
    },
  ],
};
