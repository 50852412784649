import { useMemo } from 'react';

import { useProcessManagerTranslation } from '@GlobalProviders/i18n/hooks';
import { UiTableColumnDataType } from '@UiCommon/Components/UiAnalyticalTable/types';

import { useCommonCollectedColumns } from '../useCommonCollectedColumns';

export const useLocationScoreColumns = () => {
  const { t } = useProcessManagerTranslation();
  const commonColumns = useCommonCollectedColumns({
    hideDataSource: true,
    hideBusinessValidity: true,
  });

  return useMemo(
    () => [
      ...commonColumns,
      {
        label: t('processManager.manualUpload.locationScore.table.businessIdentifier'),
        accessor: 'businessIdentifier',
        id: 'businessIdentifier',
        isVisible: true,
        beField: 'BUSINESS_IDENTIFIER',
      },
      {
        label: t('processManager.manualUpload.locationScore.table.streetHouseNr'),
        accessor: 'streetHouseNr',
        id: 'streetHouseNr',
        isVisible: true,
        beField: 'STREET_HOUSE_NR',
      },
      {
        label: t('processManager.manualUpload.locationScore.table.zipCode'),
        accessor: 'zipCode',
        id: 'zipCode',
        isVisible: true,
        beField: 'ZIP_CODE',
      },
      {
        label: t('processManager.manualUpload.locationScore.table.city'),
        accessor: 'city',
        id: 'city',
        isVisible: true,
        beField: 'CITY',
      },
      {
        label: t('processManager.manualUpload.locationScore.table.countryRegion'),
        accessor: 'countryRegion',
        id: 'countryRegion',
        isVisible: true,
        beField: 'COUNTRY_REGION',
      },
      {
        label: t('processManager.manualUpload.locationScore.table.geoquality'),
        accessor: 'geoquality',
        id: 'geoquality',
        isVisible: true,
        beField: 'GEOQUALITY',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.elevation'),
        accessor: 'elevation',
        id: 'elevation',
        isVisible: true,
        beField: 'ELEVATION',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.distanceToCoast'),
        accessor: 'distanceToCoast',
        id: 'distanceToCoast',
        isVisible: true,
        beField: 'DISTANCE_TO_COAST',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.populationDensity'),
        accessor: 'populationDensity',
        id: 'populationDensity',
        isVisible: true,
        beField: 'POPULATION_DENSITY',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.earthquake'),
        accessor: 'earthquake',
        id: 'earthquake',
        isVisible: true,
        beField: 'EARTHQUAKE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.volcanoes'),
        accessor: 'volcanoes',
        id: 'volcanoes',
        isVisible: true,
        beField: 'VOLCANOES',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.tsunami'),
        accessor: 'tsunami',
        id: 'tsunami',
        isVisible: true,
        beField: 'TSUNAMI',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.tropicalCyclone'),
        accessor: 'tropicalCyclone',
        id: 'tropicalCyclone',
        isVisible: true,
        beField: 'TROPICAL_CYCLONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.extratropicalStorm'),
        accessor: 'extratropicalStorm',
        id: 'extratropicalStorm',
        isVisible: true,
        beField: 'EXTRATROPICAL_STORM',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.hail'),
        accessor: 'hail',
        id: 'hail',
        isVisible: true,
        beField: 'HAIL',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.tornado'),
        accessor: 'tornado',
        id: 'tornado',
        isVisible: true,
        beField: 'TORNADO',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.lightning'),
        accessor: 'lightning',
        id: 'lightning',
        isVisible: true,
        beField: 'LIGHTNING',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.wildfire'),
        accessor: 'wildfire',
        id: 'wildfire',
        isVisible: true,
        beField: 'WILDFIRE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.riverFlood'),
        accessor: 'riverFlood',
        id: 'riverFlood',
        isVisible: true,
        beField: 'RIVER_FLOOD',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.flashFlood'),
        accessor: 'flashFlood',
        id: 'flashFlood',
        isVisible: true,
        beField: 'FLASH_FLOOD',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.stormSurge'),
        accessor: 'stormSurge',
        id: 'stormSurge',
        isVisible: true,
        beField: 'STORM_SURGE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.activeFaults'),
        accessor: 'activeFaults',
        id: 'activeFaults',
        isVisible: true,
        beField: 'ACTIVE_FAULTS',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.soilAndShaking'),
        accessor: 'soilAndShaking',
        id: 'soilAndShaking',
        isVisible: true,
        beField: 'SOIL_AND_SHAKING',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.overallRiskScore'),
        accessor: 'overallRiskScore',
        id: 'overallRiskScore',
        isVisible: true,
        beField: 'OVERALL_RISK_SCORE',
      },
      {
        label: t('processManager.manualUpload.locationScore.table.overallRiskIndex'),
        accessor: 'overallRiskIndex',
        id: 'overallRiskIndex',
        isVisible: true,
        beField: 'OVERALL_RISK_INDEX',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.overallPositionInClass'),
        accessor: 'overallPositionInClass',
        id: 'overallPositionInClass',
        isVisible: true,
        beField: 'OVERALL_POSITION_IN_CLASS',
      },
      {
        label: t('processManager.manualUpload.locationScore.table.earthquakeRiskScore'),
        accessor: 'earthquakeRiskScore',
        id: 'earthquakeRiskScore',
        isVisible: true,
        beField: 'EARTHQUAKE_RISK_SCORE',
      },
      {
        label: t('processManager.manualUpload.locationScore.table.earthquakeRiskIndex'),
        accessor: 'earthquakeRiskIndex',
        id: 'earthquakeRiskIndex',
        isVisible: true,
        beField: 'EARTHQUAKE_RISK_INDEX',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.earthquakePositionInClass'),
        accessor: 'earthquakePositionInClass',
        id: 'earthquakePositionInClass',
        isVisible: true,
        beField: 'EARTHQUAKE_POSITION_IN_CLASS',
      },
      {
        label: t('processManager.manualUpload.locationScore.table.stormRiskScore'),
        accessor: 'stormRiskScore',
        id: 'stormRiskScore',
        isVisible: true,
        beField: 'STORM_RISK_SCORE',
      },
      {
        label: t('processManager.manualUpload.locationScore.table.stormRiskIndex'),
        accessor: 'stormRiskIndex',
        id: 'stormRiskIndex',
        isVisible: true,
        beField: 'STORM_RISK_INDEX',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.stormPositionInClass'),
        accessor: 'stormPositionInClass',
        id: 'stormPositionInClass',
        isVisible: true,
        beField: 'STORM_POSITION_IN_CLASS',
      },
      {
        label: t('processManager.manualUpload.locationScore.table.floodRiskScore'),
        accessor: 'floodRiskScore',
        id: 'floodRiskScore',
        isVisible: true,
        beField: 'FLOOD_RISK_SCORE',
      },
      {
        label: t('processManager.manualUpload.locationScore.table.floodRiskIndex'),
        accessor: 'floodRiskIndex',
        id: 'floodRiskIndex',
        isVisible: true,
        beField: 'FLOOD_RISK_INDEX',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.floodPositionInClass'),
        accessor: 'floodPositionInClass',
        id: 'floodPositionInClass',
        isVisible: true,
        beField: 'FLOOD_POSITION_IN_CLASS',
      },
      {
        label: t('processManager.manualUpload.locationScore.table.floodDefenseLevelOfProtection'),
        accessor: 'floodDefenseLevelOfProtection',
        id: 'floodDefenseLevelOfProtection',
        isVisible: true,
        beField: 'FLOOD_DEFENSE_LEVEL_OF_PROTECTION',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t(
          'processManager.manualUpload.locationScore.table.floodDefenseStandardOfProtection',
        ),
        accessor: 'floodDefenseStandardOfProtection',
        id: 'floodDefenseStandardOfProtection',
        isVisible: true,
        beField: 'FLOOD_DEFENSE_STANDARD_OF_PROTECTION',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.tropicalCycloneCurrent'),
        accessor: 'tropicalCycloneCurrent',
        id: 'tropicalCycloneCurrent',
        isVisible: true,
        beField: 'TROPICAL_CYCLONE_CURRENT',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.tropicalCycloneRcp452030'),
        accessor: 'tropicalCycloneRcp452030',
        id: 'tropicalCycloneRcp452030',
        isVisible: true,
        beField: 'TROPICAL_CYCLONE_RCP452030',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.tropicalCycloneRcp452050'),
        accessor: 'tropicalCycloneRcp452050',
        id: 'tropicalCycloneRcp452050',
        isVisible: true,
        beField: 'TROPICAL_CYCLONE_RCP452050',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.tropicalCycloneRcp452100'),
        accessor: 'tropicalCycloneRcp452100',
        id: 'tropicalCycloneRcp452100',
        isVisible: true,
        beField: 'TROPICAL_CYCLONE_RCP452100',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.tropicalCycloneRcp852030'),
        accessor: 'tropicalCycloneRcp852030',
        id: 'tropicalCycloneRcp852030',
        isVisible: true,
        beField: 'TROPICAL_CYCLONE_RCP852030',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.tropicalCycloneRcp852050'),
        accessor: 'tropicalCycloneRcp852050',
        id: 'tropicalCycloneRcp852050',
        isVisible: true,
        beField: 'TROPICAL_CYCLONE_RCP852050',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.tropicalCycloneRcp852100'),
        accessor: 'tropicalCycloneRcp852100',
        id: 'tropicalCycloneRcp852100',
        isVisible: true,
        beField: 'TROPICAL_CYCLONE_RCP852100',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.riverFloodCurrentUndefended'),
        accessor: 'riverFloodCurrentUndefended',
        id: 'riverFloodCurrentUndefended',
        isVisible: true,
        beField: 'RIVER_FLOOD_CURRENT_UNDEFENDED',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.riverFloodCurrentDefended'),
        accessor: 'riverFloodCurrentDefended',
        id: 'riverFloodCurrentDefended',
        isVisible: true,
        beField: 'RIVER_FLOOD_CURRENT_DEFENDED',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.riverFloodRcp452030Undefended'),
        accessor: 'riverFloodRcp452030Undefended',
        id: 'riverFloodRcp452030Undefended',
        isVisible: true,
        beField: 'RIVER_FLOOD_RCP452030_UNDEFENDED',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.riverFloodRcp452030Defended'),
        accessor: 'riverFloodRcp452030Defended',
        id: 'riverFloodRcp452030Defended',
        isVisible: true,
        beField: 'RIVER_FLOOD_RCP452030_DEFENDED',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.riverFloodRcp452050Undefended'),
        accessor: 'riverFloodRcp452050Undefended',
        id: 'riverFloodRcp452050Undefended',
        isVisible: true,
        beField: 'RIVER_FLOOD_RCP452050_UNDEFENDED',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.riverFloodRcp452050Defended'),
        accessor: 'riverFloodRcp452050Defended',
        id: 'riverFloodRcp452050Defended',
        isVisible: true,
        beField: 'RIVER_FLOOD_RCP452050_DEFENDED',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.riverFloodRcp452100Undefended'),
        accessor: 'riverFloodRcp452100Undefended',
        id: 'riverFloodRcp452100Undefended',
        isVisible: true,
        beField: 'RIVER_FLOOD_RCP452100_UNDEFENDED',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.riverFloodRcp452100Defended'),
        accessor: 'riverFloodRcp452100Defended',
        id: 'riverFloodRcp452100Defended',
        isVisible: true,
        beField: 'RIVER_FLOOD_RCP452100_DEFENDED',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.riverFloodRcp852030Undefended'),
        accessor: 'riverFloodRcp852030Undefended',
        id: 'riverFloodRcp852030Undefended',
        isVisible: true,
        beField: 'RIVER_FLOOD_RCP852030_UNDEFENDED',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.riverFloodRcp852030Defended'),
        accessor: 'riverFloodRcp852030Defended',
        id: 'riverFloodRcp852030Defended',
        isVisible: true,
        beField: 'RIVER_FLOOD_RCP852030_DEFENDED',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.riverFloodRcp852050Undefended'),
        accessor: 'riverFloodRcp852050Undefended',
        id: 'riverFloodRcp852050Undefended',
        isVisible: true,
        beField: 'RIVER_FLOOD_RCP852050_UNDEFENDED',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.riverFloodRcp852050Defended'),
        accessor: 'riverFloodRcp852050Defended',
        id: 'riverFloodRcp852050Defended',
        isVisible: true,
        beField: 'RIVER_FLOOD_RCP852050_DEFENDED',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.riverFloodRcp852100Undefended'),
        accessor: 'riverFloodRcp852100Undefended',
        id: 'riverFloodRcp852100Undefended',
        isVisible: true,
        beField: 'RIVER_FLOOD_RCP852100_UNDEFENDED',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.riverFloodRcp852100Defended'),
        accessor: 'riverFloodRcp852100Defended',
        id: 'riverFloodRcp852100Defended',
        isVisible: true,
        beField: 'RIVER_FLOOD_RCP852100_DEFENDED',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.seaLevelRiseRcp26'),
        accessor: 'seaLevelRiseRcp26',
        id: 'seaLevelRiseRcp26',
        isVisible: true,
        beField: 'SEA_LEVEL_RISE_RCP26',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.seaLevelRiseRcp45'),
        accessor: 'seaLevelRiseRcp45',
        id: 'seaLevelRiseRcp45',
        isVisible: true,
        beField: 'SEA_LEVEL_RISE_RCP45',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.seaLevelRiseRcp85'),
        accessor: 'seaLevelRiseRcp85',
        id: 'seaLevelRiseRcp85',
        isVisible: true,
        beField: 'SEA_LEVEL_RISE_RCP85',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.fireWeatherCurrent'),
        accessor: 'fireWeatherCurrent',
        id: 'fireWeatherCurrent',
        isVisible: true,
        beField: 'FIRE_WEATHER_CURRENT',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.fireWeather1262030'),
        accessor: 'fireWeather1262030',
        id: 'fireWeather1262030',
        isVisible: true,
        beField: 'FIRE_WEATHER_1262030',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.fireWeather1262040'),
        accessor: 'fireWeather1262040',
        id: 'fireWeather1262040',
        isVisible: true,
        beField: 'FIRE_WEATHER_1262040',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.fireWeather1262050'),
        accessor: 'fireWeather1262050',
        id: 'fireWeather1262050',
        isVisible: true,
        beField: 'FIRE_WEATHER_1262050',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.fireWeather1262100'),
        accessor: 'fireWeather1262100',
        id: 'fireWeather1262100',
        isVisible: true,
        beField: 'FIRE_WEATHER_1262100',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.fireWeather2452030'),
        accessor: 'fireWeather2452030',
        id: 'fireWeather2452030',
        isVisible: true,
        beField: 'FIRE_WEATHER_2452030',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.fireWeather2452040'),
        accessor: 'fireWeather2452040',
        id: 'fireWeather2452040',
        isVisible: true,
        beField: 'FIRE_WEATHER_2452040',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.fireWeather2452050'),
        accessor: 'fireWeather2452050',
        id: 'fireWeather2452050',
        isVisible: true,
        beField: 'FIRE_WEATHER_2452050',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.fireWeather2452100'),
        accessor: 'fireWeather2452100',
        id: 'fireWeather2452100',
        isVisible: true,
        beField: 'FIRE_WEATHER_2452100',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.fireWeather3702030'),
        accessor: 'fireWeather3702030',
        id: 'fireWeather3702030',
        isVisible: true,
        beField: 'FIRE_WEATHER_3702030',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.fireWeather3702040'),
        accessor: 'fireWeather3702040',
        id: 'fireWeather3702040',
        isVisible: true,
        beField: 'FIRE_WEATHER_3702040',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.fireWeather3702050'),
        accessor: 'fireWeather3702050',
        id: 'fireWeather3702050',
        isVisible: true,
        beField: 'FIRE_WEATHER_3702050',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.fireWeather3702100'),
        accessor: 'fireWeather3702100',
        id: 'fireWeather3702100',
        isVisible: true,
        beField: 'FIRE_WEATHER_3702100',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.fireWeather5852030'),
        accessor: 'fireWeather5852030',
        id: 'fireWeather5852030',
        isVisible: true,
        beField: 'FIRE_WEATHER_5852030',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.fireWeather5852040'),
        accessor: 'fireWeather5852040',
        id: 'fireWeather5852040',
        isVisible: true,
        beField: 'FIRE_WEATHER_5852040',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.fireWeather5852050'),
        accessor: 'fireWeather5852050',
        id: 'fireWeather5852050',
        isVisible: true,
        beField: 'FIRE_WEATHER_5852050',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.fireWeather5852100'),
        accessor: 'fireWeather5852100',
        id: 'fireWeather5852100',
        isVisible: true,
        beField: 'FIRE_WEATHER_5852100',
        type: UiTableColumnDataType.NUMBER,
      },
      // Next, we'll add drought scenarios...
      {
        label: t('processManager.manualUpload.locationScore.table.droughtCurrent'),
        accessor: 'droughtCurrent',
        id: 'droughtCurrent',
        isVisible: true,
        beField: 'DROUGHT_CURRENT',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.drought2452030'),
        accessor: 'drought2452030',
        id: 'drought2452030',
        isVisible: true,
        beField: 'DROUGHT_2452030',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.drought2452040'),
        accessor: 'drought2452040',
        id: 'drought2452040',
        isVisible: true,
        beField: 'DROUGHT_2452040',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.drought2452050'),
        accessor: 'drought2452050',
        id: 'drought2452050',
        isVisible: true,
        beField: 'DROUGHT_2452050',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.drought2452100'),
        accessor: 'drought2452100',
        id: 'drought2452100',
        isVisible: true,
        beField: 'DROUGHT_2452100',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.drought5852030'),
        accessor: 'drought5852030',
        id: 'drought5852030',
        isVisible: true,
        beField: 'DROUGHT_5852030',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.drought5852040'),
        accessor: 'drought5852040',
        id: 'drought5852040',
        isVisible: true,
        beField: 'DROUGHT_5852040',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.drought5852050'),
        accessor: 'drought5852050',
        id: 'drought5852050',
        isVisible: true,
        beField: 'DROUGHT_5852050',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.drought5852100'),
        accessor: 'drought5852100',
        id: 'drought5852100',
        isVisible: true,
        beField: 'DROUGHT_5852100',
        type: UiTableColumnDataType.NUMBER,
      },
      // Heat-related scenarios
      {
        label: t('processManager.manualUpload.locationScore.table.heatCurrent'),
        accessor: 'heatCurrent',
        id: 'heatCurrent',
        isVisible: true,
        beField: 'HEAT_CURRENT',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.heat1262030'),
        accessor: 'heat1262030',
        id: 'heat1262030',
        isVisible: true,
        beField: 'HEAT_1262030',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.heat1262040'),
        accessor: 'heat1262040',
        id: 'heat1262040',
        isVisible: true,
        beField: 'HEAT_1262040',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.heat1262050'),
        accessor: 'heat1262050',
        id: 'heat1262050',
        isVisible: true,
        beField: 'HEAT_1262050',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.heat1262100'),
        accessor: 'heat1262100',
        id: 'heat1262100',
        isVisible: true,
        beField: 'HEAT_1262100',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.heat2452030'),
        accessor: 'heat2452030',
        id: 'heat2452030',
        isVisible: true,
        beField: 'HEAT_2452030',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.heat2452040'),
        accessor: 'heat2452040',
        id: 'heat2452040',
        isVisible: true,
        beField: 'HEAT_2452040',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.heat2452050'),
        accessor: 'heat2452050',
        id: 'heat2452050',
        isVisible: true,
        beField: 'HEAT_2452050',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.heat2452100'),
        accessor: 'heat2452100',
        id: 'heat2452100',
        isVisible: true,
        beField: 'HEAT_2452100',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.heat3702030'),
        accessor: 'heat3702030',
        id: 'heat3702030',
        isVisible: true,
        beField: 'HEAT_3702030',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.heat3702040'),
        accessor: 'heat3702040',
        id: 'heat3702040',
        isVisible: true,
        beField: 'HEAT_3702040',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.heat3702050'),
        accessor: 'heat3702050',
        id: 'heat3702050',
        isVisible: true,
        beField: 'HEAT_3702050',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.heat3702100'),
        accessor: 'heat3702100',
        id: 'heat3702100',
        isVisible: true,
        beField: 'HEAT_3702100',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.heat5852030'),
        accessor: 'heat5852030',
        id: 'heat5852030',
        isVisible: true,
        beField: 'HEAT_5852030',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.heat5852040'),
        accessor: 'heat5852040',
        id: 'heat5852040',
        isVisible: true,
        beField: 'HEAT_5852040',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.heat5852050'),
        accessor: 'heat5852050',
        id: 'heat5852050',
        isVisible: true,
        beField: 'HEAT_5852050',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.heat5852100'),
        accessor: 'heat5852100',
        id: 'heat5852100',
        isVisible: true,
        beField: 'HEAT_5852100',
        type: UiTableColumnDataType.NUMBER,
      },
      // Precipitation scenarios
      {
        label: t('processManager.manualUpload.locationScore.table.precipitationCurrent'),
        accessor: 'precipitationCurrent',
        id: 'precipitationCurrent',
        isVisible: true,
        beField: 'PRECIPITATION_CURRENT',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.precipitation1262030'),
        accessor: 'precipitation1262030',
        id: 'precipitation1262030',
        isVisible: true,
        beField: 'PRECIPITATION_1262030',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.precipitation1262040'),
        accessor: 'precipitation1262040',
        id: 'precipitation1262040',
        isVisible: true,
        beField: 'PRECIPITATION_1262040',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.precipitation1262050'),
        accessor: 'precipitation1262050',
        id: 'precipitation1262050',
        isVisible: true,
        beField: 'PRECIPITATION_1262050',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.precipitation1262100'),
        accessor: 'precipitation1262100',
        id: 'precipitation1262100',
        isVisible: true,
        beField: 'PRECIPITATION_1262100',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.precipitation2452030'),
        accessor: 'precipitation2452030',
        id: 'precipitation2452030',
        isVisible: true,
        beField: 'PRECIPITATION_2452030',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.precipitation2452040'),
        accessor: 'precipitation2452040',
        id: 'precipitation2452040',
        isVisible: true,
        beField: 'PRECIPITATION_2452040',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.precipitation2452050'),
        accessor: 'precipitation2452050',
        id: 'precipitation2452050',
        isVisible: true,
        beField: 'PRECIPITATION_2452050',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.precipitation2452100'),
        accessor: 'precipitation2452100',
        id: 'precipitation2452100',
        isVisible: true,
        beField: 'PRECIPITATION_2452100',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.precipitation3702030'),
        accessor: 'precipitation3702030',
        id: 'precipitation3702030',
        isVisible: true,
        beField: 'PRECIPITATION_3702030',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.precipitation3702040'),
        accessor: 'precipitation3702040',
        id: 'precipitation3702040',
        isVisible: true,
        beField: 'PRECIPITATION_3702040',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.precipitation3702050'),
        accessor: 'precipitation3702050',
        id: 'precipitation3702050',
        isVisible: true,
        beField: 'PRECIPITATION_3702050',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.precipitation3702100'),
        accessor: 'precipitation3702100',
        id: 'precipitation3702100',
        isVisible: true,
        beField: 'PRECIPITATION_3702100',
        type: UiTableColumnDataType.NUMBER,
      },
      // Cold scenarios
      {
        label: t('processManager.manualUpload.locationScore.table.coldCurrent'),
        accessor: 'coldCurrent',
        id: 'coldCurrent',
        isVisible: true,
        beField: 'COLD_CURRENT',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.cold1262030'),
        accessor: 'cold1262030',
        id: 'cold1262030',
        isVisible: true,
        beField: 'COLD_1262030',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.cold1262040'),
        accessor: 'cold1262040',
        id: 'cold1262040',
        isVisible: true,
        beField: 'COLD_1262040',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.cold1262050'),
        accessor: 'cold1262050',
        id: 'cold1262050',
        isVisible: true,
        beField: 'COLD_1262050',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.cold1262100'),
        accessor: 'cold1262100',
        id: 'cold1262100',
        isVisible: true,
        beField: 'COLD_1262100',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.cold2452030'),
        accessor: 'cold2452030',
        id: 'cold2452030',
        isVisible: true,
        beField: 'COLD_2452030',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.cold2452040'),
        accessor: 'cold2452040',
        id: 'cold2452040',
        isVisible: true,
        beField: 'COLD_2452040',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.cold2452050'),
        accessor: 'cold2452050',
        id: 'cold2452050',
        isVisible: true,
        beField: 'COLD_2452050',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.cold2452100'),
        accessor: 'cold2452100',
        id: 'cold2452100',
        isVisible: true,
        beField: 'COLD_2452100',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.cold3702030'),
        accessor: 'cold3702030',
        id: 'cold3702030',
        isVisible: true,
        beField: 'COLD_3702030',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.cold3702040'),
        accessor: 'cold3702040',
        id: 'cold3702040',
        isVisible: true,
        beField: 'COLD_3702040',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.cold3702050'),
        accessor: 'cold3702050',
        id: 'cold3702050',
        isVisible: true,
        beField: 'COLD_3702050',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.cold3702100'),
        accessor: 'cold3702100',
        id: 'cold3702100',
        isVisible: true,
        beField: 'COLD_3702100',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.cold5852030'),
        accessor: 'cold5852030',
        id: 'cold5852030',
        isVisible: true,
        beField: 'COLD_5852030',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.cold5852040'),
        accessor: 'cold5852040',
        id: 'cold5852040',
        isVisible: true,
        beField: 'COLD_5852040',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.cold5852050'),
        accessor: 'cold5852050',
        id: 'cold5852050',
        isVisible: true,
        beField: 'COLD_5852050',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.locationScore.table.cold5852100'),
        accessor: 'cold5852100',
        id: 'cold5852100',
        isVisible: true,
        beField: 'COLD_5852100',
        type: UiTableColumnDataType.NUMBER,
      },
    ],
    [t, commonColumns],
  );
};
