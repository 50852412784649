import { useMultiAppStore } from '@GlobalProviders/MultiApp/MultiAppProvider';

import { ProductSwitcher } from './ProductSwitcher';
import { UserInfo } from './UserInfo';

export const NavigationFooter = () => {
  const { activatedProducts } = useMultiAppStore();
  const hasTwoOrMoreActiveProducts = activatedProducts.length >= 2;

  return (
    <>
      {hasTwoOrMoreActiveProducts && <ProductSwitcher />}
      <UserInfo />
    </>
  );
};
