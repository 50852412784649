export default {
  navigationPanel: {
    appSwitcherTitle: 'ESG Apps',
    actions: {
      logout: 'Sign Out',
    },
  },
  emptyPagesPlaceholder: {
    title: 'There’s no data yet',
    subtitle: 'When there is, you’ll see it here.',
  },
  types: {
    appProducts: {
      GHG: 'Financed Emissions',
      PHYSICAL_RISK: 'Physical Risk',
    },
    quality: {
      inProgress: 'In Progress',
    },
    dataQuality: 'Score {{value}}',
    dataQualityNone: 'None',
    assetType: {
      LISTED_EQUITY_AND_CORPORATE_BONDS: 'Listed equity and Corporate Bonds',
      COMMERCIAL_REAL_ESTATE: 'Commercial Real Estate',
      PROJECT_FINANCE: 'Project Finance',
      MORTGAGES: 'Mortgages',
      MOTOR_VEHICLE_LOANS: 'Motor Vehicle Loans',
      SOVEREIGN_BONDS: 'Sovereign Bonds',
      BUSINESS_LOANS_AND_UNLISTED_EQUITY: 'Business loans and Unlisted Equity',
      SHIP_FINANCE: 'Ship Finance',
      AIRCRAFT_FINANCE: 'Aircraft Finance',
    },
    realEstateType: {
      MULTI_FAMILY_HOUSE: 'Multi Family House',
      RETAIL_SHOPPING_CENTER: 'Retail Shopping Center',
      HEALTHCARE: 'Healthcare',
      HOTEL: 'Hotel',
      INDUSTRIAL_DISTRIBUTION_WAREHOUSE: 'Industrial Distribution Warehouse',
      LEISURE_AND_SPORTS_FACILITIES: 'Leisure and Sports Facilities',
      NON_RESIDENTIAL_TOTAL: 'Non Residential Total',
      OFFICE: 'Office',
      RESIDENTIAL_TOTAL: 'Residential Total',
      RETAIL_HIGH_STREET: 'Retail High Street',
      RETAIL_SHOPPING_CENTRE: 'Retail Shopping Centre',
      RETAIL_STRIP_MALL: 'Retail Strip Mall',
      SINGLE_FAMILY_HOUSE: 'Single Family House',
    },
    entityType: {
      FINANCIAL_CONTRACT: 'Financial Contract',
      FINANCIAL_INSTRUMENT: 'Financial Instrument',
      BUSINESS_PARTNER: 'Business Partner',
      REPORTED_GOODS: 'Reported Goods',
      PROJECT: 'Project',
      BUILDING: 'Physical Asset - Building',
      PHYSICAL_ASSET: 'Physical Asset - General',
      PHYSICAL_ASSET_TO_FINANCIAL_CONTRACT: 'Physical Asset - Financial Contract',
      EMISSION_FACTOR_BUILDING_CERTIFICATE_CLASS: 'Emission Factor Building Certificate Class',
      EMISSION_FACTOR_SECTOR: 'Emission Factor Sector',
      EMISSION_FACTOR_NUMBER_OF_BUILDINGS: 'Emission Factor Number of Buildings',
      EMISSION_FACTOR_BUILDING_FLOOR_AREA: 'Emission Factor Floor Area',
      INDUSTRY_CLASSIFICATION_CODE: 'Industry Classification Code',
      ASSET_VALUATION: 'Physical Asset Valuation',
      REPORTED_ENERGY: 'Reported Energy',
      EMISSION_FACTOR_ENERGY: 'Emission Factor Energy',
      COUNTRY_REGION: 'Country-Region',
      EMISSION_FACTOR_GOODS: 'Emission Factor Goods',
      BUSINESS_PARTNER_ADDRESS: 'Business Partner Address Coordinates',
      PROJECT_ADDRESS: 'Project Address Coordinates',
      PHYSICAL_ASSET_ADDRESS: 'Physical Asset Address Coordinates',
      AIRCRAFT: 'Aircraft',
    },
    productType: {
      LOAN: 'Loan',
      MORTGAGE: 'Mortgage',
      CRE: 'Commercial Real Estate',
    },
    dataQualityScoreOption: {
      OPTION_1A: '1A',
      OPTION_1B: '1B',
      OPTION_2A: '2A',
      OPTION_2B: '2B',
      OPTION_3: '3',
      OPTION_3A: '3A',
      OPTION_3B: '3B',
      OPTION_3C: '3C',
      OPTION_9A: '9A',
      OPTION_NONE: 'None',
    },
  },
  exportTableData: {
    genericError: 'Unable to download file, please try again',
  },
  general: {
    errorFetchingActivatedProducts: 'Failed to check for activated products',
    httpErrorDictionary: {
      '403': 'You are not authorised to use this functionality',
    },
  },
  unsavedChangesDialog: {
    title: 'Unsaved Changes',
    message: 'You have unsaved changes. Do you want to leave this page?',
    ok: 'Ok',
    cancel: 'Cancel',
  },
  dataTables: {
    dataSets: 'Data Sets ({{count}})',
  },
  openVersionMenuButtonLabel: 'Version History',
};
