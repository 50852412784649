import styled from '@emotion/styled';
import { CardHeader, CardHeaderPropTypes } from '@ui5/webcomponents-react';

type Props = Pick<CardHeaderPropTypes, 'titleText' | 'subtitleText'> & { className?: string };

const StyledCardHeader = styled(CardHeader)`
  --_ui5_card_header_title_font_size: 1rem !important;
  --_ui5_card_header_padding: 1rem !important;
`;

export const UiCardHeader = ({ className, ...props }: Props) => (
  <StyledCardHeader className={className} {...props} />
);
