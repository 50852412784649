import { RouteObject } from 'react-router-dom';

import { INSIGHTS_ROUTE } from './routes';
import { InsightsGHGRouting } from '../GHG/InsightsGHGRouting';
import { InsightsPRRouting } from '../PR/InsightsPRRouting';

export const InsightsRouting: RouteObject = {
  path: INSIGHTS_ROUTE,
  children: [...InsightsGHGRouting, ...InsightsPRRouting],
};
