import {
  UiAnalyticalTableColumnDefinition,
  UiColumnFiltersType,
} from '@UiCommon/Components/UiAnalyticalTable/types';

import { getBeField } from '../utils';

type Props<T = Record<string, unknown>> = {
  filters?: UiColumnFiltersType[];
  columns?: UiAnalyticalTableColumnDefinition<T>[];
};

export const mapFiltersForPayload = <T>({ filters, columns }: Props<T>) => {
  return filters?.map(({ value, conditions }) => ({
    field: getBeField({ columnId: value as keyof T, columns }),
    conditions,
  }));
};
