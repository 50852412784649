import { useNavigate } from 'react-router-dom';

import { IconSet } from '@GlobalProviders/IconSetProvider';
import { useSharedTranslation } from '@GlobalProviders/i18n/hooks';
import { UiButton } from '@UiCommon/Components';

type Props = {
  latestProcessId?: string;
  isDisabled?: boolean;
};

export const OverviewHeaderButtons = ({ latestProcessId, isDisabled }: Props) => {
  const { t } = useSharedTranslation();
  const navigate = useNavigate();

  const handleVersioningNavigation = () => {
    if (!latestProcessId) throw new Error('Current Process id is not available');
    navigate(latestProcessId);
  };

  return (
    <UiButton
      disabled={isDisabled}
      icon={IconSet.HISTORY}
      design='Transparent'
      onClick={handleVersioningNavigation}
    >
      {t('openVersionMenuButtonLabel')}
    </UiButton>
  );
};
