import { useCallback } from 'react';
import { DragEndEvent } from '@dnd-kit/core';

import { UiListItemType } from '../types';

export const useListDragReorderHandler = <T>(
  items?: UiListItemType<T>[],
  onItemsOrderChange?: (items: UiListItemType<T>[], resourceId?: string) => void,
  resourceId?: string,
) =>
  useCallback(
    ({ active: { id: activeId }, over }: DragEndEvent) => {
      if (!items || !onItemsOrderChange || !over) return;

      const activeItem = items.find(({ value }) => value === activeId);
      const overIndex = items.findIndex(({ value }) => value === over.id);

      if (activeItem) {
        const newItems = items.filter(({ value }) => value !== activeId);
        newItems.splice(overIndex, 0, activeItem);
        onItemsOrderChange(newItems, resourceId);
      }
    },
    [items, onItemsOrderChange],
  );
