import { useCallback, useState } from 'react';

export const useOnHoverElementSupport = () => {
  const [isHovered, setIsHovered] = useState(false);

  const onMouseEnter = useCallback(() => setIsHovered(true), [setIsHovered]);
  const onMouseLeave = useCallback(() => setIsHovered(false), [setIsHovered]);

  return {
    isHovered,
    eventListeners: {
      onMouseEnter,
      onMouseLeave,
    },
  };
};
