import styled from '@emotion/styled';
import { Outlet, useNavigate } from 'react-router-dom';

import { TabConfig, UiTabSwitcher } from '@UiCommon/Components/UiTabSwitcher';

import { PageHeader } from './Headers/PageHeader';
import { SubPageHeader } from './Headers/SubPageHeader';

const StyledTabSwitcher = styled(UiTabSwitcher)`
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
`;

type Props = {
  isSubpage?: boolean;
  defaultRoute: string;
  tabsConfig: TabConfig[];
  title: string;
  productName: string;
  subtitle?: string;
  renderCustomActions?: () => React.ReactNode | React.ReactNode[];
  outletContext?: unknown;
};

export const SectionObjectPage = ({
  defaultRoute,
  tabsConfig,
  title,
  productName,
  renderCustomActions,
  outletContext,
  subtitle,
  isSubpage,
}: Props) => {
  const navigate = useNavigate();

  const activeTab = tabsConfig.find(({ id }) => location.pathname.includes(id))?.id ?? defaultRoute;

  const handleTabClick = (url: string) => navigate({ pathname: url });

  return (
    <>
      {!isSubpage && (
        <PageHeader
          title={title}
          productName={productName}
          subtitle={subtitle}
          renderCustomActions={renderCustomActions}
        />
      )}
      {isSubpage && (
        <SubPageHeader
          title={title}
          productName={productName}
          subtitle={subtitle}
          renderCustomActions={renderCustomActions}
        />
      )}
      <StyledTabSwitcher
        selectedTab={activeTab}
        onTabChange={handleTabClick}
        tabsConfig={tabsConfig}
      />
      <Outlet context={outletContext} />
    </>
  );
};
