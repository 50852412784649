import { Token } from '@ui5/webcomponents-react';

import { CommonProps } from '../types';

type Props = CommonProps & {
  text: string;
  readonly?: boolean;
};

export const UiToken = ({ ...props }: Props) => <Token {...props} />;
