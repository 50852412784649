import api from '../../api';
import { PaginatedRqHookProps } from '../../types';
import { useTablePaginatedQuery } from '../../utils';
import { GHGFiles } from './GHGFiles.keys';
import {
  CollectedAssetValuationDto,
  CollectedBPAddressDto,
  CollectedBuildingDto,
  CollectedBuildingFinancialContractAssociationDto,
  CollectedBusinessPartnerDto,
  CollectedCountryRegionDto,
  CollectedEmissionBuildingCertificatesDto,
  CollectedEmissionFactorEnergyDto,
  CollectedEmissionFactorFloorAreaDto,
  CollectedEmissionFactorGoodsDto,
  CollectedEmissionFactorNumberOfBuildingsDto,
  CollectedEmissionFactorSectorDto,
  CollectedFinancialContractDto,
  CollectedFinancialInstrumentDto,
  CollectedIndustryClassificationCodeDto,
  CollectedPAAddressDto,
  CollectedPhysicalAssetDto,
  CollectedPhysicalAssetFinancialContractAssociationDto,
  CollectedProjectAddressDto,
  CollectedProjectDto,
  CollectedReportedEnergyDto,
  CollectedReportedGoodsDto,
} from './GHGFiles.types';

export const useCollectedBusinessPartnerQuery = (
  rest: PaginatedRqHookProps<CollectedBusinessPartnerDto> = {},
) => {
  return useTablePaginatedQuery<CollectedBusinessPartnerDto>({
    apiCall: api.ghg.files.getCollectedBusinessPartner,
    key: GHGFiles.collectedBusinessPartner.list,
    ...rest,
  });
};

export const useCollectedProjectQuery = (rest: PaginatedRqHookProps<CollectedProjectDto> = {}) => {
  return useTablePaginatedQuery<CollectedProjectDto>({
    apiCall: api.ghg.files.getCollectedProject,
    key: GHGFiles.collectedProject.list,
    ...rest,
  });
};

export const useCollectedBuildingQuery = (
  rest: PaginatedRqHookProps<CollectedBuildingDto> = {},
) => {
  return useTablePaginatedQuery<CollectedBuildingDto>({
    apiCall: api.ghg.files.getCollectedBuilding,
    key: GHGFiles.collectedBuilding.list,
    ...rest,
  });
};

export const useCollectedFinancialContract = (
  rest: PaginatedRqHookProps<CollectedFinancialContractDto> = {},
) => {
  return useTablePaginatedQuery<CollectedFinancialContractDto>({
    apiCall: api.ghg.files.getCollectedFinancialContract,
    key: GHGFiles.collectedFinancialContract.list,
    ...rest,
  });
};

export const useCollectedFinancialInstrument = (
  rest: PaginatedRqHookProps<CollectedFinancialInstrumentDto> = {},
) => {
  return useTablePaginatedQuery<CollectedFinancialInstrumentDto>({
    apiCall: api.ghg.files.getCollectedFinancialInstrument,
    key: GHGFiles.collectedFinancialInstrument.list,
    ...rest,
  });
};

export const useCollectedPhysicalAsset = (
  rest: PaginatedRqHookProps<CollectedPhysicalAssetDto> = {},
) => {
  return useTablePaginatedQuery<CollectedPhysicalAssetDto>({
    apiCall: api.ghg.files.getCollectedPhysicalAsset,
    key: GHGFiles.collectedPhysicalAsset.list,
    ...rest,
  });
};

export const useCollectedIndustryClassificationCode = (
  rest: PaginatedRqHookProps<CollectedIndustryClassificationCodeDto> = {},
) => {
  return useTablePaginatedQuery<CollectedIndustryClassificationCodeDto>({
    apiCall: api.ghg.files.getCollectedIndustryClassificationCode,
    key: GHGFiles.collectedIndustryClassificationCode.list,
    ...rest,
  });
};

export const useCollectedReportedGoods = (
  rest: PaginatedRqHookProps<CollectedReportedGoodsDto> = {},
) => {
  return useTablePaginatedQuery<CollectedReportedGoodsDto>({
    apiCall: api.ghg.files.getCollectedReportedGoods,
    key: GHGFiles.collectedReportedGoods.list,
    ...rest,
  });
};

export const useCollectedBuildingFinancialContractAssociation = (
  rest: PaginatedRqHookProps<CollectedBuildingFinancialContractAssociationDto> = {},
) => {
  return useTablePaginatedQuery<CollectedBuildingFinancialContractAssociationDto>({
    apiCall: api.ghg.files.getCollectedBuildingFinancialContractAssociation,
    key: GHGFiles.collectedBuildingFinancialContractAssociation.list,
    ...rest,
  });
};

export const useCollectedPhysicalAssetFinancialContractAssociation = (
  rest: PaginatedRqHookProps<CollectedPhysicalAssetFinancialContractAssociationDto> = {},
) => {
  return useTablePaginatedQuery<CollectedPhysicalAssetFinancialContractAssociationDto>({
    apiCall: api.ghg.files.getCollectedPhysicalAssetFinancialContractAssociation,
    key: GHGFiles.collectedPhysicalAssetFinancialContractAssociation.list,
    ...rest,
  });
};

export const useCollectedEmissionBuildingCertificates = (
  rest: PaginatedRqHookProps<CollectedEmissionBuildingCertificatesDto> = {},
) => {
  return useTablePaginatedQuery<CollectedEmissionBuildingCertificatesDto>({
    apiCall: api.ghg.files.getCollectedEmissionBuildingCertificates,
    key: GHGFiles.collectedEmissionBuildingCertificates.list,
    ...rest,
  });
};

export const useCollectedEmissionFactorNumberOfBuildings = (
  rest: PaginatedRqHookProps<CollectedEmissionFactorNumberOfBuildingsDto> = {},
) => {
  return useTablePaginatedQuery<CollectedEmissionFactorNumberOfBuildingsDto>({
    apiCall: api.ghg.files.getCollectedEmissionFactorNumberOfBuildings,
    key: GHGFiles.collectedEmissionFactorNumberOfBuildings.list,
    ...rest,
  });
};

export const useCollectedCountryRegion = (
  rest: PaginatedRqHookProps<CollectedCountryRegionDto> = {},
) => {
  return useTablePaginatedQuery<CollectedCountryRegionDto>({
    apiCall: api.ghg.files.getCollectedCountryRegion,
    key: GHGFiles.collectedCountryRegion.list,
    ...rest,
  });
};

export const useCollectedEmissionFactorFloorArea = (
  rest: PaginatedRqHookProps<CollectedEmissionFactorFloorAreaDto> = {},
) => {
  return useTablePaginatedQuery<CollectedEmissionFactorFloorAreaDto>({
    apiCall: api.ghg.files.getCollectedEmissionFactorFloorArea,
    key: GHGFiles.collectedEmissionFactorFloorArea.list,
    ...rest,
  });
};

export const useCollectedEmissionFactorSector = (
  rest: PaginatedRqHookProps<CollectedEmissionFactorSectorDto> = {},
) => {
  return useTablePaginatedQuery<CollectedEmissionFactorSectorDto>({
    apiCall: api.ghg.files.getCollectedEmissionFactorSector,
    key: GHGFiles.collectedEmissionFactorSector.list,
    ...rest,
  });
};

export const useCollectedAssetValuation = (
  rest: PaginatedRqHookProps<CollectedAssetValuationDto> = {},
) => {
  return useTablePaginatedQuery<CollectedAssetValuationDto>({
    apiCall: api.ghg.files.getCollectedAssetValuation,
    key: GHGFiles.collectedAssetValuation.list,
    ...rest,
  });
};

export const useCollectedReportedEnergy = (
  rest: PaginatedRqHookProps<CollectedReportedEnergyDto> = {},
) => {
  return useTablePaginatedQuery<CollectedReportedEnergyDto>({
    apiCall: api.ghg.files.getCollectedReportedEnergy,
    key: GHGFiles.collectedReportedEnergy.list,
    ...rest,
  });
};

export const useCollectedEmissionFactorGoods = (
  rest: PaginatedRqHookProps<CollectedEmissionFactorGoodsDto> = {},
) => {
  return useTablePaginatedQuery<CollectedEmissionFactorGoodsDto>({
    apiCall: api.ghg.files.getCollectedEmissionFactorGoods,
    key: GHGFiles.collectedEmissionFactorGoods.list,
    ...rest,
  });
};

export const useCollectedEmissionFactorEnergy = (
  rest: PaginatedRqHookProps<CollectedEmissionFactorEnergyDto> = {},
) => {
  return useTablePaginatedQuery<CollectedEmissionFactorEnergyDto>({
    apiCall: api.ghg.files.getCollectedEmissionFactorEnergy,
    key: GHGFiles.collectedEmissionFactorEnergy.list,
    ...rest,
  });
};

export const useCollectedBPAddressQuery = (
  rest: PaginatedRqHookProps<CollectedBPAddressDto> = {},
) => {
  return useTablePaginatedQuery<CollectedBPAddressDto>({
    apiCall: api.ghg.files.getCollectedBPAddress,
    key: GHGFiles.collectedBPAddress.list,
    ...rest,
  });
};

export const useCollectedProjectAddressQuery = (
  rest: PaginatedRqHookProps<CollectedProjectAddressDto> = {},
) => {
  return useTablePaginatedQuery<CollectedProjectAddressDto>({
    apiCall: api.ghg.files.getCollectedProjectAddress,
    key: GHGFiles.collectedProjectAddress.list,
    ...rest,
  });
};

export const useCollectedPAAddressQuery = (
  rest: PaginatedRqHookProps<CollectedPAAddressDto> = {},
) => {
  return useTablePaginatedQuery<CollectedPAAddressDto>({
    apiCall: api.ghg.files.getCollectedPhysicalAssetAddress,
    key: GHGFiles.collectedPhysicalAssetAddress.list,
    ...rest,
  });
};
