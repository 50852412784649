import { isNil } from 'lodash';
import { useCallback, useState } from 'react';

import {
  UiAnalyticalTableColumnDefinition,
  UiColumnFiltersType,
  UiTableColumnDataType,
} from '../../types';
import { mapToFiltersWithLabels } from '../../utils';
import { ColumnFiltersType, FilterOption } from '../types';
import { mapFilterConditionsByColumnType } from './Conditions/mapFilterConditionsByColumnType';

type SettingsStateControls<DataType> = {
  columnsToBeFiltered: ColumnFiltersType[];
  onColumnsToBeFiltered: (
    columns: UiAnalyticalTableColumnDefinition<DataType>[],
  ) => (selected: ColumnFiltersType[]) => void;
  initStateWithData: (
    selectedFilters: UiColumnFiltersType[],
    columns: UiAnalyticalTableColumnDefinition<DataType>[],
  ) => void;
  getDataFromState: () => UiColumnFiltersType[];
  options: FilterOption[];
  reset: () => void;
};

export const useFilterSettingsState = <DataType>(): SettingsStateControls<DataType> => {
  const [columnsToBeFiltered, setColumnsToBeFiltered] = useState<ColumnFiltersType[]>([]);

  const [options, setOptions] = useState<FilterOption[]>([]);

  const reset = useCallback(() => {
    setColumnsToBeFiltered([]);
  }, [setColumnsToBeFiltered]);

  const initStateWithData = useCallback(
    (
      selectedFilters: UiColumnFiltersType[],
      columns: UiAnalyticalTableColumnDefinition<DataType>[],
    ) => {
      const columnItems = columns
        .filter((item) => !item.disableFilters)
        .map((item) => ({ label: item.label, value: item.id }));
      setOptions(columnItems);
      const selectedFilterWithLabels = mapToFiltersWithLabels(selectedFilters, columns);
      setColumnsToBeFiltered(selectedFilterWithLabels);
    },
    [setColumnsToBeFiltered],
  );

  const getDataFromState = useCallback(() => {
    // eslint-disable-next-line
    const columnsToBeFilteredWithValues = columnsToBeFiltered.map(({ label, ...rest }) => ({
      ...rest,
    }));

    columnsToBeFilteredWithValues.forEach((column) => {
      const conditionsWithValue = column.conditions.filter(({ value }) => !isNil(value));
      column.conditions = conditionsWithValue;
    });

    return columnsToBeFilteredWithValues.filter(({ conditions }) => conditions.length > 0);
  }, [columnsToBeFiltered]);

  const onColumnsToBeFiltered = (columns: UiAnalyticalTableColumnDefinition<DataType>[]) => {
    return (selected: ColumnFiltersType[]) => {
      const selectedFiltersWithType = selected.map(({ conditions, ...rest }) => {
        const type =
          columns.find((c) => c.accessor === rest.value)?.type || UiTableColumnDataType.TEXT;

        const newConditions = [...conditions];
        if (newConditions.length === 0) {
          const defaultCondition = mapFilterConditionsByColumnType(type)[0];
          newConditions.push({ columnCondition: defaultCondition });
        }

        const output: ColumnFiltersType = {
          ...rest,
          conditions: newConditions,
          type,
        };

        return output;
      });
      setColumnsToBeFiltered(selectedFiltersWithType);
    };
  };

  return {
    columnsToBeFiltered,
    onColumnsToBeFiltered,
    initStateWithData,
    getDataFromState,
    options,
    reset,
  };
};
