import styled from '@emotion/styled';
import { AnalyticalTable, CommonProps } from '@ui5/webcomponents-react';
import { ThemingParameters } from '@ui5/webcomponents-react-base';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { UiListItemType } from '../UiList/types';
import { UiTitle } from '../UiTitle';
import { AnalyticalTableContextProvider } from './AnalyticalTableContext';
import { SortingListItemProps } from './SettingsModal/SortSettings/useSortingSettingsState';
import { SettingsTab } from './SettingsModal/useSettingsModalTabs';
import { UiAnalyticalTableColumnDefinition, SortingOrder } from './types';
import { useOnColumnsReorderHandler } from './useOnColumnsReorderHandler';
import { UiHeader } from './UiHeader/UiHeader';

const TableWrapper = styled.div`
  width: 100%;
  overflow: auto;
  border-top: 1px solid ${ThemingParameters.sapList_BorderColor};

  div[class^='AnalyticalTable-th'] {
    padding-inline: 1rem;
  }

  div[class^='AnalyticalTable-tableCell'] {
    padding-inline: 1rem !important;
  }
`;

const TableHeader = styled.div`
  border-bottom: 1px solid ${ThemingParameters.sapPageHeader_BorderColor};
  height: 2.75rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
`;

const ActionsHeaderWrapper = styled.section`
  display: flex;
  gap: 0.5rem;
`;

type UiTableProps<DataType> = {
  columns: UiAnalyticalTableColumnDefinition<DataType>[];
  onColumnsReorder?: (columns: UiAnalyticalTableColumnDefinition<DataType>[]) => void;
  data: Record<string, unknown>[];
  title?: string;
  sortable?: boolean;
  onLoadMore?: () => void;
  totalNumberOfItems?: number;
  infiniteScroll?: boolean;
  infiniteScrollThreshold?: number;
  numberOfItems?: number;
  visibleRows?: number;
  headerActions?: CommonProps['children'];
  sortingItems?: UiListItemType<SortingListItemProps>[];
  className?: string;
  renderHeaderComponent?: (props: HeaderProps) => ReactNode;
  onHeaderSettingsItemClick?: (tabId: SettingsTab) => void;
  onHeaderSortClick?: (id: string, order: SortingOrder) => void;
  rowHeight?: number;
};

const HEADER_PADDING = 16;
const HEADER_BORDER = 1;
const SORT_ICON_MARGIN = 16;
const SORT_ICON = 8;
const HEADER_SPACING = 2 * HEADER_PADDING + HEADER_BORDER + SORT_ICON_MARGIN + SORT_ICON;
const MAX_CHAR_SIZE = 8;
const computeByLength = (length: number) => MAX_CHAR_SIZE * length + HEADER_SPACING;

type HeaderProps = {
  label: string;
  id: string;
  disableFilters?: boolean;
  disableSortBy?: boolean;
};

export const UiAnalyticalTable = <DataType,>({
  columns,
  onColumnsReorder,
  data,
  sortable = false,
  onLoadMore,
  title,
  totalNumberOfItems = 0,
  infiniteScrollThreshold = 20,
  visibleRows = 20,
  headerActions,
  sortingItems,
  infiniteScroll = true,
  onHeaderSettingsItemClick,
  onHeaderSortClick,
  renderHeaderComponent,
  className,
  rowHeight,
}: UiTableProps<DataType>) => {
  const ref = useRef<HTMLDivElement>(null);
  const visibleRowsValue = Math.max(Math.min(totalNumberOfItems, visibleRows), 1);
  const onColumnsReorderHandler = useOnColumnsReorderHandler(columns, onColumnsReorder);
  const [hasResized, setHasResized] = useState(false);

  const renderHeaderFn = useCallback(
    renderHeaderComponent ??
      ((props: HeaderProps) => {
        return (
          <UiHeader
            disableSortBy={props.disableSortBy}
            disableFilters={props.disableFilters}
            title={props.label}
            id={props.id}
          />
        );
      }),
    [renderHeaderComponent],
  );

  useEffect(() => {
    const handler = () => setHasResized((previousValue) => !previousValue); // toggle resize
    window.addEventListener('resize', handler);

    return () => window.removeEventListener('resize', handler);
  }, []);

  const containerWidth = useMemo(
    () => ref.current?.clientWidth ?? 0,
    [ref, columns, data, hasResized],
  );

  const visibleColumns = useMemo(() => columns.filter(({ isVisible }) => isVisible), [columns]);

  const visibleColumnsOrder = useMemo(() => visibleColumns.map(({ id }) => id), [visibleColumns]);

  const totalRequiredWidth = useMemo(
    () => visibleColumns.reduce((acc, c) => (acc += computeByLength(c.label.length)), 0),
    [visibleColumns],
  );

  const visibleColumnsWithComputedWidth = useMemo(
    () =>
      visibleColumns.map((c) => {
        if (!containerWidth || totalRequiredWidth < containerWidth) return c;

        return {
          ...c,
          width: c.label.length === 0 ? c.minWidth || 50 : computeByLength(c.label.length),
        };
      }),
    [containerWidth, totalRequiredWidth, visibleColumns, hasResized],
  );

  const formattedColumns = useMemo(
    () =>
      visibleColumnsWithComputedWidth.map((column) => ({
        ...column,
        Header: () =>
          renderHeaderFn({
            label: column.label,
            id: column.id,
            disableSortBy: column.disableSortBy,
            disableFilters: column.disableFilters,
          }),
      })),
    [visibleColumnsWithComputedWidth, renderHeaderFn],
  );

  return (
    <TableWrapper className={className} ref={ref}>
      {(title || headerActions) && (
        <TableHeader>
          {<UiTitle level='H5'>{title}</UiTitle>}
          <ActionsHeaderWrapper>{headerActions}</ActionsHeaderWrapper>
        </TableHeader>
      )}
      <AnalyticalTableContextProvider
        onHeaderSettingsItemClick={onHeaderSettingsItemClick}
        onHeaderSortClick={onHeaderSortClick}
        sortingItems={sortingItems}
      >
        <AnalyticalTable
          reactTableOptions={{
            manualSortBy: true,
          }}
          visibleRows={visibleRowsValue}
          infiniteScrollThreshold={infiniteScrollThreshold}
          columns={formattedColumns}
          columnOrder={visibleColumnsOrder}
          onColumnsReorder={onColumnsReorderHandler}
          data={data}
          infiniteScroll={infiniteScroll}
          sortable={sortable}
          onLoadMore={onLoadMore}
          rowHeight={rowHeight}
        />
      </AnalyticalTableContextProvider>
    </TableWrapper>
  );
};
