import { ButtonDesign } from '@ui5/webcomponents-react';
import styled from '@emotion/styled';
import { ThemingParameters } from '@ui5/webcomponents-react-base';

import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';
import { IconSet } from '@GlobalProviders/IconSetProvider';
import { UiDialog, UiButton, UiText, UiIcon, UiTitle } from '@UiCommon/Components';

type DeleteConfirmationProps = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};
const Header = styled.div`
  display: flex;
  align-items: center;
  height: 3rem;
  gap: 0.5rem;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
  gap: 0.5rem;
`;
const StyledIcon = styled(UiIcon)`
  color: ${ThemingParameters.sapAccentColor1};
`;

export const DeleteConfirmationModal = ({
  isOpen,
  onConfirm,
  onCancel,
}: DeleteConfirmationProps) => {
  const { t } = useGHGTranslation();

  return (
    <UiDialog
      header={
        <Header>
          <StyledIcon name={IconSet.WARNING} />
          <UiTitle level='H5'>{t('splitScope.deleteRule')}</UiTitle>
        </Header>
      }
      isOpen={isOpen}
      footer={
        <Footer>
          <UiButton onClick={onConfirm} design={ButtonDesign.Emphasized}>
            {t('splitScope.ok')}
          </UiButton>
          <UiButton onClick={onCancel} design={ButtonDesign.Transparent}>
            {t('splitScope.cancel')}
          </UiButton>
        </Footer>
      }
    >
      <UiText>Are you sure you want to delete row?</UiText>
    </UiDialog>
  );
};
