import { EntityType } from '@DataAccess/Platform/FileUpload/fileUpload.types';
import { useProcessManagerTranslation } from '@GlobalProviders/i18n/hooks';
import { EntityGroup } from '@Pages/DataManagement/ProcessManager/ManualUpload/Components/UploadDialog/UploadDialog';

export const useGHGUploadModalEntities = (): EntityGroup[] => {
  const { t } = useProcessManagerTranslation();

  return [
    {
      title: t('processManager.manualUpload.uploadDialog.table.classifications'),
      data: [EntityType.INDUSTRY_CLASSIFICATION_CODE, EntityType.COUNTRY_REGION],
    },
    {
      title: t('processManager.manualUpload.uploadDialog.table.statisticalData'),

      data: [
        EntityType.EMISSION_FACTOR_SECTOR,
        EntityType.EMISSION_FACTOR_ENERGY,
        EntityType.EMISSION_FACTOR_BUILDING_CERTIFICATE_CLASS,
        EntityType.EMISSION_FACTOR_BUILDING_FLOOR_AREA,
        EntityType.EMISSION_FACTOR_NUMBER_OF_BUILDINGS,
        EntityType.EMISSION_FACTOR_GOODS,
      ],
    },
    {
      title: t('processManager.manualUpload.uploadDialog.table.productionConsumption'),
      data: [EntityType.REPORTED_ENERGY, EntityType.REPORTED_GOODS],
    },
    {
      title: t('processManager.manualUpload.uploadDialog.table.ghgEmittingObjects'),
      data: [
        EntityType.BUSINESS_PARTNER,
        EntityType.PROJECT,
        EntityType.PHYSICAL_ASSET,
        EntityType.BUILDING,
      ],
    },
    {
      title: t('processManager.manualUpload.uploadDialog.table.valuations'),
      data: [EntityType.ASSET_VALUATION],
    },
    {
      title: t('processManager.manualUpload.uploadDialog.table.financialProducts'),
      data: [EntityType.FINANCIAL_CONTRACT, EntityType.FINANCIAL_INSTRUMENT],
    },
    {
      title: t('processManager.manualUpload.uploadDialog.table.associations'),
      data: [EntityType.PHYSICAL_ASSET_TO_FINANCIAL_CONTRACT],
    },
  ];
};
