import { Button } from '@ui5/webcomponents-react';
import styled from '@emotion/styled';
import { ThemingParameters } from '@ui5/webcomponents-react-base';

import { IconSet } from '@GlobalProviders/IconSetProvider';

import { useNavigationPanelContext } from '../NavigationPanelContext';

const PanelSwitcherWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: -0.8rem;
  --sapButton_Background: ${ThemingParameters.sapBaseColor};
  --_ui5_button_base_min_width: 1.5rem !important;
  --_ui5_button_base_height: 1.5rem !important;
`;

export const PanelSwitcher = () => {
  const { isPanelExtended, togglePanel } = useNavigationPanelContext();
  const buttonIconName = isPanelExtended ? IconSet.SLIM_ARROW_LEFT : IconSet.SLIM_ARROW_RIGHT;

  return (
    <PanelSwitcherWrapper>
      <Button onClick={togglePanel} icon={buttonIconName} />
    </PanelSwitcherWrapper>
  );
};
