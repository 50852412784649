import styled from '@emotion/styled';
import { DateTimePicker, Label } from '@ui5/webcomponents-react';
import { format, parseISO } from 'date-fns';

import { GenericInputWrapper, commonInputProperties } from '../styles';
import { useUiDateTimePickerOnChangeHandler } from './useUiDateTimePickerOnChangeHandler';
import { CommonProps, ElementSize } from '../../types';
import { PRIMARY_CALENDAR_TYPE } from '../constants';

const DATE_FORMAT = 'yyyy-MM-dd HH:mm';

type Props = CommonProps & {
  size?: ElementSize;
  value?: string;
  onChange?: (value?: string) => void;
  label?: string;
};

const StyledDatePicker = styled(DateTimePicker)`
  ${commonInputProperties()};
`;
const formatValue = (value?: string) => {
  return value ? format(parseISO(value), DATE_FORMAT) : '';
};

export const UiDateTimePicker = ({ value, size = ElementSize.SMALL, onChange, label }: Props) => {
  const onChangeHandler = useUiDateTimePickerOnChangeHandler(onChange);

  return (
    <GenericInputWrapper>
      {label && <Label>{label}:</Label>}
      <StyledDatePicker
        value={formatValue(value)}
        onChange={onChangeHandler}
        formatPattern={DATE_FORMAT}
        primaryCalendarType={PRIMARY_CALENDAR_TYPE}
        data-ui5-compact-size={size === ElementSize.SMALL ? true : undefined}
      />
    </GenericInputWrapper>
  );
};
