import styled from '@emotion/styled';
import { ThemingParameters } from '@ui5/webcomponents-react-base';

import { AssetType } from '@Types/types';
import { UiTableCell, UiTableRow } from '@UiCommon/Components';
import { UiNumberFormatter } from '@UiCommon/Components/UiFormatter/UiNumberFormatter';
import { DataQualityScore } from '@Pages/Shared/SmartComponents/DataQualityScore/DataQualityScore';
import { DataQualityData } from '@DataAccess/GHG/DataQuality/dataQuality.types';
import { UiCurrencyFormatter } from '@UiCommon/Components/UiFormatter/UiCurrencyFormatter';
import { useSharedTranslation } from '@GlobalProviders/i18n/hooks';

type Props = {
  assetClass: AssetType;
  rows: DataQualityData[];
};

const CellWithTopBorder = styled(UiTableCell)`
  border-top: 1px solid ${ThemingParameters.sapList_BorderColor} !important;
`;

export const AssetClassScoreSection = ({ assetClass, rows }: Props) => {
  const { t } = useSharedTranslation();

  return (
    <>
      {rows.map(({ dataSets, exposure, financedEmissions, dataQualityScore }, idx) => (
        <UiTableRow key={assetClass + dataQualityScore}>
          {idx === 0 ? (
            <CellWithTopBorder>{t(`types.assetType.${assetClass}`)}</CellWithTopBorder>
          ) : (
            <UiTableCell />
          )}
          <UiTableCell>
            <DataQualityScore score={dataQualityScore} />
          </UiTableCell>
          <UiTableCell>
            <UiNumberFormatter value={dataSets} decimalPrecision={0} />
          </UiTableCell>
          <UiTableCell>
            <UiCurrencyFormatter value={exposure} decimalPrecision={0} />
          </UiTableCell>
          <UiTableCell>
            <UiNumberFormatter value={financedEmissions} decimalPrecision={0} />
          </UiTableCell>
        </UiTableRow>
      ))}
    </>
  );
};
