import { AxiosInstance } from 'axios';

import { PaginatedTableParams, PaginationResponse } from '../../types';
import { CollectedLocationScoreDto } from './PRFiles.types';

export const initialisePrFilesEndpoints = (api: AxiosInstance) => ({
  getCollectedLocationScore({ tableParams, pageParam }: PaginatedTableParams) {
    return api
      .post<PaginationResponse<CollectedLocationScoreDto>>('collected/location-score', {
        page: pageParam,
        ...tableParams,
      })
      .then(({ data }) => data);
  },
});
