import { useCallback } from 'react';
import { FileUploaderDomRef, Ui5CustomEvent } from '@ui5/webcomponents-react';

export const useFileUploadButtonOnChangeHandler = (callback?: (files: FileList | null) => void) =>
  useCallback(
    (event: Ui5CustomEvent<FileUploaderDomRef, { files: FileList | null }>) => {
      if (!callback) return;

      const {
        detail: { files },
      } = event;

      callback(files);
    },
    [callback],
  );
