import { useMemo } from 'react';

import { UiVariantItem } from '../../UiVariantManagement/UiVariantItem';
import { UiVariantManagement } from '../../UiVariantManagement/UiVariantManagement';
import { SaveManageViewsProps } from '../../UiVariantManagement/useUiVariantManagementCallbacks';
import { VariantProps } from './types';

type Props = {
  variants: VariantProps[];
  defaultView: string;
  selectedView: string;
  handleSave: (name: string) => void;
  handleSaveAs: ({ name, isDefault }: { name: string; isDefault?: boolean }) => void;
  handleSaveManageViews: (props: SaveManageViewsProps) => void;
  handleSelect: (name: string) => void;
  isDirty: boolean;
};

export const UiViewsManagement = ({
  variants,
  defaultView,
  selectedView,
  handleSaveManageViews,
  handleSelect,
  handleSaveAs,
  handleSave,
  isDirty,
}: Props) => {
  const renderedVariants = useMemo(() => {
    return variants.map((item) => {
      return (
        <UiVariantItem
          key={item.name}
          global={item.global}
          readOnly={item.readOnly}
          labelReadOnly={item.labelReadOnly}
          favorite={item.favorite}
          isDefault={defaultView === item.name}
          selected={selectedView === item.name}
        >
          {item.name}
        </UiVariantItem>
      );
    });
  }, [variants]);

  return (
    <UiVariantManagement
      dirtyState={isDirty}
      onSelect={handleSelect}
      onSave={handleSave}
      onSaveAs={handleSaveAs}
      onSaveManageViews={handleSaveManageViews}
    >
      {renderedVariants}
    </UiVariantManagement>
  );
};
