import { useCallback } from 'react';
import { InputDomRef, Ui5CustomEvent } from '@ui5/webcomponents-react';

export const useUiInputOnChangeHandler = (callback?: (value?: string) => void) =>
  useCallback(
    (event: Ui5CustomEvent<InputDomRef, never>) => {
      if (!callback) return;

      const {
        target: { value },
      } = event;

      callback(value || undefined);
    },
    [callback],
  );
