import { ValueState } from '@ui5/webcomponents-react';

import { UiInput } from '@UiCommon/Components/UiInput/UiInput';

import { useSplitScopeContext } from '../Context/SplitScopeContext';

type EditableCellProps = {
  value: string;

  onChange?: (value?: string) => void;
  onBlur?: () => void;
  isEditable?: boolean;
  valueState?: ValueState;
  valueStateMessage?: string;
};

export const EditableCell = ({
  value,
  onBlur,
  onChange,
  isEditable,
  valueStateMessage,
  valueState,
}: EditableCellProps) => {
  const { isEditMode } = useSplitScopeContext();

  return (
    <div>
      {isEditMode && isEditable ? (
        <UiInput
          valueState={valueState}
          valueStateMessage={valueStateMessage}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      ) : (
        value
      )}
    </div>
  );
};
