import { EsgProduct } from '@DataAccess/Platform/Products/products.types';

import { GHGSidebarNavigation } from './Sidebars/GHGSidebarNavigation';
import { PRSidebarNavigation } from './Sidebars/PRSidebarNavigation';

const mapper: Record<EsgProduct, JSX.Element> = {
  [EsgProduct.GHG]: <GHGSidebarNavigation />,
  [EsgProduct.PHYSICAL_RISK]: <PRSidebarNavigation />,
};

export const navigationConfig = (product: EsgProduct) => () => mapper[product];
