import { useProcessMonitoringQuery } from '@DataAccess/Platform/ProcessMonitoring/processMonitoring.hooks';
import { ProcessMonitoringDto } from '@DataAccess/Platform/ProcessMonitoring/processMonitoring.types';
import { useSmartAnalyticalTableCard } from '@Pages/Shared/SmartComponents/SmartAnalyticalTableCard/useSmartAnalyticalTableCard';
import { SortingListItemProps } from '@UiCommon/Components/UiAnalyticalTable/SettingsModal/SortSettings/useSortingSettingsState';
import {
  SortingOrder,
  UiAnalyticalTableColumnDefinition,
} from '@UiCommon/Components/UiAnalyticalTable/types';
import { UiListItemType } from '@UiCommon/Components/UiList/types';

const defaultSorting: UiListItemType<SortingListItemProps>[] = [
  {
    label: 'triggeredStartDate',
    value: 'triggeredStartDate',
    data: {
      order: SortingOrder.DESC,
    },
  },
];

export const useProcessManagerTable = (
  columns: UiAnalyticalTableColumnDefinition<ProcessMonitoringDto>[],
) => {
  const TABLE_ID = 'processMonitoring';
  const smartAnalyticalTableData = useSmartAnalyticalTableCard({
    columns,
    queryHook: useProcessMonitoringQuery,
    defaultSorting,
  });

  return { smartAnalyticalTableData, columns, TABLE_ID };
};
