import { useMemo } from 'react';

import { useProcessManagerTranslation } from '@GlobalProviders/i18n/hooks';
import { UiTableColumnDataType } from '@UiCommon/Components/UiAnalyticalTable/types';

import { useCommonCollectedColumns } from '../useCommonCollectedColumns';

export const useBusinessPartnerAddressCoordinatesColumns = () => {
  const { t } = useProcessManagerTranslation();
  const commonColumns = useCommonCollectedColumns();

  return useMemo(() => {
    return [
      {
        label: t('processManager.manualUpload.bpAddress.table.businessPartnerId'),
        accessor: 'businessPartnerId',
        id: 'businessPartnerId',
        isVisible: true,
        beField: 'BUSINESS_PARTNER_ID',
      },
      {
        label: t('processManager.manualUpload.bpAddress.table.countryRegion'),
        accessor: 'countryRegion',
        id: 'countryRegion',
        isVisible: true,
        beField: 'COUNTRY_REGION',
      },
      {
        label: t('processManager.manualUpload.bpAddress.table.subregion'),
        accessor: 'subregion',
        id: 'subregion',
        isVisible: true,
        beField: 'SUBREGION',
      },
      {
        label: t('processManager.manualUpload.bpAddress.table.postalCode'),
        accessor: 'postalCode',
        id: 'postalCode',
        isVisible: true,
        beField: 'POSTAL_CODE',
      },
      {
        label: t('processManager.manualUpload.bpAddress.table.city'),
        accessor: 'city',
        id: 'city',
        isVisible: true,
        beField: 'CITY',
      },
      {
        label: t('processManager.manualUpload.bpAddress.table.street'),
        accessor: 'street',
        id: 'street',
        isVisible: true,
        beField: 'STREET',
      },
      {
        label: t('processManager.manualUpload.bpAddress.table.houseNumber'),
        accessor: 'houseNumber',
        id: 'houseNumber',
        isVisible: true,
        beField: 'HOUSE_NUMBER',
      },
      {
        label: t('processManager.manualUpload.bpAddress.table.streetAndHouseNumber'),
        accessor: 'streetAndHouseNumber',
        id: 'streetAndHouseNumber',
        isVisible: true,
        beField: 'STREET_AND_HOUSE_NUMBER',
      },
      {
        label: t('processManager.manualUpload.bpAddress.table.latitude'),
        accessor: 'latitude',
        id: 'latitude',
        isVisible: true,
        beField: 'LATITUDE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.bpAddress.table.longitude'),
        accessor: 'longitude',
        id: 'longitude',
        isVisible: true,
        beField: 'LONGITUDE',
        type: UiTableColumnDataType.NUMBER,
      },
      ...commonColumns,
    ];
  }, [t, commonColumns]);
};
