import styled from '@emotion/styled';
import { Card, CardPropTypes } from '@ui5/webcomponents-react';
import { ThemingParameters } from '@ui5/webcomponents-react-base';

import { CommonProps } from '../../types';

type StyledBodyProps = {
  bodyPadding?: string;
};

type Props = CommonProps &
  StyledBodyProps &
  Pick<CardPropTypes, 'header'> & {
    bodyClassName?: string;
  };

const StyledCard = styled(Card)`
  --_ui5_card_box_shadow: ${ThemingParameters.sapContent_Shadow0} !important;
  --_ui5_card_hover_box_shadow: ${ThemingParameters.sapContent_Shadow0} !important;
`;

const CardBodyWrapper = styled.section<StyledBodyProps>`
  ${({ bodyPadding }) => !!bodyPadding && `padding: ${bodyPadding}`};
`;

export const UiCard = ({ children, bodyClassName, bodyPadding, ...props }: Props) => (
  <StyledCard {...props}>
    <CardBodyWrapper className={bodyClassName} bodyPadding={bodyPadding}>
      {children}
    </CardBodyWrapper>
  </StyledCard>
);
