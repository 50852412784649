import styled from '@emotion/styled';

import { UiCard, UiCardHeader } from '@UiCommon/Components';
import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';
import { UiLabeledValue } from '@UiCommon/Components/UiLabeledValue';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
`;

export const DefaultUnitsSection = () => {
  const { t } = useGHGTranslation();

  return (
    <UiCard
      bodyPadding='1rem'
      header={<UiCardHeader titleText={t('settings.systemSettings.defaultUnitsSection.title')} />}
    >
      <Column>
        <UiLabeledValue
          title={t('settings.systemSettings.defaultUnitsSection.emissions')}
          value={'tCO₂e'}
        />
        <UiLabeledValue
          title={t('settings.systemSettings.defaultUnitsSection.energy')}
          value={'MWh'}
        />
        <UiLabeledValue
          title={t('settings.systemSettings.defaultUnitsSection.goods')}
          value={'t'}
        />
        <UiLabeledValue
          title={t('settings.systemSettings.defaultUnitsSection.floorArea')}
          value={'m²'}
        />
      </Column>
    </UiCard>
  );
};
