import { ValueState } from '@ui5/webcomponents-react';
import { t } from 'i18next';

import { UiInput } from '@UiCommon/Components/UiInput/UiInput';

import { FormProps, validateEmissions } from '../Utils/validation';

type SplitScopeFormProps<T extends FormProps> = {
  formData: T;
  onChange: (formData: T) => void;
  error: string;
  onError: (error: string) => void;
};

export const SplitScopeForm = <T extends FormProps>({
  formData,
  onChange,
  error,
  onError,
}: SplitScopeFormProps<T>) => {
  const handleChange = (name: string, value?: string) => {
    const newFormData = {
      ...formData,
      [name]: Number(value) / 100,
    };
    if (!validateEmissions(newFormData)) {
      onError(t('splitScope.splitScopeValidationError'));
    } else {
      onError('');
    }
    onChange({
      ...formData,
      [name]: Number(value) / 100,
    });
  };

  return (
    <>
      <UiInput
        label={t('splitScope.businessPartner.splitScope1Emissions')}
        value={
          formData.splitScope1Emissions ? `${Math.round(formData.splitScope1Emissions * 100)}` : '0'
        }
        valueState={error ? ValueState.Error : ValueState.None}
        valueStateMessage={error}
        onChange={(value) => handleChange('splitScope1Emissions', value)}
      />
      <UiInput
        label={t('splitScope.businessPartner.splitScope2Emissions')}
        value={
          formData.splitScope2Emissions ? `${Math.round(formData.splitScope2Emissions * 100)}` : '0'
        }
        valueState={error ? ValueState.Error : ValueState.None}
        valueStateMessage={error}
        onChange={(value) => handleChange('splitScope2Emissions', value)}
      />
    </>
  );
};
