import { useMemo } from 'react';

import { useProcessManagerTranslation } from '@GlobalProviders/i18n/hooks';
import { UiTableColumnDataType } from '@UiCommon/Components/UiAnalyticalTable/types';

import { useCommonCollectedColumns } from '../useCommonCollectedColumns';

export const useFinancialContractColumns = () => {
  const { t } = useProcessManagerTranslation();
  const commonColumns = useCommonCollectedColumns();

  return useMemo(
    () => [
      {
        label: t('processManager.manualUpload.financialContract.table.businessPartnerId'),
        accessor: 'businessPartnerId',
        id: 'businessPartnerId',
        minWidth: 120,
        isVisible: true,
        beField: 'BUSINESS_PARTNER_ID',
      },
      {
        label: t('processManager.manualUpload.financialContract.table.productType'),
        accessor: 'productType',
        id: 'productType',
        minWidth: 104,
        isVisible: true,
        beField: 'PRODUCT_TYPE',
      },
      {
        label: t('processManager.manualUpload.financialContract.table.financialProductId'),
        accessor: 'financialProductId',
        id: 'financialProductId',
        minWidth: 120,
        isVisible: true,
        beField: 'FINANCIAL_PRODUCT_ID',
      },
      {
        label: t('processManager.manualUpload.financialContract.table.outstandingAmount'),
        accessor: 'outstandingAmount',
        id: 'outstandingAmount',
        minWidth: 100,
        isVisible: true,
        type: UiTableColumnDataType.NUMBER,
        beField: 'OUTSTANDING_AMOUNT',
      },
      {
        label: t('processManager.manualUpload.financialContract.table.originationDate'),
        accessor: 'originationDate',
        id: 'originationDate',
        minWidth: 100,
        isVisible: true,
        type: UiTableColumnDataType.DATE,
        beField: 'ORIGINATION_DATE',
      },
      {
        label: t('processManager.manualUpload.financialContract.table.naceCode'),
        accessor: 'naceCode',
        id: 'naceCode',
        minWidth: 94,
        isVisible: true,
        beField: 'NACE_CODE',
      },
      {
        label: t('processManager.manualUpload.financialContract.table.useOfProceeds'),
        accessor: 'useOfProceeds',
        id: 'useOfProceeds',
        minWidth: 190,
        isVisible: true,
        beField: 'USE_OF_PROCEEDS',
      },
      {
        label: t('processManager.manualUpload.financialContract.table.pcafAssetClass'),
        accessor: 'pcafAssetClass',
        id: 'pcafAssetClass',
        minWidth: 133,
        isVisible: true,
        beField: 'PCAF_ASSET_CLASS',
      },
      ...commonColumns,
    ],
    [t, commonColumns],
  );
};
