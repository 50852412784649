import { useNavigate } from 'react-router-dom';

import { useDataHierarchyIdInUseQuery } from '@DataAccess/Core/DataHierarchy/dataHierarchy.hooks';
import { IconSet } from '@GlobalProviders/IconSetProvider';
import { useSharedTranslation } from '@GlobalProviders/i18n/hooks';
import { UiButton } from '@UiCommon/Components';
import { HeaderEditButtons } from '@UiCommon/Layout';

type Props = {
  isSubpage: boolean;
  isEditMode: boolean;
  handleEdit: () => void;
  handleSave: () => void;
  onCancel: () => void;
};

export const HierarchyHeaderEditButtons = ({
  isSubpage,
  isEditMode,
  handleEdit,
  handleSave,
  onCancel,
}: Props) => {
  const { t } = useSharedTranslation();
  const navigate = useNavigate();
  const hierarchyInUseId = useDataHierarchyIdInUseQuery();

  const handleVersioningNavigation = () => {
    if (!hierarchyInUseId) throw new Error('Current Hierarchy id is not available');
    navigate(hierarchyInUseId);
  };

  return (
    <>
      {!isEditMode && !isSubpage && (
        <UiButton icon={IconSet.HISTORY} design='Transparent' onClick={handleVersioningNavigation}>
          {t('openVersionMenuButtonLabel')}
        </UiButton>
      )}
      {!isSubpage && (
        <HeaderEditButtons
          isEditMode={isEditMode}
          handleEdit={handleEdit}
          onCancel={onCancel}
          handleSave={handleSave}
        />
      )}
    </>
  );
};
