import { Currency } from '@Types/types';
import { defaultSettings } from '@GlobalProviders/SettingsProvider';

import { NumberFormatterConfig } from './types';
import { DEFAULT_LOCALE } from './constants';

type CurrencyFormatterConfig = NumberFormatterConfig & {
  style?: string;
  currency?: Currency;
};

export const createCurrencyFormatter = ({
  lng = DEFAULT_LOCALE,
  decimalPrecision = 2,
  style,
  currency = defaultSettings.currency,
  notation,
}: CurrencyFormatterConfig) => {
  return new Intl.NumberFormat(lng, {
    style,
    currency,
    currencyDisplay: 'code',
    minimumFractionDigits: decimalPrecision,
    maximumFractionDigits: decimalPrecision,
    notation,
  });
};
