import styled from '@emotion/styled';

export const GenericInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const commonInputProperties = () => `
  width: unset;
  flex-grow: 1;
`;
