import { useMemo } from 'react';

import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';
import { UiAnalyticalTableColumnDefinition } from '@UiCommon/Components';
import { EmissionDto } from '@DataAccess/GHG/SingleExposure/singleExposure.types';
import { useProductColumns } from '@Pages/Insights/GHG/SingleExposure/Components/Emissions/Hooks/Columns/useProductColumns';
import { useBusinessPartnerColumns } from '@Pages/Insights/GHG/SingleExposure/Components/Emissions/Hooks/Columns/useBusinessPartnerColumns';
import { useProjectColumns } from '@Pages/Insights/GHG/SingleExposure/Components/Emissions/Hooks/Columns/useProjectColumns';
import { usePhysicalAssetColumns } from '@Pages/Insights/GHG/SingleExposure/Components/Emissions/Hooks/Columns/usePhysicalAssetColumns';

import { useSingleExposureColumns } from './useSingleExposureColumns';

export const useEmissionsTableColumns = (): UiAnalyticalTableColumnDefinition<EmissionDto>[] => {
  const { t } = useGHGTranslation();
  const productColumns = useProductColumns();
  const businessPartnerColumns = useBusinessPartnerColumns();
  const projectColumns = useProjectColumns();
  const physicalAssetColumns = usePhysicalAssetColumns();
  const rootColumns = useSingleExposureColumns();

  return useMemo(
    () => [
      ...productColumns,
      ...businessPartnerColumns,
      ...projectColumns,
      ...physicalAssetColumns,
      ...rootColumns,
    ],
    [t, productColumns, businessPartnerColumns, projectColumns, physicalAssetColumns, rootColumns],
  );
};
