import styled from '@emotion/styled';

import { useProcessManagerTranslation } from '@GlobalProviders/i18n/hooks';
import { UiButton, UiDialog, UiDialogFooter, UiDialogHeader, UiTitle } from '@UiCommon/Components';
import { EntityType } from '@DataAccess/Platform/FileUpload/fileUpload.types';

import { UploadDialogTable } from './UploadDialogTable';

const Wrapper = styled.div`
  width: 54rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export type EntityGroup = {
  title: string;
  data: EntityType[];
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  entities: EntityGroup[];
};

export const UploadDialog = ({ isOpen, onClose, entities }: Props) => {
  const { t } = useProcessManagerTranslation();

  const entitiesCount = entities.reduce((acc, { data }) => acc + data.length, 0);

  return (
    <UiDialog
      isOpen={isOpen}
      onAfterClose={onClose}
      header={<UiDialogHeader startContent={t('processManager.manualUpload.uploadDialog.title')} />}
      footer={
        <UiDialogFooter
          endContent={
            <UiButton onClick={onClose}>
              {t('processManager.manualUpload.uploadDialog.ok')}
            </UiButton>
          }
        />
      }
    >
      <Wrapper>
        <UiTitle level='H5'>
          {t('processManager.manualUpload.uploadDialog.entities', { count: entitiesCount })}
        </UiTitle>
        <UploadDialogTable entities={entities} />
      </Wrapper>
    </UiDialog>
  );
};
