import { InputType, ValueState } from '@ui5/webcomponents-react';

import { UiInput } from '@UiCommon/Components/UiInput/UiInput';
import { UiPercentageFormatter } from '@UiCommon/Components/UiFormatter/UiPercentageFormatter';

import { useSplitScopeContext } from '../Context/SplitScopeContext';

type EditableSplitScopeCellProps = {
  value: number;
  onChange?: (value: number) => void;
  onBlur?: () => void;
  isEditable?: boolean;
  valueState?: ValueState;
  valueStateMessage?: string;
};

export const EditableSplitScopeCell = ({
  value,
  onBlur,
  onChange,
  isEditable,
  valueState,
  valueStateMessage,
}: EditableSplitScopeCellProps) => {
  const { isEditMode } = useSplitScopeContext();

  return (
    <>
      {isEditMode && isEditable ? (
        <UiInput
          valueState={valueState}
          valueStateMessage={valueStateMessage}
          type={InputType.Number}
          onBlur={onBlur}
          onChange={(value) => value !== undefined && onChange?.(Number(value) / 100)}
          endContent={<span>%</span>}
          value={`${Math.round(value * 100)}`}
        />
      ) : (
        <UiPercentageFormatter value={value} />
      )}
    </>
  );
};
