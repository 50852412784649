import styled from '@emotion/styled';
import { ThemingParameters } from '@ui5/webcomponents-react-base';

export const TableContainer = styled.div`
  background: ${ThemingParameters.sapTile_Background};
  border-radius: 0.75rem;
  box-shadow: ${ThemingParameters.sapContent_HeaderShadow};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
`;

export const StyledTHead = styled.thead`
  height: 2.5rem;
  border: 1px solid ${ThemingParameters.sapList_BorderColor};
`;

export const StyledTh = styled.th`
  text-align: left;
  padding: 0 0.5rem;

  font-size: ${ThemingParameters.sapFontSize};
`;

export const StyledTr = styled.tr`
  height: 2.5rem;
  border-bottom: 1px solid ${ThemingParameters.sapList_BorderColor};
  padding: 0 0.5rem;
  font-family: ${ThemingParameters.sapFontFamily};
  color: ${ThemingParameters.sapTextColor};
`;

export const StyledTable = styled.table`
  border-collapse: collapse;
`;

export const StyledTd = styled.td`
  padding: 0 0.5rem;
  font-size: ${ThemingParameters.sapFontSize};
  font-family: ${ThemingParameters.sapFontFamily};
  color: ${ThemingParameters.sapTextColor};
`;

export const TableHeader = styled.div`
  height: 2.75rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
`;

export const ActionsContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;
