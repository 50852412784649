import { Dialog } from '@ui5/webcomponents-react';
import { createPortal } from 'react-dom';
import styled from '@emotion/styled';

import { CommonProps } from '../../types';

type Props = CommonProps & {
  isOpen: boolean;
  onAfterClose?: () => void;
  onBeforeOpen?: () => void;
  resizable?: boolean;
  header: CommonProps['children'];
  footer: CommonProps['children'];
};

const SlotWrapper = styled.div`
  width: 100%;
`;

export const UiDialog = ({
  isOpen,
  children,
  header,
  footer,
  onAfterClose,
  onBeforeOpen,
  className,
  resizable,
}: Props) =>
  createPortal(
    <Dialog
      resizable={resizable}
      className={className}
      open={isOpen}
      header={<SlotWrapper>{header}</SlotWrapper>}
      footer={<SlotWrapper>{footer}</SlotWrapper>}
      onAfterClose={onAfterClose}
      onBeforeOpen={onBeforeOpen}
      preventFocusRestore
    >
      {children}
    </Dialog>,
    document.body,
  );
