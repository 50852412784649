import styled from '@emotion/styled';
import { ThemingParameters } from '@ui5/webcomponents-react-base';
import { ReactNode } from 'react';

import { UiText } from '@UiCommon/Components';

type Props = {
  title: string;
  value?: string;
  CustomValueComponent?: ReactNode;
};

const Wrapper = styled.div`
  display: flex;
  gap: 2rem;
  margin-bottom: 1rem;
`;

const Key = styled(UiText)`
  width: 7rem;
`;

export const UiLabeledValue = ({ title, value, CustomValueComponent }: Props) => {
  return (
    <Wrapper>
      <Key color={ThemingParameters.sapField_PlaceholderTextColor}>{title}:</Key>
      {CustomValueComponent || <UiText>{value}</UiText>}
    </Wrapper>
  );
};
