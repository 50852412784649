import styled from '@emotion/styled';
import { useState } from 'react';
import { isNil } from 'lodash';

import { useUiCommonTranslation } from '@GlobalProviders/i18n/hooks';

import { UiDialog } from '../../UiDialog/UiDialog';
import { UiExportModalFooter } from './UiExportModalFooter';
import { UiExportModalHeader } from './UiExportModalHeader';
import { UiInput } from '../../UiInput/UiInput';
import { UiSelect } from '../../UiSelect/UiSelect';
import { UiSelectOption } from '../../UiSelect/UiSelectOption';
import { FileExtensions } from '../../../types';

type Props = {
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: (fileName: string, fileExtension?: FileExtensions) => void;
  onClose: () => void;
};

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const UiExportModal = ({ isOpen, onCancel, onSubmit, onClose }: Props) => {
  const { t } = useUiCommonTranslation();
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const fileExtension = FileExtensions.CSV;

  return (
    <UiDialog
      isOpen={isOpen}
      onBeforeOpen={() => setFileName(undefined)}
      header={<UiExportModalHeader title={t('exportModal.title')} />}
      footer={
        <UiExportModalFooter
          onExport={() => fileName && onSubmit(fileName)}
          onCancel={() => onCancel()}
          isSubmitDisabled={isNil(fileName)}
        />
      }
      onAfterClose={onClose}
    >
      <FormWrapper>
        <UiInput label={t('exportModal.fileName')} value={fileName} onChange={setFileName} />
        <UiSelect label={t('exportModal.fileType')}>
          <UiSelectOption value={fileExtension} isSelected>
            {t('exportModal.csvExtensionOption')}
          </UiSelectOption>
        </UiSelect>
      </FormWrapper>
    </UiDialog>
  );
};
