import { EsgProduct } from '@DataAccess/Platform/Products/products.types';

import { KpiRouting } from './KpiRouting';

const SS_PRODUCT_IN_USE_KEY = 'ESG_PRODUCT_IN_USE';

const mapEsgProductToURL = (esgProduct: EsgProduct) => `/${KpiRouting[esgProduct]}`;

export const getProductInUseViaURL = (activatedProducts: EsgProduct[] | undefined) => {
  const pathname = window.location.pathname;

  return activatedProducts?.find((product) => pathname.includes(mapEsgProductToURL(product)));
};

export const getProductViaKpiRoute = (kpiRoute: KpiRouting) => {
  // eslint-disable-next-line
  const keyValueKpi = Object.entries(KpiRouting).find(([_, route]) => route === kpiRoute);

  if (!keyValueKpi) return new Error('No product found for this route');

  const [product] = keyValueKpi;

  return product as EsgProduct;
};

export const getProductInUseViaSessionStorage = (activatedProducts: EsgProduct[] | undefined) => {
  const sessionStorageValue = window.sessionStorage.getItem(
    SS_PRODUCT_IN_USE_KEY,
  ) as EsgProduct | null;

  const product =
    !!sessionStorageValue && activatedProducts?.includes(sessionStorageValue)
      ? sessionStorageValue
      : null;

  return product;
};

export const setProductInUseInSessionStorage = (productInUse: EsgProduct) => {
  window.sessionStorage.setItem(SS_PRODUCT_IN_USE_KEY, productInUse);
};
