import { useState } from 'react';

import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';

import { validateCountry } from '../Utils/validation';

export const useSplitScopeForm = <T>(ruleAttributes: T) => {
  const [formData, setFormData] = useState<T>(ruleAttributes);
  const [splitRatioError, setSplitRatioError] = useState<string>('');
  const [countryError, setCountryError] = useState<string>('');
  const { t } = useGHGTranslation();
  const handleChange = (name: string, value?: string) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // TODO: it is not scalable to have a function for each field we need to create a validation mechanism or use a library
  const handleCountryChange = (value?: string) => {
    if (value && !validateCountry(value)) {
      setCountryError(t('splitScope.countryValidationError'));
    } else {
      setCountryError('');
    }
    handleChange('country', value);
  };

  return {
    formData,
    setFormData,
    handleChange,
    splitRatioError,
    handleCountryChange,
    setSplitRatioError: (error: string) => setSplitRatioError(error),
    hasError: splitRatioError || countryError,
    countryError,
  };
};
