import { CollectionQueryParams, FilterPayloadType } from '@DataAccess/types';
import { getBeField } from '@DataAccess/utils';
import { FilterConditionsType } from '@UiCommon/Components/UiAnalyticalTable/SettingsModal/types';
import { conditionalArrayElements } from '@Utils/utils';
import { SortingOrder } from '@UiCommon/Components/UiAnalyticalTable/types';

import {
  ProcessMonitoringDto,
  ProcessItemType,
  ProcessStatus,
  ProcessType,
} from './processMonitoring.types';
import { CalculationRunSearchParams } from './processMonitoring.hooks';

export const getVersionSearchQueryParams = ({
  columns,
  businessDateFilterValue,
  processIdFilterValue,
}: CalculationRunSearchParams) => {
  const businessDateFilters = conditionalArrayElements<FilterPayloadType>(
    !!businessDateFilterValue,
    [
      {
        field: getBeField<ProcessMonitoringDto>({
          columnId: 'businessDate',
          columns,
        }),
        conditions: [
          { columnCondition: FilterConditionsType.EQUAL, value: businessDateFilterValue },
        ],
      },
    ],
  );
  const processIdFilters = conditionalArrayElements<FilterPayloadType>(!!processIdFilterValue, [
    {
      field: getBeField<ProcessMonitoringDto>({
        columnId: 'processId',
        columns,
      }),
      conditions: [{ columnCondition: FilterConditionsType.EQUAL, value: processIdFilterValue }],
    },
  ]);

  const successFullRunCalculations: FilterPayloadType[] = [
    {
      field: getBeField<ProcessMonitoringDto>({
        columnId: 'itemType',
        columns,
      }),
      conditions: [
        { columnCondition: FilterConditionsType.EQUAL, value: ProcessItemType.FINANCED_EMISSIONS },
      ],
    },
    {
      field: getBeField<ProcessMonitoringDto>({
        columnId: 'status',
        columns,
      }),
      conditions: [{ columnCondition: FilterConditionsType.EQUAL, value: ProcessStatus.COMPLETE }],
    },
    {
      field: getBeField<ProcessMonitoringDto>({
        columnId: 'processType',
        columns,
      }),
      conditions: [{ columnCondition: FilterConditionsType.EQUAL, value: ProcessType.CALCULATION }],
    },
  ];

  const tableParams: CollectionQueryParams = {
    filter: [...successFullRunCalculations, ...businessDateFilters, ...processIdFilters],
    sort: [
      {
        sort: getBeField<ProcessMonitoringDto>({
          columnId: 'triggeredStartDate',
          columns,
        }),
        order: SortingOrder.DESC,
      },
    ],
    size: 20,
  };

  return tableParams;
};
