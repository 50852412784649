import { CustomListItem } from '@ui5/webcomponents-react';
import styled from '@emotion/styled';
import { ComponentProps } from 'react';
import { ThemingParameters } from '@ui5/webcomponents-react-base';

import { CommonProps } from '../../types';
import { UiCheckbox } from '../UiCheckbox/UiCheckbox';

const CheckBoxWrapper = styled.div`
  display: inline-flex;
`;

export type UiListItemChangeEvent = {
  itemValue: string;
  isSelected: boolean;
};

const StyledCustomListItem = styled(CustomListItem)<
  ComponentProps<typeof CustomListItem> & { isFocused?: boolean }
>`
  --sapContent_FocusWidth: 0;
  --_ui5_checkbox_focus_outline_display: none !important;

  border-bottom: 1px solid
    ${({ selected }) =>
      selected
        ? ThemingParameters.sapList_SelectionBorderColor
        : ThemingParameters.sapList_BorderColor};

  &:after {
    content: '';
    inset: 0.125rem;
    pointer-events: none;
    position: absolute;
    border: ${({ isFocused }) =>
      isFocused && `solid 2px ${ThemingParameters.sapList_SelectionBorderColor}`};
  }
`;

export type UiListItemProps = CommonProps & {
  isSelectionActive?: boolean;
  isSelected?: boolean;
  value?: string;
  onChange?: (value: UiListItemChangeEvent) => void;
  onFocus?: () => void;
  isFocused?: boolean;
};

export const UiSelectableListItem = ({
  isSelectionActive,
  isSelected,
  value,
  onChange,
  onFocus,
  children,
  className,
  isFocused,
}: UiListItemProps) => {
  const onChangeHandler = (isSelected: boolean) => {
    value &&
      onChange?.({
        itemValue: value,
        isSelected,
      });
  };

  return (
    <StyledCustomListItem
      className={className}
      data-value={value}
      selected={isSelected}
      onPointerDown={onFocus}
      isFocused={isFocused}
    >
      {isSelectionActive && (
        <CheckBoxWrapper data-no-dnd='true'>
          <UiCheckbox onChange={onChangeHandler} isChecked={isSelected} />
        </CheckBoxWrapper>
      )}
      {children}
    </StyledCustomListItem>
  );
};
