import { useMemo } from 'react';

import { useProcessManagerTranslation } from '@GlobalProviders/i18n/hooks';
import {
  TableInstance,
  UiAnalyticalTableColumnDefinition,
  UiTableColumnDataType,
} from '@UiCommon/Components/UiAnalyticalTable/types';
import { ProcessMonitoringDto } from '@DataAccess/Platform/ProcessMonitoring/processMonitoring.types';
import { UiDateFormatter } from '@UiCommon/Components/UiFormatter/UiDateFormatter';
import { EsgProduct } from '@DataAccess/Platform/Products/products.types';
import { UiLink } from '@UiCommon/Components';

import { ProcessStatusInfo } from './ProcessStatusInfo';
import { Duration } from './Duration';
import { useCheckProductAccess } from '../useCheckProductAccess';
import { ItemLink } from './ItemLink';

type Props = {
  additionalColumns?: UiAnalyticalTableColumnDefinition<ProcessMonitoringDto>[];
  productAccess: EsgProduct;
};

export const useProcessMonitoringColumns = ({ productAccess, additionalColumns = [] }: Props) => {
  const { t } = useProcessManagerTranslation();

  return useMemo<UiAnalyticalTableColumnDefinition<ProcessMonitoringDto>[]>(
    () => [
      {
        label: t('processManager.table.id'),
        accessor: 'processId',
        id: 'processId',
        minWidth: 200,
        isVisible: true,
        beField: 'PROCESS_ID',
      },
      {
        label: t('processManager.table.item'),
        accessor: 'itemType',
        id: 'itemType',
        isVisible: true,
        minWidth: 220,
        beField: 'ITEM_TYPE',
        Cell: (table: TableInstance<ProcessMonitoringDto>) => {
          const {
            itemType,
            processId,
            processType,
            products: applicableProducts,
          } = table.row.original;

          const hasAccess = useCheckProductAccess(applicableProducts, productAccess);

          return (
            <ItemLink
              itemType={itemType}
              processId={processId}
              processType={processType}
              isLinkDisabled={!hasAccess}
            />
          );
        },
      },
      {
        label: t('processManager.table.processType'),
        accessor: 'processType',
        id: 'processType',
        isVisible: true,
        minWidth: 140,
        beField: 'PROCESS_TYPE',
      },
      {
        label: t('processManager.table.triggeredStartDate'),
        accessor: 'triggeredStartDate',
        id: 'triggeredStartDate',
        isVisible: true,
        beField: 'TRIGGERED_START_DATE',
        type: UiTableColumnDataType.DATE_TIME,
        Cell: (table: TableInstance<ProcessMonitoringDto>) => (
          <UiDateFormatter value={table.row.original.triggeredStartDate} />
        ),
      },
      {
        label: t('processManager.table.businessDate'),
        accessor: 'businessDate',
        id: 'businessDate',
        isVisible: true,
        type: UiTableColumnDataType.DATE,
        beField: 'BUSINESS_DATE',
      },
      {
        label: t('processManager.table.systemDate'),
        accessor: 'systemDate',
        id: 'systemDate',
        isVisible: true,
        type: UiTableColumnDataType.DATE_TIME,
        beField: 'SYSTEM_DATE',
        Cell: (table: TableInstance<ProcessMonitoringDto>) => (
          <UiDateFormatter value={table.row.original.systemDate} />
        ),
      },
      {
        label: t('processManager.table.actualStartDate'),
        accessor: 'actualStartDate',
        id: 'actualStartDate',
        isVisible: true,
        type: UiTableColumnDataType.DATE_TIME,
        beField: 'ACTUAL_START_DATE',
        Cell: (table: TableInstance<ProcessMonitoringDto>) => (
          <UiDateFormatter value={table.row.original.actualStartDate} />
        ),
      },
      {
        label: t('processManager.table.endDate'),
        accessor: 'endDate',
        id: 'endDate',
        isVisible: true,
        type: UiTableColumnDataType.DATE_TIME,
        beField: 'END_DATE',
        Cell: (table: TableInstance<ProcessMonitoringDto>) => (
          <UiDateFormatter value={table.row.original.endDate} />
        ),
      },
      {
        label: t('processManager.table.duration'),
        accessor: 'duration',
        id: 'duration',
        isVisible: true,
        beField: 'DURATION',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<ProcessMonitoringDto>) => (
          <Duration seconds={table.row.original.duration} />
        ),
      },
      {
        label: t('processManager.table.totalDataSets'),
        accessor: 'totalDataSets',
        id: 'totalDataSets',
        type: UiTableColumnDataType.NUMBER,
        isVisible: true,
        beField: 'TOTAL_DATA_SETS',
      },
      {
        label: t('processManager.table.failedDataSets'),
        accessor: 'failedDataSets',
        type: UiTableColumnDataType.NUMBER,
        id: 'failedDataSets',
        isVisible: true,
        beField: 'FAILED_DATA_SETS',
      },
      {
        label: t('processManager.table.status'),
        accessor: 'status',
        id: 'status',
        isVisible: true,
        beField: 'STATUS',
        Cell: (table: TableInstance<ProcessMonitoringDto>) => (
          <ProcessStatusInfo status={table.row.original.status} />
        ),
      },
      {
        label: t('processManager.table.errors'),
        accessor: 'errors',
        id: 'errors',
        type: UiTableColumnDataType.NUMBER,
        isVisible: true,
        beField: 'ERRORS',
        Cell: (table: TableInstance<ProcessMonitoringDto>) => {
          const { errors, processId, products: applicableProducts } = table.row.original;

          const hasAccess = useCheckProductAccess(applicableProducts, productAccess);

          if (!hasAccess) {
            return <div>{errors}</div>;
          }

          return <UiLink href={`error-log/${processId}`}>{errors}</UiLink>;
        },
      },
      ...additionalColumns,
    ],
    [t],
  );
};
