type CreateSearchParamProps = {
  data: any;
  urlPath: string;
  key: string;
};

export const createURLWithSearchParam = ({ data, urlPath, key }: CreateSearchParamProps): URL => {
  const baseURL = import.meta.env.BASE_URL;
  const hasBaseURL = !!baseURL && baseURL !== '/';

  const urlPathWithBase = hasBaseURL ? `${baseURL}${urlPath}` : urlPath;
  const url = new URL(urlPathWithBase, window.location.origin);
  const queryParam = encodeURIComponent(JSON.stringify(data));
  url.searchParams.set(key, queryParam);

  return url;
};

export const getSearchParam = (key: string, searchParams: URLSearchParams) => {
  const response = searchParams.get(key);

  return response ? JSON.parse(decodeURIComponent(response)) : null;
};
