import { KpiRouting } from '@GlobalProviders/MultiApp/KpiRouting';
import { KPIRoutingFactory } from '@Pages/Shared/Routing/KPIRoutingFactory';

import { GHGOverviewPage } from '../GHGOverviewPage';
import { PROCESS_PARAM_ID } from './routes';

export const OverviewGHGRouting = KPIRoutingFactory(
  [
    {
      element: <GHGOverviewPage />,
      children: [
        {
          path: `:${PROCESS_PARAM_ID}?`,
          children: [{ index: true, element: <GHGOverviewPage /> }],
        },
      ],
    },
  ],
  KpiRouting.GHG,
);
