import { useMemo } from 'react';
import { TextAlign } from '@ui5/webcomponents-react';

import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';
import { UiAnalyticalTableColumnDefinition } from '@UiCommon/Components';
import { TableInstance, UiTableColumnDataType } from '@UiCommon/Components/UiAnalyticalTable/types';
import { UiCurrencyFormatter } from '@UiCommon/Components/UiFormatter/UiCurrencyFormatter';
import { UiNumberFormatter } from '@UiCommon/Components/UiFormatter/UiNumberFormatter';
import { EmissionDto } from '@DataAccess/GHG/SingleExposure/singleExposure.types';
import { useSettingsStore } from '@GlobalProviders/SettingsProvider';

const getPA = (tableApi: TableInstance<EmissionDto>) => tableApi.row.original.physicalAsset;

export const usePhysicalAssetColumns = () => {
  const { t } = useGHGTranslation();
  const { currency } = useSettingsStore();

  return useMemo(() => {
    const output: UiAnalyticalTableColumnDefinition<EmissionDto>[] = [
      {
        label: t('singleExposure.table.physicalAsset.physicalAssetId'),
        accessor: 'physicalAsset.id',
        id: 'physicalAsset.id',
        minWidth: 120,
        isVisible: true,
        beField: 'PHYSICAL_ASSET_ID',
      },
      {
        label: t('singleExposure.table.physicalAsset.assetType'),
        accessor: 'physicalAsset.assetType',
        id: 'physicalAsset.assetType',
        minWidth: 170,
        isVisible: true,
        beField: 'PHYSICAL_ASSET_ASSET_TYPE',
      },
      {
        label: t('singleExposure.table.physicalAsset.realEstateType'),
        accessor: 'physicalAsset.realEstateType',
        id: 'physicalAsset.realEstateType',
        minWidth: 212,
        isVisible: true,
        beField: 'PHYSICAL_ASSET_REAL_ESTATE_TYPE',
      },
      {
        label: t('singleExposure.table.physicalAsset.availableFloorSpace'),
        accessor: 'physicalAsset.availableFloorSpace',
        id: 'physicalAsset.availableFloorSpace',
        minWidth: 70,
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'PHYSICAL_ASSET_AVAILABLE_FLOOR_SPACE',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiNumberFormatter value={getPA(table)?.availableFloorSpace} />
        ),
      },
      {
        label: t('singleExposure.table.physicalAsset.valuationDate'),
        accessor: 'physicalAsset.valuationDate',
        id: 'physicalAsset.valuationDate',
        minWidth: 154,
        isVisible: true,
        beField: 'PHYSICAL_ASSET_VALUATION_DATE',
        type: UiTableColumnDataType.DATE,
      },
      {
        label: t('singleExposure.table.physicalAsset.valueAtOrigination', { currency }),
        accessor: 'physicalAsset.valueAtOrigination',
        id: 'physicalAsset.valueAtOrigination',
        minWidth: 70,
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'PHYSICAL_ASSET_VALUE_AT_ORIGINATION',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiCurrencyFormatter value={getPA(table)?.valueAtOrigination} />
        ),
      },
      {
        label: t('singleExposure.table.physicalAsset.country'),
        accessor: 'physicalAsset.country',
        id: 'physicalAsset.country',
        minWidth: 70,
        isVisible: true,
        beField: 'PHYSICAL_ASSET_COUNTRY',
      },
      {
        label: t('singleExposure.table.physicalAsset.numberOfBuildings'),
        accessor: 'physicalAsset.numberOfBuildings',
        id: 'physicalAsset.numberOfBuildings',
        minWidth: 50,
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'PHYSICAL_ASSET_NUMBER_OF_BUILDINGS',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('singleExposure.table.physicalAsset.certificateClass'),
        accessor: 'physicalAsset.certificateClass',
        id: 'physicalAsset.certificateClass',
        minWidth: 50,
        isVisible: true,
        beField: 'PHYSICAL_ASSET_CERTIFICATE_CLASS',
      },
      {
        label: t('singleExposure.table.physicalAsset.emissionsScope1'),
        accessor: 'physicalAsset.emissionsScope1',
        id: 'physicalAsset.emissionsScope1',
        minWidth: 140,
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'PHYSICAL_ASSET_EMISSIONS_SCOPE_1',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiNumberFormatter value={getPA(table)?.emissionsScope1} />
        ),
      },
      {
        label: t('singleExposure.table.physicalAsset.emissionsScope2'),
        accessor: 'physicalAsset.emissionsScope2',
        id: 'physicalAsset.scope2Emissions',
        minWidth: 140,
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'PHYSICAL_ASSET_EMISSIONS_SCOPE_2',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiNumberFormatter value={getPA(table)?.emissionsScope2} />
        ),
      },
      {
        label: t('singleExposure.table.physicalAsset.emissionsScope1Plus2'),
        accessor: 'physicalAsset.emissionsScope1Plus2',
        id: 'physicalAsset.emissionsScope1Plus2',
        minWidth: 140,
        isVisible: true,
        hAlign: TextAlign.Right,
        beField: 'PHYSICAL_ASSET_EMISSIONS_SCOPE_1_PLUS_2',
        type: UiTableColumnDataType.NUMBER,
        Cell: (table: TableInstance<EmissionDto>) => (
          <UiNumberFormatter value={getPA(table)?.emissionsScope1Plus2} />
        ),
      },
      {
        label: t('singleExposure.table.physicalAsset.dataQualityScoreScope1'),
        accessor: 'physicalAsset.dataQualityScoreScope1',
        id: 'physicalAsset.dataQualityScoreScope1',
        minWidth: 140,
        isVisible: true,
        beField: 'PHYSICAL_ASSET_DATA_QUALITY_SCORE_SCOPE_1',
      },
      {
        label: t('singleExposure.table.physicalAsset.dataQualityScoreScope2'),
        accessor: 'physicalAsset.dataQualityScoreScope2',
        id: 'physicalAsset.dataQualityScoreScope2',
        minWidth: 140,
        isVisible: true,
      },
      {
        label: t('singleExposure.table.physicalAsset.dataQualityScoreScope1Plus2'),
        accessor: 'physicalAsset.dataQualityScoreScope1Plus2',
        id: 'physicalAsset.dataQualityScoreScope1Plus2',
        minWidth: 140,
        isVisible: true,
        beField: 'PHYSICAL_ASSET_DATA_QUALITY_SCORE_SCOPE_1_PLUS_2',
      },
      {
        label: t('singleExposure.table.physicalAsset.dataQualityScoreOptionScope1'),
        accessor: 'physicalAsset.dataQualityScoreOptionScope1',
        id: 'physicalAsset.dataQualityScoreOptionScope1',
        minWidth: 210,
        isVisible: true,
        beField: 'PHYSICAL_ASSET_DATA_QUALITY_SCORE_OPTION_SCOPE_1',
      },
      {
        label: t('singleExposure.table.physicalAsset.dataQualityScoreOptionScope2'),
        accessor: 'physicalAsset.dataQualityScoreOptionScope2',
        id: 'physicalAsset.dataQualityScoreOptionScope2',
        minWidth: 210,
        isVisible: true,
        beField: 'PHYSICAL_ASSET_DATA_QUALITY_SCORE_OPTION_SCOPE_2',
      },
      {
        label: t('singleExposure.table.physicalAsset.dataQualityScoreOptionScope1Plus2'),
        accessor: 'physicalAsset.dataQualityScoreOptionScope1Plus2',
        id: 'physicalAsset.dataQualityScoreOptionScope1Plus2',
        minWidth: 210,
        isVisible: true,
        beField: 'PHYSICAL_ASSET_DATA_QUALITY_SCORE_OPTION_SCOPE_1_PLUS_2',
      },
      {
        label: t('singleExposure.table.physicalAsset.dataSource'),
        accessor: 'physicalAsset.dataSource',
        id: 'physicalAsset.dataSource',
        isVisible: true,
        beField: 'PHYSICAL_ASSET_DATA_SOURCE',
      },
      {
        label: t('singleExposure.table.physicalAsset.valuationDataSource'),
        accessor: 'physicalAsset.valuationDataSource',
        id: 'physicalAsset.valuationDataSource',
        isVisible: true,
        beField: 'PHYSICAL_ASSET_VALUATION_DATA_SOURCE',
      },
      {
        label: t(
          'singleExposure.table.physicalAsset.emissionFactorBuildingCertificateClassDataSource',
        ),
        accessor: 'physicalAsset.emissionFactorBuildingCertificateClassDataSource',
        id: 'physicalAsset.emissionFactorBuildingCertificateClassDataSource',
        isVisible: true,
        beField: 'EMISSION_FACTOR_BUILDING_CERTIFICATE_CLASS_DATA_SOURCE',
      },
      {
        label: t('singleExposure.table.physicalAsset.emissionFactorBuildingFloorAreaDataSource'),
        accessor: 'physicalAsset.emissionFactorBuildingFloorAreaDataSource',
        id: 'physicalAsset.emissionFactorBuildingFloorAreaDataSource',
        isVisible: true,
        beField: 'EMISSION_FACTOR_BUILDING_FLOOR_AREA_DATA_SOURCE',
      },
      {
        label: t('singleExposure.table.physicalAsset.emissionFactorNumberOfBuildingsDataSource'),
        accessor: 'physicalAsset.emissionFactorNumberOfBuildingsDataSource',
        id: 'physicalAsset.emissionFactorNumberOfBuildingsDataSource',
        isVisible: true,
        beField: 'EMISSION_FACTOR_NUMBER_OF_BUILDINGS_DATA_SOURCE',
      },
      {
        label: t('singleExposure.table.physicalAsset.emissionsDataSource'),
        accessor: 'physicalAsset.emissionsDataSource',
        id: 'physicalAsset.emissionsDataSource',
        isVisible: true,
        beField: 'PHYSICAL_ASSET_EMISSIONS_DATA_SOURCE',
      },
    ];

    return output;
  }, [t]);
};
