import { useMemo } from 'react';

import { usePRTranslation } from '@GlobalProviders/i18n/hooks';
import {
  UiAnalyticalTableColumnDefinition,
  UiTableColumnDataType,
} from '@UiCommon/Components/UiAnalyticalTable/types';
import { AcuteAssetDto } from '@DataAccess/PhysicalRisk/SingleAsset/singleAsset.types';

import { useCommonPhysicalAssetColumns } from '../../../Hooks/Columns/useCommonPhysicalAssetColumns';

export const usePhysicalAssetAcuteRootColumns = () => {
  const { t } = usePRTranslation();

  const commonColumns = useCommonPhysicalAssetColumns();

  return useMemo<UiAnalyticalTableColumnDefinition<AcuteAssetDto>[]>(() => {
    return [
      ...commonColumns,
      {
        label: t('physicalAsset.acute.table.processId'),
        accessor: 'processId',
        id: 'processId',
        isVisible: true,
        beField: 'PROCESS_ID',
      },
      {
        label: t('physicalAsset.acute.table.geoquality'),
        accessor: 'geoquality',
        id: 'geoquality',
        isVisible: true,
        beField: 'GEOQUALITY',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.acute.table.overallRiskScore'),
        accessor: 'overallRiskScore',
        id: 'overallRiskScore',
        isVisible: true,
        beField: 'OVERALL_RISK_SCORE',
      },
      {
        label: t('physicalAsset.acute.table.overallRiskIndex'),
        accessor: 'overallRiskIndex',
        id: 'overallRiskIndex',
        isVisible: true,
        beField: 'OVERALL_RISK_INDEX',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.acute.table.earthquakeRiskScore'),
        accessor: 'earthquakeRiskScore',
        id: 'earthquakeRiskScore',
        isVisible: true,
        beField: 'EARTHQUAKE_RISK_SCORE',
      },
      {
        label: t('physicalAsset.acute.table.earthquakeRiskIndex'),
        accessor: 'earthquakeRiskIndex',
        id: 'earthquakeRiskIndex',
        isVisible: true,
        beField: 'EARTHQUAKE_RISK_INDEX',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.acute.table.stormRiskScore'),
        accessor: 'stormRiskScore',
        id: 'stormRiskScore',
        isVisible: true,
        beField: 'STORM_RISK_SCORE',
      },
      {
        label: t('physicalAsset.acute.table.stormRiskIndex'),
        accessor: 'stormRiskIndex',
        id: 'stormRiskIndex',
        isVisible: true,
        beField: 'STORM_RISK_INDEX',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.acute.table.floodRiskScore'),
        accessor: 'floodRiskScore',
        id: 'floodRiskScore',
        isVisible: true,
        beField: 'FLOOD_RISK_SCORE',
      },
      {
        label: t('physicalAsset.acute.table.floodRiskIndex'),
        accessor: 'floodRiskIndex',
        id: 'floodRiskIndex',
        isVisible: true,
        beField: 'FLOOD_RISK_INDEX',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.acute.table.earthquakeHazardZone'),
        accessor: 'earthquakeHazardZone',
        id: 'earthquakeHazardZone',
        isVisible: true,
        beField: 'EARTHQUAKE_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.acute.table.volcanoesHazardZone'),
        accessor: 'volcanoesHazardZone',
        id: 'volcanoesHazardZone',
        isVisible: true,
        beField: 'VOLCANOES_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.acute.table.tsunamiHazardZone'),
        accessor: 'tsunamiHazardZone',
        id: 'tsunamiHazardZone',
        isVisible: true,
        beField: 'TSUNAMI_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.acute.table.tropicalCycloneHazardZone'),
        accessor: 'tropicalCycloneHazardZone',
        id: 'tropicalCycloneHazardZone',
        isVisible: true,
        beField: 'TROPICAL_CYCLONE_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.acute.table.extratropicalStormHazardZone'),
        accessor: 'extratropicalStormHazardZone',
        id: 'extratropicalStormHazardZone',
        isVisible: true,
        beField: 'EXTRATROPICAL_STORM_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.acute.table.hailHazardZone'),
        accessor: 'hailHazardZone',
        id: 'hailHazardZone',
        isVisible: true,
        beField: 'HAIL_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.acute.table.tornadoHazardZone'),
        accessor: 'tornadoHazardZone',
        id: 'tornadoHazardZone',
        isVisible: true,
        beField: 'TORNADO_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.acute.table.lightningHazardZone'),
        accessor: 'lightningHazardZone',
        id: 'lightningHazardZone',
        isVisible: true,
        beField: 'LIGHTNING_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.acute.table.riverFloodHazardZone'),
        accessor: 'riverFloodHazardZone',
        id: 'riverFloodHazardZone',
        isVisible: true,
        beField: 'RIVER_FLOOD_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.acute.table.flashFloodHazardZone'),
        accessor: 'flashFloodHazardZone',
        id: 'flashFloodHazardZone',
        isVisible: true,
        beField: 'FLASH_FLOOD_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.acute.table.stormSurgeHazardZone'),
        accessor: 'stormSurgeHazardZone',
        id: 'stormSurgeHazardZone',
        isVisible: true,
        beField: 'STORM_SURGE_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.acute.table.wildfireHazardZone'),
        accessor: 'wildfireHazardZone',
        id: 'wildfireHazardZone',
        isVisible: true,
        beField: 'WILDFIRE_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.acute.table.soilAndShakingHazardZone'),
        accessor: 'soilAndShakingHazardZone',
        id: 'soilAndShakingHazardZone',
        isVisible: true,
        beField: 'SOIL_AND_SHAKING_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.acute.table.activeFaultsDistanceInMeters'),
        accessor: 'activeFaultsDistanceInMeters',
        id: 'activeFaultsDistanceInMeters',
        isVisible: true,
        beField: 'ACTIVE_FAULTS_DISTANCE_IN_METERS',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.acute.table.elevationHeightInMetersASL'),
        accessor: 'elevationHeightInMetersASL',
        id: 'elevationHeightInMetersASL',
        isVisible: true,
        beField: 'ELEVATION_HEIGHT_IN_METERS_ASL',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.acute.table.populationDensityClassificationPeoplePerSqm'),
        accessor: 'populationDensityClassificationPeoplePerSqm',
        id: 'populationDensityClassificationPeoplePerSqm',
        isVisible: true,
        beField: 'POPULATION_DENSITY_CLASSIFICATION_PEOPLE_PER_SQM',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.acute.table.distanceToCoastInMeters'),
        accessor: 'distanceToCoastInMeters',
        id: 'distanceToCoastInMeters',
        isVisible: true,
        beField: 'DISTANCE_TO_COAST_IN_METERS',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.acute.table.jbaFloodDefenseZonesStandardOfProtection'),
        accessor: 'jbaFloodDefenseZonesStandardOfProtection',
        id: 'jbaFloodDefenseZonesStandardOfProtection',
        isVisible: true,
        beField: 'JBA_FLOOD_DEFENSE_ZONES_STANDARD_OF_PROTECTION',
        type: UiTableColumnDataType.NUMBER,
      },
    ];
  }, [t]);
};
