import { Menu } from '@ui5/webcomponents-react';

import { useOnItemClickHandler } from './useOnItemClickHandler';
import { CommonProps } from '../../types';

type Props = CommonProps & {
  opener?: string;
  open?: boolean;
  onAfterClose?: () => void;
};

export const UiMenu = ({ children, ...props }: Props) => {
  const handleItemClick = useOnItemClickHandler();

  return (
    <Menu onItemClick={handleItemClick} {...props}>
      {children}
    </Menu>
  );
};
