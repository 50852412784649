import { skipToken, useQuery } from '@tanstack/react-query';

import api from '@DataAccess/api';

import { dataQualityKeys } from './dataQuality.keys';

export const useKpiInfoQuery = (processId?: string) =>
  useQuery({
    queryKey: dataQualityKeys.kpi.index(processId),
    queryFn: processId ? () => api.ghg.dataQuality.getKpiInfo(processId) : skipToken,
  });

export const useDataQualityQuery = (processId?: string) =>
  useQuery({
    queryKey: dataQualityKeys.dataQualityOverview.index(processId),
    queryFn: processId ? () => api.ghg.dataQuality.getDataQualityOverview(processId) : skipToken,
  });
