import { ThemingParameters } from '@ui5/webcomponents-react-base';

import { DataQuality } from '@Types/types';
import { useSharedTranslation } from '@GlobalProviders/i18n/hooks';

export const useDataQualityScore = () => {
  const { t } = useSharedTranslation();

  return {
    [DataQuality.SCORE_1]: {
      color: ThemingParameters.sapContent_Illustrative_Color4,
      label: t('types.dataQuality', { value: 1 }),
    },
    [DataQuality.SCORE_2]: {
      color: ThemingParameters.sapContent_Illustrative_Color14,
      label: t('types.dataQuality', { value: 2 }),
    },
    [DataQuality.SCORE_3]: {
      color: ThemingParameters.sapInformativeColor,
      label: t('types.dataQuality', { value: 3 }),
    },
    [DataQuality.SCORE_4]: {
      color: ThemingParameters.sapChart_OrderedColor_1,
      label: t('types.dataQuality', { value: 4 }),
    },
    [DataQuality.SCORE_5]: {
      color: ThemingParameters.sapContent_Illustrative_Color20,
      label: t('types.dataQuality', { value: 5 }),
    },
    [DataQuality.SCORE_6]: {
      color: ThemingParameters.sapContent_Illustrative_Color15,
      label: t('types.dataQuality', { value: 6 }),
    },
    [DataQuality.NONE]: {
      color: ThemingParameters.sapContent_Illustrative_Color15,
      label: t('types.dataQualityNone'),
    },
  };
};
