import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import api from '@DataAccess/api';

import { settingsKeys } from './settings.keys';
import { GeneralSettingsDto } from './settings.types';

export const useGeneralSettingsQuery = () =>
  useQuery({
    queryKey: settingsKeys.general.index,
    queryFn: () => api.core.settings.getGeneral(),
  });

export const useGeneralSettingsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<void, void, GeneralSettingsDto>({
    mutationFn: (payload) => api.core.settings.updateGeneral(payload),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: settingsKeys.general.index });
    },
  });
};
