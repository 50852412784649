import { TFunction } from 'i18next';

import { UiAnalyticalTableColumnDefinition } from '@UiCommon/Components';
import { UiColumnFiltersType } from '@UiCommon/Components/UiAnalyticalTable/types';
import { UiListItemType } from '@UiCommon/Components/UiList/types';
import { SortingListItemProps } from '@UiCommon/Components/UiAnalyticalTable/SettingsModal/SortSettings/useSortingSettingsState';

export enum WorkerPayloadStatus {
  INIT_THREAD = 'INIT_THREAD',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
}

export type MainToWorkerPayloadCsvType<DataType> = {
  tableId: string;
  apiPath: string;
  totalElements: number;
  token: string;
  language?: 'en';
  columns: UiAnalyticalTableColumnDefinition<DataType>[];
  filters?: UiColumnFiltersType[];
  sorting?: UiListItemType<SortingListItemProps>[];
  status: WorkerPayloadStatus;
  pathParameter?: string;
};

export type WorkerToMainPayloadCsvType = {
  progress: number;
  urlFile?: string;
};

export type MappersParameters = {
  t: TFunction;
  object: any;
  index?: number;
};

export type GlobalMappersType = Record<
  string,
  // eslint-disable-next-line
  ((params: MappersParameters) => Record<string, any>) | undefined
>;
