import { useState } from 'react';
import { ValueState } from '@ui5/webcomponents-react';

import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';

import { useSplitScopeContext } from '../Context/SplitScopeContext';
import { EditableCell } from '../Components/EditableCell';

type BuildingCellProps = {
  value: string;
  index: number;
  id: string;
  isEditable?: boolean;
};

export const BuildingCell = ({ value, index, id, isEditable }: BuildingCellProps) => {
  const { building } = useSplitScopeContext();
  const { t } = useGHGTranslation();
  const { actions, state } = building;
  const [inputValue, setInputValue] = useState<string>(value);

  const handleOnBlur = () => {
    if (id === 'country') {
      actions.updateCountryCell({ index, value: inputValue, id });
    } else {
      actions.updateCell({ index, value: inputValue, id });
    }
  };

  const containsError = id === 'country' && state.errors.countryErrors.has(index);

  return (
    <EditableCell
      isEditable={isEditable}
      value={inputValue}
      onChange={(value) => value !== undefined && setInputValue(value)}
      onBlur={handleOnBlur}
      valueState={containsError ? ValueState.Error : ValueState.None}
      valueStateMessage={t('splitScope.countryValidationError')}
    />
  );
};
