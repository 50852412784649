import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';

import api from '@DataAccess/api';
import { PaginatedRqHookProps, RqHandlerConfigs } from '@DataAccess/types';
import {
  getNextPageParam,
  useFlattenPaginatedResponse,
  useTablePaginatedQuery,
} from '@DataAccess/utils';
import { UiAnalyticalTableColumnDefinition } from '@UiCommon/Components';

import { processMonitoringKeys } from './processMonitoring.keys';
import {
  ParametrizationProps,
  ProcessMonitoringDto,
  ValidationReportDto,
} from './processMonitoring.types';
import { getVersionSearchQueryParams } from './getVersionSearchQueryParams';

export const useProcessMonitoringQuery = (rest: PaginatedRqHookProps<ProcessMonitoringDto>) => {
  return useTablePaginatedQuery<ProcessMonitoringDto>({
    apiCall: api.platform.processMonitoring.getProcess,
    key: processMonitoringKeys.list,
    refetchInterval: 10000,
    ...rest,
  });
};

export const useProcessDetailsQuery = (processId?: string) => {
  return useQuery({
    queryKey: processMonitoringKeys.details(processId),
    queryFn: () => api.platform.processMonitoring.getProcessDetails(processId),
    enabled: !!processId,
  });
};

export const useValidationReportQuery = (
  processId: string,
  rest: PaginatedRqHookProps<ValidationReportDto>,
) => {
  return useTablePaginatedQuery<ValidationReportDto>({
    apiCall: ({ tableParams, pageParam }) =>
      api.platform.processMonitoring.getProcessValidationReport({
        pathParameter: processId,
        pageParam,
        tableParams,
      }),
    key: processMonitoringKeys.list,
    ...rest,
  });
};

export const useTriggerCalculationMutation = ({ onSuccess, onError }: RqHandlerConfigs) =>
  useMutation({
    mutationFn: (parametrizationProps: ParametrizationProps) =>
      api.platform.processMonitoring.runCalculation(parametrizationProps),
    onSuccess,
    onError,
  });

export const useLatestEmissionRunCalculationProcessIdQuery = () => {
  return useQuery({
    queryKey: processMonitoringKeys.latestEmissionRunId,
    queryFn: () => api.platform.processMonitoring.getLatestEmissionRunCalculationProcessId(),
  });
};

export type CalculationRunSearchParams = {
  columns: UiAnalyticalTableColumnDefinition<ProcessMonitoringDto>[];
  businessDateFilterValue?: string;
  processIdFilterValue?: string;
};

export const useEmissionsCalculationRunSearchQuery = (searchParams: CalculationRunSearchParams) => {
  const tableParams = getVersionSearchQueryParams(searchParams);

  const { businessDateFilterValue, processIdFilterValue } = searchParams;

  const query = useInfiniteQuery({
    queryKey: processMonitoringKeys.searchVersionList({
      businessDateFilterValue,
      processIdFilterValue,
    }),
    queryFn: ({ pageParam }) =>
      api.platform.processMonitoring.getProcess({ tableParams, pageParam }),
    initialPageParam: 0,
    getNextPageParam,
  });

  const runVersions = useFlattenPaginatedResponse(query.data);

  return { ...query, runVersions };
};
