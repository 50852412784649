import { useUiCommonTranslation } from '@GlobalProviders/i18n/hooks';

import { stretchedUiInputClass } from '../Styles';
import { mapColumnTypeToInputType } from './mapColumnTypeToInputType';
import { ConditionType } from '../../types';
import { UiInput } from '../../../../UiInput/UiInput';
import { UiTableColumnDataType } from '../../../types';

type Props = {
  value: ConditionType['value'];
  columnType: UiTableColumnDataType;
  onValueChange: (newValue: ConditionType['value']) => void;
};

export const InputCondition = ({ value, columnType, onValueChange }: Props) => {
  const { t } = useUiCommonTranslation();

  return (
    <UiInput
      type={mapColumnTypeToInputType(columnType)}
      className={stretchedUiInputClass}
      placeholder={t('settingsModal.filterModal.inputPlaceholder')}
      value={value?.toString()}
      onChange={(changedValue) => {
        if (!changedValue) return;
        const newValue =
          columnType === UiTableColumnDataType.TEXT ? changedValue : parseFloat(changedValue);
        onValueChange(newValue);
      }}
    />
  );
};
