import { useCallback } from 'react';
import { MultiInputDomRef, Ui5CustomEvent } from '@ui5/webcomponents-react';

export const useUiMultiInputOnChangeHandler = (callback?: (value?: string) => void) =>
  useCallback(
    (event: Ui5CustomEvent<MultiInputDomRef, never>) => {
      if (!callback) return;

      const {
        target: { value },
      } = event;

      callback(value);
    },
    [callback],
  );
