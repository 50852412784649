import styled from '@emotion/styled';
import { ThemingParameters } from '@ui5/webcomponents-react-base';

import { useDataQualityScore } from '@Pages/Shared/SmartComponents/DataQualityScore/UseDataQualityScore';
import { DataQuality } from '@Types/types';

type Props = {
  score?: DataQuality;
};

const ScoreLabel = styled.span<{ color: string }>`
  font-family: ${ThemingParameters.sapFontBoldFamily};
  color: ${({ color }) => color};
`;

export const DataQualityScore = ({ score }: Props) => {
  const dataQualityScores = useDataQualityScore();

  const { color, label } = dataQualityScores[score || DataQuality.NONE];

  return <ScoreLabel color={color}>{label}</ScoreLabel>;
};
