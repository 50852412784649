import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { useState } from 'react';

import { EsgProduct } from '@DataAccess/Platform/Products/products.types';
import { IconSet } from '@GlobalProviders/IconSetProvider';
import { useMultiAppStore } from '@GlobalProviders/MultiApp/MultiAppProvider';
import { defaultAppRouting } from '@GlobalProviders/MultiApp/defaultAppRouting';
import {
  UiIcon,
  UiList,
  UiResponsivePopover,
  UiStandardListItem,
  UiText,
} from '@UiCommon/Components';
import { useNavigationPanelContext } from '@UiCommon/Layout/Navigation/NavigationPanel/NavigationPanelContext';
import { FontWeight } from '@UiCommon/typography';
import { openNewTabWithBaseURL } from '@Utils/utils';
import { useSharedTranslation } from '@GlobalProviders/i18n/hooks';

const PRODUCT_SWITCHER_ID = 'product-switcher';

const popoverContentClass = css`
  ::part(content) {
    padding: 0;
  }
`;

const paddedIconClass = css`
  padding: 8px;
`;

const appProductClass = css`
  margin-left: 8px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StyledUiStandardListItem = styled(UiStandardListItem)`
  ${({ isSelected }) => isSelected && `cursor: auto;`}
`;

export const ProductSwitcher = () => {
  const { t } = useSharedTranslation();

  const { productInUse, activatedProducts } = useMultiAppStore();

  const { isPanelExtended } = useNavigationPanelContext();

  const [isProductSwitcherOpen, setIsProductSwitcherOpen] = useState(false);

  const handleAppSwitcherClick = (product: EsgProduct) =>
    product !== productInUse ? () => openNewTabWithBaseURL(defaultAppRouting(product)) : undefined;

  return (
    <>
      <Wrapper
        role='button'
        id={PRODUCT_SWITCHER_ID}
        onClick={() => setIsProductSwitcherOpen(true)}
      >
        <UiIcon className={paddedIconClass} name={IconSet.GRID} />
        {isPanelExtended && (
          <UiText className={appProductClass} variant='caption2' weight={FontWeight.BOLD}>
            {t(`types.appProducts.${productInUse}`)}
          </UiText>
        )}
      </Wrapper>
      <UiResponsivePopover
        headerText={t('navigationPanel.appSwitcherTitle')}
        className={popoverContentClass}
        openerId={PRODUCT_SWITCHER_ID}
        open={isProductSwitcherOpen}
        onAfterClose={() => {
          setIsProductSwitcherOpen(false);
        }}
      >
        <UiList>
          {activatedProducts.map((product) => (
            <StyledUiStandardListItem
              onClick={handleAppSwitcherClick(product)}
              key={product}
              isSelected={product === productInUse}
            >
              {t(`types.appProducts.${product}`)}
            </StyledUiStandardListItem>
          ))}
        </UiList>
      </UiResponsivePopover>
    </>
  );
};
