import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';
import { useMultiAppStore } from '@GlobalProviders/MultiApp/MultiAppProvider';
import { PageHeader } from '@UiCommon/Layout';

import { Emissions } from './Components/Emissions/Emissions';

export const GHGSingleExposurePage = () => {
  const { t } = useGHGTranslation();
  const { translatedProductInUse } = useMultiAppStore();

  // [FOOD-FOR-THOUGHTS]: our header could also be automated via the useMatches hook (check handle)
  // + creating a smart component for our breadcrumbs => these components would be placed inside
  // Dashboard.tsx
  return (
    <>
      <PageHeader title={t('singleExposure.title')} productName={translatedProductInUse} />
      <Emissions />
    </>
  );
};
