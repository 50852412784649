import { Avatar, AvatarColorScheme, AvatarShape, AvatarSize } from '@ui5/webcomponents-react';

import { IconSet } from '@GlobalProviders/IconSetProvider';

type AvatarProps = {
  icon?: IconSet;
  shape?: AvatarShape;
  initials?: string;
  size?: AvatarSize;
  colorScheme?: AvatarColorScheme;
};

export const UiAvatar = ({
  icon,
  shape,
  initials,
  size,
  colorScheme = AvatarColorScheme.Accent1,
}: AvatarProps) => (
  <Avatar icon={icon} shape={shape} size={size} initials={initials} colorScheme={colorScheme} />
);
