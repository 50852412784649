import { differenceBy } from 'lodash';
import { useCallback, useMemo } from 'react';

import { useUiCommonTranslation } from '@GlobalProviders/i18n/hooks';

import { useSettingsModalContext } from '../SettingsModalContext';
import { ColumnFilters } from './ColumnFilters';
import { FilterSettingsList, FilterSettingsRow, uiSelectClass } from './Styles';
import { UiTableColumnDataType } from '../../types';
import { UiListItemType } from '../../../UiList/types';
import { UiSelect } from '../../../UiSelect/UiSelect';
import { UiSelectOption } from '../../../UiSelect/UiSelectOption';

export const FilterSettings = () => {
  const { t } = useUiCommonTranslation();

  const { filtersOptions, columns, columnsToBeFiltered, onColumnsToBeFiltered } =
    useSettingsModalContext();

  const newFilterSettingsHandler = useCallback(
    (value?: string) => {
      if (!value) return;
      const newColumnSelected = columns?.find((column) => column.value === value);
      const newItem = newColumnSelected && {
        ...newColumnSelected,
        conditions: [],
        type: UiTableColumnDataType.TEXT,
      };

      const newColumnsToBeFiltered = structuredClone(columnsToBeFiltered);
      newItem && newColumnsToBeFiltered?.push(newItem);
      newColumnsToBeFiltered && onColumnsToBeFiltered?.(newColumnsToBeFiltered);
    },
    [columns, columnsToBeFiltered, onColumnsToBeFiltered],
  );

  const onDelete = useCallback(
    ({ value }: UiListItemType) => {
      columnsToBeFiltered &&
        onColumnsToBeFiltered?.(columnsToBeFiltered.filter((filter) => filter.value !== value));
    },
    [columnsToBeFiltered, onColumnsToBeFiltered],
  );

  const availableColumnsToBeFiltered = useMemo(
    () =>
      differenceBy(filtersOptions, columnsToBeFiltered as UiListItemType[], ({ value }) => value),
    [filtersOptions, columnsToBeFiltered],
  );

  return (
    <FilterSettingsList>
      {columnsToBeFiltered?.map(({ label, value }) => (
        <ColumnFilters
          key={value}
          label={label}
          columnId={value}
          onDelete={() => onDelete({ label, value })}
        />
      ))}
      <FilterSettingsRow>
        <UiSelect
          className={uiSelectClass}
          label={t('settingsModal.filterBy')}
          onChange={(value) => newFilterSettingsHandler(value)}
        >
          <UiSelectOption isSelected />
          {availableColumnsToBeFiltered?.map(({ label, value }) => (
            <UiSelectOption value={value} key={value}>
              {label}
            </UiSelectOption>
          ))}
        </UiSelect>
      </FilterSettingsRow>
    </FilterSettingsList>
  );
};
