import { css } from '@emotion/css';

import { IllustratedMessagesSet } from '@GlobalProviders/IllustratedMessagesSetProvider';
import { UiCard, UiIllustrateMessage } from '@UiCommon/Components';
import { PageHeader } from '@UiCommon/Layout';
import { useMultiAppStore } from '@GlobalProviders/MultiApp/MultiAppProvider';
import { useSharedTranslation } from '@GlobalProviders/i18n/hooks';

const cardBodyClass = css`
  padding: 200px 0;
`;
type Props = {
  title: string;
};

export const EmptyPlaceHolderPage = ({ title }: Props) => {
  const { t } = useSharedTranslation();
  const { translatedProductInUse } = useMultiAppStore();

  return (
    <>
      <PageHeader productName={translatedProductInUse} title={title} />

      <UiCard bodyClassName={cardBodyClass}>
        <UiIllustrateMessage
          name={IllustratedMessagesSet.EMPTY_LIST}
          titleText={t('emptyPagesPlaceholder.title')}
          subtitleText={t('emptyPagesPlaceholder.subtitle')}
        />
      </UiCard>
    </>
  );
};
