type Props = {
  hasExportCSV?: boolean;
  hasSettingsModal?: boolean;
  filters?: unknown;
  sorting?: unknown;
  apiPath?: string;
};

const SMART_TABLE = 'SmartTableCard';

export const checkForSmartTableInputErrors = ({ hasExportCSV, apiPath }: Props) => {
  if (hasExportCSV && !apiPath) {
    throw new Error(
      `${SMART_TABLE}: Missing apiPath props for request (e.g. 'emissions.getEmissions')`,
    );
  }
};
