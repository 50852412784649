import styled from '@emotion/styled';
import { useMemo } from 'react';

import { IconSet } from '../../../../../../GlobalProviders/IconSetProvider';
import { MoveDirection } from '../../../../../../Types/types';
import { useOnHoverElementSupport } from '../../../../../../Utils/useOnHoverElementSupport';
import { UiButton } from '../../../../UiButton/UiButton';
import { UiListNavigationItem } from '../../../../UiList/UiListNavigationItem';
import { UiSelect } from '../../../../UiSelect/UiSelect';
import { UiSelectOption } from '../../../../UiSelect/UiSelectOption';
import { SortingOrder } from '../../../types';
import { SortingButtons } from './SortingButtons';
import { ContentWrapper, SortingItemWrapper, smallSelectClass } from './styles';
import { useSortingItem } from './useSortingItem';

type SortingListItemProps = {
  onNavigate?: (direction: MoveDirection) => void;
  value?: string;
  order?: SortingOrder;
  focusedItemValue?: string;
  isFirstActiveItem?: boolean;
  isLastActiveItem?: boolean;
};

const ActionWrapper = styled.div`
  display: flex;
`;

export const SortingListItem = ({
  onNavigate,
  value,
  order,
  focusedItemValue,
  isFirstActiveItem,
  isLastActiveItem,
}: SortingListItemProps) => {
  const {
    sortingItems,
    sortingOptions,
    handleSortingButtonsChange,
    handleSelectChange,
    handleRemoveClick,
  } = useSortingItem(value);
  const { isHovered, eventListeners } = useOnHoverElementSupport();
  const filteredOptions = useMemo(
    () =>
      sortingOptions?.filter(
        (option) =>
          !sortingItems?.some((item) => item.value === option.value && item.value !== value),
      ),
    [sortingItems, sortingOptions],
  );

  const isOtherFocused = focusedItemValue && focusedItemValue !== value;
  const isFocused = focusedItemValue === value;

  const isNavigationActive = !isOtherFocused && (isFocused || isHovered);

  return (
    <SortingItemWrapper {...eventListeners}>
      <ContentWrapper>
        <UiSelect className={smallSelectClass} onChange={handleSelectChange}>
          {filteredOptions?.map((item) => (
            <UiSelectOption key={item.value} value={item.value} isSelected={item.value === value}>
              {item.label}
            </UiSelectOption>
          ))}
        </UiSelect>
        <SortingButtons order={order} onChange={handleSortingButtonsChange} />
      </ContentWrapper>
      <ActionWrapper>
        {isNavigationActive && (
          <UiListNavigationItem
            isUpwardDisabled={isFirstActiveItem}
            isDownwardDisabled={isLastActiveItem}
            hideTop
            hideBottom
            onNavigate={onNavigate}
          />
        )}

        <UiButton
          data-no-dnd='true'
          onClick={handleRemoveClick}
          design={'Transparent'}
          icon={IconSet.DECLINE}
        />
      </ActionWrapper>
    </SortingItemWrapper>
  );
};
