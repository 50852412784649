import styled from '@emotion/styled';

import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';
import { UiSwitch, UiText } from '@UiCommon/Components';
import { FontWeight } from '@UiCommon/typography';

const Wrapper = styled.div`
  display: flex;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

type Props = {
  isChecked?: boolean;
  onChange?: (value?: boolean) => void;
};

export const HierarchyEnableSourceSwitch = ({ isChecked, onChange }: Props) => {
  const { t } = useGHGTranslation();

  return (
    <Wrapper>
      <TextWrapper>
        <UiText weight={FontWeight.BOLD}>{t('hierarchyManager.editDialog.enable.title')}</UiText>
        <UiText>{t('hierarchyManager.editDialog.enable.subText')}</UiText>
      </TextWrapper>
      <UiSwitch onChange={onChange} isChecked={isChecked} />
    </Wrapper>
  );
};
