import { RouteObject } from 'react-router-dom';

import { Dashboard } from './Dashboard';
import { InsightsRouting } from './Insights/Routing/InsightsRouting';
import { DataManagementRouting } from './DataManagement/Routing/DataManagementRouting';
import { OverviewRouting } from './Overview/Routing/OverviewRouting';
import { CustomizationRouting } from './Customization/Routing/CustomizationRouting';
import { SettingsRouting } from './Settings/Routing/SettingsRouting';

export const DashboardRouting: RouteObject = {
  path: '/',
  element: <Dashboard />,
  children: [
    OverviewRouting,
    DataManagementRouting,
    InsightsRouting,
    CustomizationRouting,
    SettingsRouting,
  ],
};
