import { useDataQualityQuery } from '@DataAccess/GHG/DataQuality/dataQuality.hooks';
import { DataQualityOverviewDto } from '@DataAccess/GHG/DataQuality/dataQuality.types';

import { DataQualityOverviewTable } from './DataQualityOverviewTable';
import { mapToDataQualityOverviewUi } from './mapToDataQualityOverviewUi';

type Props = {
  processId?: string;
};

const noData: DataQualityOverviewDto = { scope1: [], scope2: [], scope3: [] };

export const DataQualityOverview = ({ processId }: Props) => {
  const { data } = useDataQualityQuery(processId);

  const inputDtoData = processId && data ? data : noData;
  const inputData = mapToDataQualityOverviewUi(inputDtoData);

  return (
    <>
      {Object.entries(inputData).map(([score, dataPerScore]) => {
        return <DataQualityOverviewTable key={score} data={dataPerScore} />;
      })}
    </>
  );
};
