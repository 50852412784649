import { UniqueIdentifier, DragEndEvent, DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import {
  flexRender,
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  Table,
  Row,
  Column,
  OnChangeFn,
  VisibilityState,
} from '@tanstack/react-table';
import React, { ReactNode, useMemo } from 'react';

import { useCustomSensors } from '@Utils/Draggable/useCustomSensors';

import {
  ActionsContainer,
  StyledTable,
  StyledTh,
  StyledTd,
  StyledTHead,
  TableContainer,
  TableHeader,
  StyledTr,
} from './Styles';
import { UiTitle } from '../UiTitle';
import { DraggableRow } from './UiDraggableRow';

export type CellProps<T> = {
  table: Table<T>;
  row: Row<T>;
  column: Column<T>;
  getValue: () => any;
  renderValue: () => any;
};

export type UniqueIdentifiedType = { id: string };
type Props<T extends { id: string }> = {
  columns: ColumnDef<T>[];
  data: T[];
  onDragEnd?: (values: T[]) => void;
  ActionsComponent?: ReactNode;
  title: string;
  children?: ReactNode;
  isDragAndDropActive?: boolean;
  onColumnVisibilityChange?: OnChangeFn<VisibilityState>;
  columnVisibility?: VisibilityState;
  renderAdditionalContent?: (table: Table<T>) => ReactNode;
};

export const UiDraggableTable = <T extends UniqueIdentifiedType>({
  columns,
  data,
  onDragEnd,
  ActionsComponent,
  title,
  children,
  isDragAndDropActive,
  columnVisibility,
  onColumnVisibilityChange,
  renderAdditionalContent,
}: Props<T>) => {
  const dataIds = useMemo<UniqueIdentifier[]>(() => data?.map(({ id }) => id), [data]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id,
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange,
    enableRowSelection: true,
    enableMultiRowSelection: false,
  });

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const oldIndex = dataIds.indexOf(active.id);
      const newIndex = dataIds.indexOf(over.id);
      const updatedData = arrayMove(data, oldIndex, newIndex);
      onDragEnd?.(updatedData);
    }
  };

  const sensors = useCustomSensors();

  return (
    <TableContainer>
      <TableHeader>
        <UiTitle level='H5'>{title}</UiTitle>
        <ActionsContainer>{ActionsComponent}</ActionsContainer>
      </TableHeader>

      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={handleDragEnd} sensors={sensors}>
        <StyledTable>
          <StyledTHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <StyledTr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <StyledTh key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </StyledTh>
                ))}
              </StyledTr>
            ))}
          </StyledTHead>
          <tbody>
            <SortableContext items={dataIds} strategy={verticalListSortingStrategy}>
              {table.getRowModel().rows.map((row) => (
                <DraggableRow
                  isDragAndDropActive={isDragAndDropActive}
                  key={row.id}
                  id={row.original.id}
                >
                  {row.getVisibleCells().map((cell) => (
                    <StyledTd key={cell.id} style={{ width: cell.column.getSize() }}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </StyledTd>
                  ))}
                </DraggableRow>
              ))}
            </SortableContext>
            {renderAdditionalContent?.(table)}
            {children}
          </tbody>
        </StyledTable>
      </DndContext>
    </TableContainer>
  );
};
