import { initialiseCoreEndpoints } from './Core/core.api';
import { initialiseCoreLibEndpoints } from './CoreLib/coreLib.api';
import { initialiseGhgEndpoints } from './GHG/ghg.api';
import { initialisePhysicalRiskEndpoints } from './PhysicalRisk/physicalRisk.api';
import { initialisePlatformEndpoints } from './Platform/platform.api';
import { axiosFactory } from './axiosFactory';

class Api {
  private api;

  platform: ReturnType<typeof initialisePlatformEndpoints>;
  core: ReturnType<typeof initialiseCoreEndpoints>;
  coreLib: ReturnType<typeof initialiseCoreLibEndpoints>;
  ghg: ReturnType<typeof initialiseGhgEndpoints>;
  physicalRisk: ReturnType<typeof initialisePhysicalRiskEndpoints>;

  constructor() {
    this.api = axiosFactory.newInstance();
    this.platform = initialisePlatformEndpoints(this.api);
    this.core = initialiseCoreEndpoints(this.api);
    this.coreLib = initialiseCoreLibEndpoints(this.api);
    this.ghg = initialiseGhgEndpoints(this.api);
    this.physicalRisk = initialisePhysicalRiskEndpoints(this.api);
  }

  getServerInfo() {
    return this.api.get<unknown>('/server_info').then(({ data }) => data);
  }
}

export default new Api();
