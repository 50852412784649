import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';
import { IconSet } from '@GlobalProviders/IconSetProvider';
import { KpiRouting } from '@GlobalProviders/MultiApp/KpiRouting';
import { HIERARCHY_MANAGER_ROUTE, PROCESS_MANAGER_ROUTE } from '@Pages/DataManagement/dmRoutes';
import { DATA_MANAGEMENT_ROUTE } from '@Pages/DataManagement/Routing/routes';
import { OVERVIEW_ROUTE } from '@Pages/Overview/Routing/routes';
import { NavigationItem, NavigationExpandableItem } from '@UiCommon/Layout';
import { CUSTOMIZATION_ROUTE } from '@Pages/Customization/Routing/routes';
import { SPLIT_SCOPE_ROUTE } from '@Pages/Customization/GHG/SplitScope/Routing/routes';
import { INSIGHTS_ROUTE } from '@Pages/Insights/Routing/routes';
import { SINGLE_EXPOSURE_ROUTE } from '@Pages/Insights/GHG/SingleExposure/Routing/routes';

export const GHGSidebarNavigation = () => {
  const { t } = useGHGTranslation();

  return (
    <>
      <NavigationItem
        label={t('navigationPanel.links.overview')}
        pathTo={`${OVERVIEW_ROUTE}/${KpiRouting.GHG}`}
        iconName={IconSet.HOME}
      />
      <NavigationExpandableItem
        label={t('navigationPanel.links.insights')}
        iconName={IconSet.MANAGER_INSIGHT}
        subItemsConfig={[
          {
            label: t('navigationPanel.links.singleExposure'),
            pathTo: `${INSIGHTS_ROUTE}/${KpiRouting.GHG}/${SINGLE_EXPOSURE_ROUTE}`,
          },
        ]}
      />
      <NavigationExpandableItem
        label={t('navigationPanel.links.dataManagement')}
        iconName={IconSet.COMPETITOR}
        subItemsConfig={[
          {
            label: t('navigationPanel.links.hierarchyManager'),
            pathTo: `${DATA_MANAGEMENT_ROUTE}/${KpiRouting.GHG}/${HIERARCHY_MANAGER_ROUTE}`,
          },
          {
            label: t('navigationPanel.links.processManager'),
            pathTo: `${DATA_MANAGEMENT_ROUTE}/${KpiRouting.GHG}/${PROCESS_MANAGER_ROUTE}`,
          },
        ]}
      />
      <NavigationExpandableItem
        label={t('navigationPanel.links.customization')}
        iconName={IconSet.SIMULATE}
        subItemsConfig={[
          {
            label: t('navigationPanel.links.splitScope'),
            pathTo: `${CUSTOMIZATION_ROUTE}/${KpiRouting.GHG}/${SPLIT_SCOPE_ROUTE}`,
          },
        ]}
      />
    </>
  );
};
