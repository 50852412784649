import styled from '@emotion/styled';
import { Label, Select, SelectPropTypes } from '@ui5/webcomponents-react';
import { useEffect, useRef } from 'react';

import { CommonProps, ElementSize } from '../../types';
import { GenericInputWrapper, commonInputProperties } from '../styles';
import { useUiSelectOnChangeHandler } from './useUiSelectOnChangeHandler';

type StyledWrapperProps = {
  isHorizontal?: boolean;
};

type Props = CommonProps &
  StyledWrapperProps &
  Pick<SelectPropTypes, 'disabled' | 'valueState'> & {
    size?: ElementSize;
    label?: string;
    placeholder?: string;
    onChange?: (value?: string) => void;
    inputClassName?: string;
  };

const StyledGenericInputWrapper = styled(GenericInputWrapper)<StyledWrapperProps>`
  ${({ isHorizontal }) =>
    isHorizontal && 'flex-direction: row; align-items: center; gap: 0.625rem;'}
`;

const StyledSelect = styled(Select)`
  ${commonInputProperties()};
`;

export const UiSelect = ({
  children,
  label,
  size = ElementSize.SMALL,
  onChange,
  className,
  inputClassName,
  isHorizontal,
  ...props
}: Props) => {
  const onChangeHandler = useUiSelectOnChangeHandler(onChange);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const closeListener = (e: Event) => {
      e.stopPropagation();
    };
    ref.current?.addEventListener('ui5-close', closeListener);

    return () => {
      ref.current?.removeEventListener('ui5-close', closeListener);
    };
  }, []);

  return (
    <StyledGenericInputWrapper
      ref={ref}
      className={className}
      data-ui5-compact-size={size === ElementSize.SMALL ? true : undefined}
      isHorizontal={isHorizontal}
    >
      {label && <Label>{label}:</Label>}
      <StyledSelect
        className={inputClassName}
        data-no-dnd='true'
        {...props}
        onChange={onChangeHandler}
      >
        {children}
      </StyledSelect>
    </StyledGenericInputWrapper>
  );
};
