import { ResponsivePopover } from '@ui5/webcomponents-react';

import { CommonProps } from '@UiCommon/types';

type Props = CommonProps & {
  openerId: string;
  open?: boolean;
  onAfterClose?: () => void;
  headerText?: string;
};

export const UiResponsivePopover = ({
  openerId,
  open,
  className,
  headerText,
  onAfterClose,
  children,
}: Props) => {
  return (
    <ResponsivePopover
      opener={openerId}
      open={open}
      className={className}
      headerText={headerText}
      onAfterClose={onAfterClose}
    >
      {children}
    </ResponsivePopover>
  );
};
