import styled from '@emotion/styled';
import { useEffect, useRef, useState } from 'react';
import { ButtonDesign } from '@ui5/webcomponents-react';
import toast from 'react-hot-toast';

import { useProcessManagerTranslation } from '@GlobalProviders/i18n/hooks';
import { UiBusyIndicator, UiButton, UiFileUploadButton } from '@UiCommon/Components';
import { FileExtensions } from '@UiCommon/types';
import { IconSet } from '@GlobalProviders/IconSetProvider';
import { EntityType, FileErrorCode } from '@DataAccess/Platform/FileUpload/fileUpload.types';
import { useUploadFileMutation } from '@DataAccess/Platform/FileUpload/fileUpload.hooks';

type UploadFileCellProps = {
  entity: EntityType;
};

const StyledBusyIndicator = styled(UiBusyIndicator)`
  margin-left: 0.5rem;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FileNameWrapper = styled.div`
  margin-left: 0.5rem;
  overflow: hidden;
  word-wrap: nowrap;
  text-overflow: ellipsis;
`;

export const UploadFileCell = ({ entity }: UploadFileCellProps) => {
  const { t } = useProcessManagerTranslation();
  const [file, setFile] = useState<string>();
  const [showTooltip, setShowTooltip] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textRef.current && textRef.current.scrollWidth > textRef.current.clientWidth) {
      setShowTooltip(true);
    }
  }, [file]);

  const { mutate: uploadFile, isPending } = useUploadFileMutation({
    onSuccess: (fileName) => {
      toast.success(t('processManager.manualUpload.toast.onSuccessUpload'));
      setFile(fileName);
    },
    onError: (error) => {
      if (error?.response?.status === 504) {
        toast.error(t('processManager.manualUpload.toast.onGatewayTimeout'));

        return;
      }
      const fileErrorMessageMap: Record<FileErrorCode, string> = {
        [FileErrorCode.INVALID_ENTITY]: t('processManager.manualUpload.toast.onInvalidEntity'),
        [FileErrorCode.INVALID_EXTENSION]: t(
          'processManager.manualUpload.toast.onInvalidExtension',
        ),
        [FileErrorCode.GENERAL_ERROR]: t('processManager.manualUpload.toast.onGeneralError'),
        [FileErrorCode.INVALID_GEOCOORDINATES]: t(
          'processManager.manualUpload.toast.onInvalidGeoCoordinates',
        ),
      };
      const errorCode = error?.response?.data.error;
      errorCode && toast.error(fileErrorMessageMap[errorCode]);
    },
  });
  const onFileSelectedHandler = (files: FileList, selectedEntity: EntityType) => {
    files && uploadFile({ files, entityType: selectedEntity });
  };

  return (
    <Wrapper>
      {isPending && <StyledBusyIndicator isActive />}
      {file && (
        <>
          <FileNameWrapper ref={textRef} title={showTooltip ? file : ''}>
            {file}
          </FileNameWrapper>
          <UiButton
            onClick={() => setFile('')}
            design={ButtonDesign.Transparent}
            icon={IconSet.DECLINE}
          />
        </>
      )}
      {!file && !isPending && (
        <UiFileUploadButton
          acceptedExtensions={[FileExtensions.CSV]}
          design='Transparent'
          onChange={(files) => files && onFileSelectedHandler(files, entity)}
        >
          {t('processManager.manualUpload.uploadDialog.uploadBtnLabel')}
        </UiFileUploadButton>
      )}
    </Wrapper>
  );
};
