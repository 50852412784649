import { PageContentWrapper } from '@UiCommon/Layout/Components/PageContentWrapper';

import { CurrencySection } from './CurrencySection';
import { DefaultUnitsSection } from './DefaultUnitsSection';

export const SystemSettingsPage = () => {
  return (
    <PageContentWrapper>
      <CurrencySection />
      <DefaultUnitsSection />
    </PageContentWrapper>
  );
};
