import { UnsavedChangesDialog } from '@Pages/Shared/SmartComponents/UnsavedChangesDialog';

import { SplitScopeTable } from '../Components/SplitScopeTable';
import { useSplitScopeContext } from '../Context/SplitScopeContext';
import { BuildingCreateRuleModal } from './BuildingCreateRuleModal';
import { useBuildingCustomizationColumns } from './useBuildingCustomizationColumns';
import { useSplitScopeTable } from '../Hooks/useSplitScopeTable';

export const SplitScopeBuildingTable = () => {
  const columns = useBuildingCustomizationColumns();
  const { building, isEditMode, handleSaveSplitRatios, resetState } = useSplitScopeContext();
  const {
    state: { list },
    actions,
  } = building;
  const { editableItems, setIsCreateRuleModalOpen, isCreateRuleModalOpen } =
    useSplitScopeTable(list);

  return (
    <>
      <SplitScopeTable
        onSaveClick={handleSaveSplitRatios}
        onAddClick={() => setIsCreateRuleModalOpen(true)}
        columns={columns}
        data={editableItems}
        onDragEnd={actions.reorderItems}
        totalElements={list.length}
      />
      <BuildingCreateRuleModal
        isOpen={isCreateRuleModalOpen}
        onClose={() => setIsCreateRuleModalOpen(false)}
      />
      <UnsavedChangesDialog
        isEditing={isEditMode}
        onContinue={() => {
          resetState();
        }}
      />
    </>
  );
};
