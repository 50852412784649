import { useMemo } from 'react';

import { NumberFormatterConfig } from '@Utils/Formatters/types';
import { createDateFormatter } from '@Utils/Formatters/date';

import { CommonProps } from '../../types';

type Props = NumberFormatterConfig & {
  className?: CommonProps['className'];
  value?: string;
};

export const UiDateFormatter = ({ className, value }: Props) => {
  const formattedValue = useMemo(() => {
    const dateFormatter = createDateFormatter({});

    return value && dateFormatter.format(new Date(value));
  }, [value]);

  // Added after useMemo to avoid conditional hook render
  if (!value) return null;

  return (
    <div className={className} title={String(value)}>
      {formattedValue}
    </div>
  );
};
