import { useState } from 'react';
import { ValueState } from '@ui5/webcomponents-react';

import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';

import { EditableSplitScopeCell } from '../Components/EditableSplitScopeCell';
import { useSplitScopeContext } from '../Context/SplitScopeContext';

type BusinessPartnerSplitScopeCellProps = {
  value: number;
  index: number;
  id: string;
  isEditable?: boolean;
};

export const BusinessPartnerSplitScopeCell = ({
  value,
  index,
  id,
  isEditable,
}: BusinessPartnerSplitScopeCellProps) => {
  const { t } = useGHGTranslation();
  const [inputValue, setInputValue] = useState<number>(value);
  const { businessPartner } = useSplitScopeContext();
  const { actions, state } = businessPartner;
  const handleBlur = () => {
    actions.updateSplitScopeCell({ value: inputValue, index, id });
  };
  const containsError = state.errors.splitErrors.has(index);

  return (
    <EditableSplitScopeCell
      isEditable={isEditable}
      value={inputValue}
      valueState={containsError ? ValueState.Error : ValueState.None}
      valueStateMessage={t('splitScope.splitScopeValidationError')}
      onChange={(value) => value !== undefined && setInputValue(value)}
      onBlur={handleBlur}
    />
  );
};
