import { RouteObject } from 'react-router-dom';

import { FinancialContractPage } from '../FinancialContractPage';
import { FINANCIAL_CONTRACT_ROUTE } from './routes';

export const FinancialContractRouting: RouteObject = {
  path: FINANCIAL_CONTRACT_ROUTE,
  children: [
    {
      index: true,
      element: <FinancialContractPage />,
    },
  ],
};
