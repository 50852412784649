import { ThemingParameters } from '@ui5/webcomponents-react-base';
import { useCallback, useMemo, useState } from 'react';

import { useUiCommonTranslation } from '@GlobalProviders/i18n/hooks';
import { IconSet } from '@GlobalProviders/IconSetProvider';

import { generateFilterTokens } from '../../generateFilterTokens';
import { greyColorUiButtonClass } from '../../styles';
import { UiTableColumnDataType } from '../../types';
import { useSettingsModalContext } from '../SettingsModalContext';
import { FilterConditionsType } from '../types';
import { UiSettingsModalFooter } from '../UiSettingsModalFooter';
import { ConditionRow } from './Conditions/ConditionRow';
import {
  AddRowButtonWrapper,
  ConditionsDialog,
  ConditionsWrapper,
  DialogContentWrapper,
  FilterSettingsRow,
  uiMultiInputClass,
  uiTextClass,
} from './Styles';
import { UiButton } from '../../../UiButton/UiButton';
import { UiDialogHeader } from '../../../UiDialog/UiDialogHeader';
import { UiMultiInput } from '../../../UiMultiInput/UiMultiInput';
import { UiText } from '../../../UiText';
import { mapFilterConditionsByColumnType } from './Conditions/mapFilterConditionsByColumnType';

type Props = {
  columnId: string;
  label: string;
  onDelete: () => void;
};

export const ColumnFilters = ({ label, onDelete, columnId }: Props) => {
  const { t } = useUiCommonTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const { columnsToBeFiltered, onColumnsToBeFiltered } = useSettingsModalContext();

  const previousFilters = useMemo(() => structuredClone(columnsToBeFiltered), [isOpen]);

  const columnToBeFiltered = useMemo(
    () => columnsToBeFiltered?.find(({ value }) => value === columnId),
    [columnsToBeFiltered, columnId],
  );

  const conditions = useMemo(() => columnToBeFiltered?.conditions || [], [columnToBeFiltered]);

  const onConfirm = () => {
    setIsOpen(false);
  };

  const onCancel = () => {
    previousFilters && onColumnsToBeFiltered?.(previousFilters);
    setIsOpen(false);
  };

  const onFilterTypeChange = useCallback(
    (indexToUpdate: number) => (newColumnCondition: FilterConditionsType) => {
      const newColumnsToBeFiltered = structuredClone(columnsToBeFiltered);
      const columnToBeUpdated = newColumnsToBeFiltered?.find(({ value }) => columnId === value);
      const conditionToBeUpdated = columnToBeUpdated?.conditions[indexToUpdate];
      if (!conditionToBeUpdated) return;

      conditionToBeUpdated.columnCondition = newColumnCondition;

      newColumnsToBeFiltered && onColumnsToBeFiltered?.(newColumnsToBeFiltered);
    },
    [columnId, columnsToBeFiltered, onColumnsToBeFiltered],
  );

  const onFilterValueChange = useCallback(
    (indexToUpdate: number) => (newValue?: string | number | boolean) => {
      const newColumnsToBeFiltered = structuredClone(columnsToBeFiltered);
      const columnToBeUpdated = newColumnsToBeFiltered?.find(({ value }) => columnId === value);
      const conditionToBeUpdated = columnToBeUpdated?.conditions[indexToUpdate];
      if (!conditionToBeUpdated) return;

      conditionToBeUpdated.value = newValue;

      newColumnsToBeFiltered && onColumnsToBeFiltered?.(newColumnsToBeFiltered);
    },
    [columnId, columnsToBeFiltered, onColumnsToBeFiltered],
  );

  const onDeleteCondition = useCallback(
    (indexToDelete: number) => {
      const newColumnsToBeFiltered = structuredClone(columnsToBeFiltered);
      const columnToBeUpdated = newColumnsToBeFiltered?.find(({ value }) => columnId === value);
      columnToBeUpdated?.conditions.splice(indexToDelete, 1);

      newColumnsToBeFiltered && onColumnsToBeFiltered?.(newColumnsToBeFiltered);
    },
    [columnId, columnsToBeFiltered, onColumnsToBeFiltered],
  );

  const onAddNewCondition = useCallback(() => {
    const newColumnsToBeFiltered = structuredClone(columnsToBeFiltered);
    const columnToBeUpdated = newColumnsToBeFiltered?.find(({ value }) => columnId === value);
    const defaultCondition = mapFilterConditionsByColumnType(
      columnToBeFiltered?.type || UiTableColumnDataType.TEXT,
    )[0];
    columnToBeUpdated?.conditions.push({ columnCondition: defaultCondition });

    newColumnsToBeFiltered && onColumnsToBeFiltered?.(newColumnsToBeFiltered);
  }, [columnId, columnsToBeFiltered, onColumnsToBeFiltered]);

  return (
    <>
      <FilterSettingsRow>
        <UiText
          className={uiTextClass}
          variant='caption2'
          color={ThemingParameters.sapContent_LabelColor}
        >
          {label}:
        </UiText>
        <UiMultiInput
          className={uiMultiInputClass}
          onClick={() => setIsOpen(true)}
          endContent={
            <UiButton
              className={greyColorUiButtonClass}
              design='Transparent'
              icon={IconSet.VALUE_HELP}
            />
          }
          tokens={generateFilterTokens(conditions, columnToBeFiltered?.type)}
        />
        <UiButton design='Transparent' icon={IconSet.DECLINE} onClick={onDelete} />
      </FilterSettingsRow>

      <ConditionsDialog
        isOpen={isOpen}
        header={
          <UiDialogHeader
            startContent={t('settingsModal.filterModal.title', {
              label,
              interpolation: { escapeValue: false },
            })}
          />
        }
        footer={<UiSettingsModalFooter onConfirm={onConfirm} onCancel={onCancel} />}
        onAfterClose={() => setIsOpen(false)}
      >
        <DialogContentWrapper>
          <ConditionsWrapper>
            {conditions.map((condition, idx) => (
              <ConditionRow
                key={idx}
                columnType={columnToBeFiltered?.type || UiTableColumnDataType.TEXT}
                condition={condition}
                onFilterChange={onFilterTypeChange(idx)}
                onValueChange={onFilterValueChange(idx)}
                onDelete={() => onDeleteCondition(idx)}
              />
            ))}
          </ConditionsWrapper>

          <AddRowButtonWrapper>
            <UiButton onClick={onAddNewCondition}>{t('settingsModal.filterModal.add')}</UiButton>
          </AddRowButtonWrapper>
        </DialogContentWrapper>
      </ConditionsDialog>
    </>
  );
};
