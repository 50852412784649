import { AxiosInstance } from 'axios';

import { EntityType } from './fileUpload.types';

export const initialiseFileUploadEndpoints = (api: AxiosInstance) => ({
  uploadFilesForEntity(entityType: EntityType, files: FileList) {
    const formData = new FormData();
    Array.from(files).forEach((file: File) => {
      formData.append('files', file, file.name);
    });

    formData.append('types', entityType);

    return api.post('/file/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
});
