import styled from '@emotion/styled';
import { ThemingParameters } from '@ui5/webcomponents-react-base';
import { ReactNode, useState } from 'react';

import { CommonProps } from '../../../types';
import { Logo } from './Logo/Logo';
import { NavigationPanelContext } from './NavigationPanelContext';
import { PanelSwitcher } from './PanelSwitcher/PanelSwitcher';
import { StyleProps } from './types';

const FooterWrapper = styled.nav`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  gap: 1rem;
  margin: 0 0.75rem;
  border-top: 1px solid ${ThemingParameters.sapAccentBackgroundColor10};
`;

const NavigationContainer = styled.div<StyleProps>`
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  bottom: 0;
  min-width: ${({ isExtended }) => (isExtended ? '16.5rem' : '4rem')};
  background: ${ThemingParameters.sapBaseColor};
  box-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.16), 0 1rem 2rem rgba(85, 107, 130, 0.08);
  border-radius: 0 1rem 1rem 0;
`;

const ContentWrapper = styled.nav`
  border-top: 1px solid ${ThemingParameters.sapAccentBackgroundColor10};
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  margin: 0 0.75rem 0.75rem;
`;

type NavigationPanelProps = CommonProps & {
  footer?: ReactNode;
};

export const NavigationPanel = ({ children, footer }: NavigationPanelProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isExtended, setIsExtended] = useState(true);

  const togglePanel = () => {
    setIsExtended(!isExtended);
  };

  const onMouseOver = () => {
    setIsHovered(true);
  };

  const onMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <NavigationPanelContext.Provider value={{ isPanelExtended: isExtended, togglePanel }}>
        <NavigationContainer
          data-testid='container'
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
          isExtended={isExtended}
        >
          <Logo />
          <ContentWrapper>{children}</ContentWrapper>
          {isHovered && <PanelSwitcher />}

          {!!footer && <FooterWrapper>{footer}</FooterWrapper>}
        </NavigationContainer>
      </NavigationPanelContext.Provider>
    </>
  );
};
