import { useCallback, useEffect, useMemo, useState } from 'react';
import { useOutletContext } from 'react-router';

import { Category, Subcategory } from '@DataAccess/Core/DataHierarchy/dataHierarchy.types';
import { UiListItemType } from '@UiCommon/Components/UiList/types';

import { DataHierarchyData, HierarchyContext } from './Components/types';

export const useHierarchy = (category: Category) => {
  const { categories, isFetched, isRefetching, isEditMode, updateDraftCategories } =
    useOutletContext<HierarchyContext>();

  const subcategoriesData = useMemo(() => categories?.[category], [categories, category]);

  const [subcategories, setSubcategories] = useState(subcategoriesData);

  useEffect(() => {
    if (isEditMode) return;

    setSubcategories(subcategoriesData);
  }, [isFetched, isRefetching, isEditMode]);

  useEffect(() => {
    if (!subcategories) return;
    const newHierarchy = { ...categories, [category]: subcategories };
    updateDraftCategories(newHierarchy);
  }, [subcategories]);

  const handleItemsUpdate = useCallback(
    (items: UiListItemType<DataHierarchyData>[], resourceId?: string) => {
      if (!subcategories) return;
      const newItems = items.map((item, index) => ({
        ...item,
        data: { ...item.data, order: index + 1 },
      }));

      const newHierarchy = {
        ...subcategories,
        [resourceId as string]: {
          ...subcategories[resourceId as Subcategory],
          items: newItems,
        },
      };

      setSubcategories(newHierarchy);
    },
    [subcategories, setSubcategories],
  );

  return {
    subcategories,
    handleItemsUpdate,
    isEditMode,
    updateDraftCategories,
  };
};
