import { useTranslation } from 'react-i18next';

export const useGHGTranslation = () => {
  return useTranslation('translationGHG');
};

export const usePRTranslation = () => {
  return useTranslation('translationPR');
};

export const useSharedTranslation = () => {
  return useTranslation('shared');
};

export const useProcessManagerTranslation = () => {
  return useTranslation('processManager');
};

export const useUiCommonTranslation = () => {
  return useTranslation('uiCommon');
};
