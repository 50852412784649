import { createContext, useContext } from 'react';

type NavigationPanelContext = {
  isPanelExtended: boolean;
  togglePanel: () => void;
};

export const NavigationPanelContext = createContext<NavigationPanelContext>({
  isPanelExtended: true,
  togglePanel: () => null,
});

export const useNavigationPanelContext = () => useContext(NavigationPanelContext);
