import { ComponentType, ReactNode } from 'react';
import { TextAlign } from '@ui5/webcomponents-react';

import { ColumnFiltersType } from './SettingsModal/types';

export type TableInstance<T> = {
  row: {
    original: T;
    index: number;
  };
};

export enum SortingOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum UiTableColumnDataType {
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
}

export type UiAnalyticalTableColumnDefinition<T> = {
  id: string;
  label: string;
  accessor?: string | ((row: TableInstance<T>, rowIndex: number) => unknown);
  Cell?: string | ComponentType<TableInstance<T>> | ((props?: TableInstance<T>) => ReactNode);
  width?: number;
  minWidth?: number;
  isVisible?: boolean;
  sorting?: SortingOrder;
  hAlign?: TextAlign;
  type?: UiTableColumnDataType;
  beField?: string;
  disableSortBy?: boolean;
  disableFilters?: boolean;
};

export type UiColumnFiltersType = Omit<ColumnFiltersType, 'label'>;
