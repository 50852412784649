import styled from '@emotion/styled';
import { ThemingParameters } from '@ui5/webcomponents-react-base';

import { CommonProps } from '../types';

export type HorizontalItem = {
  term: string;
  value?: JSX.Element | string;
};

type Props = CommonProps & {
  items: HorizontalItem[];
  descriptionTermClassName?: string;
  termValueClassName?: string;
  className?: string;
};

const HorizontalList = styled.dl`
  display: flex;
  column-gap: 3rem;
  margin: 0;
  margin-bottom: 0.5rem;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
`;

const DescriptionTerm = styled.dt`
  margin-bottom: 0.5rem;
  color: ${ThemingParameters.sapTitleColor};
`;

const TermValue = styled.dd`
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  font-size: 1.5rem;
`;

const NULL_VALUES_PLACEHOLDER = '–';

export const UiHorizontalList = ({
  items,
  descriptionTermClassName,
  termValueClassName,
  className,
}: Props) => (
  <HorizontalList className={className}>
    {items.map(({ term, value }) => (
      <Item key={term}>
        <DescriptionTerm className={descriptionTermClassName}>{term}</DescriptionTerm>
        <TermValue className={termValueClassName}>{value ?? NULL_VALUES_PLACEHOLDER}</TermValue>
      </Item>
    ))}
  </HorizontalList>
);
