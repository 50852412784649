export type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];

export enum ScopeType {
  SCOPE_1 = 'scope1',
  SCOPE_2 = 'scope2',
  SCOPE_3 = 'scope3',
}

export enum DataQuality {
  SCORE_1 = 'SCORE_1',
  SCORE_2 = 'SCORE_2',
  SCORE_3 = 'SCORE_3',
  SCORE_4 = 'SCORE_4',
  SCORE_5 = 'SCORE_5',
  SCORE_6 = 'SCORE_6',
  NONE = 'NONE',
}

export enum DataQualityScoreOption {
  OPTION_1A = 'OPTION_1A',
  OPTION_1B = 'OPTION_1B',
  OPTION_2A = 'OPTION_2A',
  OPTION_2B = 'OPTION_2B',
  OPTION_3 = 'OPTION_3',
  OPTION_3A = 'OPTION_3A',
  OPTION_3B = 'OPTION_3B',
  OPTION_3C = 'OPTION_3C',
  OPTION_9A = 'OPTION_9A',
  OPTION_NONE = 'OPTION_NONE',
}

export enum AssetType {
  LISTED_EQUITY_AND_CORPORATE_BONDS = 'LISTED_EQUITY_AND_CORPORATE_BONDS',
  COMMERCIAL_REAL_ESTATE = 'COMMERCIAL_REAL_ESTATE',
  PROJECT_FINANCE = 'PROJECT_FINANCE',
  MORTGAGES = 'MORTGAGES',
  MOTOR_VEHICLE_LOANS = 'MOTOR_VEHICLE_LOANS',
  SOVEREIGN_BONDS = 'SOVEREIGN_BONDS',
  BUSINESS_LOANS_AND_UNLISTED_EQUITY = 'BUSINESS_LOANS_AND_UNLISTED_EQUITY',
  SHIP_FINANCE = 'SHIP_FINANCE',
  AIRCRAFT_FINANCE = 'AIRCRAFT_FINANCE',
}

export enum ImpactAssessmentType {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export enum MoveDirection {
  UP,
  DOWN,
  TOP,
  BOTTOM,
}

export enum Currency {
  EUR = 'EUR',
  USD = 'USD',
  CAD = 'CAD',
  GBP = 'GBP',
  JPY = 'JPY',
}
