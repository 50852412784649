import styled from '@emotion/styled';
import { useBlocker } from 'react-router-dom';
import { css } from '@emotion/css';
import { ThemingParameters } from '@ui5/webcomponents-react-base';

import { IconSet } from '@GlobalProviders/IconSetProvider';
import { UiButton, UiDialog, UiDialogFooter, UiDialogHeader, UiIcon } from '@UiCommon/Components';
import { CommonProps } from '@UiCommon/types';
import { useSharedTranslation } from '@GlobalProviders/i18n/hooks';

type Props = CommonProps & {
  isEditing: boolean;
  onContinue?: () => void;
};

const IconClassName = css`
  color: ${ThemingParameters.sapCriticalColor};
`;

const HeaderContentWrapper = styled.section`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const UnsavedChangesDialog = ({ isEditing, onContinue }: Props) => {
  const { t } = useSharedTranslation();
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isEditing && currentLocation.pathname !== nextLocation.pathname,
  );

  return (
    <UiDialog
      isOpen={blocker.state === 'blocked'}
      header={
        <UiDialogHeader
          startContent={
            <HeaderContentWrapper>
              <UiIcon className={IconClassName} name={IconSet.ALERT} />
              {t('unsavedChangesDialog.title')}
            </HeaderContentWrapper>
          }
        />
      }
      footer={
        <UiDialogFooter
          endContent={
            <>
              <UiButton
                design='Emphasized'
                onClick={() => {
                  onContinue?.();
                  blocker?.proceed?.();
                }}
              >
                {t('unsavedChangesDialog.ok')}
              </UiButton>
              <UiButton design='Transparent' onClick={() => blocker.reset?.()}>
                {t('unsavedChangesDialog.cancel')}
              </UiButton>
            </>
          }
        />
      }
    >
      {t('unsavedChangesDialog.message')}
    </UiDialog>
  );
};
