import { RouteObject } from 'react-router-dom';

import { PA_ADDRESS_COORDINATES_ROUTE } from './routes';
import { PhysicalAssetAddressCoordinatesPage } from '../PhysicalAssetAddressCoordinatesPage';

export const PhysicalAssetAddressCoordinatesRouting: RouteObject = {
  path: PA_ADDRESS_COORDINATES_ROUTE,
  children: [
    {
      index: true,
      element: <PhysicalAssetAddressCoordinatesPage />,
    },
  ],
};
