import { useMemo } from 'react';

import { usePRTranslation } from '@GlobalProviders/i18n/hooks';
import { UiAnalyticalTableColumnDefinition } from '@UiCommon/Components/UiAnalyticalTable/types';

export const usePhysicalAssetInputAddressColumns = () => {
  const { t } = usePRTranslation();

  return useMemo<UiAnalyticalTableColumnDefinition<any>[]>(() => {
    return [
      {
        label: t('physicalAsset.inputAddress.countryRegion'),
        accessor: 'inputAddress.countryRegion',
        id: 'inputAddress.countryRegion',
        isVisible: true,
        beField: 'INPUT_ADDRESS_COUNTRY_REGION',
      },
      {
        label: t('physicalAsset.inputAddress.street'),
        accessor: 'inputAddress.street',
        id: 'inputAddress.street',
        isVisible: true,
        beField: 'INPUT_ADDRESS_STREET',
      },
      {
        label: t('physicalAsset.inputAddress.houseNr'),
        accessor: 'inputAddress.houseNr',
        id: 'inputAddress.houseNr',
        isVisible: true,
        beField: 'INPUT_ADDRESS_HOUSE_NR',
      },
      {
        label: t('physicalAsset.inputAddress.city'),
        accessor: 'inputAddress.city',
        id: 'inputAddress.city',
        isVisible: true,
        beField: 'INPUT_ADDRESS_CITY',
      },
      {
        label: t('physicalAsset.inputAddress.zipCode'),
        accessor: 'inputAddress.zipCode',
        id: 'inputAddress.zipCode',
        isVisible: true,
        beField: 'INPUT_ADDRESS_ZIP_CODE',
      },
      {
        label: t('physicalAsset.inputAddress.latitude'),
        accessor: 'inputAddress.latitude',
        id: 'inputAddress.latitude',
        isVisible: true,
        beField: 'INPUT_ADDRESS_LATITUDE',
      },
      {
        label: t('physicalAsset.inputAddress.longitude'),
        accessor: 'inputAddress.longitude',
        id: 'inputAddress.longitude',
        isVisible: true,
        beField: 'INPUT_ADDRESS_LONGITUDE',
      },
    ];
  }, [t]);
};
