import { ReactNode } from 'react';

declare global {
  interface Window {
    canvas?: HTMLCanvasElement;
  }
}

export type QuerySelectorAllShadowsProps = (
  selector: string,
  container?: HTMLElement | ShadowRoot,
) => Element[];

export type CommonProps = {
  children?: ReactNode | ReactNode[];
  className?: string;
};

export enum ElementSize {
  SMALL = 'SMALL',
  NORMAL = 'NORMAL',
}

export enum FileExtensions {
  CSV = '.csv',
}
