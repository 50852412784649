import { useCallback, useState } from 'react';
import { isNil } from 'lodash';

import { UiListItemType } from '@UiCommon/Components/UiList/types';
import { generateUUID } from '@Utils/utils';
import { Subcategory } from '@DataAccess/Core/DataHierarchy/dataHierarchy.types';

import { DataHierarchyData, EditSourceForm, HandleEditParams, Subcategories } from './types';

type Props = {
  subcategories?: Subcategories;
  handleItemsUpdate: (items: UiListItemType<DataHierarchyData>[], resourceId?: string) => void;
};

export const useDHSourceActions = ({ subcategories, handleItemsUpdate }: Props) => {
  const [sourceForm, setSourceForm] = useState<null | EditSourceForm>(null);
  const [resourceIdToAdd, setResourceIdToAdd] = useState<null | string>(null);

  const handleEditSource = useCallback(
    (items: UiListItemType<DataHierarchyData>[], resourceId?: string) => (id: string) => {
      const sourceItem = items.find((item) => item.value === id);

      if (!sourceItem) return;

      setSourceForm({
        id,
        sourceName: sourceItem.label,
        isEnabled: sourceItem.data?.isEnabled,
        resourceId,
        items,
      });
    },
    [setSourceForm],
  );

  const handleEdit = ({ items, id, name, resourceId, isEnabled }: HandleEditParams) => {
    if (!items || !id || !name || !resourceId || isNil(isEnabled)) return;

    const newItems = [...items];
    const item = newItems.find((item) => item.value === id);

    if (!item?.data) return;
    item.label = name;
    item.data = { ...item.data, isEnabled };

    handleItemsUpdate(newItems, resourceId);
    setSourceForm(null);
  };

  const handleAddSource = useCallback(
    (resourceId: string) => setResourceIdToAdd(resourceId),
    [setResourceIdToAdd],
  );

  const checkIfSourceAlreadyExists = (name?: string) => {
    if (!subcategories || !name) return false;

    // check when adding resource
    if (resourceIdToAdd) {
      return !!subcategories[resourceIdToAdd as Subcategory].items.find(
        (item) => item.label === name,
      );
    }

    // check when editing resource
    if (sourceForm?.resourceId) {
      return !!subcategories[sourceForm.resourceId as Subcategory].items.find(
        (item) => item.label === name,
      );
    }

    return false;
  };

  const handleNewDataSource = (name?: string) => {
    if (!resourceIdToAdd || !subcategories || !name) return;

    const currentItems = [...subcategories[resourceIdToAdd as Subcategory].items];
    const newItems: UiListItemType<DataHierarchyData>[] = [
      ...currentItems,
      {
        label: name,
        value: generateUUID(),
        data: { order: currentItems.length + 1, isEnabled: true },
      },
    ];

    handleItemsUpdate(newItems, resourceIdToAdd);
    setResourceIdToAdd(null);
  };

  const handleOnDelete = ({ id, resourceId, items }: Partial<EditSourceForm>) => {
    if (!id || !resourceId || !items) return;

    const newItems = [...items];
    const itemIndex = newItems.findIndex((item) => item.value === id);

    if (itemIndex === -1) return;

    newItems.splice(itemIndex, 1);

    handleItemsUpdate(newItems, resourceId);
    setSourceForm(null);
  };

  return {
    sourceForm,
    resourceIdToAdd,
    onCloseEditSourceDialog: () => setSourceForm(null),
    onCloseAddSourceDialog: () => setResourceIdToAdd(null),
    handleEditSource,
    handleEdit,
    handleAddSource,
    handleNewDataSource,
    handleOnDelete,
    checkIfSourceAlreadyExists,
  };
};
