import { useState } from 'react';
import { ValueState } from '@ui5/webcomponents-react';

import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';

import { EditableSplitScopeCell } from '../Components/EditableSplitScopeCell';
import { useSplitScopeContext } from '../Context/SplitScopeContext';

type ProjectSplitScopeCellProps = {
  value: number;
  index: number;
  id: string;
  isEditable?: boolean;
};

export const ProjectSplitScopeCell = ({
  value,
  index,
  id,
  isEditable,
}: ProjectSplitScopeCellProps) => {
  const { t } = useGHGTranslation();
  const [inputValue, setInputValue] = useState<number>(value);
  const { project } = useSplitScopeContext();
  const { state, actions } = project;
  const handleBlur = () => {
    actions.updateSplitScopeCell({ value: inputValue, index, id });
  };
  const containsError = state.errors.splitErrors.has(index);

  return (
    <EditableSplitScopeCell
      isEditable={isEditable}
      valueStateMessage={t('splitScope.splitScopeValidationError')}
      valueState={containsError ? ValueState.Error : ValueState.None}
      value={inputValue}
      onChange={(value) => value !== undefined && setInputValue(value)}
      onBlur={handleBlur}
    />
  );
};
