import styled from '@emotion/styled';
import { useMemo } from 'react';

import { useProcessManagerTranslation } from '@GlobalProviders/i18n/hooks';
import {
  TableInstance,
  UiAnalyticalTableColumnDefinition,
} from '@UiCommon/Components/UiAnalyticalTable/types';
import {
  ValidationReportDto,
  ErrorType,
} from '@DataAccess/Platform/ProcessMonitoring/processMonitoring.types';

const ErrorLogMessageCell = styled.div`
  text-wrap: wrap;
`;

export const useErrorLogColumns = () => {
  const { t } = useProcessManagerTranslation();

  return useMemo<UiAnalyticalTableColumnDefinition<ValidationReportDto>[]>(
    () => [
      {
        label: t('processManager.errorLog.table.error'),
        accessor: 'errorIndex',
        id: 'errorIndex',
        isVisible: true,
        disableSortBy: true,
        disableFilters: true,
      },
      {
        label: t('processManager.errorLog.table.entity'),
        accessor: 'entityType',
        id: 'entityType',
        isVisible: true,
        beField: 'ENTITY_TYPE',
      },
      {
        label: t('processManager.errorLog.table.identifier'),
        accessor: 'businessId',
        id: 'businessId',
        isVisible: true,
        beField: 'BUSINESS_ID',

        Cell: (table: TableInstance<ValidationReportDto>) => {
          const { businessId, errorType, rowNumber } = table.row.original;
          if (errorType === ErrorType.MISSING_BUSINESS_IDENTIFIER) {
            return <div>{t('processManager.errorLog.table.rows', { value: rowNumber })}</div>;
          }

          return <div>{businessId}</div>;
        },
      },
      {
        label: t('processManager.errorLog.table.attribute'),
        accessor: 'columnName',
        id: 'columnName',
        isVisible: true,
        beField: 'COLUMN_NAME',
      },
      {
        label: t('processManager.errorLog.table.value'),
        accessor: 'rawData',
        id: 'rawData',
        isVisible: true,
        beField: 'RAW_DATA',
      },
      {
        label: t('processManager.errorLog.table.errorMessage'),
        accessor: 'errorType',
        id: 'errorType',
        isVisible: true,
        beField: 'ERROR_TYPE',
        disableSortBy: true,
        disableFilters: true,
        minWidth: 200,
        Cell: (table: TableInstance<ValidationReportDto>) => {
          const { errorType } = table.row.original;

          return (
            <ErrorLogMessageCell>
              {t(`processManager.errorLog.error.${errorType}`)}
            </ErrorLogMessageCell>
          );
        },
      },
    ],
    [t],
  );
};
