import { MenuItem } from '@ui5/webcomponents-react';

import { CommonProps } from '../../types';

type Props = CommonProps & {
  icon?: string;
  text?: string;
  onClick?: () => void;
};

export const UiMenuItem = ({ ...props }: Props) => <MenuItem {...props} />;
