import { SortingOrder } from '../../../types';
import { useSettingsModalContext } from '../../SettingsModalContext';
import { updateSortingListItem } from '../utils';

export const useSortingItem = (value?: string) => {
  const { onSortingItemsChange, sortingItems, sortingOptions } = useSettingsModalContext();

  const handleSortingButtonsChange = (order: SortingOrder) => {
    if (sortingItems && value) {
      onSortingItemsChange?.(
        updateSortingListItem({
          sortingListItems: sortingItems,
          valueToUpdate: value,
          updatedItem: { data: { order } },
        }),
      );
    }
  };
  const handleSelectChange = (selectValue?: string) => {
    if (sortingItems && value) {
      onSortingItemsChange?.(
        updateSortingListItem({
          sortingListItems: sortingItems,
          valueToUpdate: value,
          updatedItem: { value: selectValue },
        }),
      );
    }
  };

  const handleRemoveClick = () => {
    if (sortingItems) {
      onSortingItemsChange?.(sortingItems.filter((item) => item.value !== value));
    }
  };

  return {
    handleRemoveClick,
    handleSelectChange,
    handleSortingButtonsChange,
    sortingItems,
    sortingOptions,
  };
};
