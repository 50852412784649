import { createContext, useContext } from 'react';

import { UiListItemType } from '../UiList/types';
import { CommonProps } from '../../types';
import { SortingListItemProps } from './SettingsModal/SortSettings/useSortingSettingsState';
import { SettingsTab } from './SettingsModal/useSettingsModalTabs';
import { SortingOrder } from './types';

type AnalyticalTableContextProps = {
  sortingItems?: UiListItemType<SortingListItemProps>[];
  onHeaderSettingsItemClick?: (tabId: SettingsTab) => void;
  onHeaderSortClick?: (id: string, order: SortingOrder) => void;
};

const AnalyticalTableContext = createContext<AnalyticalTableContextProps>({});

export const useAnalyticalTableContext = () => useContext(AnalyticalTableContext);

type Props = CommonProps & AnalyticalTableContextProps;

export const AnalyticalTableContextProvider = ({ children, ...context }: Props) => (
  <AnalyticalTableContext.Provider value={context}>{children}</AnalyticalTableContext.Provider>
);
