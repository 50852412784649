import { RouteObject } from 'react-router-dom';

import { DATA_MANAGEMENT_ROUTE } from './routes';
import { DmGHGRouting } from '../GHG/DmGHGRouting';
import { DmPRRouting } from '../PR/DmPRRouting';

export const DataManagementRouting: RouteObject = {
  path: DATA_MANAGEMENT_ROUTE,
  children: [...DmGHGRouting, ...DmPRRouting],
};
