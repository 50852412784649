import { Label, Table, TableColumn } from '@ui5/webcomponents-react';

import { UiTableCell } from './UiTableCell';
import { UiTableRow } from './UiTableRow';
import { CommonProps } from '../../types';

type UiTableCellProps =
  | Date
  | number
  | string
  | null
  | undefined
  | Record<string, Date | number | string | null>;

type CellProps = { cellData: UiTableCellProps; rowIdx: number; rowData: UiTableRowProps };

export type UiTableRowProps = Record<string, UiTableCellProps>;

// As in react table either accessor or id is required
export type UiTableColumnProps =
  | {
      label?: string;
      accessor?: string;
      id: string;
      minWidth?: number;
      Cell?: (props: CellProps) => JSX.Element;
    }
  | {
      label?: string;
      accessor: string;
      id?: string;
      minWidth?: number;
      Cell?: (props: CellProps) => JSX.Element;
    };

type Props = CommonProps & {
  tableRowClassName?: string;
  tableCellClassName?: string;
  tableClassName?: string;
  hasStickyHeader?: boolean;

  columns: UiTableColumnProps[];
  rows?: UiTableRowProps[];
};

const generateUiTableColumnsProps = (columns: UiTableColumnProps[]) => (
  <>
    {columns.map(({ label, minWidth, id, accessor }) => (
      <TableColumn key={`${accessor || id}`} minWidth={minWidth}>
        <Label>{label}</Label>
      </TableColumn>
    ))}
  </>
);

type UiTableRowsProps = {
  rows: UiTableRowProps[];
  columns: UiTableColumnProps[];
  tableRowClassName?: string;
  tableCellClassName?: string;
};

const UiTableRows = ({
  rows,
  columns,
  tableRowClassName,
  tableCellClassName,
}: UiTableRowsProps) => (
  <>
    {rows.map((row, idx) => (
      <UiTableRow className={tableRowClassName} key={idx}>
        {columns.map(({ accessor, Cell, id }) => {
          const cellData = accessor ? row[accessor] : null;

          return (
            <UiTableCell className={tableCellClassName} key={`${idx}${accessor || id}`}>
              {Cell ? (
                <Cell rowIdx={idx} rowData={row} cellData={cellData} />
              ) : (
                cellData?.toString()
              )}
            </UiTableCell>
          );
        })}
      </UiTableRow>
    ))}
  </>
);

export const UiTable = ({
  columns,
  rows,
  tableClassName,
  children,
  tableRowClassName,
  tableCellClassName,
  hasStickyHeader,
}: Props) => (
  <Table
    className={tableClassName}
    columns={generateUiTableColumnsProps(columns)}
    stickyColumnHeader={hasStickyHeader}
  >
    {rows && (
      <UiTableRows
        rows={rows}
        columns={columns}
        tableRowClassName={tableRowClassName}
        tableCellClassName={tableCellClassName}
      />
    )}
    {children}
  </Table>
);
