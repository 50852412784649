import { Navigate } from 'react-router-dom';

import { KpiRouting } from '@GlobalProviders/MultiApp/KpiRouting';
import { PHYSICAL_ASSET_ROUTE } from '@Pages/DataManagement/ProcessManager/ManualUpload/Entities/PhysicalAsset/Routing/routes';
import { KPIRoutingFactory } from '@Pages/Shared/Routing/KPIRoutingFactory';

import { PhysicalAssetRouting } from './PhysicalAsset/Routing/PhysicalAssetRouting';

export const InsightsPRRouting = KPIRoutingFactory(
  [{ index: true, element: <Navigate to={PHYSICAL_ASSET_ROUTE} /> }, PhysicalAssetRouting],
  KpiRouting.PHYSICAL_RISK,
);
