import styled from '@emotion/styled';
import { useMemo } from 'react';

import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';
import { IconSet } from '@GlobalProviders/IconSetProvider';
import { NavigationItem, NavigationPanel } from '@UiCommon/Layout';
import { useMultiAppStore } from '@GlobalProviders/MultiApp/MultiAppProvider';
import { SETTINGS_ROUTE } from '@Pages/Settings/Routing/routes';

import { NavigationFooter } from './NavigationFooter/NavigationFooter';
import { navigationConfig } from './navigationConfig';

const BottomNavigationItem = styled(NavigationItem)`
  margin-top: auto;
`;

export const NavigationSideBar = () => {
  const { t } = useGHGTranslation();

  const { productInUse } = useMultiAppStore();

  const NavigationContent = useMemo(() => navigationConfig(productInUse), [productInUse]);

  return (
    <NavigationPanel footer={<NavigationFooter />}>
      <NavigationContent />
      <BottomNavigationItem
        label={t('navigationPanel.links.settings')}
        iconName={IconSet.ACTION_SETTINGS}
        pathTo={SETTINGS_ROUTE}
      />
    </NavigationPanel>
  );
};
