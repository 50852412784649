import { Subcategory } from '@DataAccess/Core/DataHierarchy/dataHierarchy.types';

import { AllowedScoring } from './Components/types';

export const scoreNumberingConfig: Record<Subcategory, AllowedScoring[]> = {
  DIRECT_EMISSIONS: [1, 2],
  ENERGY: [2],
  ENERGY_EMISSION_FACTOR: [2],
  PRODUCTION: [3],
  PRODUCTION_EMISSION_FACTOR: [3],
  CORE: [1, 2, 3, 4],
  SECTOR_EMISSION_FACTOR: [4, 5],
  BUILDING: ['*'],
  BUILDING_CERTIFICATE_CLASS_EMISSION_FACTOR: [3],
  FLOOR_AREA_EMISSION_FACTOR: [4],
  BUILDINGS_EMISSION_FACTOR: [5],
  PHYSICAL_ASSET: ['*'],
};
