import { useMemo } from 'react';

import { ChronicAssetDto } from '@DataAccess/PhysicalRisk/SingleAsset/singleAsset.types';
import { usePRTranslation } from '@GlobalProviders/i18n/hooks';
import { UiAnalyticalTableColumnDefinition } from '@UiCommon/Components';
import { UiTableColumnDataType } from '@UiCommon/Components/UiAnalyticalTable/types';

import { useCommonPhysicalAssetColumns } from '../../../Hooks/Columns/useCommonPhysicalAssetColumns';

export const usePhysicalAssetChronicRootColumns = () => {
  const { t } = usePRTranslation();

  const commonColumns = useCommonPhysicalAssetColumns();

  return useMemo<UiAnalyticalTableColumnDefinition<ChronicAssetDto>[]>(() => {
    return [
      ...commonColumns,
      {
        label: t('physicalAsset.chronic.table.processId'),
        accessor: 'processId',
        id: 'processId',
        isVisible: true,
        beField: 'PROCESS_ID',
      },
      {
        label: t('physicalAsset.chronic.table.geoquality'),
        accessor: 'geoquality',
        id: 'geoquality',
        isVisible: true,
        beField: 'GEOQUALITY',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.tropicalCycloneCurrentHazardZone'),
        accessor: 'tropicalCycloneCurrentHazardZone',
        id: 'tropicalCycloneCurrentHazardZone',
        isVisible: true,
        beField: 'TROPICAL_CYCLONE_CURRENT_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.tropicalCycloneRCP45Y2030HazardZone'),
        accessor: 'tropicalCycloneRCP45Y2030HazardZone',
        id: 'tropicalCycloneRCP45Y2030HazardZone',
        isVisible: true,
        beField: 'TROPICAL_CYCLONE_RCP_45_Y_2030_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.tropicalCycloneRCP45Y2050HazardZone'),
        accessor: 'tropicalCycloneRCP45Y2050HazardZone',
        id: 'tropicalCycloneRCP45Y2050HazardZone',
        isVisible: true,
        beField: 'TROPICAL_CYCLONE_RCP_45_Y_2050_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.tropicalCycloneRCP45Y2100HazardZone'),
        accessor: 'tropicalCycloneRCP45Y2100HazardZone',
        id: 'tropicalCycloneRCP45Y2100HazardZone',
        isVisible: true,
        beField: 'TROPICAL_CYCLONE_RCP_45_Y_2100_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.tropicalCycloneRCP85Y2030HazardZone'),
        accessor: 'tropicalCycloneRCP85Y2030HazardZone',
        id: 'tropicalCycloneRCP85Y2030HazardZone',
        isVisible: true,
        beField: 'TROPICAL_CYCLONE_RCP_85_Y_2030_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.tropicalCycloneRCP85Y2050HazardZone'),
        accessor: 'tropicalCycloneRCP85Y2050HazardZone',
        id: 'tropicalCycloneRCP85Y2050HazardZone',
        isVisible: true,
        beField: 'TROPICAL_CYCLONE_RCP_85_Y_2050_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.tropicalCycloneRCP85Y2100HazardZone'),
        accessor: 'tropicalCycloneRCP85Y2100HazardZone',
        id: 'tropicalCycloneRCP85Y2100HazardZone',
        isVisible: true,
        beField: 'TROPICAL_CYCLONE_RCP_85_Y_2100_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.riverFloodCurrentHazardZoneDefended'),
        accessor: 'riverFloodCurrentHazardZoneDefended',
        id: 'riverFloodCurrentHazardZoneDefended',
        isVisible: true,
        beField: 'RIVER_FLOOD_CURRENT_HAZARD_ZONE_DEFENDED',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.riverFloodCurrentHazardZoneUndefended'),
        accessor: 'riverFloodCurrentHazardZoneUndefended',
        id: 'riverFloodCurrentHazardZoneUndefended',
        isVisible: true,
        beField: 'RIVER_FLOOD_CURRENT_HAZARD_ZONE_UNDEFENDED',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.riverFloodRCP45Y2030HazardZoneDefended'),
        accessor: 'riverFloodRCP45Y2030HazardZoneDefended',
        id: 'riverFloodRCP45Y2030HazardZoneDefended',
        isVisible: true,
        beField: 'RIVER_FLOOD_RCP_45_Y_2030_HAZARD_ZONE_DEFENDED',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.riverFloodRCP45Y2030HazardZoneUndefended'),
        accessor: 'riverFloodRCP45Y2030HazardZoneUndefended',
        id: 'riverFloodRCP45Y2030HazardZoneUndefended',
        isVisible: true,
        beField: 'RIVER_FLOOD_RCP_45_Y_2030_HAZARD_ZONE_UNDEFENDED',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.riverFloodRCP45Y2050HazardZoneDefended'),
        accessor: 'riverFloodRCP45Y2050HazardZoneDefended',
        id: 'riverFloodRCP45Y2050HazardZoneDefended',
        isVisible: true,
        beField: 'RIVER_FLOOD_RCP_45_Y_2050_HAZARD_ZONE_DEFENDED',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.riverFloodRCP45Y2050HazardZoneUndefended'),
        accessor: 'riverFloodRCP45Y2050HazardZoneUndefended',
        id: 'riverFloodRCP45Y2050HazardZoneUndefended',
        isVisible: true,
        beField: 'RIVER_FLOOD_RCP_45_Y_2050_HAZARD_ZONE_UNDEFENDED',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.riverFloodRCP45Y2100HazardZoneDefended'),
        accessor: 'riverFloodRCP45Y2100HazardZoneDefended',
        id: 'riverFloodRCP45Y2100HazardZoneDefended',
        isVisible: true,
        beField: 'RIVER_FLOOD_RCP_45_Y_2100_HAZARD_ZONE_DEFENDED',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.riverFloodRCP45Y2100HazardZoneUndefended'),
        accessor: 'riverFloodRCP45Y2100HazardZoneUndefended',
        id: 'riverFloodRCP45Y2100HazardZoneUndefended',
        isVisible: true,
        beField: 'RIVER_FLOOD_RCP_45_Y_2100_HAZARD_ZONE_UNDEFENDED',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.riverFloodRCP85Y2030HazardZoneDefended'),
        accessor: 'riverFloodRCP85Y2030HazardZoneDefended',
        id: 'riverFloodRCP85Y2030HazardZoneDefended',
        isVisible: true,
        beField: 'RIVER_FLOOD_RCP_85_Y_2030_HAZARD_ZONE_DEFENDED',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.riverFloodRCP85Y2030HazardZoneUndefended'),
        accessor: 'riverFloodRCP85Y2030HazardZoneUndefended',
        id: 'riverFloodRCP85Y2030HazardZoneUndefended',
        isVisible: true,
        beField: 'RIVER_FLOOD_RCP_85_Y_2030_HAZARD_ZONE_UNDEFENDED',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.riverFloodRCP85Y2050HazardZoneDefended'),
        accessor: 'riverFloodRCP85Y2050HazardZoneDefended',
        id: 'riverFloodRCP85Y2050HazardZoneDefended',
        isVisible: true,
        beField: 'RIVER_FLOOD_RCP_85_Y_2050_HAZARD_ZONE_DEFENDED',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.riverFloodRCP85Y2050HazardZoneUndefended'),
        accessor: 'riverFloodRCP85Y2050HazardZoneUndefended',
        id: 'riverFloodRCP85Y2050HazardZoneUndefended',
        isVisible: true,
        beField: 'RIVER_FLOOD_RCP_85_Y_2050_HAZARD_ZONE_UNDEFENDED',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.riverFloodRCP85Y2100HazardZoneDefended'),
        accessor: 'riverFloodRCP85Y2100HazardZoneDefended',
        id: 'riverFloodRCP85Y2100HazardZoneDefended',
        isVisible: true,
        beField: 'RIVER_FLOOD_RCP_85_Y_2100_HAZARD_ZONE_DEFENDED',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.riverFloodRCP85Y2100HazardZoneUndefended'),
        accessor: 'riverFloodRCP85Y2100HazardZoneUndefended',
        id: 'riverFloodRCP85Y2100HazardZoneUndefended',
        isVisible: true,
        beField: 'RIVER_FLOOD_RCP_85_Y_2100_HAZARD_ZONE_UNDEFENDED',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.seaLevelRiseRCP26Y2100HazardZone'),
        accessor: 'seaLevelRiseRCP26Y2100HazardZone',
        id: 'seaLevelRiseRCP26Y2100HazardZone',
        isVisible: true,
        beField: 'SEA_LEVEL_RISE_RCP_26_Y_2100_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.seaLevelRiseRCP45Y2100HazardZone'),
        accessor: 'seaLevelRiseRCP45Y2100HazardZone',
        id: 'seaLevelRiseRCP45Y2100HazardZone',
        isVisible: true,
        beField: 'SEA_LEVEL_RISE_RCP_45_Y_2100_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.seaLevelRiseRCP85Y2100HazardZone'),
        accessor: 'seaLevelRiseRCP85Y2100HazardZone',
        id: 'seaLevelRiseRCP85Y2100HazardZone',
        isVisible: true,
        beField: 'SEA_LEVEL_RISE_RCP_85_Y_2100_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.fireWeatherStressIndexCurrentHazardZone'),
        accessor: 'fireWeatherStressIndexCurrentHazardZone',
        id: 'fireWeatherStressIndexCurrentHazardZone',
        isVisible: true,
        beField: 'FIRE_WEATHER_STRESS_INDEX_CURRENT_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.fireWeatherStressIndexSSP126Y2030HazardZone'),
        accessor: 'fireWeatherStressIndexSSP126Y2030HazardZone',
        id: 'fireWeatherStressIndexSSP126Y2030HazardZone',
        isVisible: true,
        beField: 'FIRE_WEATHER_STRESS_INDEX_SSP_126_Y_2030_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.fireWeatherStressIndexSSP126Y2040HazardZone'),
        accessor: 'fireWeatherStressIndexSSP126Y2040HazardZone',
        id: 'fireWeatherStressIndexSSP126Y2040HazardZone',
        isVisible: true,
        beField: 'FIRE_WEATHER_STRESS_INDEX_SSP_126_Y_2040_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.fireWeatherStressIndexSSP126Y2050HazardZone'),
        accessor: 'fireWeatherStressIndexSSP126Y2050HazardZone',
        id: 'fireWeatherStressIndexSSP126Y2050HazardZone',
        isVisible: true,
        beField: 'FIRE_WEATHER_STRESS_INDEX_SSP_126_Y_2050_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.fireWeatherStressIndexSSP126Y2100HazardZone'),
        accessor: 'fireWeatherStressIndexSSP126Y2100HazardZone',
        id: 'fireWeatherStressIndexSSP126Y2100HazardZone',
        isVisible: true,
        beField: 'FIRE_WEATHER_STRESS_INDEX_SSP_126_Y_2100_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.fireWeatherStressIndexSSP245Y2030HazardZone'),
        accessor: 'fireWeatherStressIndexSSP245Y2030HazardZone',
        id: 'fireWeatherStressIndexSSP245Y2030HazardZone',
        isVisible: true,
        beField: 'FIRE_WEATHER_STRESS_INDEX_SSP_245_Y_2030_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.fireWeatherStressIndexSSP245Y2040HazardZone'),
        accessor: 'fireWeatherStressIndexSSP245Y2040HazardZone',
        id: 'fireWeatherStressIndexSSP245Y2040HazardZone',
        isVisible: true,
        beField: 'FIRE_WEATHER_STRESS_INDEX_SSP_245_Y_2040_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.fireWeatherStressIndexSSP245Y2050HazardZone'),
        accessor: 'fireWeatherStressIndexSSP245Y2050HazardZone',
        id: 'fireWeatherStressIndexSSP245Y2050HazardZone',
        isVisible: true,
        beField: 'FIRE_WEATHER_STRESS_INDEX_SSP_245_Y_2050_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.fireWeatherStressIndexSSP245Y2100HazardZone'),
        accessor: 'fireWeatherStressIndexSSP245Y2100HazardZone',
        id: 'fireWeatherStressIndexSSP245Y2100HazardZone',
        isVisible: true,
        beField: 'FIRE_WEATHER_STRESS_INDEX_SSP_245_Y_2100_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.fireWeatherStressIndexSSP370Y2030HazardZone'),
        accessor: 'fireWeatherStressIndexSSP370Y2030HazardZone',
        id: 'fireWeatherStressIndexSSP370Y2030HazardZone',
        isVisible: true,
        beField: 'FIRE_WEATHER_STRESS_INDEX_SSP_370_Y_2030_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.fireWeatherStressIndexSSP370Y2040HazardZone'),
        accessor: 'fireWeatherStressIndexSSP370Y2040HazardZone',
        id: 'fireWeatherStressIndexSSP370Y2040HazardZone',
        isVisible: true,
        beField: 'FIRE_WEATHER_STRESS_INDEX_SSP_370_Y_2040_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.fireWeatherStressIndexSSP370Y2050HazardZone'),
        accessor: 'fireWeatherStressIndexSSP370Y2050HazardZone',
        id: 'fireWeatherStressIndexSSP370Y2050HazardZone',
        isVisible: true,
        beField: 'FIRE_WEATHER_STRESS_INDEX_SSP_370_Y_2050_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.fireWeatherStressIndexSSP370Y2100HazardZone'),
        accessor: 'fireWeatherStressIndexSSP370Y2100HazardZone',
        id: 'fireWeatherStressIndexSSP370Y2100HazardZone',
        isVisible: true,
        beField: 'FIRE_WEATHER_STRESS_INDEX_SSP_370_Y_2100_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.fireWeatherStressIndexSSP585Y2030HazardZone'),
        accessor: 'fireWeatherStressIndexSSP585Y2030HazardZone',
        id: 'fireWeatherStressIndexSSP585Y2030HazardZone',
        isVisible: true,
        beField: 'FIRE_WEATHER_STRESS_INDEX_SSP_585_Y_2030_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.fireWeatherStressIndexSSP585Y2040HazardZone'),
        accessor: 'fireWeatherStressIndexSSP585Y2040HazardZone',
        id: 'fireWeatherStressIndexSSP585Y2040HazardZone',
        isVisible: true,
        beField: 'FIRE_WEATHER_STRESS_INDEX_SSP_585_Y_2040_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.fireWeatherStressIndexSSP585Y2050HazardZone'),
        accessor: 'fireWeatherStressIndexSSP585Y2050HazardZone',
        id: 'fireWeatherStressIndexSSP585Y2050HazardZone',
        isVisible: true,
        beField: 'FIRE_WEATHER_STRESS_INDEX_SSP_585_Y_2050_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.fireWeatherStressIndexSSP585Y2100HazardZone'),
        accessor: 'fireWeatherStressIndexSSP585Y2100HazardZone',
        id: 'fireWeatherStressIndexSSP585Y2100HazardZone',
        isVisible: true,
        beField: 'FIRE_WEATHER_STRESS_INDEX_SSP_585_Y_2100_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.droughtStressIndexCurrentHazardZone'),
        accessor: 'droughtStressIndexCurrentHazardZone',
        id: 'droughtStressIndexCurrentHazardZone',
        isVisible: true,
        beField: 'DROUGHT_STRESS_INDEX_CURRENT_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.droughtStressIndexSSP245Y2030HazardZone'),
        accessor: 'droughtStressIndexSSP245Y2030HazardZone',
        id: 'droughtStressIndexSSP245Y2030HazardZone',
        isVisible: true,
        beField: 'DROUGHT_STRESS_INDEX_SSP_245_Y_2030_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.droughtStressIndexSSP245Y2040HazardZone'),
        accessor: 'droughtStressIndexSSP245Y2040HazardZone',
        id: 'droughtStressIndexSSP245Y2040HazardZone',
        isVisible: true,
        beField: 'DROUGHT_STRESS_INDEX_SSP_245_Y_2040_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.droughtStressIndexSSP245Y2050HazardZone'),
        accessor: 'droughtStressIndexSSP245Y2050HazardZone',
        id: 'droughtStressIndexSSP245Y2050HazardZone',
        isVisible: true,
        beField: 'DROUGHT_STRESS_INDEX_SSP_245_Y_2050_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.droughtStressIndexSSP245Y2100HazardZone'),
        accessor: 'droughtStressIndexSSP245Y2100HazardZone',
        id: 'droughtStressIndexSSP245Y2100HazardZone',
        isVisible: true,
        beField: 'DROUGHT_STRESS_INDEX_SSP_245_Y_2100_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.droughtStressIndexSSP585Y2030HazardZone'),
        accessor: 'droughtStressIndexSSP585Y2030HazardZone',
        id: 'droughtStressIndexSSP585Y2030HazardZone',
        isVisible: true,
        beField: 'DROUGHT_STRESS_INDEX_SSP_585_Y_2030_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.droughtStressIndexSSP585Y2040HazardZone'),
        accessor: 'droughtStressIndexSSP585Y2040HazardZone',
        id: 'droughtStressIndexSSP585Y2040HazardZone',
        isVisible: true,
        beField: 'DROUGHT_STRESS_INDEX_SSP_585_Y_2040_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.droughtStressIndexSSP585Y2050HazardZone'),
        accessor: 'droughtStressIndexSSP585Y2050HazardZone',
        id: 'droughtStressIndexSSP585Y2050HazardZone',
        isVisible: true,
        beField: 'DROUGHT_STRESS_INDEX_SSP_585_Y_2050_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.droughtStressIndexSSP585Y2100HazardZone'),
        accessor: 'droughtStressIndexSSP585Y2100HazardZone',
        id: 'droughtStressIndexSSP585Y2100HazardZone',
        isVisible: true,
        beField: 'DROUGHT_STRESS_INDEX_SSP_585_Y_2100_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.heatStressIndexCurrentHazardZone'),
        accessor: 'heatStressIndexCurrentHazardZone',
        id: 'heatStressIndexCurrentHazardZone',
        isVisible: true,
        beField: 'HEAT_STRESS_INDEX_CURRENT_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.heatStressIndexSSP126Y2030HazardZone'),
        accessor: 'heatStressIndexSSP126Y2030HazardZone',
        id: 'heatStressIndexSSP126Y2030HazardZone',
        isVisible: true,
        beField: 'HEAT_STRESS_INDEX_SSP_126_Y_2030_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.heatStressIndexSSP126Y2040HazardZone'),
        accessor: 'heatStressIndexSSP126Y2040HazardZone',
        id: 'heatStressIndexSSP126Y2040HazardZone',
        isVisible: true,
        beField: 'HEAT_STRESS_INDEX_SSP_126_Y_2040_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.heatStressIndexSSP126Y2050HazardZone'),
        accessor: 'heatStressIndexSSP126Y2050HazardZone',
        id: 'heatStressIndexSSP126Y2050HazardZone',
        isVisible: true,
        beField: 'HEAT_STRESS_INDEX_SSP_126_Y_2050_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.heatStressIndexSSP126Y2100HazardZone'),
        accessor: 'heatStressIndexSSP126Y2100HazardZone',
        id: 'heatStressIndexSSP126Y2100HazardZone',
        isVisible: true,
        beField: 'HEAT_STRESS_INDEX_SSP_126_Y_2100_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.heatStressIndexSSP245Y2030HazardZone'),
        accessor: 'heatStressIndexSSP245Y2030HazardZone',
        id: 'heatStressIndexSSP245Y2030HazardZone',
        isVisible: true,
        beField: 'HEAT_STRESS_INDEX_SSP_245_Y_2030_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.heatStressIndexSSP245Y2040HazardZone'),
        accessor: 'heatStressIndexSSP245Y2040HazardZone',
        id: 'heatStressIndexSSP245Y2040HazardZone',
        isVisible: true,
        beField: 'HEAT_STRESS_INDEX_SSP_245_Y_2040_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.heatStressIndexSSP245Y2050HazardZone'),
        accessor: 'heatStressIndexSSP245Y2050HazardZone',
        id: 'heatStressIndexSSP245Y2050HazardZone',
        isVisible: true,
        beField: 'HEAT_STRESS_INDEX_SSP_245_Y_2050_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.heatStressIndexSSP245Y2100HazardZone'),
        accessor: 'heatStressIndexSSP245Y2100HazardZone',
        id: 'heatStressIndexSSP245Y2100HazardZone',
        isVisible: true,
        beField: 'HEAT_STRESS_INDEX_SSP_245_Y_2100_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.heatStressIndexSSP370Y2030HazardZone'),
        accessor: 'heatStressIndexSSP370Y2030HazardZone',
        id: 'heatStressIndexSSP370Y2030HazardZone',
        isVisible: true,
        beField: 'HEAT_STRESS_INDEX_SSP_370_Y_2030_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.heatStressIndexSSP370Y2040HazardZone'),
        accessor: 'heatStressIndexSSP370Y2040HazardZone',
        id: 'heatStressIndexSSP370Y2040HazardZone',
        isVisible: true,
        beField: 'HEAT_STRESS_INDEX_SSP_370_Y_2040_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.heatStressIndexSSP370Y2050HazardZone'),
        accessor: 'heatStressIndexSSP370Y2050HazardZone',
        id: 'heatStressIndexSSP370Y2050HazardZone',
        isVisible: true,
        beField: 'HEAT_STRESS_INDEX_SSP_370_Y_2050_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.heatStressIndexSSP370Y2100HazardZone'),
        accessor: 'heatStressIndexSSP370Y2100HazardZone',
        id: 'heatStressIndexSSP370Y2100HazardZone',
        isVisible: true,
        beField: 'HEAT_STRESS_INDEX_SSP_370_Y_2100_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.heatStressIndexSSP585Y2030HazardZone'),
        accessor: 'heatStressIndexSSP585Y2030HazardZone',
        id: 'heatStressIndexSSP585Y2030HazardZone',
        isVisible: true,
        beField: 'HEAT_STRESS_INDEX_SSP_585_Y_2030_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.heatStressIndexSSP585Y2040HazardZone'),
        accessor: 'heatStressIndexSSP585Y2040HazardZone',
        id: 'heatStressIndexSSP585Y2040HazardZone',
        isVisible: true,
        beField: 'HEAT_STRESS_INDEX_SSP_585_Y_2040_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.heatStressIndexSSP585Y2050HazardZone'),
        accessor: 'heatStressIndexSSP585Y2050HazardZone',
        id: 'heatStressIndexSSP585Y2050HazardZone',
        isVisible: true,
        beField: 'HEAT_STRESS_INDEX_SSP_585_Y_2050_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.heatStressIndexSSP585Y2100HazardZone'),
        accessor: 'heatStressIndexSSP585Y2100HazardZone',
        id: 'heatStressIndexSSP585Y2100HazardZone',
        isVisible: true,
        beField: 'HEAT_STRESS_INDEX_SSP_585_Y_2100_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.precipitationStressIndexCurrentHazardZone'),
        accessor: 'precipitationStressIndexCurrentHazardZone',
        id: 'precipitationStressIndexCurrentHazardZone',
        isVisible: true,
        beField: 'PRECIPITATION_STRESS_INDEX_CURRENT_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.precipitationStressIndexSSP126Y2030HazardZone'),
        accessor: 'precipitationStressIndexSSP126Y2030HazardZone',
        id: 'precipitationStressIndexSSP126Y2030HazardZone',
        isVisible: true,
        beField: 'PRECIPITATION_STRESS_INDEX_SSP_126_Y_2030_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.precipitationStressIndexSSP126Y2040HazardZone'),
        accessor: 'precipitationStressIndexSSP126Y2040HazardZone',
        id: 'precipitationStressIndexSSP126Y2040HazardZone',
        isVisible: true,
        beField: 'PRECIPITATION_STRESS_INDEX_SSP_126_Y_2040_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.precipitationStressIndexSSP126Y2050HazardZone'),
        accessor: 'precipitationStressIndexSSP126Y2050HazardZone',
        id: 'precipitationStressIndexSSP126Y2050HazardZone',
        isVisible: true,
        beField: 'PRECIPITATION_STRESS_INDEX_SSP_126_Y_2050_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.precipitationStressIndexSSP126Y2100HazardZone'),
        accessor: 'precipitationStressIndexSSP126Y2100HazardZone',
        id: 'precipitationStressIndexSSP126Y2100HazardZone',
        isVisible: true,
        beField: 'PRECIPITATION_STRESS_INDEX_SSP_126_Y_2100_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.precipitationStressIndexSSP245Y2030HazardZone'),
        accessor: 'precipitationStressIndexSSP245Y2030HazardZone',
        id: 'precipitationStressIndexSSP245Y2030HazardZone',
        isVisible: true,
        beField: 'PRECIPITATION_STRESS_INDEX_SSP_245_Y_2030_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.precipitationStressIndexSSP245Y2040HazardZone'),
        accessor: 'precipitationStressIndexSSP245Y2040HazardZone',
        id: 'precipitationStressIndexSSP245Y2040HazardZone',
        isVisible: true,
        beField: 'PRECIPITATION_STRESS_INDEX_SSP_245_Y_2040_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.precipitationStressIndexSSP245Y2050HazardZone'),
        accessor: 'precipitationStressIndexSSP245Y2050HazardZone',
        id: 'precipitationStressIndexSSP245Y2050HazardZone',
        isVisible: true,
        beField: 'PRECIPITATION_STRESS_INDEX_SSP_245_Y_2050_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.precipitationStressIndexSSP245Y2100HazardZone'),
        accessor: 'precipitationStressIndexSSP245Y2100HazardZone',
        id: 'precipitationStressIndexSSP245Y2100HazardZone',
        isVisible: true,
        beField: 'PRECIPITATION_STRESS_INDEX_SSP_245_Y_2100_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.precipitationStressIndexSSP370Y2030HazardZone'),
        accessor: 'precipitationStressIndexSSP370Y2030HazardZone',
        id: 'precipitationStressIndexSSP370Y2030HazardZone',
        isVisible: true,
        beField: 'PRECIPITATION_STRESS_INDEX_SSP_370_Y_2030_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.precipitationStressIndexSSP370Y2040HazardZone'),
        accessor: 'precipitationStressIndexSSP370Y2040HazardZone',
        id: 'precipitationStressIndexSSP370Y2040HazardZone',
        isVisible: true,
        beField: 'PRECIPITATION_STRESS_INDEX_SSP_370_Y_2040_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.precipitationStressIndexSSP370Y2050HazardZone'),
        accessor: 'precipitationStressIndexSSP370Y2050HazardZone',
        id: 'precipitationStressIndexSSP370Y2050HazardZone',
        isVisible: true,
        beField: 'PRECIPITATION_STRESS_INDEX_SSP_370_Y_2050_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.precipitationStressIndexSSP370Y2100HazardZone'),
        accessor: 'precipitationStressIndexSSP370Y2100HazardZone',
        id: 'precipitationStressIndexSSP370Y2100HazardZone',
        isVisible: true,
        beField: 'PRECIPITATION_STRESS_INDEX_SSP_370_Y_2100_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.precipitationStressIndexSSP585Y2030HazardZone'),
        accessor: 'precipitationStressIndexSSP585Y2030HazardZone',
        id: 'precipitationStressIndexSSP585Y2030HazardZone',
        isVisible: true,
        beField: 'PRECIPITATION_STRESS_INDEX_SSP_585_Y_2030_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.precipitationStressIndexSSP585Y2040HazardZone'),
        accessor: 'precipitationStressIndexSSP585Y2040HazardZone',
        id: 'precipitationStressIndexSSP585Y2040HazardZone',
        isVisible: true,
        beField: 'PRECIPITATION_STRESS_INDEX_SSP_585_Y_2040_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.precipitationStressIndexSSP585Y2050HazardZone'),
        accessor: 'precipitationStressIndexSSP585Y2050HazardZone',
        id: 'precipitationStressIndexSSP585Y2050HazardZone',
        isVisible: true,
        beField: 'PRECIPITATION_STRESS_INDEX_SSP_585_Y_2050_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.precipitationStressIndexSSP585Y2100HazardZone'),
        accessor: 'precipitationStressIndexSSP585Y2100HazardZone',
        id: 'precipitationStressIndexSSP585Y2100HazardZone',
        isVisible: true,
        beField: 'PRECIPITATION_STRESS_INDEX_SSP_585_Y_2100_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.coldStressIndexCurrentHazardZone'),
        accessor: 'coldStressIndexCurrentHazardZone',
        id: 'coldStressIndexCurrentHazardZone',
        isVisible: true,
        beField: 'COLD_STRESS_INDEX_CURRENT_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.coldStressIndexSSP126Y2030HazardZone'),
        accessor: 'coldStressIndexSSP126Y2030HazardZone',
        id: 'coldStressIndexSSP126Y2030HazardZone',
        isVisible: true,
        beField: 'COLD_STRESS_INDEX_SSP_126_Y_2030_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.coldStressIndexSSP126Y2040HazardZone'),
        accessor: 'coldStressIndexSSP126Y2040HazardZone',
        id: 'coldStressIndexSSP126Y2040HazardZone',
        isVisible: true,
        beField: 'COLD_STRESS_INDEX_SSP_126_Y_2040_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.coldStressIndexSSP126Y2050HazardZone'),
        accessor: 'coldStressIndexSSP126Y2050HazardZone',
        id: 'coldStressIndexSSP126Y2050HazardZone',
        isVisible: true,
        beField: 'COLD_STRESS_INDEX_SSP_126_Y_2050_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.coldStressIndexSSP126Y2100HazardZone'),
        accessor: 'coldStressIndexSSP126Y2100HazardZone',
        id: 'coldStressIndexSSP126Y2100HazardZone',
        isVisible: true,
        beField: 'COLD_STRESS_INDEX_SSP_126_Y_2100_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.coldStressIndexSSP245Y2030HazardZone'),
        accessor: 'coldStressIndexSSP245Y2030HazardZone',
        id: 'coldStressIndexSSP245Y2030HazardZone',
        isVisible: true,
        beField: 'COLD_STRESS_INDEX_SSP_245_Y_2030_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.coldStressIndexSSP245Y2040HazardZone'),
        accessor: 'coldStressIndexSSP245Y2040HazardZone',
        id: 'coldStressIndexSSP245Y2040HazardZone',
        isVisible: true,
        beField: 'COLD_STRESS_INDEX_SSP_245_Y_2040_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.coldStressIndexSSP245Y2050HazardZone'),
        accessor: 'coldStressIndexSSP245Y2050HazardZone',
        id: 'coldStressIndexSSP245Y2050HazardZone',
        isVisible: true,
        beField: 'COLD_STRESS_INDEX_SSP_245_Y_2050_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.coldStressIndexSSP245Y2100HazardZone'),
        accessor: 'coldStressIndexSSP245Y2100HazardZone',
        id: 'coldStressIndexSSP245Y2100HazardZone',
        isVisible: true,
        beField: 'COLD_STRESS_INDEX_SSP_245_Y_2100_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.coldStressIndexSSP370Y2030HazardZone'),
        accessor: 'coldStressIndexSSP370Y2030HazardZone',
        id: 'coldStressIndexSSP370Y2030HazardZone',
        isVisible: true,
        beField: 'COLD_STRESS_INDEX_SSP_370_Y_2030_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.coldStressIndexSSP370Y2040HazardZone'),
        accessor: 'coldStressIndexSSP370Y2040HazardZone',
        id: 'coldStressIndexSSP370Y2040HazardZone',
        isVisible: true,
        beField: 'COLD_STRESS_INDEX_SSP_370_Y_2040_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.coldStressIndexSSP370Y2050HazardZone'),
        accessor: 'coldStressIndexSSP370Y2050HazardZone',
        id: 'coldStressIndexSSP370Y2050HazardZone',
        isVisible: true,
        beField: 'COLD_STRESS_INDEX_SSP_370_Y_2050_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.coldStressIndexSSP370Y2100HazardZone'),
        accessor: 'coldStressIndexSSP370Y2100HazardZone',
        id: 'coldStressIndexSSP370Y2100HazardZone',
        isVisible: true,
        beField: 'COLD_STRESS_INDEX_SSP_370_Y_2100_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.coldStressIndexSSP585Y2030HazardZone'),
        accessor: 'coldStressIndexSSP585Y2030HazardZone',
        id: 'coldStressIndexSSP585Y2030HazardZone',
        isVisible: true,
        beField: 'COLD_STRESS_INDEX_SSP_585_Y_2030_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.coldStressIndexSSP585Y2040HazardZone'),
        accessor: 'coldStressIndexSSP585Y2040HazardZone',
        id: 'coldStressIndexSSP585Y2040HazardZone',
        isVisible: true,
        beField: 'COLD_STRESS_INDEX_SSP_585_Y_2040_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.coldStressIndexSSP585Y2050HazardZone'),
        accessor: 'coldStressIndexSSP585Y2050HazardZone',
        id: 'coldStressIndexSSP585Y2050HazardZone',
        isVisible: true,
        beField: 'COLD_STRESS_INDEX_SSP_585_Y_2050_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('physicalAsset.chronic.table.coldStressIndexSSP585Y2100HazardZone'),
        accessor: 'coldStressIndexSSP585Y2100HazardZone',
        id: 'coldStressIndexSSP585Y2100HazardZone',
        isVisible: true,
        beField: 'COLD_STRESS_INDEX_SSP_585_Y_2100_HAZARD_ZONE',
        type: UiTableColumnDataType.NUMBER,
      },
    ];
  }, [t]);
};
