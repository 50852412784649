import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { TFunction } from 'i18next';
import toast from 'react-hot-toast';
import { sortBy } from 'lodash';

import api from '@DataAccess/api';
import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';
import {
  DataHierarchy,
  Subcategories,
  SubcategoryProps,
} from '@Pages/DataManagement/GHG/HierarchyManager/Components/types';
import { scoreNumberingConfig } from '@Pages/DataManagement/GHG/HierarchyManager/scoreNumberingConfig';
import { getNextPageParam, useFlattenPaginatedResponse } from '@DataAccess/utils';

import { dataHierarchyKeys } from './dataHierarchy.keys';
import {
  Category,
  DataHierarchyDto,
  DataHierarchyEntry,
  DataHierarchySearchRequestDto,
  SubcategoriesDto,
  Subcategory,
} from './dataHierarchy.types';

const mapSubcategoriesToDto = (subcategories: Subcategories) => {
  return Object.entries(subcategories).reduce((acc, curr) => {
    const [subcategory, { items }] = curr as [Subcategory, SubcategoryProps];
    acc[subcategory] = items.map(({ value, label, data }) => ({
      id: value,
      name: label,
      isDefault: data?.isDefault ?? false,
      isEnabled: data?.isEnabled ?? true,
    }));

    return acc;
  }, {} as SubcategoriesDto);
};

const mapToDto = (newDataHierarchy: Partial<DataHierarchy>): DataHierarchyDto => {
  return Object.entries(newDataHierarchy).reduce((acc, curr) => {
    const [category, subcategories] = curr as [Category, Subcategories];
    acc[category] = mapSubcategoriesToDto(subcategories);

    return acc;
  }, {} as DataHierarchyDto);
};

const mapSubcategoriesDtoToSubcategories = (dto: SubcategoriesDto, t: TFunction): Subcategories => {
  return Object.entries(dto).reduce((acc, curr) => {
    const [subcategory, dhEntries] = curr as [Subcategory, DataHierarchyEntry[]];
    acc[subcategory] = {
      scoreNumbering: sortBy(scoreNumberingConfig[subcategory]),
      subcategoryTitle: t(`hierarchyManager.subcategory.${subcategory}`),
      items: dhEntries.map((order, idx) => ({
        label: order.name,
        value: order.id,
        data: {
          order: idx + 1,
          isDefault: order.isDefault,
          isEnabled: order.isEnabled,
        },
      })),
    } as SubcategoryProps;

    return acc;
  }, {} as Subcategories);
};

export const useDataHierarchyQuery = (hierarchyId?: string) => {
  const { t } = useGHGTranslation();

  return useQuery({
    queryKey: dataHierarchyKeys.orderOfUsage.all(hierarchyId),
    queryFn: () => api.core.dataHierarchy.getDataHierarchy(hierarchyId),
    select: (dto) => {
      return Object.entries(dto).reduce((acc, curr) => {
        const [category, subcategoriesDto] = curr as [Category, SubcategoriesDto];

        const subcategories = mapSubcategoriesDtoToSubcategories(subcategoriesDto, t);

        return { ...acc, [category]: subcategories };
      }, {} as DataHierarchy);
    },
  });
};

export const useDataHierarchyMutation = (hierarchyId?: string) => {
  const { t } = useGHGTranslation();
  const queryClient = useQueryClient();

  return useMutation<void, void, Partial<DataHierarchy>>({
    mutationFn: (payload) => api.core.dataHierarchy.updateDataHierarchy(mapToDto(payload)),
    onError: () => toast.error(t('hierarchyManager.genericToastError')),
    onSettled: () => {
      Promise.all([
        queryClient.invalidateQueries({ queryKey: dataHierarchyKeys.orderOfUsage.fullSearchList }),
        queryClient.invalidateQueries({
          queryKey: dataHierarchyKeys.orderOfUsage.all(hierarchyId),
        }),
      ]);
    },
  });
};

export const useDataHierarchySearchQuery = (searchObject: DataHierarchySearchRequestDto) => {
  const query = useInfiniteQuery({
    queryKey: dataHierarchyKeys.orderOfUsage.searchList(searchObject),
    queryFn: ({ pageParam }) => api.core.dataHierarchy.searchDataHierarchy(searchObject, pageParam),
    initialPageParam: 0,
    getNextPageParam,
  });

  const dhVersions = useFlattenPaginatedResponse(query.data);

  return { ...query, dhVersions };
};

export const useDataHierarchyIdInUseQuery = () => {
  const { data } = useDataHierarchySearchQuery({ size: 1 });

  return data?.pages[0]?.content[0]?.id;
};
