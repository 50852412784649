import { QuerySelectorAllShadowsProps } from './types';

export const querySelectorAllShadows: QuerySelectorAllShadowsProps = (
  selector,
  container = document.body,
) => {
  const childShadows = Array.from(container.querySelectorAll('*'))
    .map((el) => el.shadowRoot)
    .filter(Boolean);

  const childResults = childShadows.flatMap((child) => querySelectorAllShadows(selector, child!));
  const result = Array.from(container.querySelectorAll(selector));

  return result.concat(childResults).flat();
};
