import { UiTableColumnDataType } from '../../../types';
import { FilterConditionsType } from '../../types';

const {
  EQUAL,
  NOT_EQUAL,
  GREATER_THAN,
  GREATER_THAN_OR_EQUAL,
  LESS_THAN,
  LESS_THAN_OR_EQUAL,
  STARTS_WITH,
  NOT_STARTS_WITH,
  CONTAINS,
  ENDS_WITH,
  NOT_CONTAINS,
  NOT_ENDS_WITH,
} = FilterConditionsType;

const mapper = {
  [UiTableColumnDataType.BOOLEAN]: [EQUAL],
  [UiTableColumnDataType.NUMBER]: [
    EQUAL,
    NOT_EQUAL,
    GREATER_THAN,
    GREATER_THAN_OR_EQUAL,
    LESS_THAN,
    LESS_THAN_OR_EQUAL,
  ],
  [UiTableColumnDataType.TEXT]: [
    CONTAINS,
    EQUAL,
    NOT_EQUAL,
    STARTS_WITH,
    NOT_STARTS_WITH,
    ENDS_WITH,
    NOT_ENDS_WITH,
    NOT_CONTAINS,
    GREATER_THAN,
    GREATER_THAN_OR_EQUAL,
    LESS_THAN,
    LESS_THAN_OR_EQUAL,
  ],
  [UiTableColumnDataType.DATE]: [
    EQUAL,
    NOT_EQUAL,
    LESS_THAN,
    LESS_THAN_OR_EQUAL,
    GREATER_THAN,
    GREATER_THAN_OR_EQUAL,
  ],
  [UiTableColumnDataType.DATE_TIME]: [LESS_THAN, GREATER_THAN],
};

export const mapFilterConditionsByColumnType = (columnType: UiTableColumnDataType) => {
  return mapper[columnType];
};
