import styled from '@emotion/styled';
import { ThemingParameters } from '@ui5/webcomponents-react-base';
import { AvatarSize } from '@ui5/webcomponents-react';
import { useState } from 'react';
import { useAuth } from 'oidc-react';

import { UiActionSheet, UiButton } from '@UiCommon/Components';
import { IconSet } from '@GlobalProviders/IconSetProvider';
import { UiAvatar } from '@UiCommon/Components/UiAvatar';
import { useNavigationPanelContext } from '@UiCommon/Layout/Navigation/NavigationPanel/NavigationPanelContext';
import { useSharedTranslation } from '@GlobalProviders/i18n/hooks';

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;

  font-family: ${ThemingParameters.sapFontFamily};
  ${({ onClick }) => !!onClick && `cursor: pointer`};
`;

const UserName = styled.div`
  font-size: ${ThemingParameters.sapFontSmallSize};
  font-weight: 700;
`;

const UserEmail = styled.div`
  --sapFontSmallSize: 0.6865rem;
  font-size: ${ThemingParameters.sapFontSmallSize};
  color: ${ThemingParameters.sapField_PlaceholderTextColor};
`;

const UserDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
`;

const AVATAR_ID = 'avatar';

export const UserInfo = () => {
  const { t } = useSharedTranslation();

  const auth = useAuth();
  const emails = auth.userData?.profile.emails as string[];
  const email = emails?.[0];

  const { isPanelExtended } = useNavigationPanelContext();

  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);

  const onAvatarClick = () => {
    setIsActionSheetOpen(true);
  };

  return (
    <>
      <AvatarWrapper id={AVATAR_ID} role='button' onClick={onAvatarClick}>
        <UiAvatar size={AvatarSize.XS} initials='EM' />
        {isPanelExtended && (
          <UserDataWrapper>
            <UserName>ESG Manager</UserName>
            <UserEmail>{email}</UserEmail>
          </UserDataWrapper>
        )}
      </AvatarWrapper>
      <UiActionSheet
        openerId={AVATAR_ID}
        open={isActionSheetOpen}
        onAfterClose={() => {
          setIsActionSheetOpen(false);
        }}
      >
        <UiButton icon={IconSet.LOG} onClick={auth.signOut}>
          {t('navigationPanel.actions.logout')}
        </UiButton>
      </UiActionSheet>
    </>
  );
};
