import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';

import { createDateFormatter } from '@Utils/Formatters/date';
import { useProcessManagerTranslation } from '@GlobalProviders/i18n/hooks';
import { useProcessDetailsQuery } from '@DataAccess/Platform/ProcessMonitoring/processMonitoring.hooks';
import { UiLabeledValue } from '@UiCommon/Components/UiLabeledValue';

import { Duration } from '../../Components/Duration';

const Wrapper = styled.div`
  display: flex;
  gap: 5rem;
  margin-bottom: 2.5rem;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
`;

export const ErrorLogDetails = () => {
  const { processId } = useParams();
  const { data } = useProcessDetailsQuery(processId);
  const { t } = useProcessManagerTranslation();
  const dateFormatter = createDateFormatter({});

  if (!data) return null;

  return (
    <Wrapper>
      <Column>
        <UiLabeledValue
          title={t('processManager.errorLog.infoSection.processId')}
          value={data.processId}
        />
        <UiLabeledValue
          title={t('processManager.errorLog.infoSection.item')}
          value={data.itemType}
        />
        <UiLabeledValue
          title={t('processManager.errorLog.infoSection.actualStart')}
          value={dateFormatter.format(new Date(data.actualStartDate))}
        />
        <UiLabeledValue
          title={t('processManager.errorLog.infoSection.actualEnd')}
          value={dateFormatter.format(new Date(data.endDate))}
        />
      </Column>

      <Column>
        <UiLabeledValue
          title={t('processManager.errorLog.infoSection.status')}
          value={data.status}
        />
        <UiLabeledValue
          title={t('processManager.errorLog.infoSection.duration')}
          CustomValueComponent={<Duration seconds={data.duration} />}
        />
        <UiLabeledValue
          title={t('processManager.errorLog.infoSection.totalDataSets')}
          value={data.totalDataSets}
        />
        <UiLabeledValue
          title={t('processManager.errorLog.infoSection.failedDataSets')}
          value={data.failedDataSets}
        />
      </Column>
    </Wrapper>
  );
};
