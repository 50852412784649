import { useCollectedEmissionFactorEnergy } from '@DataAccess/GHG/GHGFiles/GHGFiles.hooks';
import { useProcessManagerTranslation } from '@GlobalProviders/i18n/hooks';
import { SmartAnalyticalTableCard } from '@Pages/Shared/SmartComponents/SmartAnalyticalTableCard/SmartAnalyticalTableCard';
import { useSmartAnalyticalTableCard } from '@Pages/Shared/SmartComponents/SmartAnalyticalTableCard/useSmartAnalyticalTableCard';
import { SubPageHeader } from '@UiCommon/Layout/Components/Headers/SubPageHeader';
import { useMultiAppStore } from '@GlobalProviders/MultiApp/MultiAppProvider';

import { useEmissionFactorEnergyColumns } from './useEmissionFactorEnergyColumns';

export const EmissionFactorEnergyPage = () => {
  const { t } = useProcessManagerTranslation();
  const { translatedProductInUse } = useMultiAppStore();

  const TABLE_ID = 'collectedEmissionFactorEnergy';
  const columns = useEmissionFactorEnergyColumns();

  const { sorting, filters, data, fetchNextPage, totalElements, setFilters, setSorting } =
    useSmartAnalyticalTableCard({
      columns,
      queryHook: useCollectedEmissionFactorEnergy,
    });

  return (
    <>
      <SubPageHeader
        productName={translatedProductInUse}
        title={t('processManager.manualUpload.emissionFactorEnergy.title')}
        subtitle={t('processManager.manualUpload.emissionFactorEnergy.subtitle')}
      />
      <SmartAnalyticalTableCard
        columns={columns}
        data={data}
        title={t('processManager.manualUpload.emissionFactorEnergy.tableTitle', {
          amount: totalElements,
        })}
        onLoadMore={fetchNextPage}
        totalNumberOfItems={totalElements}
        tableId={TABLE_ID}
        hasSettingsModal
        hasExportCSV
        sorting={sorting}
        filters={filters}
        onSorting={setSorting}
        onFilters={setFilters}
        apiPath='ghg.files.getCollectedEmissionFactorEnergy'
      />
    </>
  );
};
