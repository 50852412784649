import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';
import { flatMap, last } from 'lodash';
import { useMemo } from 'react';

import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';

import { mapFiltersForPayload } from './Mappers/mapFiltersForPayload';
import { mapSortingForPayload } from './Mappers/mapSortingForPayload';
import {
  CustomPaginatedDataQueryOutput,
  GetBeFieldProps,
  CollectionQueryParams,
  PaginationResponse,
  TablePaginatedQueryProps,
} from './types';

export const getBeField = <T>({ columnId, columns }: GetBeFieldProps<T>) => {
  const column = columns?.find(({ id }) => id === columnId);

  if (!column) {
    throw new Error(`NO COLUMN ID CONFIGURED FOR: ${String(columnId)}`);
  }
  if (!column.beField) {
    throw new Error(`NO BE FIELD DEFINED FOR: ${String(columnId)}`);
  }

  return column.beField;
};

export const useFlattenPaginatedResponse = <T>(
  response: InfiniteData<PaginationResponse<T>> | undefined,
): T[] => useMemo(() => flatMap(response?.pages, (page) => page.content) || [], [response]);

export const getNextPageParam = <T>(lastPage: PaginationResponse<T>) => {
  if (lastPage.totalPages === lastPage.currentPage + 1 || !lastPage.totalPages) return undefined;

  return lastPage.currentPage + 1;
};

export const useTablePaginatedQuery = <T = Record<string, unknown>>({
  apiCall,
  key,
  columns,
  filters,
  mapper,
  size = 50,
  sorting,
  enabled,
  refetchInterval,
}: TablePaginatedQueryProps<T>): CustomPaginatedDataQueryOutput<T> => {
  const { t } = useGHGTranslation();

  const params: CollectionQueryParams = {
    size,
    filter: mapFiltersForPayload({ filters, columns }),
    sort: mapSortingForPayload({ sorting, columns }),
  };

  const query = useInfiniteQuery({
    queryKey: key(params),
    queryFn: ({ pageParam }) => apiCall({ tableParams: params, pageParam }),
    initialPageParam: 0,
    getNextPageParam,
    refetchInterval,
    enabled,
  });

  const response = useFlattenPaginatedResponse(query.data);
  const paginationData = last(query.data?.pages);

  const mappedData = mapper
    ? response.map((d, index) => mapper({ t, object: d, index }))
    : response;

  return {
    ...query,
    totalElements: paginationData?.totalElements ?? 0,
    currentPage: paginationData?.currentPage,
    totalPages: paginationData?.totalPages,
    mappedData,
    pageParams: { ...params, size },
  };
};
