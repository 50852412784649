import api from '@DataAccess/api';
import { PaginatedRqHookProps } from '@DataAccess/types';
import { useTablePaginatedQuery } from '@DataAccess/utils';

import { EmissionDto } from './singleExposure.types';
import { singleExposureKeys } from './singleExposure.keys';

export const useEmissionQuery = (rest: PaginatedRqHookProps<EmissionDto>) => {
  return useTablePaginatedQuery<EmissionDto>({
    apiCall: api.ghg.singleExposure.getEmissions,
    key: singleExposureKeys.list,
    ...rest,
  });
};
