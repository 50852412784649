import { MessageStrip } from '@ui5/webcomponents-react';

import { CommonProps } from '../types';

export enum UiMessageStripType {
  Information = 'Information',
  Positive = 'Positive',
  Negative = 'Negative',
  Warning = 'Warning',
}

type Props = CommonProps & {
  design?: UiMessageStripType;
  onClose?: () => void;
  hideCloseButton?: boolean;
};

export const UiMessageStrip = ({
  children,
  design,
  onClose,
  hideCloseButton,
  className,
}: Props) => (
  <MessageStrip
    hideCloseButton={hideCloseButton}
    className={className}
    design={design}
    onClose={onClose}
  >
    {children}
  </MessageStrip>
);
