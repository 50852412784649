import { Title, TitleLevel } from '@ui5/webcomponents-react';
import styled from '@emotion/styled';

import { CommonProps } from '@UiCommon/types';

import { FontWeight, fontWeights } from '../typography';

type TypographyProps = { weight?: FontWeight };

type Props = CommonProps & TypographyProps & { level?: TitleLevel | keyof typeof TitleLevel };

const StyledTitle = styled(Title)<TypographyProps>`
  font-family: ${({ weight }) => fontWeights[weight as FontWeight]};
`;

// TODO: figure out why I cannot use enum that is defined with "declare"
export const UiTitle = ({
  children,
  weight = FontWeight.HEADER,
  level = 'H2',
  className,
}: Props) => (
  <StyledTitle className={className} weight={weight} level={level}>
    {children}
  </StyledTitle>
);
