import { useSplitScopeContext } from '../Context/SplitScopeContext';
import { SplitScopeActions } from '../Components/SplitScopeActions';

type Props = {
  index: number;
};

export const ProjectActions = ({ index }: Props) => {
  const { project } = useSplitScopeContext();

  return <SplitScopeActions categoryState={project} index={index} />;
};
