import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';

import { ReactQueryProvider } from '@GlobalProviders/ReactQueryProvider';
import { RoutingProvider } from '@GlobalProviders/Routing/RoutingProvider';
import { ThemingProvider } from '@GlobalProviders/ThemingProvider';
import { SapToaster } from '@GlobalProviders/SapToaster';
import { i18nInit } from '@GlobalProviders/i18n/i18nInit';
import { AuthProvider } from '@GlobalProviders/Auth/AuthProvider';
import { AppGuard } from '@GlobalProviders/Auth/AppGuard';
import { InjectHttpErrorDictionary } from '@GlobalProviders/InjectHttpErrorDictionary';
import { SettingsProvider } from '@GlobalProviders/SettingsProvider';
import { MultiAppProvider } from '@GlobalProviders/MultiApp/MultiAppProvider';

i18nInit();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <Suspense fallback='loading'>
        <ReactQueryProvider>
          <ThemingProvider>
            <AppGuard>
              <MultiAppProvider>
                <SettingsProvider>
                  <SapToaster />
                  <InjectHttpErrorDictionary />
                  <RoutingProvider />
                </SettingsProvider>
              </MultiAppProvider>
            </AppGuard>
          </ThemingProvider>
        </ReactQueryProvider>
      </Suspense>
    </AuthProvider>
  </React.StrictMode>,
);
