import { css } from '@emotion/css';
import { ThemingParameters } from '@ui5/webcomponents-react-base';

import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';
import { DataQualityOverviewMapped } from '@DataAccess/GHG/DataQuality/dataQuality.types';
import { AssetType } from '@Types/types';
import {
  UiCardHeader,
  UiTable,
  UiTableCard,
  UiTableCell,
  UiTableColumnProps,
  UiTableRow,
} from '@UiCommon/Components';
import { CommonProps } from '@UiCommon/types';
import { UiNumberFormatter } from '@UiCommon/Components/UiFormatter/UiNumberFormatter';
import { useSettingsStore } from '@GlobalProviders/SettingsProvider';

import { AssetClassScoreSection } from './AssetClassScoreSection';

type Props = CommonProps & {
  data: DataQualityOverviewMapped;
};

const tableStyles = css`
  ui5-table-column::part(column) {
    border: 1px solid ${ThemingParameters.sapList_TableGroupHeaderBorderColor};
  }

  ui5-table-row::part(row) {
    border-top: none;
  }

  ui5-table-column:first-child::part(column) {
    border-left: none;
  }

  ui5-table-column:last-of-type::part(column) {
    border-right: none;
  }

  ui5-table-cell {
    text-align: end;
    border: 1px solid ${ThemingParameters.sapList_BorderColor};
  }

  ui5-table-row ui5-table-cell:last-of-type {
    border-right: none;
  }

  ui5-table-row ui5-table-cell:first-child {
    text-align: start;
    border: none;
  }

  ui5-table-row:last-child ui5-table-cell {
    border: none;
  }

  ui5-table-row:last-child ui5-table-cell:first-child {
    border-top: 1px solid ${ThemingParameters.sapList_BorderColor};
  }

  ui5-table-row:last-child::part(row) {
    font-weight: var(--ui5_table_header_row_font_weight);
  }
`;

export const DataQualityOverviewTable = ({ data }: Props) => {
  const { t } = useGHGTranslation();
  const { currency } = useSettingsStore();

  const { scoresPerAssetClass, computedTotalFields, scopeNumber } = data;

  const columns: UiTableColumnProps[] = [
    {
      label: t('overview.dataQualityOverview.assetClass'),
      id: 'assetClass',
      minWidth: 120,
    },
    {
      label: t('overview.dataQualityOverview.dataQualityScore'),
      id: 'dataQualityScore',
      minWidth: 120,
    },
    {
      label: t('overview.dataQualityOverview.dataSets'),
      id: 'dataSets',
      minWidth: 120,
    },
    {
      label: t('overview.dataQualityOverview.exposure', { currency }),
      id: 'exposure',
      minWidth: 120,
    },
    {
      label: t('overview.dataQualityOverview.totalFinanced', { count: scopeNumber }),
      id: 'totalFinanced',
      minWidth: 120,
    },
  ];

  return (
    <UiTableCard
      header={
        <UiCardHeader titleText={t('overview.dataQualityOverview.title', { count: scopeNumber })} />
      }
    >
      <UiTable tableClassName={tableStyles} columns={columns}>
        {Object.entries(scoresPerAssetClass).map(([assetClass, rows]) => (
          <AssetClassScoreSection
            key={assetClass}
            assetClass={assetClass as AssetType}
            rows={rows}
          />
        ))}
        <UiTableRow>
          <UiTableCell>{t('overview.table.total')}</UiTableCell>
          <UiTableCell />
          <UiTableCell>
            <UiNumberFormatter value={computedTotalFields.dataSets} decimalPrecision={0} />
          </UiTableCell>
          <UiTableCell>
            <UiNumberFormatter value={computedTotalFields.exposure} decimalPrecision={0} />
          </UiTableCell>
          <UiTableCell>
            <UiNumberFormatter value={computedTotalFields.financedEmissions} decimalPrecision={0} />
          </UiTableCell>
        </UiTableRow>
      </UiTable>
    </UiTableCard>
  );
};
