import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';
import { TabConfig } from '@UiCommon/Components/UiTabSwitcher';
import { SectionObjectPage } from '@UiCommon/Layout/Components/SectionObjectPage';
import { useMultiAppStore } from '@GlobalProviders/MultiApp/MultiAppProvider';

import { SYSTEM_SETTINGS } from './Routing/routes';

export const SettingsPage = () => {
  const { t } = useGHGTranslation();
  const { translatedProductInUse } = useMultiAppStore();

  const tabsConfig: TabConfig[] = [
    {
      label: t('settings.systemSettings.title'),
      id: SYSTEM_SETTINGS,
    },
  ];

  return (
    <SectionObjectPage
      title={t('settings.title')}
      productName={translatedProductInUse}
      defaultRoute={SYSTEM_SETTINGS}
      tabsConfig={tabsConfig}
    />
  );
};
