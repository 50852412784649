import { useCallback, useMemo } from 'react';

import { MoveDirection } from '@Types/types';
import { useEnsureElementScrollVisibility } from '@Utils/domUtils';
import { moveListItem } from '@Utils/utils';

import { UiListItemType } from '../types';

export const useListItemMoveHandler = <T>(
  items: UiListItemType<T>[] = [],
  onItemsOrderChange?: (items: UiListItemType<T>[]) => void,
) => {
  const ensureScrollVisibility = useEnsureElementScrollVisibility({
    closestTargetElement: 'ui5-li-custom',
  });
  const moveItemByValue = useMemo(() => moveListItem(items, 'value'), [items]);

  return useCallback(
    (value: string, direction: MoveDirection, element?: HTMLElement | null) => {
      if (!items) return;

      const newItems = moveItemByValue(value, direction);
      onItemsOrderChange?.(newItems);
      if (element) ensureScrollVisibility(element);
    },
    [items, onItemsOrderChange, moveItemByValue],
  );
};
