import { useMemo } from 'react';

import { CellProps } from '@UiCommon/Components/UiDraggableTable/UiDraggableTable';
import { useGHGTranslation } from '@GlobalProviders/i18n/hooks';
import { ProjectSplitScope } from '@DataAccess/CoreLib/SplitRatio/splitRatio.types';

import { ProjectCell } from './ProjectCell';
import { ProjectSplitScopeCell } from './ProjectSplitScopeCell';
import { ProjectActions } from './ProjectActions';
import {
  RULE_ORDER_NUMBER_COLUMN_ID,
  SPLIT_SCOPE_1_EMISSIONS_COLUMN_ID,
  SPLIT_SCOPE_2_EMISSIONS_COLUMN_ID,
} from '../Utils/constants';

export const useProjectCustomizationColumns = () => {
  const { t } = useGHGTranslation();

  return useMemo(
    () => [
      {
        id: RULE_ORDER_NUMBER_COLUMN_ID,
        header: 'Rule Order No.',
        cell: (props: CellProps<ProjectSplitScope>) => {
          return <>{props.row.index + 1}</>;
        },
      },
      {
        id: 'naceCode',
        header: t('splitScope.project.naceCode'),
        accessorKey: 'naceCode',
        cell: (props: CellProps<ProjectSplitScope>) => {
          return (
            <ProjectCell
              isEditable={!props.row.original.isDefault}
              value={props.row.original.naceCode}
              index={props.row.index}
              id='naceCode'
            />
          );
        },
      },
      {
        id: 'secondaryClassificationCode',
        header: t('splitScope.project.secondaryClassificationCode'),
        accessorKey: 'secondaryClassificationCode',
        cell: (props: CellProps<ProjectSplitScope>) => {
          return (
            <ProjectCell
              isEditable={!props.row.original.isDefault}
              value={props.row.original.secondaryClassificationCode}
              index={props.row.index}
              id='secondaryClassificationCode'
            />
          );
        },
      },
      {
        id: 'country',
        header: t('splitScope.project.country'),
        accessorKey: 'country',
        cell: (props: CellProps<ProjectSplitScope>) => {
          return (
            <ProjectCell
              isEditable={!props.row.original.isDefault}
              value={props.row.original.country}
              index={props.row.index}
              id='country'
            />
          );
        },
      },
      {
        id: 'category',
        header: t('splitScope.project.category'),
        accessorKey: 'category',
        cell: (props: CellProps<ProjectSplitScope>) => {
          return (
            <ProjectCell
              isEditable={!props.row.original.isDefault}
              value={props.row.original.category}
              index={props.row.index}
              id='category'
            />
          );
        },
      },
      {
        id: SPLIT_SCOPE_1_EMISSIONS_COLUMN_ID,
        header: t('splitScope.project.splitScope1Emissions'),
        accessorKey: 'splitScope1Emissions',
        cell: (props: CellProps<ProjectSplitScope>) => {
          return (
            <ProjectSplitScopeCell
              isEditable={!props.row.original.isDefault}
              value={props.row.original.splitScope1Emissions}
              index={props.row.index}
              id={SPLIT_SCOPE_1_EMISSIONS_COLUMN_ID}
            />
          );
        },
      },
      {
        id: SPLIT_SCOPE_2_EMISSIONS_COLUMN_ID,
        header: t('splitScope.project.splitScope2Emissions'),
        accessorKey: 'splitScope2Emissions',
        cell: (props: CellProps<ProjectSplitScope>) => {
          return (
            <ProjectSplitScopeCell
              isEditable={!props.row.original.isDefault}
              value={props.row.original.splitScope2Emissions}
              index={props.row.index}
              id={SPLIT_SCOPE_2_EMISSIONS_COLUMN_ID}
            />
          );
        },
      },
      {
        header: '',
        id: 'actions',
        size: 50,
        enableHiding: true,
        cell: (props: CellProps<ProjectSplitScope>) => <ProjectActions index={props.row.index} />,
      },
    ],
    [t],
  );
};
