import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';
import { ThemingParameters } from '@ui5/webcomponents-react-base';
import { ReactNode } from 'react';

import { IconSet } from '@GlobalProviders/IconSetProvider';
import { CommonProps } from '@UiCommon/types';

import { UiIcon } from '../../../../Components/UiIcon';
import { useNavigationPanelContext } from '../NavigationPanelContext';
import { StyleProps } from '../types';

const StyledLink = styled(NavLink)`
  cursor: pointer;
  text-decoration: none;
  margin-top: 1rem;
`;

const IconWrapper = styled.div`
  width: 1rem;
  height: 1rem;
`;

const Label = styled.span<Partial<NavigationItemProps>>`
  font-size: ${ThemingParameters.sapFontSize};
  font-family: ${ThemingParameters.sapFontFamily};
  font-weight: ${({ isSubItem }) => (isSubItem ? 300 : 700)};
  color: ${ThemingParameters.sapShell_Category_15_Background};
  margin: 0 auto 0 0.5rem;
`;

const LinkWrapper = styled.div<StyleProps>`
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  border-radius: 6px;
  ${({ isExtended }) => isExtended && 'padding-right: 0.7rem; padding-left: 0.5rem;'}

  :hover {
    background-color: ${ThemingParameters.sapBackgroundColor};
  }

  :active {
    background-color: ${ThemingParameters.sapAccentBackgroundColor6};
  }

  ${({ isActive }) =>
    isActive && `background-color: ${ThemingParameters.sapAccentBackgroundColor6}`}
`;

const Dot = styled.div`
  border-radius: 50%;
  width: 0.5rem;
  height: 0.5rem;
  background: ${ThemingParameters.sapShell_Category_1_Background};
`;

export type NavigationItemProps = CommonProps & {
  pathTo: string;
  label: string;
  iconName?: IconSet;
  isSubItem?: boolean;
  children?: ReactNode | ReactNode[];
};

export const NavigationItem = ({
  pathTo,
  iconName,
  label,
  isSubItem,
  className,
}: NavigationItemProps) => {
  const { isPanelExtended } = useNavigationPanelContext();

  return (
    <StyledLink className={className} to={pathTo}>
      {({ isActive }) => (
        <LinkWrapper isExtended={isPanelExtended} isActive={isActive}>
          <>
            <IconWrapper>{iconName && <UiIcon name={iconName} />}</IconWrapper>
            {isPanelExtended && <Label isSubItem={isSubItem}>{label}</Label>}
            {isActive && isPanelExtended && <Dot data-testid='dot' />}
          </>
        </LinkWrapper>
      )}
    </StyledLink>
  );
};
