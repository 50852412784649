import { ProgressIndicator, ValueState } from '@ui5/webcomponents-react';

import { CommonProps } from '../types';

type Props = CommonProps & {
  value: number;
  valueState: keyof typeof ValueState;
};

export const UiProgressIndicator = ({ ...props }: Props) => <ProgressIndicator {...props} />;
