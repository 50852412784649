import { useMemo } from 'react';

import { useProcessManagerTranslation } from '@GlobalProviders/i18n/hooks';
import { UiTableColumnDataType } from '@UiCommon/Components/UiAnalyticalTable/types';

import { useCommonCollectedColumns } from '../useCommonCollectedColumns';

export const useEmissionBuildingCertificatesColumns = () => {
  const { t } = useProcessManagerTranslation();
  const commonColumns = useCommonCollectedColumns();

  return useMemo(
    () => [
      {
        label: t('processManager.manualUpload.emissionBuildingCertificates.table.countryRegion'),
        accessor: 'countryRegion',
        id: 'countryRegion',
        isVisible: true,
        beField: 'COUNTRY_REGION',
      },
      {
        label: t('processManager.manualUpload.emissionBuildingCertificates.table.realEstateType'),
        accessor: 'realEstateType',
        id: 'realEstateType',
        minWidth: 104,
        isVisible: true,
        beField: 'REAL_ESTATE_TYPE',
      },
      {
        label: t('processManager.manualUpload.emissionBuildingCertificates.table.certificateClass'),
        accessor: 'certificateClass',
        id: 'certificateClass',
        minWidth: 120,
        isVisible: true,
        beField: 'CERTIFICATE_CLASS',
      },
      {
        label: t('processManager.manualUpload.emissionBuildingCertificates.table.emissionFactor'),
        accessor: 'emissionFactor',
        id: 'emissionFactor',
        minWidth: 100,
        isVisible: true,
        beField: 'EMISSION_FACTOR',
        type: UiTableColumnDataType.NUMBER,
      },
      {
        label: t('processManager.manualUpload.shared.year'),
        accessor: 'year',
        id: 'year',
        isVisible: true,
        beField: 'YEAR',
        type: UiTableColumnDataType.NUMBER,
      },
      ...commonColumns,
    ],
    [t, commonColumns],
  );
};
